/*-----------------------------------------------------------------
- Comment Routes
-----------------------------------------------------------------*/
const prefix = 'amin';
const routes = [
    {
        path     : `${prefix}/members`,
        name     : 'amin-members',
        component: () => import(/* webpackChunkName: "amin" */ '@/modules/amin/views/Members'),
        
    },
    {
        path     : `${prefix}/wallets`,
        name     : 'amin-wallets',
        component: () => import(/* webpackChunkName: "amin" */ '@/modules/amin/views/Wallets'),
    },
    {
        path     : `${prefix}/records/:id`,
        name     : 'amin-records',
        component: () => import(/* webpackChunkName: "amin" */ '@/modules/amin/views/Records'),
    },
    {
        path     : `${prefix}/meetings`,
        name     : 'amin-meetings',
        component: () => import(/* webpackChunkName: "amin" */ '@/modules/amin/views/Meetings'),
    },
    {
        path     : `${prefix}/costs`,
        name     : 'amin-costs',
        component: () => import(/* webpackChunkName: "amin" */ '@/modules/amin/views/Costs'),
    },
];

export default routes;
