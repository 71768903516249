<template>
    <swiper
        ref="swiper"
        class="slideshow"
        :options="swiperOption"
        :style="{height: height}"
        :class="classes"
        @ready="$emit('ready')"
    >
        <!-- slides -->
        <slot />

        <!-- Optional controls -->
        <div
            v-if="hasPagination"
            slot="pagination"
            class="slideshow-pagination"
        />

        <div
            v-if="hasNavigation"
            slot="button-prev"
            :class="buttonPrev"
        >
            <span class="icon-wrap">
                <i
                    class="icon"
                    :class="navPrev"
                />
            </span>
        </div>
        <div
            v-if="hasNavigation"
            slot="button-next"
            :class="buttonNext"
        >
            <span class="icon-wrap">
                <i
                    class="icon"
                    :class="navNext"
                />
            </span>
        </div>
    </swiper>
</template>

<script>
    // eslint-disable-next-line import/no-extraneous-dependencies
    import 'swiper/css/swiper.css';
    import { Swiper } from 'vue-awesome-swiper';

    export default {
        name: 'Slideshow',

        components: {
            Swiper,
        },

        props: {
            /**
             * Slideshow height
             */
            height: {
                type   : String,
                default: '100vh',
            },

            /**
             * Slideshow Lazy Loading Images
             */
            lazy: {
                type   : Boolean,
                default: false,
            },

            /**
             * Set slideshow height automatically
             */
            autoHeight: {
                type   : Boolean,
                default: false,
            },

            /**
             * Space between slides
             */
            spaceBetween: {
                type   : Number,
                default: 0,
            },

            /**
             * Slides shown per view
             */
            slidesPerView: {
                type   : [String, Number],
                default: 'auto',
            },

            /**
             * Slides shown in columns
             */
            slidesPerColumn: {
                type   : [String, Number],
                default: 1,
            },

            /**
             * Enable loop through slides
             */
            loop: {
                type   : Boolean,
                default: false,
            },

            /**
             * Transition effects
             */
            effect: {
                type   : String,
                default: 'slide',
            },

            /**
             * Slideshow direction
             */
            direction: {
                type   : String,
                default: 'horizontal',
            },

            /**
             * Controls Pagination type
             */
            pagination: {
                type: [String, Number],
            },

            /**
             * Specifies pagination element
             */
            paginationEl: {
                type   : String,
                default: '.slideshow-pagination',
            },

            /**
             * Controls Pagination background
             */
            paginationNoBg: {
                type   : Boolean,
                default: false,
            },

            /**
             * Controls navigation type
             */
            nav: {
                type: [String, Number],
            },

            /**
             * Sets right nav icon
             */
            navRight: {
                type   : String,
                default: 'css-arrow',
            },

            /**
             * Sets left nav icon
             */
            navLeft: {
                type   : String,
                default: 'css-arrow',
            },

            /**
             * Specifies navigation elements
             */
            navigationEL: {
                type   : String,
                default: '.slideshow-nav-next,.slideshow-nav-prev',
            },

            /**
             * Controls slideshow auto-play
             */
            autoplay: {
                type   : Boolean,
                default: false,
            },

            /**
             * Controls slideshow speed
             */
            speed: {
                type   : String,
                default: '300',
            },

            /**
             * Controls slideshow interval between changes
             */
            delay: {
                type   : String,
                default: '5000',
            },

            /**
             * Disables auto-play on interaction
             */
            disableOnInteraction: {
                type   : Boolean,
                default: false,
            },

            /**
             * Specifies number of slides to be viewed when auto-play is on
             */
            loopedSlides: {
                type: String,
            },

            /**
             * Center slides
             */
            centeredSlides: {
                type   : Boolean,
                default: false,
            },

            /**
             * Other Swipper Options
             */
            otherOptions: {
                type   : Object,
                default: () => {
                },
            },
        },

        computed: {

            /**
             * Swiper slideshow options
             */
            swiperOption() {
                return {
                    speed          : Number(this.speed),
                    lazy           : this.lazy,
                    autoHeight     : this.autoHeight,
                    spaceBetween   : this.spaceBetween,
                    slidesPerView  : this.slidesPerView,
                    slidesPerColumn: this.slidesPerColumn,
                    loop           : this.loop,
                    effect         : this.effect,
                    direction      : this.direction,
                    loopedSlides   : this.loopedSlides,
                    centeredSlides : this.centeredSlides,
                    grabCursor     : true,

                    pagination: {
                        el       : this.paginationEl,
                        clickable: true,
                    },

                    navigation: {
                        nextEl: '',
                        prevEl: '',
                    },

                    autoplay: this.autoplay ? {
                        delay               : Number(this.delay),
                        disableOnInteraction: this.disableOnInteraction,
                    } : false,

                    flipEffect: {
                        slideShadows: false,
                    },

                    cubeEffect: {
                        slideShadows: false,
                    },

                    coverflowEffect: {
                        slideShadows: true,
                    },

                    ...this.otherOptions,
                };
            },

            /**
             * Next nav icon
             */
            navNext() {
                return this.navLeft;
            },

            /**
             * Previous nav icon
             */
            navPrev() {
                return this.navRight;
            },

            /**
             * Checks if pagination is active
             */
            hasPagination() {
                return !!this.pagination;
            },

            /**
             * Checks id navigation is active
             */
            hasNavigation() {
                return !!this.nav;
            },

            /**
             * Creates navigation class based on required nav type
             */
            navigationStyle() {
                if (this.nav && !!this.nav) {
                    return `nav-${this.nav}`;
                }
                return '';
            },

            /**
             * Creates pagination class based on required pagination type
             */
            paginationStyle() {
                if (this.pagination && !!this.pagination) {
                    return `pagination-${this.pagination}`;
                }
                return '';
            },

            /**
             * Creates no-bg class for pagination if required
             */
            paginationNoBgClass() {
                if (this.paginationNoBg) {
                    return 'no-bg';
                }

                return '';
            },

            /**
             * Merge all slideshow classes
             */
            classes() {
                const classes = [];

                classes.push(this.paginationStyle);
                classes.push(this.navigationStyle);
                classes.push(this.paginationNoBgClass);

                return classes.join(' ');
            },

            /**
             * Prev button class
             */
            buttonPrev() {
                const classes = [];
                
                classes.push(this.swiperOption.navigation.prevEl.split('-prev')[0].replace('.', ''));
                classes.push(this.swiperOption.navigation.prevEl.replace('.', ''));

                return classes.join(' ');
            },

            /**
             * Next button class
             */
            buttonNext() {
                const classes = [];
                
                classes.push(this.swiperOption.navigation.nextEl.split('-next')[0].replace('.', ''));
                classes.push(this.swiperOption.navigation.nextEl.replace('.', ''));

                return classes.join(' ');
            },

        },

        watch: {
            /**
             * Updates Slideshow if options change
             */
            swiperOption() {
                this.$refs.swiper.swiper.update();
                if (this.autoplay) {
                    this.$refs.swiper.swiper.autoplay.start();
                }
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.setNavClasses();
        },

        methods: {

            /**
             * Finds nav element class
             *
             * @param eq
             * @returns {*|string}
             */
            navElementClass(eq) {
                return this.navigationEL.split(',')[eq];
            },

            /**
             * Set nav class for slideshow options
             */
            setNavClasses() {
                this.swiperOption.navigation.nextEl = this.navElementClass(0);
                this.swiperOption.navigation.prevEl = this.navElementClass(1);
            },

        },
    };
</script>
