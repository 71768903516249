/*-----------------------------------------------------------------
- Global guards
-----------------------------------------------------------------*/
import project from '@configs/project';

/**
 * beforeEach navigation guard
 *
 * @param {object} to
 * @param {object} from
 * @param {Function} next
 */
const beforeEach = (to, from, next) => {
    if (to.name?.startsWith('auth-') && localStorage.getItem('token') && localStorage.getItem(`${project.name}.auth.isLoggedIn`)) {
        next({ name: 'home' });
        return;
    }
    if (to.meta?.guest === false && !localStorage.getItem('token') && !localStorage.getItem(`${project.name}.auth.isLoggedIn`)) {
        const params = {
            name  : to.name,
            params: { ...to.params },
            query : { ...to.query },
        };
        localStorage.setItem('redirect', JSON.stringify(params));
        next({ name: 'auth-login', params: { locale: to.params.locale } });
        return;
    }
    next();
};

/**
 * beforeEach navigation guard
 *
 * @param {object} to
 * @param {object} from
 */
const afterEach = (to, from) => { // eslint-disable-line no-unused-vars
    // Put your guard actions here
};

export default {
    beforeEach,
    afterEach,
};
