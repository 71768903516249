<template>
    <div
        v-if="valueContent"
        class="form-email-label"
    >
        <i
            v-if="icon"
            class="icon"
            :class="[icon]"
        />
        <span
            v-if="prefix"
            class="prefix"
        >{{ prefix }}</span>
        <a
            target="_top"
            :href="`mailto:${valueContent}`"
        >{{ valueContent }}</a>
        <y-copy-value
            v-if="copy"
            :value="copy && typeof copy === 'string' ? copy : valueContent"
        />
    </div>
</template>

<script>

    import ComponentClassesMixin from '@/mixins/ComponentClasses';
    import YCopyValue from '@deps/CopyValue';

    export default {

        name: 'YFormEmailLabel',

        components: {
            YCopyValue,
        },

        mixins: [ComponentClassesMixin],

        props: {
            /**
             * Element
             */
            element: Object,

            content: String,

            icon: String,

            prefix: String,

            copy: [String, Boolean],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                valueContent: this.content || this.valueContent,
            };
        },

    };
</script>
