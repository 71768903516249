/*-----------------------------------------------------------------
- Shipping trans
-----------------------------------------------------------------*/
export default {

    title: {
        single       : 'درخواست آنلاین',
        details      : 'جزئیات درخواست',
        items        : 'ویژگی های درخواست',
        change_status: 'تغییر وضعیت',
    },

    details: {
        company_size : 'تعداد پرسنل شرکت',
        favorite_site: 'سایت های مورد علاقه',
        phone        : 'تلفن',
        status       : 'وضعیت',
        user_position: 'سمت کاربر',
        website      : 'وب سایت',
    },

    change_status: {
        message: 'تغییر وضعیت',
    },
};
