/*-----------------------------------------------------------------
+- Fields translations
-----------------------------------------------------------------*/
export default {
    calendar: {
        setting: {
            starts_at        : 'شروع دوره',
            ends_at          : 'پایان دوره',
            opening_time     : 'ساعت شروع روز کاری',
            closing_time     : 'ساعت پایان روز کاری',
            off_days         : 'روزهای تعطیل',
            off_week_days    : 'روزهای تعطیل هفته',
            show_months_limit: 'محدودیت ماه‌ها',
        },
    },
};
