/*-----------------------------------------------------------------
- Divisions Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Division Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    fetch: (...args) => Resource.get('divisions-fetch', args),
    
    /**
     * Division Save
     *
     * @param {...any} args
     * @returns {*}
     */
    save: (...args) => Resource.post('divisions-save', args),
    
    /**
     * Division Trash (Delete)
     *
     * @param {...any} args
     * @returns {*}
     */
    trash: (...args) => Resource.delete('divisions-trash', args),
    
    /**
     * Division Restore (Undelete)
     *
     * @param {...any} args
     * @returns {*}
     */
    restore: (...args) => Resource.put('divisions-restore', args),
    
    /**
     * Division Destroy (Permanent Delete)
     *
     * @param {...any} args
     * @returns {*}
     */
    destroy: (...args) => Resource.delete('divisions-destroy', args),
    
    /**
     * Division  Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    divisionsGrid: (...args) => Resource.get('divisions-grid', args),

};
