/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    tagging: {
        create    : 'دسته‌ی جدید با موفقیت ساخته شد.',
        update    : 'دسته با موفقیت به‌روزرسانی شد.',
        save_order: 'مرتب‌سازی دسته‌ها با موفقیت ذخیره شد.',
    },
    
};
