<template>
    <y-form-field-wrapper v-bind="fieldWrapperProps">
        <editor
            v-if="configured"
            :id="editorId"
            :key="editorId"
            :api-key="apiKey"
            :init="config"
            :value="value"
            :class="[{panel: toolbar === 'inline'}, 'p20']"
            :tinymce-script-src="tinymceUrl"
            @input="onEditorChange"
            @onBeforeSetContent="onBeforeSetContent"
        />
    </y-form-field-wrapper>
</template>

<script>
    /* eslint-disable max-len */
    import { generateId } from '@nodes/helpers/string';
    import FormElementMixin from '@/mixins/FormElement';
    import Editor from '@tinymce/tinymce-vue';

    /**
     * Textarea Component
     */
    export default {

        name: 'YFormEditor',

        components: {
            Editor,
        },

        mixins: [FormElementMixin],

        props: {

            /**
             * component type, required by YFormFieldWrapper
             */
            component: {
                type   : String,
                default: 'editor',
            },

            /**
             * Custom toolbar items
             */
            customToolbar: {
                type   : String,
                default: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ltr rtl alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | insertfile image media link',
            },

            /**
             * Custom font formats
             */
            fonts: {
                type   : String,
                default: 'Vazir=Vazir; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
            },

            /**
             * Default font
             */
            defaultFont: {
                type   : String,
                default: 'Vazir',
            },

            /**
             * Menubar control
             */
            menubar: {
                type   : Boolean,
                default: true, // eslint-disable-line vue/no-boolean-default
            },

            /**
             * Height of editor
             */
            height: {
                type   : Number,
                default: 500,
            },

            /**
             * Language of editor
             */
            locale: {
                type: String,
            },

            /**
             * Preset of configs for editor toolbar. Can be: full, mini, comment, and inline
             */
            toolbar: String,

            /**
             * TinyCloud API Key
             */
            apiKey: String,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                editorId: generateId(),

                config: {
                    height: this.height,

                    plugins: [
                        'print preview importcss searchreplace autolink',
                        'save directionality visualblocks visualchars fullscreen',
                        'image link media template codesample table charmap',
                        'hr pagebreak nonbreaking toc insertdatetime advlist lists',
                        'wordcount imagetools textpattern noneditable',
                        'help charmap',
                        'quickbars emoticons code table',
                    ],

                    image_advtab: true,
                    
                    font_formats: this.fonts,

                    images_upload_handler: this.imageUploader,

                    content_style: `html > body { font-family: ${this.defaultFont}; } img {max-width: 100%; height: auto}`,
                    
                    quickbars_insert_toolbar: this.toolbar === 'full',

                    menubar: this.menubar ? 'file edit view insert format tools table' : false,

                    toolbar: this.customToolbar,

                    image_caption: true,

                    automatic_uploads: true,
                    file_picker_types: 'image',

                    content_css: `${window.location.origin}/tinymce/css/customize.css`,
                },

                supportedLanguages: ['fa_IR'],

                configured: false,

                tinymceUrl: `${window.location.origin}/tinymce/js/tinymce/tinymce.min.js`,
            };
        },

        /**
         * @inheritDoc
         */
        mounted() {
            // Set language
            this.setLanguage();

            // Set presets
            this.setPreset();

            this.configured = true;
        },

        /**
         * @inheritDoc
         */
        beforeDestroy() {
            this.configured = false;
            this.editorId = generateId();
        },

        methods: {

            /**
             * Emit change event
             *
             * @param event
             */
            onEditorChange(event) {
                this.$emit('input', event);
            },

            /**
             * Editor BeforeSetContent event 
             * Using to fix class='' issue on setContent issue
             * More info: https://git.yasna.team/yasnateam/cms-board/-/issues/8343
             *
             * @param event
             */
            onBeforeSetContent(event) {
                if (event.content.includes('class=')) {
                    // eslint-disable-next-line no-param-reassign
                    event.content += '<p></p>';
                }
            },

            /**
             * Set language of the editor
             */
            setLanguage() {
                const languages = {
                    fa: 'fa_IR',
                    en: 'en_US',
                };
                const locale = this.locale || this.$i18n.locale;
                this.config.language = languages[locale];

                if (this.config.language !== 'en_US' && this.supportedLanguages.includes(this.config.language)) {
                    this.config.language_url = `${window.location.origin}/tinymce-languages/${this.config.language}.js`;
                }

                this.config.directionality = locale === 'fa' ? 'rtl' : 'ltr';
            },

            /**
             * Config editor based on presets
             */
            setPreset() {
                if (!this.toolbar) {
                    return;
                }

                if (this.toolbar === 'full') {
                    this.config.toolbar = 'undo redo | bold italic code underline strikethrough superscript subscript | fontselect fontsizeselect formatselect | ltr rtl alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | insertfile image media link table';
                    this.config.menubar = 'file edit view insert format tools table';
                }

                if (this.toolbar === 'mini') {
                    this.config.toolbar = 'undo redo | bold italic superscript subscript code | fontsizeselect | ltr rtl alignleft aligncenter alignright alignjustify | numlist bullist | forecolor backcolor';
                    this.config.menubar = false;
                }

                if (this.toolbar === 'comment') {
                    this.config.toolbar = 'undo redo | bold italic | ltr rtl alignleft alignright | numlist bullist | link';
                    this.config.menubar = false;
                }

                if (this.toolbar === 'inline') {
                    this.config.toolbar = 'undo redo | bold italic | ltr rtl alignleft alignright | numlist bullist | link';
                    this.config.menubar = false;
                    this.config.inline = true;
                }
            },

            /**
             * Callback for tinyMCE image uploader
             *
             * @callback imageUploaderCallback
             * Upload image from tinyMCE editor
             * @param {Blob} blobInfo - blob info of selected image.
             * @param {imageUploaderCallback} success - A success callback to run.
             * @param {imageUploaderCallback} failure - A failure callback to run.
             */
            imageUploader(blobInfo, success, failure) {
                const formData = new FormData();
                formData.append('file', blobInfo.blob(), blobInfo.filename());
                this.$services.Uploader.upload(formData).then((response) => {
                    success(response.data.metadata.link);
                }).catch((error) => {
                    failure(error.userMessage);
                });
            },
        },

    };

</script>
