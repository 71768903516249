/*-----------------------------------------------------------------
- Wares trans
-----------------------------------------------------------------*/
export default {
    
    grid: {
        used_count: 'تعداد کلیک',
        actions   : {
            copy  : 'کپی کردن',
            share : 'اشتراک‌گذاری',
            qrcode: 'کد دو‌بعدی',
        },
    },

    modal: {
        title        : 'اشتراک‌گذاری کد د‌و‌بعدی',
        actions_title: 'اشتراک‌گذاری',
    },
    
};
