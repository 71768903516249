/*-----------------------------------------------------------------
- Coupons trans
-----------------------------------------------------------------*/
export default {

    title: {
        create: 'ایجاد سناریوی جدید',
        edit  : 'ویرایش سناریو',
    },

    code_generate: {
        title         : 'ساخت کد‌های تخفیف جدید',
        prefix        : 'پیشوند',
        postfix       : 'پسوند',
        pattern       : 'الگو',
        length        : 'تعداد کاراکتر الگو',
        count         : 'تعداد کد تخفیف',
        discount_code : 'کد تخفیف',
        preview       : 'نمونه کد',
        pattern_select: {
            numeric: 'الگوی عددی',
            alpha  : 'الگوی الفبایی',
            mix    : 'الگوی مخلوط',
        },
        generated : 'کد‌های تخفیف با موفقیت ساخته شدند.',
        max_length: 'تعداد طول مجاز {0} کاراکتر است.',
    },
    
    editor: {
        amount: {
            panel         : 'میزان تخفیف',
            fee           : 'مقدار تخفیف',
            fee_number    : 'حداکثر مبلغ تخفیف',
            percent       : 'درصد تخفیف',
            percent_number: 'درصد',
            percent_max   : 'تا سقف',
        },
        
        group: {
            panel: 'انتخاب افراد مجاز',
            user : 'افراد خاص',
            role : 'نقش‌های خاص',
            all  : 'تمام کاربران',
        },
        
        limitation: {
            panel    : 'محدودیت استفاده',
            once     : 'فقط یک ‌بار',
            many     : 'بیش از یک بار',
            unlimited: 'نامحدود',
        },
        
        messages: {
            success: 'سناریو ذخیره شد.',
            user   : 'کدهای تخفیف برای تمام کاربران قابل استفاده خواهند بود.',
            limit  : 'کدهای تخفیف، بارها و بارها قابل استفاده خواهند بود.',
        },

        rules: {
            rules          : 'قوانین',
            available_rules: 'قوانین موجود',
            selected_rules : 'قوانین انتخاب‌شده',
            rule_settings  : 'تنظیمات قانون',
            empty          : 'قانونی یافت نشد.',
        },
    },

    code_tracking: {
        search       : 'جستجوی کد تخفیف',
        empty        : 'هیچ کد تخفیفی پیدا نشد.',
        search_again : 'جستجوی مجدد',
        search_button: 'جستجو',
        result       : {
            scenario: 'سناریوی تخفیف',
            allowed : {
                title: 'استفاده‌کننده‌های مجاز',
                user : 'کاربران مجاز',
                role : 'نقش‌های مجاز',
                all  : 'همه کاربران',
            },
            amount        : 'حداکثر مبلغ تخفیف',
            percent       : 'درصد تخفیف',
            total_discount: 'جمع تخفیف‌های داده شده',
            allowed_usage : 'تعداد دفعات مجاز',
            usage         : 'تعداد دفعات استفاده شده',
            remain        : 'تعداد دفعات باقی‌مانده',
            users         : 'کاربران',
        },
    },
        
    batch: {
        title: 'گروه‌های تخفیف',
    },
    
};
