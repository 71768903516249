/*-----------------------------------------------------------------
- Fluorocarbon translations
-----------------------------------------------------------------*/
export default {
    calendar: {
        title: {
            calendar  : 'مشاوره‌ها',
            setting   : 'تنظیمات تقویم',
            day_booked: 'رویداد‌های روز {0}',
        },

        booked: {
            info_form_title: 'اطلاعات رزرو',
            button         : 'رزرو',

            days: {
                sunday   : 'یکشنبه',
                monday   : 'دوشنبه',
                tuesday  : 'سه‌شنبه',
                wednesday: 'چهارشنبه',
                thursday : 'پنج‌شنبه',
                friday   : 'جمعه',
                saturday : 'یکشنبه',
            },
        },

        setting: {
            message: {
                success: 'تنظیمات با موفقیت ذخیره شد.',
            },
        },

        message: {
            success: {
                free: 'رزرو با موفقیت خالی شد.',
                book: 'ساعت مورد نظر با موفقیت رزرو شد.',
            },
        },
    },
};
