/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    menu: {
        list: 'Menu Maker',
        edit: 'Manage Menu',
    },
    
};
