/*-----------------------------------------------------------------
- Form handler mixin
-----------------------------------------------------------------*/

export default {
    
    name: 'FormHandler',
    
    /**
     * @inheritDoc
     */
    data() {
        return {
            model: {},
            
            loading: 'submitting-form',
        };
    },
    
    /**
     * @inheritDoc
     */
    created() {
        this.$validator.reset();
        this.$validator.locale = this.$i18n.locale;
    },
    
    computed: {
        /**
         * Check if form is submitting
         */
        isSubmittingForm() {
            return Boolean(this.$wait.is(this.loading));
        },
    },
    
    methods: {
        
        /**
         * Submit form
         *
         * @param method
         * @param scope
         */
        submit(method, scope = 'form') {
            this.loading = `submitting-${scope}`;
            this.$validator.resume();
            this.$validator.validateAll(scope).then((valid) => {
                if (valid) {
                    this.$validator.pause();
                    this.enableSubmitting();
                    
                    if (typeof method === 'function') {
                        method().then(() => this.disableSubmitting());
                    } else {
                        this.disableSubmitting();
                    }
                } else {
                    this.handleValidationErrors();
                }
            });
        },
        
        /**
         * Enabling submitting form
         */
        enableSubmitting() {
            this.$wait.start(this.loading);
        },
        
        /**
         * Disable submitting form
         */
        disableSubmitting() {
            this.$wait.end(this.loading);
        },
        
        /**
         * Check model has a specific key
         *
         * @param key
         * @returns {boolean}
         */
        has(key) {
            return key in this.model;
        },
        
        /**
         * Handle validation errors method
         */
        handleValidationErrors() {
        },
        
    },
    
};
