<template>
    <y-panel :title="$t('specs.feature.title')">
        <y-form-field
            v-model="model.form_id"
            type="select"
            :options="options"
            allow-empty
            :placeholder="$t('specs.feature.select.placeholder')"
            :label="$t('specs.feature.select.label')"
            name="form"
        />

        <y-loading
            v-if="model.form_id"
            :active="$wait.is('specs-form-loading')"
            height="150"
        >
            <div v-if="model.form_id">
                <div
                    v-if="model.items && model.items.length"
                    class="specs-form"
                >
                    <table class="table bordered">
                        <tbody>
                            <template v-for="(group, groupIndex) in model.items">
                                <tr :key="`g-${group.id}`">
                                    <th
                                        class="specs-group-title"
                                        colspan="3"
                                    >
                                        {{ group.title }}
                                    </th>
                                </tr>
                                <tr
                                    v-for="(field, index) in group.children"
                                    :key="`f-${field.id}`"
                                >
                                    <th class="specs-field-active">
                                        <y-form-field
                                            :id="`f-a-${field.id}`"
                                            v-model="model.items[groupIndex].children[index].active"
                                            type="checkbox"
                                            no-label
                                            :name="`f-a-${field.id}`"
                                        />
                                    </th>
                                    <th class="specs-field-title">
                                        {{ field.title }}
                                    </th>
                                    <td class="specs-field-value">
                                        <y-form-field
                                            v-if="field.type === 'boolean'"
                                            :id="`f-ch-${field.id}`"
                                            v-model="model.items[groupIndex].children[index].value"
                                            type="checkbox"
                                            :name="`f-ch-${field.id}`"
                                            :label="$t('fields.specs.active')"
                                        />
                                        <y-form-field
                                            v-else
                                            v-model="model.items[groupIndex].children[index].value"
                                            :type="field.type"
                                            rows="5"
                                            no-label
                                            size="sm"
                                        />
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <y-empty-state
                    v-else
                    height="150"
                    class="mb0"
                    no-image
                    :title="$t('specs.feature.empty_form')"
                />
            </div>
        </y-loading>
    </y-panel>
</template>

<script>

    import { isEqual } from 'lodash';

    import YFormField from '@deps/form/FormField';
    import YEmptyState from '@deps/EmptyState';

    import { convertToSelect } from '@nodes/helpers/object';

    export default {

        name: 'YFormContentSpecs',

        components: {
            YFormField,
            YEmptyState,
        },

        props: {
            /**
             * Element of slug
             */
            element: Object,

            /**
             * Input Value
             */
            value: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value ? { ...this.value, lang: this.element.locale } : {},
            };
        },

        computed: {

            /**
             * Specs forms options
             */
            options() {
                return convertToSelect(this.element.forms);
            },

        },

        /**
         *
         */
        watch: {

            /**
             * Watch value and change model
             *
             * @param val
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(newVal, oldVal) {
                    if (!isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            /**
             * Watch model and emit on change
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

            /**
             * Watch form id and fetch form
             *
             * @param val
             */
            'model.form_id': {
                handler(val) { // eslint-disable-line func-names,require-jsdoc
                    if (val) {
                        const params = {
                            form_id: this.model.form_id,
                            post_id: this.$route.params.id || null,
                        };
                        this.$wait.start('specs-form-loading');
                        this.$services.Specs.valuesForm(params).then((response) => {
                            this.$set(this.model, 'items', this.sort(response.data.results.items));
                        }).catch((error) => {
                            this.handleError(error);
                        }).finally(() => this.$wait.end('specs-form-loading'));
                    }
                },
                immediate: true,
            },

        },

        methods: {

            /**
             * Sort items
             *
             * @param items
             */
            sort(items) {
                const results = items.sort(this.sortHelper);
                return results.map((item) => {
                    // eslint-disable-next-line no-param-reassign
                    item.children = item.children.sort(this.sortHelper).map((el) => {
                        if (!el.hasOwnProperty('active')) {
                            el.active = 1; // eslint-disable-line no-param-reassign
                        }
                        return el;
                    });
                    return item;
                });
            },

            /**
             * Sort helper
             *
             * @param a
             * @param b
             */
            sortHelper(a, b) {
                return a.order > b.order ? 1 : -1;
            },

        },

    };
</script>
