/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    stock: {
        list  : 'Stock',
        prices: 'History',
    },
    
};
