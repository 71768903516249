/*-----------------------------------------------------------------
- Profile trans
-----------------------------------------------------------------*/
export default {
    
    generate_backup_codes: 'تولید کدهای پشتیبان',
    google_code          : 'کد فعال‌سازی',
    backup_codes         : 'کدهای پشتیبان',
    
    receive_channel: 'کانال دریافتی',
    recovery_token : 'کد بازیابی گذرواژه',

    roles: {
        is_unique             : 'یکتا باشد',
        display               : 'نمایش داده شود',
        required              : 'الزامی باشد',
        group                 : 'گروه‌بندی',
        order                 : 'ترتیب',
        multi_address         : 'قابلیت چند‌آدرسی فعال باشد.',
        multi_phone           : 'قابلیت چند‌تلفنی فعال باشد.',
        has_country_code      : 'موبایل به همراه کد کشور دریافت شود.',
        phone_has_country_code: 'تلفن به همراه کد کشور دریافت شود.',
        phone_validation      : 'اعتبارسنجی تلفن انجام شود.',
    },

    request_password_recovery: 'درخواست بازیابی رمز عبور',
    request_otp              : 'ورود با رمز یکبار مصرف',
    select_channel           : 'مسیر ارسال',
    verify_token             : 'کد دریافتی خود را وارد کنید',

};
