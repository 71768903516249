/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/
export default {
    
    page_head: 'منوساز: {title}',
    
    type    : 'منو',
    itemType: 'آیتم منو',
    
    custom_link: 'لینک دلخواه',
    
    empty_state: 'اولین گزینه‌ی منوی خود را از سمت راست اضافه کنید.',
    
    add_item : 'افزودن به منو',
    edit_item: 'ویرایش گزینه منو',
    
    add_item_ok: 'گزینه‌ی جدید به منو اضافه شد.',
    
    save_item_ok: 'گزینه‌ی منو با موفقیت بروزرسانی شد.',
    
    manage_items: 'مدیریت گزینه‌های منو',

};
