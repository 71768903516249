/*-----------------------------------------------------------------
- Content Label General Locales
-----------------------------------------------------------------*/
export default {
    title: {
        singular: 'دسته‌بندی مطالب',
    },

    type: 'دسته‌بندی',

    label: {
        title         : 'دسته‌ها',
        create        : 'افزودن دسته جدید',
        edit          : 'ویرایش دسته',
        delete        : 'حذف دسته',
        delete_confirm: 'آیا از حذف این دسته مطمئن هستید؟',
        titleFull     : 'دسته‌ها: {0}',
    },
    
    empty_state: {
        tag     : 'برچسبی یافت نشد. اولین را بسازید.',
        category: 'دسته‌ای یافت نشد. اولین را بسازید.',
    },

    feature: {
        title             : 'دسته‌بندی‌ها',
        no_folder         : 'دسته‌بندی یافت نشد',
        no_label          : 'دسته‌ای یافت نشد',
        select_placeholder: 'دسته‌های خود را انتخاب کنید',
    },
};
