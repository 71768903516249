/*-----------------------------------------------------------------
- App mixin to use in App.vue in every theme
-----------------------------------------------------------------*/

import '@nodes/mixins/Global';
import FaviconMixin from '@nodes/mixins/Favicon';

export default {

    name: 'AppMixin',

    mixins: [FaviconMixin],

    /**
     * Start `loading-page` before create
     */
    beforeCreate() {
        if (!this.$config('project.useToken')) {
            localStorage.removeItem('token');
        }
        this.$wait.start('loading-page');
    },

    /**
     * @inheritDoc
     */
    metaInfo() {
        return {
            title        : this.siteTitle,
            titleTemplate: this.siteTitle ? `%s ${this.$t('general.layout.separator')} ${this.siteTitle}` : '%s',
            link         : this.favicon,
        };
    },

    computed: {
        /**
         * return site title from setting
         *
         * @returns {*}
         */
        siteTitle() {
            return this.$settings('site_title.site_title', this.$t('general.layout.title'));
        },
    },

    /**
     * @inheritDoc
     */
    created() {
        // Set interceptors for axios to handle token error
        this.setInterceptors();
        this.setHeaderTimezone();
        if (this.$config('locales.locales').length === 1 || process.env.VUE_APP_PANEL === 'true') {
            this.setupAppLocale();
        }
    },

    watch: {
        /**
         * Watch state of user
         */
        '$store.state.auth.locale': function () { // eslint-disable-line func-names
            this.setupAppLocale();
        },
        
        /**
         * Watch state of user timezone
         */
        '$store.state.auth.timezone': function () { // eslint-disable-line func-names
            this.setHeaderTimezone();
        },

        '$route.params.locale': {
            /**
             * Watch resolving route
             *
             * @param {*} val
             */
            handler(val) {
                if (val) {
                    if (!this.$config('locales.locales').includes(this.$route.params.locale)) {
                        this.$router.push({
                            name  : 'home',
                            params: { locale: this.$config('locales.locales')[0] },
                        });
                        return;
                    }

                    this.setupAppLocale();
                }
            },
            immediate: true,
        },

        favicon: {
            /**
             * Set Manifest
             * 
             * @param {Array} newVal - Array of icons
             * @param {Array} oldValue - Array of icons
             */
            handler(newVal, oldValue) {
                if (newVal && !this.isEqual(newVal, oldValue)) {
                    const myDynamicManifest = {
                        // eslint-disable-next-line global-require
                        ...require('@/configs/project.ts').manifest,
                        // eslint-disable-next-line no-param-reassign
                        icons    : newVal.map((item) => ({ ...item, src: item.href })),
                        start_url: `${window.location.origin}/`,
                        scope    : `${window.location.origin}/`,
                    };
                    const stringManifest = JSON.stringify(myDynamicManifest);
                    const blob = new Blob([stringManifest], { type: 'application/json' });
                    const manifestURL = URL.createObjectURL(blob);

                    const exist = document.querySelector('head > link[rel="manifest"]');
                    if (exist) {
                        exist.setAttribute('href', manifestURL);
                    } else {
                        const link = document.createElement('link');
                        link.rel = 'canonical';
                        link.href = manifestURL;
                        document.querySelector('head').appendChild(link);
                    }
                }
            },
            immediate: true,
            deep     : true,
        },
    },

    methods: {
        /**
         * Set app locale
         */
        setupAppLocale() {
            // Set i18n locale
            this.$i18n.locale = this.$store.getters['auth/locale'] || this.$route.params.locale || this.$config('locales.locales')[0];
            const locale = this.$config('locales.options').find((item) => item.code === this.$i18n.locale);

            // Set `dir` and `lang` attributes of html
            const html = document.querySelector('html');
            html.setAttribute('dir', locale.dir);
            html.setAttribute('lang', this.$i18n.locale);

            // Set axios locale header
            this.$http.defaults.headers.common.locale = this.$i18n.locale;
        },

        /**
         * Set user timezone
         */
        setHeaderTimezone() {
            this.$http.defaults.headers.common.timezone = this.$store.getters['auth/timezone'];
        },

        /**
         * Set new access token
         *
         * @param {object} response
         */
        setNewToken(response) {
            const accessToken = this.get(response, 'headers.new_access_token');
            if (accessToken) {
                this.$store.commit('auth/login', accessToken);
            }
        },

        /**
         * Set interceptors for axios
         */
        setInterceptors() {
            this.$http.interceptors.response.use((response) => {
                this.setNewToken(response);
                return response;
            }, (err) => {
                this.setNewToken(err.response);

                if (err.response.data.errorCode === 'endpoint-401' || err.response.data.errorCode === '401') {
                    const params = {
                        name  : this.$route.name,
                        params: { ...this.$route.params },
                        query : { ...this.$route.query },
                    };
                    return this.$store.dispatch('auth/setRedirect', params).then(() => {
                        this.$router.push({
                            name : 'auth-login',
                            query: { logout: 1 },
                        });
                    });
                }
                return Promise.reject(err);
            });
        },
    },

};
