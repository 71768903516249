/*-----------------------------------------------------------------
- Cart trans
-----------------------------------------------------------------*/
export default {
    
    coupon: {
        base: {
            title: 'Title',
        },
        
        amount: {
            radio         : 'Discount Amount',
            fee_number    : 'Max Discount Price',
            percent_number: 'Percent',
            percent_max   : 'Max',
        },
        
        group: {
            radio      : 'Allowed Group',
            search_user: 'Search Users',
            role       : 'Select Roles',
        },
        
        limitation: {
            radio: 'Usage Limitation',
            many : 'How Many time?',
        },
    },
    
};
