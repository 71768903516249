<template>
    <div class="links-grid-cell">
        <p>{{ element.title }}</p>

        <div class="row">
            <div class="col-sm-6">
                <div class="detail-item">
                    <div class="value dir-ltr">
                        {{ element.short_link }}
                    </div>
                    <div class="title">
                        {{ element.creation_date | date('YYYY/MM/DD', $i18n.locale) }}
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="detail-item">
                    <div class="value">
                        <y-form-amount
                            class="mb0 d-ib"
                            :element="{ value: element.used_count }"
                        />
                    </div>
                    <div class="title">
                        {{ $t('forwarder.grid.used_count') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="links-grid-cell__actions">
            <y-button @click="doCopy(element.short_link)">
                {{ $t('forwarder.grid.actions.copy') }}
            </y-button>
            <y-form-link
                type="button"
                :element="shareModal(element.short_link, element.title)"
            >
                {{ $t('forwarder.grid.actions.share') }}
            </y-form-link>
            <y-form-link
                type="button"
                :element="shareModal(element.short_link, element.title)"
            >
                {{ $t('forwarder.grid.actions.qrcode') }}
            </y-form-link>
        </div>
    </div>
</template>

<script>
    import YFormAmount from '@deps/form/elements/Amount';
    import YFormLink from '@deps/form/elements/Link';

    export default {
        name: 'YFormLinksGridCell',

        components: {
            YFormAmount,
            YFormLink,
        },

        props: {
            element: [Object, Array],
        },

        methods: {
            /**
             * Make change price link
             *
             * @param link
             * @param title
             */
            shareModal(link, title) {
                return {
                    link: {
                        action : 'openModal',
                        options: {
                            id: 'Forwarder-ShareQrCodeModal',
                        },

                        params: {
                            link,
                            title,
                        },
                    },
                };
            },

            /**
             * Copy Link
             *
             * @param value
             */
            doCopy(value) {
                this.$copyText(value).then(() => {
                    this.$toast.success(this.$t('general.copy_value.copied'));
                }).catch(() => {
                    this.$toast.success(this.$t('general.copy_value.error'));
                });
            },
        },
    };
</script>
