/*-----------------------------------------------------------------
- Wallet trans
-----------------------------------------------------------------*/
export default {
    
    types: {
        deposit   : 'Deposit',
        withdrawal: 'Withdrawal',
    },

    balance       : 'Balance',
    blocked_amount: 'Blocked amounts',
    
};
