<template>
    <div
        v-if="list && list[type] && list[type].length"
        class="tag-box"
    >
        <span
            v-for="(item, index) in list[type]"
            :key="index"
            class="label"
            :class="allow ? 'green' : 'red'"
        >
            {{ item.label }}
            <i
                class="icon md-close"
                @click="$emit('remove', type, item)"
            />
        </span>
    </div>
</template>

<script>
    export default {
        name: 'AddedDivisions',

        props: {
            type        : String,
            list        : [Object, Array],
            allowability: String,
        },

        computed: {
            /**
             * Check allowability
             */
            allow() {
                return this.allowability === 'allowed';
            },
        },
    };
</script>
