<template>
    <transition :name="transition">
        <div
            v-if="display"
            class="alert"
            :class="classes"
        >
            <span
                v-if="dismissible"
                class="alert-close"
                @click="close"
                v-html="'&times;'"
            />

            <slot>
                {{ message }}
            </slot>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'Alert',

        props: {

            /**
             * Alert Colors
             */
            color: {
                type   : String,
                default: '',
            },

            /**
             * Alert Message
             */
            message: {
                type   : String,
                default: '',
            },

            /**
             * Alt Alert Style
             */
            alt: {
                type   : Boolean,
                default: false,
            },

            /**
             * Compact Alert Size
             */
            compact: {
                type   : Boolean,
                default: false,
            },

            /**
             * Makes alert dismissible
             * Add cross button
             */
            dismissible: {
                type   : Boolean,
                default: false,
            },

            /**
             * Fix alert in window frame
             */
            fixedAt: {
                type   : String,
                default: '',
            },

            /**
             * Controls alert default display
             */
            hidden: {
                type   : Boolean,
                default: false,
            },

            /**
             * Transition to show alert
             */
            transition: {
                type   : String,
                default: 'fade',
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                /**
                 * Controls alert display
                 */
                display: !this.hidden,
            };
        },

        computed: {

            /**
             * Creates fixed class
             */
            getFixedClass() {
                if (!this.fixedAt) {
                    return '';
                }

                return `fixed ${this.fixedAt}`;
            },

            /**
             * Merge the alert classes
             */
            classes() {
                const classes = [];

                if (this.alt) {
                    classes.push('alt');
                }

                if (this.compact) {
                    classes.push('compact');
                }

                classes.push(this.type);
                classes.push(this.color);
                classes.push(this.getFixedClass);

                return classes.join(' ');
            },
        },

        methods: {

            /**
             * Open alert and emit open event
             */
            open() {
                this.display = true;
                this.$emit('open');
            },

            /**
             * Close alert and emit close event
             */
            close() {
                this.display = false;
                this.$emit('close');
            },

            /**
             * Toggle alert view
             */
            toggle() {
                if (this.display) {
                    this.close();
                } else {
                    this.open();
                }
            },
        },
    };
</script>
