<template>
    <section class="panel">
        <header>
            <div class="title">
                {{ element.title }}
            </div>
        </header>
        <article class="p0">
            <template v-for="(id, locale) in element.options">
                <div
                    :key="`locale-${locale}`"
                    class="locale-option"
                    :class="{current: element.current === locale}"
                >
                    <div class="desc">
                        <img
                            :src="require(`@/assets/images/flags/simple/${locale}.svg`)"
                            :alt="$t(`general.locales.${locale}`)"
                        >
                        <div class="title">
                            {{ $t(`general.locales.${locale}`) }}
                        </div>
                    </div>
                    <div class="action">
                        <div
                            v-if="element.current === locale"
                            class="label sm green"
                        >
                            <i class="icon md-check" />
                            {{ $t('general.current_locale') }}
                        </div>
                        <router-link
                            v-else-if="id"
                            :to="editLink(id)"
                            class="button xs color-blue"
                        >
                            {{ $t('button.edit') }}
                        </router-link>
                        <router-link
                            v-else
                            :to="createLink(locale)"
                            class="button xs color-green"
                        >
                            {{ $t('button.create') }}
                        </router-link>
                    </div>
                </div>
            </template>
        </article>
    </section>
</template>

<script>

    export default {

        name: 'YFormLocales',

        props: {
            /**
             * Element of slug
             */
            element: Object,
        },

        /**
         * @inheritDoc
         */
        created() {
            const { lang } = this.$route.query;
            if (lang && !this.has(this.element.options, lang)) {
                this.$router.push(this.createLink(this.element.current));
            }
        },

        methods: {

            /**
             * Edit link
             *
             * @param id
             * @returns {{name: string, params: {id: *, type: string}}}
             */
            editLink(id) {
                const routeSplit = this.element.sister_route.split('-');
                const link = routeSplit.splice(0, routeSplit.length - 1);
                link.push('edit');
                const linkName = link.join('-');
                return {
                    name  : linkName,
                    params: {
                        type: this.$route.params.type,
                        id,
                    },
                };
            },

            /**
             * Create link
             *
             * @param locale
             * @returns {{name: string, params: {id: *, type: string}}}
             */
            createLink(locale) {
                return {
                    name  : this.element.sister_route,
                    params: {
                        type: this.$route.params.type,
                    },

                    query: {
                        lang      : locale,
                        sisterhood: this.element.sisterhood,
                    },
                };
            },

        },

    };
</script>
