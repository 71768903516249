<template>
    <section
        class="panel"
        :class="classes"
    >
        <header v-if="hasHeader">
            <slot name="header">
                <div class="title">
                    <span
                        v-if="icon"
                        class="icon"
                        :class="icon"
                    />
                    {{ title }}
                    <small v-if="subtitle">{{ subtitle }}</small>
                </div>
            </slot>

            <div class="functions">
                <slot name="functions" />

                <a
                    v-if="collapsible"
                    href=""
                    class="panel-toggle"
                    @click.prevent="toggle"
                >
                    <template v-if="isOpen">
                        <svg
                            width="14"
                            height="14"
                            color="#aaa"
                            enable-background="new 0 0 357 357"
                            version="1.1"
                            viewBox="0 0 357 357"
                            xml:space="preserve"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M357,204H0v-51h357V204z" />
                        </svg>
                    </template>
                    <template v-else>
                        <svg
                            width="14"
                            height="14"
                            enable-background="new 0 0 357 357"
                            version="1.1"
                            viewBox="0 0 357 357"
                            xml:space="preserve"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M357,204H204v153h-51V204H0v-51h153V0h51v153h153V204z" />
                        </svg>
                    </template>
                </a>
            </div>
        </header>

        <slide-up-down
            v-if="collapsible"
            :active="isOpen"
        >
            <div>
                <article>
                    <slot />
                </article>
                <footer v-if="hasFooterSlot">
                    <slot name="footer" />
                </footer>
            </div>
        </slide-up-down>

        <div v-else>
            <article>
                <slot />
            </article>
            <footer v-if="hasFooterSlot">
                <slot name="footer" />
            </footer>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Panel',

        inject: {
            $validator: '$validator',
        },

        props: {
            /**
             * Panel Title
             */
            title: {
                type: String,
            },

            /**
             * Panel Icon
             */
            icon: {
                type: String,
            },

            /**
             * Panel Subtitle
             */
            subtitle: {
                type: String,
            },

            /**
             * Panel color
             */
            color: {
                type   : String,
                default: '',
            },

            /**
             * Panel type
             */
            type: {
                type: String,
            },

            /**
             * Panel Bar Style
             */
            bar: {
                type   : Boolean,
                default: false,
            },

            /**
             * Panel Fill Style
             */
            fill: {
                type   : Boolean,
                default: false,
            },

            /**
             * Panel Size
             * _sm, lg_
             */
            size: {
                type   : String,
                default: '',
            },

            /**
             * Makes panel collapsible
             */
            collapsible: {
                type   : Boolean,
                default: false,
            },

            /**
             * Default Collapsing Mode
             */
            collapsed: {
                type   : Boolean,
                default: false,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                collapseClass: '',
                isPanelOpened: !this.collapsed,
            };
        },

        computed: {

            /**
             * Check panel is open
             */
            isOpen() {
                return this.isPanelOpened;
            },

            /**
             * Checks if footer slot exists
             *
             * @returns {boolean}
             */
            hasFooterSlot() {
                return 'footer' in this.$slots;
            },

            /**
             * Checks if header slot exists
             *
             * @returns {boolean}
             */
            hasHeader() {
                return !!(this.title || this.subtitle || this.icon || this.$slots.header);
            },

            /**
             * Generates color class
             *
             * @returns {string}
             */
            getColorClass() {
                let colorClass = '';

                if (this.color) {
                    colorClass += this.color;
                }

                if (this.bar) {
                    return `bar-${colorClass}`;
                }

                if (this.fill) {
                    return `fill-${colorClass}`;
                }

                return colorClass;
            },

            /**
             * Return type of panel
             *
             * @returns {Array}
             */
            typeColor() {
                const types = {
                    default: '',
                    danger : 'red',
                    warning: 'orange',
                    success: 'green',
                    info   : 'blue',
                };
                return types[this.type || 'default'];
            },

            /**
             * Create classes string
             *
             * @returns {string}
             */
            classes() {
                const classes = [];

                classes.push(this.getColorClass);
                classes.push(this.collapseClass);
                classes.push(this.size);
                classes.push(this.typeColor);

                return classes.join(' ');
            },

        },

        watch: {
            /**
             * Watch to update isPanelOpened
             *
             * @param val
             */
            collapsed(val) {
                this.isPanelOpened = !val;
            },
        },

        methods: {
            /**
             * Closes Panel
             */
            close() {
                this.collapseClass = 'closed';
                this.isPanelOpened = false;
                this.$emit('close');
            },

            /**
             * Opens Panel
             */
            open() {
                this.collapseClass = '';
                this.isPanelOpened = true;
                this.$emit('open');
            },

            /**
             * Toggle panel
             */
            toggle() {
                if (!this.isOpen) {
                    this.open();
                } else {
                    this.close();
                }
            },
        },
    };
</script>
