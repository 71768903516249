/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    content: {
        post: {
            list  : 'نوشته‌ها',
            create: 'افزودن نوشته',
            edit  : 'ویرایش نوشته',
        },
        type: {
            list  : 'گونه‌های محتوا',
            create: 'افزودن گونه محتوا',
            edit  : 'ویرایش گونه محتوا',
        },
    },
    
};
