/*-----------------------------------------------------------------
- Specs Routes
-----------------------------------------------------------------*/
const prefix = 'specs';

const routes = [
    {
        path     : `${prefix}/manage/:id`,
        name     : `${prefix}-manage`,
        component: () => import(/* webpackChunkName: "specs" */ '@/modules/specs/views/Manage'),
    },
    {
        path     : `${prefix}`,
        name     : `${prefix}-list`,
        component: () => import(/* webpackChunkName: "specs" */ '@/modules/specs/views/List'),
    },
];

export default routes;
