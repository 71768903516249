/*-----------------------------------------------------------------
- Cart Configs
-----------------------------------------------------------------*/
export default {
    
    menu: {
        pre: {
            'pre-list-all': {
                icon: 'md-cart',
                name: 'cart-pre-orders-list',
            },
            
            'pre-list-drafted': {
                icon : 'md-cart-outline',
                name : 'cart-pre-orders-list',
                query: {
                    pre_order_status: '0',
                },
            },
    
            'pre-list-raised': {
                icon : 'md-cart-plus',
                name : 'cart-pre-orders-list',
                query: {
                    pre_order_status: '101',
                },
            },
            'pre-list-confirmed': {
                icon : 'md-check-all',
                name : 'cart-pre-orders-list',
                query: {
                    pre_order_status: '111',
                },
            },
            'pre-list-expired': {
                icon : 'md-clock-alert-outline',
                name : 'cart-pre-orders-list',
                query: {
                    pre_order_status: '181',
                },
            },
            'pre-list-rejected': {
                icon : 'md-cart-off',
                name : 'cart-pre-orders-list',
                query: {
                    pre_order_status: '191',
                },
            },
            'pre-list-sent': {
                icon : 'md-cart-arrow-right',
                name : 'cart-pre-orders-list',
                query: {
                    pre_order_status: '191',
                },
            },
            'pre-list-canceled': {
                icon : 'md-cart-remove',
                name : 'cart-pre-orders-list',
                query: {
                    pre_order_status: '191',
                },
            },
            'pre-list-finalized': {
                icon : 'md-file-check',
                name : 'cart-pre-orders-list',
                query: {
                    pre_order_status: 'finalized',
                },
            },
            
            'pre-create': {
                icon: 'md-library-plus',
                name: 'cart-pre-orders-create',
            },
        },
        
        order: {
            'order-list-all': {
                icon: 'md-cart',
                name: 'cart-orders-list',
            },
            
            'order-list-raised': {
                icon : 'md-cart-plus',
                name : 'cart-orders-list',
                query: {
                    order_status: '301',
                },
            },
            
            'order-list-confirmed': {
                icon : 'md-check-all',
                name : 'cart-orders-list',
                query: {
                    order_status: '311',
                },
            },
            
            'order-list-prepared': {
                icon : 'md-truck',
                name : 'cart-orders-list',
                query: {
                    order_status: '321',
                },
            },
            
            'order-list-sent': {
                icon : 'md-truck-fast',
                name : 'cart-orders-list',
                query: {
                    order_status: '331',
                },
            },
            
            'order-list-delivered': {
                icon : 'md-truck-check',
                name : 'cart-orders-list',
                query: {
                    order_status: '341',
                },
            },
            
            'order-list-rejected': {
                icon : 'md-cart-remove',
                name : 'cart-orders-list',
                query: {
                    order_status: '391',
                },
            },

            'order-list-constructed': {
                icon : 'md-cart-arrow-down',
                name : 'cart-orders-list',
                query: {
                    order_status: '', // TODO: Fill this with backend data
                },
            },
            
            'order-create': {
                icon: 'md-cart-plus',
                name: 'cart-orders-create',
            },
            
        },
        
        claim: {
            'claim-list-new': {
                icon : 'md-comment-plus-outline',
                name : 'cart-claims-list',
                query: {
                    status: 'new',
                },
            },
            
            'claim-list-confirmed': {
                icon : 'md-comment-check-outline',
                name : 'cart-claims-list',
                query: {
                    status: 'confirmed',
                },
            },
            
            'claim-list-rejected': {
                icon : 'md-comment-remove-outline',
                name : 'cart-claims-list',
                query: {
                    status: 'rejected',
                },
            },
            
            'claim-create': {
                icon: 'md-comment-plus-outline',
                name: 'cart-claims-create',
            },
        },
        
        invoice: {
            'invoice-list-paid': {
                icon : 'md-cash',
                name : 'cart-invoices-list',
                query: {
                    status: 'paid',
                },
            },
            
            'invoice-list-unpaid': {
                icon : 'md-cash-100',
                name : 'cart-invoices-list',
                query: {
                    status: 'unpaid',
                },
            },
            
            'invoice-list-cancelled': {
                icon : 'md-close',
                name : 'cart-invoices-list',
                query: {
                    status: 'cancelled',
                },
            },
            
            'invoice-list-returned': {
                icon : 'md-cash-refund',
                name : 'cart-invoices-list',
                query: {
                    status: 'returned',
                },
            },
            
            'invoice-list-due': {
                icon : 'md-clock-outline',
                name : 'cart-invoices-list',
                query: {
                    status: 'due',
                },
            },
        },
    },
    
};
