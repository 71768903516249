/*-----------------------------------------------------------------
- Button translations
-----------------------------------------------------------------*/

export default {
    
    submit           : 'Submit',
    save             : 'Save',
    confirm          : 'Confirm',
    cancel           : 'Cancel',
    close            : 'Close',
    create           : 'Create',
    edit             : 'Edit',
    update           : 'Update',
    delete           : 'Delete',
    trash            : 'Move to trash',
    insert           : 'Insert',
    add              : 'Add',
    sure             : 'I\'m sure!',
    activate         : 'Activated',
    deactivate       : 'Deactivate',
    back             : 'Back',
    search           : 'Search',
    submit_comment   : 'Submit comment',
    submit_newsletter: 'Approve',
    action           : 'Action',
    mass_actions     : 'Mass actions',
    filter           : 'Filter',
    draft            : 'Save in my drafts',
    send             : 'Send to editor',
    unpublish        : 'Unpublish',
    moderate         : 'Moderate',
    reject           : 'Reject',
    publish          : 'Publish',
    block            : 'Block',
    unblock          : 'Unblock',
    manage           : 'Manage',
    setting          : 'Setting',
    back_to_list     : 'Back to the list',
    download         : 'Download',
    refresh          : 'Refresh',
    more             : 'More',
    print            : 'Print',
    excel            : 'Export to Excel',
    copy             : 'Copy',
    reset            : 'Reset',
    restore          : 'Restore',

    // Panel
    help              : 'Help',
    addWidget         : 'Add widget',
    manageWidgets     : 'Manage widgets',
    deleteMapMarker   : 'Delete Places',
    details           : 'Details',
    exit_impersonate  : 'Exit impersonate mode',
    newCoordinates    : 'Show new coordinates on map',
    clear             : 'Empty fields',
    manualCoordinators: {
        show: 'Manual coordinators',
        hide: 'Hide',
    },
};
