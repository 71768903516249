<template>
    <y-panel
        class="form-adder"
        :title="label"
        icon="md-content-duplicate"
    >
        <div slot="functions">
            <!-- Add Row Button -->
            <y-button
                v-if="showAddButton"
                color="green"
                icon="md-plus mdi-plus"
                size="sm"
                inverted
                @click.prevent="addInput"
            />
            <!-- Remove All Button -->
            <y-button
                v-if="allowEmpty && rows.length"
                color="red"
                icon="md-delete-forever"
                size="sm"
                inverted
                class="mx5"
                @click.prevent="onRemoveAll"
            />
        </div>

        <!-- Remove All Prompt -->
        <div
            v-if="showRemoveAllPrompt"
            class="w-100 d-f flex-wrap justify-content-end mb10"
        >
            <y-form-alert
                type="danger"
                :content="$t('messages.cloner.confirm_delete_all')"
            />
            <y-button @click.prevent="cancelRemoveAllPrompt">
                {{ $t('button.cancel') }}
            </y-button>
            <y-button
                class="mx5"
                color="red"
                @click.prevent="removeAll"
            >
                {{ $t('button.confirm') }}
            </y-button>
        </div>

        <!-- Cloner Rows -->
        <div
            v-for="(field, index) in rows"
            :key="'row-'+ id + '-'+ index"
            class="cloner-row flex-wrap"
        >
            <!-- Row Remove Prompt -->
            <div
                v-if="isPromptActive(index)"
                class="w-100 d-f flex-wrap justify-content-end mb10"
            >
                <y-form-alert
                    type="warning"
                    :content="$t('messages.cloner.confirm_delete')"
                />
                <!-- Prompt Dismiss Button -->
                <y-button @click.prevent="cancelRemovePrompt(index)">
                    {{ $t('button.cancel') }}
                </y-button>
                <!-- Row Remove Button -->
                <y-button
                    class="mx5"
                    color="orange"
                    @click.prevent="removeInput(index)"
                >
                    {{ $t('button.confirm') }}
                </y-button>
            </div>

            <!-- Row -->
            <div class="cloner-form-col">
                <slot
                    :row="field"
                    :index="index"
                >
                    {{ field }}
                </slot>
            </div>

            <!-- Row Remove Button -->
            <div class="cloner-form-action">
                <y-button
                    v-if="!isPromptActive(index)"
                    icon="md-close"
                    color="red"
                    inverted
                    size="sm"
                    @click.prevent="onRemoveInput(index)"
                />
            </div>
        </div>
    </y-panel>
</template>

<script>

    import ClonerWrapperMixin from '@/mixins/ClonerWrapper';

    export default {

        name: 'ClonerPanel',

        mixins: [ClonerWrapperMixin],

        props: {
            /**
             * YPanel's title prop
             */
            label: String,

            /**
             * Determine whether to allow cloner to be empty
             */
            allowEmpty: Boolean,
        },

        computed: {
            /**
             * Determine whether to show add button
             */
            showAddButton() {
                return this.rows.length < this.max;
            },
        },

    };
</script>
