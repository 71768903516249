import moment from 'moment-jalaali';
import { digits, betweenRange } from './number';

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;
const month = day * 30;
const year = month * 12;

const dateRanges = {
    /**
     * total range of 0 seconds to 50 seconds
     * range 0 seconds to 50 seconds counted as multiple seconds
     * has no single range
     */
    second: {
        min : 0,
        max : 50 * second,
        base: second,
    },
    /**
     * total range of 51 seconds to 55 minutes
     * range 51 seconds to 2 minutes counted as 1 minute
     * range 2 minutes to 55 minutes counted as multiple minutes
     */
    minute: {
        min   : 51 * second,
        max   : 55 * minute,
        base  : minute,
        single: {
            min: 51 * second,
            max: 2 * minute,
        },
    },
    /**
     * total range of 55 minutes to 23 hours
     * range 55 minutes to 1.5 hour counted as 1 hour
     * range 1.5 hour to 23 hour counted as multiple hours
     */
    hour: {
        min   : 55 * minute,
        max   : 23 * hour,
        base  : hour,
        single: {
            min: 55 * minute,
            max: 1.5 * hour,
        },
    },
    /**
     * total range of 23 hours to 29 days
     * range 23 hours to 1.5 day counted as 1 day
     * range 1.5 day to 29 days counted as multiple days
     */
    day: {
        min   : 23 * hour,
        max   : 29 * day,
        base  : day,
        single: {
            min: 23 * hour,
            max: 1.5 * day,
        },
    },
    /**
     * total range of 29 days to 11.5 months
     * range 29 days to 2 months counted as 1 month
     * range 2 months to 11.5 months counted as multiple months
     */
    month: {
        min   : 29 * day,
        max   : 11.5 * month,
        base  : month,
        single: {
            min: 29 * day,
            max: 2 * month,
        },
    },
    /**
     * total range of 11.5 months to ∞
     * range 11.5 months to 23 months counted as 1 year
     * range 23 months to ∞  counted as multiple years
     */
    year: {
        min   : 11.5 * month,
        max   : Infinity,
        base  : year,
        single: {
            min: 11.5 * month,
            max: 23 * month,
        },
    },
};

moment.locale('en');
moment.loadPersian({
    usePersianDigits: true,
    dialect         : 'persian-modern',
});

/**
 * Return the Current Date in timeStamp format
 * 
 * @returns {number}
 */
export const currentDate = () => moment().unix();

/**
 * Converts Api date to Javascript Date
 *
 * @param {*} timeStamp
 * @returns {Date}
 */
export const jsDateString = (timeStamp) => new Date(timeStamp * 1000);

/**
 * Gets millisecond date and returns formatted date
 *
 * @param {*} timeStamp {Number}
 * @param {*} format {String}
 * @param {*} locale {String}
 * @returns {string}
 */
export const date = (timeStamp, format = 'DD MMMM YYYY', locale = 'fa') => {
    if (locale === 'fa') {
        // eslint-disable-next-line no-param-reassign
        format = format.replace(/([YMD])\1{0,}/g, (w) => `j${w}`);
    }
    return digits(moment(timeStamp, 'X').locale(locale).format(format), locale);
};

/**
 * Gets millisecond date and returns formatted date
 *
 * @param {*} timeStamp {Number}
 * @param {*} format {String}
 * @param {*} locale {String}
 * @returns {string}
 */
export const jDate = (timeStamp, format = 'jYYYY/jM/jD', locale = 'fa') => {
    const value = jsDateString(timeStamp);
    return moment(value).locale(locale).format(format);
};

/**
 * Converts millisecond to jalaali date
 *
 * @param {*} timeStamp
 * @param {*} format
 * @param {*} isMs
 * @returns {*}
 */
export const toJalaali = (timeStamp, format = 'jYYYY/jM/jD', isMs = false) => {
    const value = (isMs) ? timeStamp : jsDateString(timeStamp);
    
    return moment(value).format(format);
};

/**
 * Converts jalaali date to milliseconds
 *
 * @param {*} value
 * @param {*} format
 * @param {*} standardFormat
 * @returns {number}
 */
export const toMilliSeconds = (value, format = 'jYYYY/jM/jD', standardFormat = 'YYYY/M/D') => {
    const jsDate = new Date(moment(value, format).format(standardFormat));
    
    return Date.parse(jsDate) / 1000;
};

/**
 * Gets date difference between two dates
 *
 * @param {*} from {Date}
 * @param {*} till {Date}
 * @param {*} unit {string}
 * @returns {*}
 */
export const dateDiff = (from, till, unit = 'milliseconds') => moment.duration(from - till, unit);

/**
 * Gets date string or human date relative to now
 *
 * @param {*} timeStamp {Number}
 * @param {*} format {String}
 * @param {*} full {Boolean}
 * @param {*} locale {String}
 * @returns {*}
 */
export const humanRelativeDate = (timeStamp, format = 'jYYYY/jM/jD', full = false, locale) => {
    const now = new Date();
    const then = jsDateString(timeStamp);
    const diff = dateDiff(then, now);
    const daysDiff = diff.asDays();
    
    if ((daysDiff >= 0 && !full) || full) {
        return diff.locale(locale).humanize();
    }
    return jDate(timeStamp, format, locale);
};

/**
 * get time in ms and returns human readable relative date
 *
 * @param {*} time {Number} time in miliseconds
 * @returns {*}
 */
export const relativeDate = (time) => {
    const now = new Date().getTime();
    const then = jsDateString(time).getTime();
    let diff = now - then;

    const relativeDateObject = {};

    if (diff < 0) {
        relativeDateObject.isFuture = true;
        diff *= -1;
    }

    const rangesName = Object.keys(dateRanges);

    for (let i = 0; i < rangesName.length; i += 1) {
        const rangeName = rangesName[i];
        const range = dateRanges[rangeName];
        const { min, max } = range;
        if (betweenRange(diff, min, max)) {
            relativeDateObject.type = rangeName;
            relativeDateObject.count = (diff / range.base).toFixed();
            if (range.single && betweenRange(diff, range.single.min, range.single.max)) {
                relativeDateObject.isSingle = true;
            }
            break;
        }
    }
    return relativeDateObject;
};
