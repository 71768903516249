/*-----------------------------------------------------------------
- Auth trans
-----------------------------------------------------------------*/

export default {
    
    page_title: {
        login   : 'ورود به سایت',
        logout  : 'خروج',
        register: 'ثبت نام',
        forget  : 'بازیابی رمز عبور',
        otp     : 'ورود با رمز عبور یکبار مصرف',
    },
    
    username: 'شماره موبایل یا ایمیل خود را وارد کنید.',
    send    : 'ارسال',
    verify  : 'بررسی',
    
    google: {
        verify_text: 'کد فعال در اپلیکیشن یا یکی از کدهای پشتیبان خود را وارد کنید.',
    },
    
    messages: {
        request_token: {
            success: {
                email : 'توکن بازیابی رمز عبور به آدرس ایمیل شما ارسال شد.',
                mobile: 'توکن بازیابی رمز عبور به شماره موبایل شما پیامک شد.',
            },
        },
        
        verify_token: {
            success: 'لطفا رمز عبور جدید خود را وارد کنید.',
        },
        
        reset_password: {
            success: 'رمز عبور با موفقیت تغییر کرد.',
        },
    },

    get_code: {
        resend              : 'ارسال مجدد',
        time_to_expire_token: 'زمان باقی‌مانده تا انقضای کد بازیابی: {0}',
        token               : 'کد بازیابی منقضی شده است.',
    },

    token_channel: {
        sent_to: 'کد بازیابی به {0} ارسال خواهد شد.',
    },

    channels: {
        sms  : 'پیامک',
        email: 'ایمیل',
    },
    
    otp: {
        title      : 'ورود با رمز عبور یکبار مصرف',
        otp_request: 'درخواست رمز عبور یکبار مصرف',
        go_register: 'لطفا ثبت نام کنید.',
        channels   : {
            pattern   : 'پیامک',
            mail      : 'ایمیل',
            two_factor: 'ورود دو مرحله‌ای',
        },
    },
};
