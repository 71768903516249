<template>
    <span class="form-link">
        <y-button @click.prevent="element.handleClick">
            <slot>{{ label }}</slot>
        </y-button>
    </span>
</template>

<script>

    import LinkMixin from '@/mixins/Link';

    export default {
        name: 'YFormSubscriptionModalButton',

        mixins: [LinkMixin],

        props: {
            /**
             * Element of link
             */
            element: Object,

            /**
             * Type of component
             */
            type: String,

            /**
             * Loading button on wait slug
             */
            loadingOn: String,
        },

    };
</script>
