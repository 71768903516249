/*-----------------------------------------------------------------
- Toast translations
-----------------------------------------------------------------*/

export default {

    faqs: {

        save: {
            success: 'Successfully saved.',
            error  : 'A problem occurred!',
        },

        trash: {
            success: 'Successfully deactivated.',
            error  : 'A problem occurred!',
        },

        restore: {
            success: 'Successfully activated.',
            error  : 'A problem occurred!',
        },

        destroy: {
            success: 'Permanently deleted.',
            error  : 'A problem occurred!',
        },

        update: {
            success: 'Successfully updated.',
            error  : 'A problem occurred!',
        },

    },
    
    slideshow: {
        
        save: {
            success: 'Successfully saved.',
            error  : 'A problem occurred!',
        },
        
        trash: {
            success: 'Successfully deactivated.',
            error  : 'A problem occurred!',
        },
        
        restore: {
            success: 'Successfully activated.',
            error  : 'A problem occurred!',
        },
        
        destroy: {
            success: 'Permanently deleted.',
            error  : 'A problem occurred!',
        },
        
        update: {
            success: 'Successfully updated.',
            error  : 'A problem occurred!',
        },
        
    },

};
