/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    slideshows: {
        edit: 'مدیریت اسلایدها',
    },
    
};
