/*-----------------------------------------------------------------
- Commenting Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Commenting Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    commentsGrid: (...args) => Resource.get('commenting-grid', args),
    
    /**
     * Commenting Single View
     *
     * @param {...any} args
     * @returns {*}
     */
    single: (...args) => Resource.get('commenting-single', args),
    
    /**
     * Commenting Reply by Admin
     *
     * @param {...any} args
     * @returns {*}
     */
    replyByAdmin: (...args) => Resource.post('commenting-reply-by-admin', args),
    
};
