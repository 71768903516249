/*-----------------------------------------------------------------
- Breadcrumbs store
-----------------------------------------------------------------*/
import Vue from 'vue';

const state = {
    items: null,
};

const getters = {
    state: (state) => state, // eslint-disable-line no-shadow
    items: (state) => state.items, // eslint-disable-line no-shadow
};

const mutations = {
    /**
     * Set breadcrumbs
     *
     * @param state
     * @param items
     * @returns {boolean}
     */
    set(state, items) { // eslint-disable-line no-shadow
        return Vue.set(state, 'items', items);
    },
    
    /**
     * Clear items
     *
     * @param state
     */
    clear(state) { // eslint-disable-line no-shadow
        return Vue.set(state, 'items', null);
    },
};
const actions = {
    /**
     * Set items
     *
     * @param commit
     * @param commit.commit
     * @param items
     */
    set({ commit }, items) {
        commit('set', items);
    },
    
    /**
     * Clear items
     *
     * @param commit
     * @param commit.commit
     */
    clear({ commit }) {
        commit('clear');
    },
    
};

export default {
    
    namespaced: true,
    
    state,
    getters,
    mutations,
    actions,
};
