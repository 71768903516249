<template>
    <div>
        <y-panel class="studio-grid-cell">
            <span
                v-if="element.title"
                class="fw-b"
            >
                {{ element.title }}
            </span>

            <!-- Other locales -->
            <div
                v-if="hasOtherLocales"
                class="studio-grid-cell__other-locales"
            >
                <p class="fw-b fz-sm mt0">
                    {{ $t('studio.other_locales') }}
                </p>

                <ul class="studio-grid-cell__other-locales--list">
                    <li
                        v-for="locale in element.other_locales"
                        :key="`locale-${locale.id}`"
                        class="studio-grid-cell__other-locales--item"
                    >
                        <router-link
                            :to="locale.link"
                            class="studio-grid-cell__other-locales--item__link button xs"
                        >
                            <img
                                v-if="!locale.exists"
                                class="new-badge"
                                :src="require('@/assets/images/bow-tie.png')"
                            >
                            <img
                                class="flag"
                                :src="require(`@/assets/images/flags/circular/${locale.locale}.svg`)"
                                :alt="$t(`general.locales.${locale.locale}`)"
                            >
                            <div class="title">
                                {{ $t(`general.locales.${locale.locale}`) }}
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>


            <div class="row-item-refresh">
                <!-- Refresh -->
                <y-button
                    v-if="false"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    size="sm"
                    @click.prevent="refresh(element.post_id)"
                />

                <!-- Action -->
                <y-dropdown
                    class="grid-sort"
                    position="bottom-left"
                >
                    <template slot="toggle">
                        <y-button>{{ $t('fields.action') }}</y-button>
                    </template>

                    <div class="grid-sort-form">
                        <y-form-link
                            v-if="element.can_view"
                            type="link"
                            :element="{ link: element.page_builder_link }"
                        >
                            <i class="icon md-eye" />
                            {{ $t('studio.view') }}
                        </y-form-link>
                        <y-form-link
                            v-if="element.can_edit"
                            type="link"
                            :element="{ link: { name:'content-post-edit', params:{ id: element.post_id } } }"
                        >
                            <i class="icon md-fountain-pen-tip" />
                            {{ $t('studio.edit') }}
                        </y-form-link>
                    </div>
                </y-dropdown>
            </div>
        </y-panel>

        <!-- Children posts -->
        <template v-if="element.children_posts && element.children_posts.length">
            <div
                v-for="child in element.children_posts"
                :key="child.id"
                class="studio-grid-cell__children"
            >
                <GridCellItem :element="child" />
            </div>
        </template>
    </div>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';

    import { YDropdown } from '@deps';
    import YFormLink from '@deps/form/elements/Link';

    export default {
        name: 'GridCellItem',

        components: {
            YDropdown,
            YFormLink,
        },

        props: {
            element: Object,
        },

        computed: {
            /**
             * Check element has other locales
             */
            hasOtherLocales() {
                return this.element.other_locales && this.element.other_locales.length;
            },
        },

        methods: {
            generateId,

            /**
             * Refresh row
             *
             * @param id
             */
            refresh(id) {
                this.$emit('refresh', { refresh: id });
            },
        },
    };
</script>
