/*-----------------------------------------------------------------
- Subscription trans
-----------------------------------------------------------------*/
export default {

    title: {
        create          : 'ایجاد بسته اشتراک جدید',
        edit            : 'ویرایش بسته اشتراک',
        general_settings: 'تنظیمات عمومی اشتراک',
    },

    empty_fields: 'خالی‌کردن فیلدها',
    categories  : 'دسته‌بندی‌ها',

    wallet_settings: {
        title: 'تنظیمات کیف پول',
        label: 'مشتری می‌تواند از کیف پول خود پرداخت کند.',
    },

    payment_settings: {
        title       : 'تنظیمات پرداخت',
        unavailable : 'غیرفعال باشد',
        online_path : 'مسیر پرداخت آنلاین',
        offline_path: 'مسیر پرداخت آفلاین',
    },

    invoice_settings: {
        title                 : 'تنظیمات فاکتور رسمی',
        invoice_generate      : 'صدور فاکتور برای خرید اشتراک',
        invoice_prefix        : 'پیشوند کد',
        invoice_prefix_help   : 'این پیشوند، به ابتدای کدها اضافه می‌شود. ترجیحاً با حروف انگلیسی وارد نمایید.',
        invoice_required_field: 'فیلد‌های ضروری برای کاربران حقیقی و حقوقی',
        seller_info           : 'اطلاعات فروشنده',
    },

    notifier_settings: {
        title               : 'تنظیمات اعلان',
        notif_activate      : 'ارسال اعلان در زمان فعال سازی',
        notif_one_month     : 'ارسال اعلان یک ماه مانده به اتمام زمان بسته',
        notif_one_week      : 'ارسال اعلان یک هفته مانده به اتمام زمان بسته',
        notif_one_day       : 'ارسال اعلان یک روز مانده به اتمام زمان بسته',
        notif_fifty_percent : 'ارسال اعلان با عبور استفاده کاربر از ۵۰درصد تعداد مجاز',
        notif_ninety_percent: 'ارسال اعلان با عبور استفاده کاربر از ۹۰درصد تعداد مجاز',
        notif_finished      : 'ارسال اعلان با اتمام زمان یا تعداد به کاربر',
    },

    discount_settings: {
        title             : 'کدهای تخفیف',
        discount_available: 'استفاده از کدهای تخفیف فعال باشد.',
    },

    seller_info_modal: {
        title: 'اطلاعات فروشنده',
        done : 'انجام شد.',
        error: 'بروز خطا!',
    },

    seller_type: {
        title: 'نوع شخص',
        real : 'حقیقی',
        legal: 'حقوقی',
    },

    editor_base          : 'جزئیات بسته',
    advanced_settings    : 'تنظیمات پیشرفته',
    use_advanced_settings: 'استفاده از تنظیمات پیشرفته',
    volume_time_value    : 'مدت زمان بسته',
    volume_const_value   : 'تعداد مجاز بسته ',
    volume_time_type     : 'واحد زمان',
    currency             : 'ارز',
    original_price       : 'مبلغ اصلی',
    sale_price           : 'قیمت پس از تخفیف',

    editor: {
        messages: {
            success: 'بسته اشتراک با موفقیت ذخیره شد.',
        },
    },

};
