/*-----------------------------------------------------------------
- Content Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Content Type Destroy (Permanent Delete)
     *
     * @param {...any} args
     * @returns {*}
     */
    typeDestroy: (...args) => Resource.delete('content-type-destroy', args),

    /**
     * Content Type Restore (Undelete)
     *
     * @param {...any} args
     * @returns {*}
     */
    typeRestore: (...args) => Resource.put('content-type-restore', args),

    /**
     * Content Type Trash (Delete)
     *
     * @param {...any} args
     * @returns {*}
     */
    typeTrash: (...args) => Resource.delete('content-type-trash', args),

    /**
     * Content Type Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    typeFetch: (...args) => Resource.get('content-type-fetch', args),

    /**
     * Content Type Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    typeGrid: (...args) => Resource.get('content-type-grid', args),

    /**
     * Content Type List
     *
     * @param {...any} args
     * @returns {*}
     */
    typesList: (...args) => Resource.get('content-types-list', args),

    /**
     * Content Type Save
     *
     * @param {...any} args
     * @returns {*}
     */
    typeSave: (...args) => Resource.post('content-type-save', args),

    /**
     * Content Post Destroy
     *
     * @param {...any} args
     * @returns {*}
     */
    postDestroy: (...args) => Resource.delete('content-post-destroy', args),

    /**
     * Content Post Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    postFetch: (...args) => Resource.get('content-post-fetch', args),

    /**
     * Content Post Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    postGrid: (...args) => Resource.get('content-post-grid', args),

    /**
     * Content Post Pin
     *
     * @param {...any} args
     * @returns {*}
     */
    postPin: (...args) => Resource.post('content-post-pin', args),

    /**
     * Content Post Restore
     *
     * @param {...any} args
     * @returns {*}
     */
    postRestore: (...args) => Resource.put('content-post-restore', args),

    /**
     * Content Post Save
     *
     * @param {...any} args
     * @returns {*}
     */
    postSave: (...args) => Resource.post('content-post-save', args),

    /**
     * Content Post Trash
     *
     * @param {...any} args
     * @returns {*}
     */
    postTrash: (...args) => Resource.delete('content-post-trash', args),

    /**
     * Content Post Unpin
     *
     * @param {...any} args
     * @returns {*}
     */
    postUnpin: (...args) => Resource.post('content-post-unpin', args),

    /**
     * Content Features
     *
     * @param {...any} args
     * @returns {*}
     */
    features: (...args) => Resource.get('content-features', args),

    /**
     * Content Templates
     *
     * @param {...any} args
     * @returns {*}
     */
    templates: (...args) => Resource.get('content-templates', args),

    /**
     * Content Slug Suggest
     *
     * @param {...any} args
     * @returns {*}
     */
    slugSuggest: (...args) => Resource.post('content-slug-suggest', args),
    
    /**
     * Content Post Fetch Buttons
     *
     * @param {...any} args
     * @returns {*}
     */
    postFetchButtons: (...args) => Resource.get('content-post-fetch-buttons', args),
    
    /**
     * Content Post Quick View
     *
     * @param {...any} args
     * @returns {*}
     */
    postQuickView: (...args) => Resource.get('content-post-quick-view', args),

};
