<template>
    <div>
        <y-panel
            class="seo-inspector-preview"
            :class="device"
        >
            <div class="seo-inspector-preview__icons">
                <i
                    class="icon md-desktop-mac"
                    :class="{'is-active': device === 'is-desktop'}"
                    @click="selectDevice('desktop')"
                />
                <i
                    class="icon md-cellphone"
                    :class="{'is-active': device === 'is-mobile'}"
                    @click="selectDevice('mobile')"
                />
            </div>
            <y-seo-inspector-preview
                :element="element"
                :value="value"
                :post-title="model.seo_post_title"
                :custom-text-title="model.seo_custom_post_title"
                :meta-description="model.seo_meta_description"
                :custom-text-description="model.seo_custom_meta_description"
                :lang="value.lang"
                @titleCount="titleCount"
                @descCount="descCount"
            />
        </y-panel>

        <y-panel
            :title="$t('seo.post_title')"
            icon="md-format-title"
        >
            <y-seo-item-selector
                type="post_title"
                :element="element"
                :parameters="element.parameters"
                :count="titleCharCount"
                @input="seoItemSelectorHandler('post_title', $event)"
            />
        </y-panel>

        <y-panel
            :title="$t('seo.meta_description')"
            icon="md-subtitles-outline"
        >
            <y-seo-item-selector
                type="meta_description"
                :element="element"
                :parameters="element.parameters"
                :count="descCharCount"
                @input="seoItemSelectorHandler('meta_description', $event)"
            />
        </y-panel>
        
        <y-panel
            :title="$t('seo.canonical_url')"
            icon="md-link-variant"
        >
            <y-form-field
                v-model="model.seo_canonical"
                type="text"
                :placeholder="$t('seo.canonical_url_placeholder')"
                no-label
                name="canonical_url_placeholder"
                class="mb0"
            />
        </y-panel>
    </div>
</template>

<script>
    import YFormField from '@deps/form/FormField';
    import YSeoItemSelector from '@/modules/seo/components/ItemSelector';
    import YSeoInspectorPreview from '@/modules/seo/components/InspectorPreview';

    export default {
        name: 'YFormSeo',

        components: {
            YFormField,
            YSeoItemSelector,
            YSeoInspectorPreview,
        },

        props: {
            value  : Object,
            element: Object,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                model         : {},
                device        : 'is-desktop',
                titleCharCount: 0,
                descCharCount : 0,
            };
        },

        watch: {
            model: {
                /**
                 * Watch value set new model
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

            element: {
                /**
                 * Watch value set new model
                 */
                handler() {
                    this.$set(this.model, 'seo_canonical', this.element.canonical);
                },
                deep     : true,
                immediate: true,
            },
        },

        methods: {
            /**
             * Change Preview based on device
             *
             * @param {string} device - Device type
             */
            selectDevice(device) {
                this.device = `is-${device}`;
            },

            /**
             * Set title count
             *
             * @param count
             */
            titleCount(count) {
                this.$set(this, 'titleCharCount', count);
            },

            /**
             * Set desc count
             *
             * @param count
             */
            descCount(count) {
                this.$set(this, 'descCharCount', count);
            },

            /**
             * Seo Item Selector Handler
             *
             * @param type
             * @param item
             */
            seoItemSelectorHandler(type, item) {
                this.$set(this.model, `seo_${type}`, item.parameters);

                if (item.parameters.includes(`custom_${type}`)) {
                    this.$set(this.model, `seo_custom_${type}`, item.custom_text);
                } else if (this.model[`seo_custom_${type}`]) {
                    this.$set(this.model, `seo_custom_${type}`, null);
                }
            },
        },
    };
</script>
