/*-----------------------------------------------------------------
- Auth Routes
-----------------------------------------------------------------*/
const prefix = 'auth';
const routes = [
    {
        path     : `${prefix}/login`,
        name     : 'auth-login',
        component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/auth/Login'),
        meta     : {
            guest : true,
            layout: 'auth',
        },
    },
    {
        path    : `${prefix}/logout`,
        name    : 'auth-logout',
        redirect: {
            name : 'auth-login',
            query: {
                logout: 1,
            },
        },
    },
    {
        path     : `${prefix}/register`,
        name     : 'auth-register',
        component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/auth/Register'),
        meta     : {
            guest : true,
            layout: 'auth',
        },
    },
    {
        path     : `${prefix}/forget-password`,
        name     : 'auth-forget-password',
        component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/auth/ForgetPassword'),
        meta     : {
            guest : true,
            layout: 'auth',
        },
    },
    {
        path     : 'forbidden',
        name     : 'auth-forbidden',
        component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/auth/Forbidden'),
        meta     : {
            guest : true,
            layout: 'auth',
        },
    },
    {
        path     : `${prefix}/otp-login`,
        name     : 'auth-otp-login',
        component: () => import(/* webpackChunkName: "auth" */ '@/modules/auth/views/auth/OTPLogin'),
        meta     : {
            guest : true,
            layout: 'auth',
        },
    },
];

export default routes;
