/*-----------------------------------------------------------------
- General translations
-----------------------------------------------------------------*/
export default {

    layout: {
        title      : 'Admin Panel',
        separator  : '::',
        description: 'Quark :: powerful by YasnaTeam',
    },

    empty: {
        msg    : 'Nothing found.',
        msgFull: 'No {title} found.',
    },

    home: 'Home page',

    author: {
        1: 'Author',
        s: 'Authors',
    },

    category: {
        1: 'Category',
        s: 'Categories',
    },

    form_select: {
        no_options: 'No options found',
        no_search : 'Search for options',
        no_result : 'No results found',
    },

    settings: 'Settings',

    division: {
        neighbourhood: 'Neighbourhood',
        city         : 'City',
        province     : 'Province',
        country      : 'Country',
        continent    : 'Continent',
    },

    profile: 'Profile',

    status: {
        active  : 'Active',
        inactive: 'Inactive',
    },

    locales: {
        fa: 'Farsi',
        en: 'English',
        de: 'Dutch',
        zh: 'Chinese',
        it: 'Italian',
        fr: 'French',
        ar: 'Arabic',
        tr: 'Turkish',
        es: 'Spanish',
        ru: 'Russian',
    },

    current_locale: 'Current language',

    dropzone: {
        dictRemoveFile              : 'Remove File',
        dictRemoveFileConfirmation  : 'Sure to remove the files?',
        dictCancelUpload            : 'Cancel!',
        dictUploadCanceled          : 'Upload Canceled!',
        dictCancelUploadConfirmation: 'Sure to cancel?',
        dictFileTooBig              : 'File is too large ({filesize} MB). Maximum {maxFileSize} MB is allowed.',
        dictInvalidFileType         : 'Invalid File Type',
        dictResponseError           : 'Response Error!',
        dictMaxFilesExceeded        : 'Cannot upload more than {maxFiles} number of files.',
    },

    pagination: {
        prev: 'Prev',
        next: 'Next',
    },

    filters: 'Filters',

    active  : 'Active',
    inactive: 'Inactive',

    copy_value: {
        copied: 'Copied!',
        error : 'There was a problem copying the phrase. Please try again.',
    },

    // Panel
    breadcrumbs: {
        dashboard: 'Dashboard',
        preview  : 'Preview',
        404      : '404',
        500      : '500',
    },
    
    dashboard: 'Dashboard',
    
    timeline: {
        by            : ' By {0}',
        node_not_found: 'Undefined node {0}',
        private_note  : 'Private note',
        by_admin      : 'Registered by Admin',
        add_in_status : 'Registered in {0} status',
    },
    
    error: {
        404: {
            title: '404 Error',
        },

        invalid: 'The value entered as coordinates is invalid.',
    },

};
