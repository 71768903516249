/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    tagging: {
        create    : 'New label created.',
        update    : 'Label updated.',
        save_order: 'Labels sorted.',
    },
    
};
