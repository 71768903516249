/*-----------------------------------------------------------------
- Content Themes Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Content Themes  Faq Lists
     *
     * @param {...any} args
     * @returns {*}
     */
    faqList: (...args) => Resource.get('content-themes-faq-list', args),

    /**
     * Content Themes  Slideshows Lists
     *
     * @param {...any} args
     * @returns {*}
     */
    slideshowsList: (...args) => Resource.get('content-themes-slideshows-list', args),

};
