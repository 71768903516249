<template>
    <y-form-field-wrapper v-bind="fieldWrapperProps">
        <span class="form-color-picker">

            <span class="handle">
                <span
                    class="color-value"
                    @click.stop.prevent="toggleShowPicker"
                >
                    <span :style="{backgroundColor: color.hex8 || color.hex}" />
                    <div
                        class="vc-checkerboard"
                        :style="pickerStyle"
                    />
                </span>
                <span class="hex-value">{{ hexColor }}</span>
            </span>

            <template v-if="showPicker">
                <compact-color-picker
                    v-if="onlyPalette"
                    :value="color"
                    :palette="getPresetColors"
                    @input="handleInput"
                />
                <sketch-color-picker
                    v-else
                    :value="color"
                    :preset-colors="getPresetColors"
                    @input="handleInput"
                />
            </template>

        </span>
    </y-form-field-wrapper>
</template>
<script>

    import { Sketch, Compact } from 'vue-color';

    import FormElementMixin from '@/mixins/FormElement';

    /**
     * Link component
     */
    export default {
        name: 'YFormColorPicker',

        components: {
            SketchColorPicker : Sketch,
            CompactColorPicker: Compact,
        },

        mixins: [FormElementMixin],

        inject: {
            $validator: '$validator',
        },

        props: {
            palette: {
                type: Array,
            },
            onlyPalette: {
                type: Boolean,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                showPicker: false,

                color: {
                    hex: this.value || '#000000',
                },
            };
        },

        computed: {
            /**
             * Return hex color
             */
            hexColor() {
                return this.color.hex8 && !this.color.hex8.endsWith('FF')
                    ? this.color.hex8
                    : this.color.hex;
            },

            /**
             * Return palette of colors
             */
            getPresetColors() {
                const defaultPreset = ['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF'];
                return this.palette || defaultPreset;
            },

            /**
             * Style of the Picker
             */
            pickerStyle() {
                return {
                    backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC")',
                };
            },
        },
        /**
         * @inheritDoc
         */
        mounted() {
            this.setListenerForClosing();

            this.$emit('input', this.color.hex);

            this.$on('close', () => {
                this.showPicker = false;
            });
        },

        methods: {

            /**
             * Toggle picker display
             */
            toggleShowPicker() {
                this.showPicker = !this.showPicker;
            },

            /**
             * Closes dropdown list and emit relevant event
             */
            close() {
                if (this.disabled) {
                    return;
                }

                this.showPicker = false;
                this.$emit('close');
            },

            /**
             * Sets event listener on document to close dropdown
             * list when anywhere out of dropdown is clicked
             */
            setListenerForClosing() {
                const vm = this;
                document.addEventListener('click', (event) => {
                    if (!event.target.className.startsWith('vc-')) {
                        vm.close();
                    }
                });
            },

            /**
             * Handle input change
             *
             * @param event
             */
            handleInput(event) {
                this.color = event;
                this.$emit('input', event.hex);
            },

        },

    };
</script>
