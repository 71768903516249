<template>
    <y-panel
        :title="title || null"
        :icon="icon || null"
        :size="size || null"
        :type="type || null"
    >
        <y-form
            v-model="model"
            :params="children"
            @input="$emit('input', model)"
        />
    </y-panel>
</template>

<script>

    /**
     * Panel Component
     */
    export default {

        name: 'YFormPanel',

        components: {
            YForm: () => import('@deps/form/Form'),
        },

        props: {

            /**
             * Panel children as form elements
             */
            children: [Object, Array],

            /**
             * Form default value passed by parent
             */
            value: null,

            title: String,

            icon: String,

            size: String,

            type: String,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value,
            };
        },

        watch: {

            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },

        },

    };

</script>
