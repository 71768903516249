/*-----------------------------------------------------------------
- Relations Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Relations  Favorites List
     *
     * @param {...any} args
     * @returns {*}
     */
    favouritesList: (...args) => Resource.get('relations-favourites-list', args),
    
    /**
     * Relations  Favorites Change Item
     *
     * @param {...any} args
     * @returns {*}
     */
    favouritesChange: (...args) => Resource.post('relations-favourite-change', args),
};
