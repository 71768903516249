/*-----------------------------------------------------------------
- Coupons trans
-----------------------------------------------------------------*/
export default {

    title: {
        create: 'Create New Campaigns',
        edit  : 'Edit Campaigns',
    },

    editor: {
        base: {
            panel: 'Title Settings',
        },

        amount: {
            panel         : 'Discount Amount',
            fee           : 'Discount Amount',
            fee_number    : 'Discount Price',
            percent       : 'Discount Percent',
            percent_number: 'Percent',
            percent_max   : 'Max',
            currency      : 'Currency',
        },

        slug: {
            panel: 'Slug settings',
        },

        date: {
            panel    : 'Date',
            starts_at: 'Starts at',
            ends_at  : 'Ends at',
        },

        category: {
            panel        : 'Categories and Products',
            categories   : 'Categories list',
            type         : 'Select shop',
            empty_cat    : 'No categories is found',
            empty_product: 'No products is found',
            init_product : 'Search in all categories',
            search       : {
                category: 'Search category',
                product : 'Search product',
            },
        },

        product: {
            products: 'Products list',
            title   : {
                category_selected: 'Products of {0}',
                search           : 'Search results in all categories',
            },
        },

        messages: {
            success: 'The campaign successfully saved.',
        },
    },

};
