<template>
    <div class="shipping-division-form">
        <y-form-field
            v-model="model.allowability"
            type="select"
            name="type"
            :options="types"
        />
        <y-loading
            :active="$wait.is('cid-loading')"
            height="100px"
        >
            <div
                v-if="cId"
                class="d-f align-items-center"
            >
                <y-form-division
                    ref="divisionSelector"
                    :continent="cId"
                    limit="city"
                    allow-empty
                    :multi="multiDivs"
                    @divs="selectedDivs"
                />
                <y-button
                    class="add-button"
                    :disabled="existance"
                    @click.prevent.native="addDivs"
                >
                    {{ $t('button.add') }}
                </y-button>
            </div>

            <div class="row">
                <div class="col">
                    <y-added-divisions
                        type="country"
                        :list="addedDivs"
                        :allowability="model.allowability"
                        @remove="removeItem"
                    />
                </div>
                <div class="col">
                    <y-added-divisions
                        type="province"
                        :list="addedDivs"
                        :allowability="model.allowability"
                        @remove="removeItem"
                    />
                </div>
                <div class="col">
                    <y-added-divisions
                        type="city"
                        :list="addedDivs"
                        :allowability="model.allowability"
                        @remove="removeItem"
                    />
                </div>
            </div>
        </y-loading>
    </div>
</template>

<script>
    import { isEqual } from 'lodash';

    import YFormField from '@deps/form/FormField';
    import { YFormDivision } from '@deps/form/elements/Division';
    import YAddedDivisions from '@/modules/shipping/components/form/elements/ShippingDivisions/AddedDivisions';

    export default {
        name: 'YFormShippingDivisions',

        components: {
            YFormField,
            YFormDivision,
            YAddedDivisions,
        },

        props: {
            value  : [String, Object, Array],
            element: Object,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                continent: [],
                types    : [
                    {
                        value: 'allowed',
                        label: this.$t('shipping.editor.rules.divisions.allowed'),
                    },
                    {
                        value: 'not_allowed',
                        label: this.$t('shipping.editor.rules.divisions.not_allowed'),
                    },
                ],

                divs     : null,
                multiDivs: ['city'],
                existance: false,
                addedDivs: {
                    country : [],
                    province: [],
                    city    : [],
                },

                model: {
                    allowability: null,
                    divisions   : {
                        cities   : [],
                        countries: [],
                        provinces: [],
                    },
                },
            };
        },

        computed: {
            /**
             * Check if there is init value
             */
            hasValue() {
                return this.value;
            },
            
            /**
             * Return if country are empty
             */
            emptyCountry() {
                return (this.addedDivs && this.addedDivs.country && this.addedDivs.country.length === 0);
            },

            /**
             * Return if province are empty
             */
            emptyProvince() {
                return (this.addedDivs && this.addedDivs.province && this.addedDivs.province.length === 0);
            },

            /**
             * Return if city are empty
             */
            emptyCity() {
                return (this.addedDivs && this.addedDivs.city && this.addedDivs.city.length === 0);
            },

            /**
             * Return the title of the type
             */
            typeLabel() {
                return this.model.allowability === 'allowed' ? this.$t('shipping.editor.rules.divisions.allowed') : this.$t('shipping.editor.rules.divisions.not_allowed');
            },

            /**
             * Continent Id
             */
            cId() {
                let id = null;
                this.$wait.start('cid-loading');
                Object.keys(this.continent).forEach((key) => {
                    if ((this.continent[key].title_fa === 'آسیا')
                        || (this.continent[key].title_fa === 'Asia')) {
                        id = this.continent[key].id;
                        this.$wait.end('cid-loading');
                    }
                });
                return id;
            },
        },

        watch: {
            /**
             * Watch value and change model
             *
             * @param val
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(newVal, oldVal) {
                    if (!isEqual(newVal, oldVal)) {
                        this.$set(this.addedDivs, 'country', newVal.divisions.countries);
                        this.$set(this.addedDivs, 'province', newVal.divisions.provinces);
                        this.$set(this.addedDivs, 'city', newVal.divisions.cities);
                    }
                },
                deep: true,
            },

            'addedDivs.country': {
                /**
                 * Add country to model
                 */
                handler() {
                    this.model.divisions.countries = this.addedDivs.country.map((i) => i.value);
                },
                deep: true,
            },

            'addedDivs.province': {
                /**
                 * Add province to model
                 */
                handler() {
                    this.model.divisions.provinces = this.addedDivs.province.map((i) => i.value);
                },
                deep: true,
            },

            'addedDivs.city': {
                /**
                 * Add city to model
                 */
                handler() {
                    this.model.divisions.cities = this.addedDivs.city.map((i) => i.value);
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            this.fetchContinent();
            if (this.hasValue) {
                this.$set(this.addedDivs, 'country', this.value.divisions.countries);
                this.$set(this.addedDivs, 'province', this.value.divisions.provinces);
                this.$set(this.addedDivs, 'city', this.value.divisions.cities);
            }
            if (this.value && this.value.allowability) {
                this.$set(this.model, 'allowability', this.value.allowability);
            } else {
                this.$set(this.model, 'allowability', 'allowed');
            }
        },

        methods: {
            /**
             * Set modal after Init Value
             */
            setInitModel() {
                this.model.divisions.countries = this.addedDivs.country.map((i) => (i.value ? i.value : ''));
                this.model.divisions.provinces = this.addedDivs.province.map((i) => i.value);
                this.model.divisions.cities = this.addedDivs.city.map((i) => i.value);
            },

            /**
             * Get continent
             */
            fetchContinent() {
                const params = {
                    types    : ['continent'],
                    order    : 'asc',
                    including: [
                        'id',
                        'title_fa',
                    ],
                };
                return this.$services.list('division', params).then((response) => {
                    this.continent = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Add selected Items to the list
             *
             * @param data
             */
            selectedDivs(data) {
                this.$set(this, 'divs', data);
                this.checkExistance();
            },

            /**
             * Check item added to the _addedDivs_
             * 
             * @param division - city . province . country
             * @param key - index of array
             * @returns {boolean}
             */
            isDivAdded(division, key) {
                return this.addedDivs[division].find((item) => item.value === this.divs[division][key].value);
            },

            /**
             * Add Selected Divs
             */
            addDivs() {
                if (this.divs && this.divs.city && this.divs.city.length) {
                    if (this.multiDivs.includes('city') || (this.value && this.value.divisions.cities && this.value.divisions.cities.length)) {
                        Object.keys(this.divs.city).forEach((key) => {
                            if (!this.isDivAdded('city', key)) {
                                this.addedDivs.city.push(this.divs.city[key]);
                            }
                        });
                    } else {
                        this.$set(this.addedDivs, 'city', this.divs.city);
                    }
                } else if (this.divs && this.divs.province && this.divs.province.length) {
                    if (this.multiDivs.includes('province') || (this.value && this.value.divisions.provinces && this.value.divisions.provinces.length)) {
                        Object.keys(this.divs.province).forEach((key) => {
                            if (!this.isDivAdded('province', key)) {
                                this.addedDivs.province.push(this.divs.province[key]);
                            }
                        });
                    } else {
                        this.$set(this.addedDivs, 'province', this.divs.province);
                    }
                } else if (this.divs && this.divs.country && this.divs.country.length) {
                    if (this.multiDivs.includes('country') || (this.value && this.value.divisions.countries && this.value.divisions.countries.length)) {
                        Object.keys(this.divs.country).forEach((key) => {
                            if (!this.isDivAdded('country', key)) {
                                this.addedDivs.country.push(this.divs.country[key]);
                            }
                        });
                    } else {
                        this.$set(this.addedDivs, 'country', this.divs.country);
                    }
                }
                this.$refs.divisionSelector.clearSelectedDiv();
            },

            /**
             * Remove the Item
             *
             * @param div
             * @param item
             */
            removeItem(div, item) {
                let removed = false;
                Object.keys(this.addedDivs[div]).forEach((key) => {
                    if (!removed && this.addedDivs[div][key] && (this.addedDivs[div][key].value === item.value)) {
                        this.addedDivs[div].splice(key, 1);
                        removed = true;
                    }
                });
            },

            /**
             * Check if the selected divs exist in the list
             */
            checkExistance() {
                let exist = false;
                if (this.addedDivs.country && this.addedDivs.country.length) {
                    Object.keys(this.divs.country).forEach((key) => {
                        Object.keys(this.addedDivs.country).forEach((k) => {
                            if (this.divs.country[key].value === this.addedDivs.country[k].value) {
                                // eslint-disable-next-line no-unused-vars
                                exist = true;
                            }
                        });
                    });
                }

                if (this.addedDivs.province && this.addedDivs.province.length) {
                    Object.keys(this.divs.province).forEach((key) => {
                        Object.keys(this.addedDivs.province).forEach((k) => {
                            if (this.divs.province[key].value === this.addedDivs.province[k].value) {
                                // eslint-disable-next-line no-unused-vars
                                exist = true;
                            }
                        });
                    });
                }

                this.$set(this, 'existance', exist);
                return exist;
            },
        },
    };
</script>
