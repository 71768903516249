/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/
export default {
    
    page_head: 'Menu maker: {title}',
    
    type    : 'Menu',
    itemType: 'Menu Item',
    
    custom_link: 'Custom Link',
    
    empty_state: 'Add your first menu item from the sidebar.',
    
    add_item : 'Add to Menu',
    edit_item: 'Edit Menu Item',
    
    add_item_ok: 'New Item Added.',
    
    save_item_ok: 'Menu item saved successfully.',
    
    manage_items: 'Manage Menu Items',
    
};
