/*-----------------------------------------------------------------
- Wares trans
-----------------------------------------------------------------*/
export default {
    
    tabs: {
        base     : 'Base',
        measure  : 'Measure',
        inventory: 'Inventory',
        price    : 'Price',
        sizes    : 'Sizes',
        payment  : 'Payment',
        settings : 'Settings',
        tagging  : 'Options',
    },
    
    settings: {
        tax           : 'Tax',
        code          : 'Product Item Settings',
        post          : 'Product Specifications',
        auto_ware_code: 'The product code is generated automatically',
    },

    payment: {
        price              : 'Price',
        price_with_discount: 'Price after discount',
        tax                : 'Tax',
        sale_price         : 'Sale Price',
    },
    
    tax: {
        value_added : 'Value-Added Tax ({0}%)',
        withholding : 'Withholding Taxes ({0}%)',
        municipality: 'Municipal Charges ({0}%)',
        separate    : 'Add to the Price',
        on          : 'Involving the Price',
    },
    
};
