/* -------------------------------------------------------------------------- */
/* STRING HELPERS                                                             */
/* -------------------------------------------------------------------------- */

import { getRandomInt } from './number.js';

/**
 *
 * @param {*} string
 * @returns {string}
 */
export const capitalize = (string) => {
    if (typeof string !== 'string') {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Clear text and upper case
 *
 * @param {*} text
 * @returns {string}
 */
export const clearAndUpper = (text) => text.replace(/-/, '').replace(/_/, '').toUpperCase();

/**
 * Convert to camel case
 *
 * @param {*} text
 * @returns {*}
 */
export const toCamelCase = (text) => text.replace(/-\w/g, clearAndUpper).replace(/_\w/g, clearAndUpper);

/**
 * Convert to pascal case
 *
 * @param {*} text
 * @returns {*}
 */
export const toPascalCase = (text) => text.replace(/(^\w|-\w)/g, clearAndUpper);

/**
 * Convert to kebab case
 *
 * @param {*} text
 * @returns {*}
 */
export const toKebabCase = (text) => {
    // uppercase after a non-uppercase or uppercase before non-uppercase
    const upper = /(?:(?<!\p{Uppercase_Letter})\p{Uppercase_Letter}|\p{Uppercase_Letter}(?!\p{Uppercase_Letter}))/gu;
    return text.replace(upper, '-$&').replace(/^-/, '').toLowerCase();
};

/**
 * Convert kebab to snake case
 *
 * @param {*} text
 * @returns {*}
 */
export const kebabToSnake = (text) => text.replace(/-/g, '_');

/**
 * Convert snake case to camel case
 *
 * @param {*} str
 * @returns {*}
 */
export const snakeToCamel = (str) => str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

/**
 * Generates random string id
 *
 * @param {*} prefix {string}
 * @param {*} postfix {string}
 * @returns {string}
 */
export const generateId = (prefix = '', postfix = '') => prefix + Date.now().toString(36) + getRandomInt(1000000, 100000000) + postfix;

/**
 * Create slug
 *
 * @param {*} rawSlug
 * @returns {string}
 */
export const slug = (rawSlug) => {
    if (!rawSlug) {
        return rawSlug;
    }

    let sanitizedSlug = rawSlug.toString().toLowerCase();

    sanitizedSlug = sanitizedSlug.replace(/[\u202B|\u202C]/g, '')
        .replace(/\s+/g, '-')
        .replace(/--+/g, '-')
        .replace(/[&$+,/\\:;=?@#<>[\]{}|^%*()]+/g, '')
        .replace(/^-+/, '')
        .replace('‌', '-');

    return sanitizedSlug;
};

/**
 * Truncate text if it's to long
 *
 * @param {*} text
 * @param {*} length
 * @returns {*}
 */
export const truncate = (text, length = 25) => (text.length > length ? `${text.substring(0, length)}...` : text);
