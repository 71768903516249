/*-----------------------------------------------------------------
- Toast configs
-----------------------------------------------------------------*/

const toast = {
    position              : 'bottom-left',
    timeout               : 5000,
    closeOnClick          : true,
    pauseOnFocusLoss      : true,
    pauseOnHover          : true,
    draggable             : false,
    draggablePercent      : 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar       : false,
    closeButton           : 'button',
    icon                  : true,
    rtl                   : true,
};

export default toast;
