<template>
    <div class="field form-images">
        <label v-if="element.label">{{ element.label }}</label>

        <div
            v-if="images && images.length"
            :id="`images-${element.id}`"
            class="images-wrapper"
        >
            <a
                v-for="(image, key) in images"
                :key="`image-${element.id}-${key}`"
                :href="image.src"
                target="_blank"
                rel="noopener noreferrer"
                :title="image.title"
            >
                <y-form-image :element="makeElement(image)" />
            </a>
        </div>

        <div
            v-else
            class="panel empty-state mb0"
            :style="imageStyle"
        >
            <div class="desc">
                <div class="title">
                    {{ $t('components.images.empty') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import SimpleLightbox from 'simple-lightbox';

    export default {

        name: 'YFormImages',

        components: {
            YFormImage: () => import('@deps/form/elements/Image'),
        },

        props: {
            /**
             * Element
             */
            element: Object,

            /**
             * Image sources
             */
            value: Array,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                lightbox: null,
            };
        },

        computed: {

            /**
             * Return images
             */
            images() {
                return this.value;
            },

            /**
             * Image Style
             */
            imageStyle() {
                return {
                    height: '100px',
                };
            },

        },

        /**
         * @inheritDoc
         */
        mounted() {
            if (this.element.lightbox) {
                this.lightbox = new SimpleLightbox({ elements: `#images-${this.element.id} a` });
            }
        },

        methods: {

            /**
             * Make element of image
             *
             * @param image
             */
            makeElement(image) {
                return {
                    isThumbnail: true,
                    width      : image.width || 150,
                    height     : image.height || 150,
                    src        : image.thumbnail,
                };
            },

        },

    };
</script>
