/*-----------------------------------------------------------------
- Shipping trans
-----------------------------------------------------------------*/
export default {
    logo               : 'لوگو',
    days_off           : 'روز‌های تعطیل هفته',
    dates_off          : 'تعطیلات پیش رو',
    capacity           : 'ظرفیت',
    start_time         : 'زمان شروع',
    end_time           : 'زمان پایان',
    method_booking_days: 'امکان رزرو این شیوه تا چند روز بعد فراهم باشد؟',
};
