<template>
    <div :class="{field: size !== 'invisible'}">
        <vue-recaptcha
            ref="recaptcha"
            :sitekey="useSiteKey"
            class="recaptcha"
            :load-recaptcha-script="true"
            :size="size"
            @expired="onExpired"
            @verify="onVerify"
        />
    </div>
</template>

<script>
    import VueRecaptcha from 'vue-recaptcha';

    export default {

        name: 'Recaptcha',

        components: {
            VueRecaptcha,
        },

        props: {
            /**
             * Recaptcha Site key
             */
            siteKey: {
                type: String,
            },

            /**
             * Recaptcha size
             */
            size: {
                type   : String,
                default: 'invisible',
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                verified  : false,
                token     : null,
                useSiteKey: this.siteKey || process.env.VUE_APP_RECAPTCHA_SITEKEY,
            };
        },

        methods: {

            /**
             * Verify recaptcha
             */
            verify() {
                this.$refs.recaptcha.execute();
            },

            /**
             * Emit verified
             *
             * @param token
             */
            onVerify(token) {
                this.verified = true;
                this.token = token;
                this.$emit('verify', token);
            },

            /**
             * Reset recaptcha
             */
            reset() {
                this.verified = false;
                this.$refs.recaptcha.reset();
            },

            /**
             * Refresh on expired
             */
            onExpired() {
                this.verified = false;
                this.$refs.recaptcha.reset();
            },

        },

    };

</script>
