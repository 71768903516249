<template>
    <div>
        {{ label(node.label, count) }}
    </div>
</template>

<script>
    import { digits } from '@nodes/helpers/number';

    export default {
        name: 'YOptionSlot',

        props: {
            node           : Object,
            count          : Number,
            shouldShowCount: Boolean,
            labelClassName : String,
            countClassName : String,
        },

        methods: {
            /**
             * Return the label
             * 
             * @param {string} label
             * @param {number} count
             * @returns {string}
             */
            label(label, count) {
                let c = '';
                if (count) {
                    c = ` (${digits(count, this.$i18n.locale)})`;
                }
                return `${label}${c}`;
            },
        },
    };
</script>
