<template>
    <div v-html="content" />
</template>
<script>

    /**
     * Html component
     */
    export default {
        name: 'YFormHtml',

        props: {
            /**
             * Html Content
             */
            content: String,
        },
    };
</script>
