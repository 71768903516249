/*-----------------------------------------------------------------
- Cart Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Timeline Single Node Details
     *
     * @param {...any} args
     * @returns {*}
     */
    node: (...args) => Resource.get('timeline-node', args),
    
};
