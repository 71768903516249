/*-----------------------------------------------------------------
- Shipping trans
-----------------------------------------------------------------*/
export default {
    logo               : 'Logo',
    days_off           : 'Weekend Days',
    dates_off          : 'Incoming Off Days',
    capacity           : 'Capacity',
    start_time         : 'Start Time',
    end_time           : 'End Time',
    method_booking_days: 'How many days is it possible to book this method?',
};
