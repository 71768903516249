/*-----------------------------------------------------------------
- Filters
-----------------------------------------------------------------*/
import Vue from 'vue';
import i18n from '@nodes/locales';

import { digits, numberFormat } from '@nodes/helpers/number';
import { humanRelativeDate, toJalaali, date } from '@nodes/helpers/date';

import moment from 'moment';

/**
 * Converts digits to persian
 *
 * @param val
 * @returns {string}
 */
Vue.filter('pd', (val) => digits(val, 'fa'));

/**
 * Converts digits to english
 *
 * @param val
 * @returns {*}
 */
Vue.filter('ed', (val) => digits(val, 'en'));

/**
 * Converts digits to arabic
 *
 * @param val
 * @returns {*}
 */
Vue.filter('ar', (val) => digits(val, 'ar'));

/**
 * Converts digits to proper locale
 *
 * @param val
 * @param locale
 * @returns {*}
 */
Vue.filter('digits', (val, locale) => digits(val, locale || i18n.locale));

/**
 * Converts string to uppercase
 *
 * @param val
 * @returns {*}
 */
Vue.filter('upper', (val) => val.toUpperCase());

/**
 * Get date formatted
 *
 * @param val
 * @returns {*}
 */
Vue.filter('date', (val, format = 'DD MMMM YYYY', locale = 'fa') => date(val, format, locale));

/**
 * Get Jalaali human relative date
 *
 * @param val
 * @returns {*}
 */
Vue.filter('humanRelativeDate', (val, format = 'jYYYY/jM/jD HH:mm', full) => humanRelativeDate(val, format, full));

/**
 * Converts api received date to Jalaali
 * Date format ('jYYYY/jM/jD')
 *
 * @param val
 * @returns {*}
 */
Vue.filter('toJalaali', (val, format = 'jYYYY/jM/jD HH:mm') => toJalaali(val, format));

/**
 * Converts api received date to Jalaali
 * Date format ('jYYYY/jM/jD')
 *
 * @param val
 * @returns {*}
 */
Vue.filter('formatDate', (val, format = 'dddd, MMMM Do YYYY') => moment(val).locale('en').format(format));

/**
 * Convert date to age
 *
 * @param val
 * @returns {*}
 */
Vue.filter('age', (val) => {
    Math.floor((new Date() - val * 1000) / 3.15576e+10);
});

/**
 * Format Numbers
 *
 * @param val
 * @returns {*}
 */
Vue.filter('numberFormat', (val) => numberFormat(val));
