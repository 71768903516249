/*-----------------------------------------------------------------
- Link mixin
-----------------------------------------------------------------*/
import EventBus from '@/mixins/EventBus';
import ComponentClassesMixin from '@/mixins/ComponentClasses';
import GlobalMixin from '@/mixins/Global';

export default {
    
    name: 'LinkMixin',
    
    mixins: [
        ComponentClassesMixin,
        GlobalMixin,
    ],
    
    /**
     * @inheritDoc
     */
    data() {
        return {
            themeColorPrefix: 'link-',
        };
    },
    
    computed: {
        
        /**
         * Return classes of element
         *
         * @returns {*}
         */
        classes() {
            const classes = [this.theme];
            
            if (this.element.color) {
                classes.push(`link-${this.element.color}`);
            }
            
            return classes;
        },
        
        /**
         * Check if type is link
         *
         * @returns {boolean}
         */
        isLink() {
            return !!(typeof this.link === 'object' && !Array.isArray(this.link));
        },
        
        /**
         * Return component name
         *
         * @returns {*}
         */
        typeName() {
            return this.type || this.element.component_name;
        },
        
        /**
         * Return link of component
         */
        link() {
            return this.element.link;
        },
        
        /**
         * Return link of component
         */
        label() {
            return this.element.label || this.element.title || this.element.text;
        },
        
        /**
         * Return link of component
         */
        to() {
            return this.linkType === 'router-link' ? this.link : null;
        },
        
        /**
         * Get type of the link
         *
         * @returns {string}
         */
        tag() {
            if (typeof this.link === 'object') {
                if (this.element.link.hasOwnProperty('action')) {
                    return 'button';
                }
                if (this.link.hasOwnProperty('name')) {
                    return 'router-link';
                }
            }
            
            return 'a';
        },
        
        /**
         * Get type of the link
         *
         * @returns {string}
         */
        linkType() {
            if (typeof this.link === 'object') {
                if (this.element.link.hasOwnProperty('action')) {
                    return 'action-link';
                }
                if (this.link.hasOwnProperty('name')) {
                    return 'router-link';
                }
            }
            
            return 'anchor';
        },
        
        /**
         * Check if link is router link
         */
        isRouterLink() {
            return this.linkType === 'router-link';
        },
        
        /**
         * Check if link is action link
         */
        isAction() {
            return this.linkType === 'action-link';
        },
        
        /**
         * Get target of anchor link
         */
        linkTarget() {
            return this.linkType === 'anchor' ? this.element.link.target || '_self' : null;
        },
        
        /**
         * Get link of anchor
         */
        linkHref() {
            return this.linkType === 'anchor' ? this.element.link.url || this.link || '#' : null;
        },
    },
    
    methods: {
        
        /**
         * Handle click
         *
         * @param event
         */
        handleClick(event) {
            if (event) {
                event.preventDefault();
            }
            
            if (this.isAction) {
                this.handleAction();
            }
        },
        
        /**
         * Handle action of button
         */
        handleAction() {
            if (this.link.action === 'openModal' && typeof this.openGlobalModal === 'function') {
                this.openGlobalModal(this.link.options.id, this.link.params);
            } else if (this.link.action === 'refreshLayout') {
                EventBus.$emit('refreshLayout');
            }
        },
        
    },
    
};
