/*-----------------------------------------------------------------
- Notifications translations
-----------------------------------------------------------------*/
export default {
    filters: {
        all   : 'همه',
        unread: 'خوانده نشده',
    },
    empty: 'نتیجه‌ای یافت نشد.',
};
