/*-----------------------------------------------------------------
- User Routes
-----------------------------------------------------------------*/
const prefix = 'persons/users';
const namePrefix = 'persons-users';

const routes = [
    {
        path     : `${prefix}/:id/login-history`,
        name     : `${namePrefix}-login-history`,
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/persons/views/users/UserLoginHistory'),
    },
    
    {
        path     : `${prefix}/:id/edit`,
        name     : `${namePrefix}-edit`,
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/persons/views/users/Edit'),
    },
    
    {
        path     : `${prefix}/:role`,
        name     : `${namePrefix}-list`,
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/persons/views/users/List'),
    },

    {
        path     : `${prefix}/:id/profile`,
        name     : `${namePrefix}-profile`,
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/persons/views/users/Profile'),
    },

];

export default routes;
