/*-----------------------------------------------------------------
- Grid trans
-----------------------------------------------------------------*/
export default {
    
    filters: 'Filters',
    
    total: 'Showing {perPage} from {total}',
    
};
