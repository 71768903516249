/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    market: {
        page : 'فروشگاه',
        units: 'واحدهای اندازه‌گیری',
        ware : {
            create: 'افزودن کالای جدید',
            edit  : 'ویرایش کالا',
        },
    },
    campaigns: {
        list  : 'فروش ویژه',
        create: 'ایجاد فروش ویژه جدید',
        edit  : 'ویرایش فروش ویژه',
    },
    
};
