<template>
    <y-loading
        :active="!model"
        height="200px"
    >
        <div
            v-if="model"
            class="product-quick-view"
        >
            <!-- Title -->
            <div class="field d-f align-items-center product-quick-view__header">
                <div class="product-quick-view__image">
                    <img
                        v-if="model.thumbnail"
                        :src="model.thumbnail.versions.panel_thumb"
                        :alt="model.title"
                    >

                    <i
                        v-else
                        class="icon md-shopping"
                    />
                </div>
                <div>
                    <div class="form-title">
                        <router-link :to="generateButtonRoute('edit')">
                            {{ model.title }}
                        </router-link>
                    </div>
                    <div
                        v-if="model.product_tagging_categories && model.product_tagging_categories.length"
                        class="color-gray"
                    >
                        <span
                            v-for="tag in model.product_tagging_categories"
                            :key="tag.id"
                            class="label"
                        >
                            {{ tag }}
                        </span>
                    </div>
                </div>
            </div>

            <!-- Wares -->
            <div class="row">
                <div class="col">
                    <div class="field">
                        <label>{{ $t('market.product.availability_status') }}</label>
                        <div
                            v-if="model.ware_is_available"
                            class="label green"
                        >
                            {{ $t('market.product.availability_true') }}
                        </div>
                        <div
                            v-else
                            class="label red"
                        >
                            {{ $t('market.product.availability_false') }}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="field">
                        <label class="d-f justify-start align-items-center">
                            {{ $t('market.product.wares_count') }}
                            <y-button
                                tag="router-link"
                                size="xs"
                                :to="generateButtonRoute('ware-list')"
                                class="mr5"
                            >
                                {{ $t('market.wares.manage') }}
                            </y-button>
                        </label>
                        <div class="color-gray">
                            {{ model.wares_titles.length | digits }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="field">
                        <label>{{ $t('market.product.ware_available_count') }}</label>
                        <div class="color-gray">
                            {{ model.available_wares_count | digits }}
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="field">
                        <label>{{ $t('market.product.ware_not_available_count') }}</label>
                        <div class="color-gray">
                            {{ model.unavailable_wares_count | digits }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- Created -->
            <div
                v-if="model.created_at"
                class="row"
            >
                <div class="col">
                    <div class="field">
                        <label>{{ $t('fields.created_at') }}</label>
                        <y-form-date-label
                            class="mb0 color-gray-light"
                            :element="makeDateElement(model.created_at)"
                        />
                    </div>
                </div>
                <div class="col">
                    <div class="field">
                        <label>{{ $t('fields.created_by') }}</label>
                        <div class="color-gray">
                            {{ model.created_by.full_name }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- Published -->
            <div
                v-if="model.published_at"
                class="row"
            >
                <div class="col">
                    <div class="field">
                        <label>{{ $t('fields.published_at') }}</label>
                        <y-form-date-label
                            class="mb0 color-gray-light"
                            :element="makeDateElement(model.published_at)"
                        />
                    </div>
                </div>
                <div class="col">
                    <div class="field">
                        <label>{{ $t('fields.published_by') }}</label>
                        <div class="color-gray">
                            {{ model.published_by.full_name }}
                        </div>
                    </div>
                </div>
            </div>

            <!-- Updated -->
            <div
                v-if="model.updated_at && model.updated_by && model.updated_by.id"
                class="row"
            >
                <div class="col">
                    <div class="field">
                        <label>{{ $t('fields.updated_at') }}</label>
                        <y-form-date-label
                            class="mb0 color-gray-light"
                            :element="makeDateElement(model.updated_at)"
                        />
                    </div>
                </div>
                <div class="col">
                    <div class="field">
                        <label>{{ $t('fields.updated_by') }}</label>
                        <div class="color-gray">
                            {{ model.updated_by.full_name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </y-loading>
</template>

<script>

    import { listToTree } from '@nodes/helpers/misc';

    export default {

        name: 'YFormProductQuickView',

        components: {
            YFormDateLabel: () => import('@deps/form/elements/DateLabel'),
        },

        props: {
            value  : Object,
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model    : null,
                separator: this.$i18n.locale === 'fa' ? '، ' : ', ',
            };
        },

        computed: {
            /**
             * Calculate Not Available Wares
             */
            notAvailableWare() {
                const diff = this.model.wares_titles.length - this.model.ware_is_available;
                if (this.model.wares_titles.length > 1) {
                    if (diff > 0) {
                        return diff;
                    }
                }
                return 0;
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.fetch();
        },

        /**
         * @inheritDoc
         */
        beforeDestroy() {
            this.model = null;
        },

        methods: {

            /**
             * @inheritDoc
             */
            fetch() {
                const params = {
                    including: [
                        'title', 'published_at', 'published_by',
                        'wares_titles', 'ware_is_available',
                        'product_tagging_categories', 'created_at', 'created_by',
                        'updated_at', 'updated_by', 'available_wares_count', 'unavailable_wares_count',
                        'thumbnail',
                    ],
                };
                this.$services.single('content-post', this.value.id, params).then((response) => {
                    this.model = response.data.results;
                    this.model.product_tagging_categories = this.sortCategories(this.model.product_tagging_categories);
                });
            },

            /**
             * Make tagging order
             *
             * @param cats
             */
            sortCategories(cats) {
                if (!cats || !cats.length) {
                    return cats;
                }

                const sorted = this.treeToList(listToTree(cats));
                return sorted.map((item) => item.title);
            },

            /**
             * Tree to list
             *
             * @param arr
             * @param list
             * @param parent
             */
            treeToList(arr, list = [], parent = null) {
                arr.forEach((item) => {
                    list.push({
                        ...item,
                        parent_id: parent,
                    });
                    if (item.children && Array.isArray(item.children) && item.children.length) {
                        this.treeToList(item.children, list, item.id);
                    }
                });
                return list;
            },

            /**
             * Make date element
             *
             * @param value
             */
            makeDateElement(value) {
                return {
                    prefix : '',
                    postfix: '',
                    value,
                };
            },

            /**
             * Generate Route
             *
             * @param type
             */
            generateButtonRoute(type) {
                let routeName;
                if (type === 'edit') {
                    routeName = 'market-product-edit';
                }
                if (type === 'ware-list') {
                    routeName = 'market-wares-list';
                }
                return {
                    name  : routeName,
                    params: {
                        type: this.$route.params.type,
                        id  : this.value.id,
                    },
                };
            },

        },

    };

</script>
