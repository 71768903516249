/* -------------------------------------------------------------------------- */
/* ROUTES                                                                     */
/* -------------------------------------------------------------------------- */
const routes = [
    {
        path     : '/',
        name     : 'dashboard',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Dashboard.vue'),
    },
    // TODO: Uncomment this when it is ready
    // {
    //     path     : 'help/list',
    //     name     : 'help-list',
    //     component: () => import(/* webpackChunkName: "panel" */ '@/views/HelpList.vue'),
    // },
    {
        path     : 'grid/:module/:class/:part/:version?',
        name     : 'panel-grid',
        component: () => import(/* webpackChunkName: "panel" */ '@/views/Grid.vue'),
    },
];

export default routes;
