/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    accountant: {
        create    : 'گروه جدید با موفقیت ساخته شد.',
        update    : 'گروه با موفقیت بروزرسانی شد.',
        save_order: 'مرتب‌سازی گروه‌ها با موفقیت ذخیره شد.',
    },
    
    trashed: {
        confirm: 'آیا از انتقال این زیر‌سند به زباله‌دان مطمئن هستید؟',
        success: 'انتقال {type} به زباله‌دان با موفقیت انجام شد.',
        error  : 'مشکلی در انتقال {type} به زباله‌دان به وجود آمده است.',
    },
    
};
