/*-----------------------------------------------------------------
- Divisions Routes
-----------------------------------------------------------------*/
const prefix = 'divisions';
const namePrefix = 'divisions';

const routes = [
    {
        path     : `${prefix}`,
        name     : `${namePrefix}-list`,
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/divisions/views/List'),
    },
];

export default routes;
