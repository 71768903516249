<template>
    <div :class="[{checkbox: !isSwitch, disabled: disabled}, ...classes]">
        <input
            :id="checkboxId"
            type="checkbox"
            :true-value="trueVal"
            :false-value="falseVal"
            :checked="value == trueVal"
            :disabled="disabled"
            @change="updateChecked"
        >
        <label :for="checkboxId">
            <template v-if="!noLabel">{{ labelValue }}</template>
        </label>
    </div>
</template>

<script>
    import { Resource } from '@nodes/services';
    import { generateId } from '@nodes/helpers/string';

    import FormElementMixin from '@/mixins/FormElement';
    import SwitchInputMixin from '@/mixins/SwitchInput';

    /**
     * Checkbox Component
     */
    export default {

        name: 'YFormCheckbox',

        mixins: [FormElementMixin, SwitchInputMixin],

        props: {
            /**
             * Checkbox Label
             */
            label: String,

            /**
             * On Value
             */
            trueVal: { // eslint-disable-line vue/require-prop-types
                default: 1,
            },

            id: {
                type: [String, Number],
            },

            /**
             * Off Value
             */
            falseVal: { // eslint-disable-line vue/require-prop-types
                default: 0,
            },

            /**
             * Endpoint
             */
            endpoint: [Object, Array],

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                isChecked: this.value || this.falseVal || 0,
                r        : generateId(this.random()),
            };
        },

        computed: {
            /**
             * Gets label value
             *
             * @returns {* | StringConstructor | string}
             */
            labelValue() {
                return this.label || this.el || (this.name === undefined || this.name === 'undefined' ? '' : this.$t(`fields.${this.name}`));
            },

            /**
             * Generates Checkbox Id
             *
             * @returns {string}
             */
            checkboxId() {
                return `checkbox-${this.r}`;
            },

            /**
             * Check if endpoint is provided
             */
            hasEndpoint() {
                return this.has(this.endpoint, 'name', false);
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.$emit('input', this.isChecked);
        },

        methods: {
            /**
             * Update checked item and emit input event
             *
             * @param event
             */
            updateChecked(event) {
                this.isChecked = event.target.checked ? this.trueVal : this.falseVal;
                this.$emit('input', this.isChecked);
                this.$emit('change', this.isChecked);
                if (this.hasEndpoint) {
                    this.callAction(this.isChecked);
                }
            },

            /**
             * Create a random integer
             */
            random() {
                return Math.floor(Math.random() * (9999999 - 1000000 + 1) + 1000000);
            },
            
            /**
             * Call Endpoint of the switch
             *
             * @param status
             */
            callAction(status) {
                const method = (this.endpoint.method || 'get').toLowerCase();
                const version = this.endpoint.version || 1;
                const { name } = this.endpoint;

                let { params } = this.endpoint;
                if (!params || (typeof params === 'object' && Array.isArray(params))) {
                    params = {};
                }
                params[this.name] = status;

                Resource[method](name, [params, version]).then(() => {
                    this.$toast.success(this.$t('messages.operation.success'));
                }).catch((error) => {
                    this.handleError(error);
                    this.isChecked = !status;
                });
            },
        },

    };

</script>
