/*-----------------------------------------------------------------
- Permissions trans
-----------------------------------------------------------------*/
export default {
    
    title: 'Permissions',
    
    create     : 'Add New Permission',
    impersonate: 'Login as This User',
    
    delete: {
        title  : 'Delete',
        message: 'Are sure of deleting this permission?',
    },
    
    block: {
        title  : 'Block user',
        message: 'Are sure of blocking this user?',
    },
    
    unblock: {
        title  : 'Unblock User',
        message: 'Are sure of unblocking this user?',
    },
    
    alert: {
        title  : 'Alert!',
        message: 'You have changed permissions settings. Do you want to save this changes or discard them?',
    },
    
    row: {
        as          : 'As',
        in          : 'In',
        has         : 'Has',
        status      : 'status',
        role        : 'role',
        organization: 'organization',
    },
    
};
