/* -------------------------------------------------------------------------- */
/* OBJECT HELPER                                                              */
/* -------------------------------------------------------------------------- */

/**
 * Search in object by dot notation string
 *
 * @param {*} Object
 * @param {*} key
 * @param {*} defaultValue
 * @returns {*}
 */
export const searchInObject = (Object, key = null, defaultValue = null) => {
    if (!key) {
        return Object;
    }
    
    const config = key.split('.').reduce((o, i) => o[i], Object);
    
    return config || defaultValue;
};

/**
 * Overwrite merge function to use in deepMerge
 *
 * @param {*} destinationArray
 * @param {*} sourceArray
 * @returns {*}
 */
export const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

/**
 * Convert object to multi select array
 *
 * @param {*} object
 * @returns {Array}
 */
export const convertToSelect = (object) => {
    const array = [];
    if (object) {
        Object.keys(object).map((value) => array.push({
            label: object[value],
            value,
        }));
    }
    return array;
};
