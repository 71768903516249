/*-----------------------------------------------------------------
- Slideshows fields
-----------------------------------------------------------------*/
export default {
    
    button_title   : 'عنوان دکمه',
    button_link    : 'لینک دکمه',
    button_external: 'باز شدن لینک در صفحه جدید',
    
    slide: 'تصویر اسلاید',
    
    position: 'موقعیت',
    color   : 'رنگ',
    
    publish_now : 'انتشار فوری',
    published_at: 'انتشار در تاریخ',
    
    slideshow: {
        autoplay      : 'پخش خودکار',
        pause_on_hover: 'مکث به هنگام ورود ماوس',
        spaceBetween  : 'فاصله بین هر اسلاید',
        slidesPerView : 'اسلاید در هر صفحه',
        loop          : 'حلقه',
        effect        : 'افکت',
        direction     : 'جهت اسلایدر',
        speed         : 'سرعت به میلی‌ثانیه',
        transition    : 'زمان بین هر تغییر به میلی‌ثانیه',
    },
    
    has_content: 'دارای محتوای متنی',
    has_button : 'دارای دکمه',
    
    slideshow_colors: {
        light: 'روشن',
        dark : 'تیره',
    },
    
    slideshow_positions: {
        center_top   : 'وسط - بالا',
        center_center: 'وسط - وسط',
        center_bottom: 'وسط - پایین',
        right_top    : 'راست - بالا',
        right_center : 'راست - وسط',
        right_bottom : 'راست - پایین',
        left_top     : 'چپ - بالا',
        left_center  : 'چپ - وسط',
        left_bottom  : 'چپ - پایین',
    },
    
};
