/*-----------------------------------------------------------------
- Auth translations
-----------------------------------------------------------------*/
export default {
    
    login            : 'ورود',
    logout           : 'خروج',
    register         : 'عضویت',
    remember         : 'مرا به خاطر بسپار',
    status           : 'وضعیت',
    forget_link      : 'رمز خود را فراموش کرده‌اید؟',
    skip_link        : 'فعلا نه، بعدا بپرس!',
    verification_code: 'ارسال کد تایید',
    forbidden        : 'دسترسی مجاز نیست',
    
    alt: {
        register: 'عضو نیستید؟ ثبت نام کنید',
        login   : 'عضو هستید؟ وارد شوید',
    },
    
    messages: {
        login: {
            success: 'خوش آمدید :)',
            error  : 'مشکلی در ورود به وجود آمده است.',
        },
        
        register: {
            success: 'ثبت نام با موفقیت انجام شد.',
            error  : 'مشکلی در ثبت نام به وجود آمده است.',
        },
        
        request_token: {
            success: {
                email : 'توکن بازیابی رمز عبور به آدرس ایمیل شما ارسال شد.',
                mobile: 'توکن بازیابی رمز عبور به شماره موبایل شما پیامک شد.',
            },
            
            error: 'در فرایند ارسال مشکلی به وجود آمد. لطفا دوباره تلاش کنید.',
        },
        
        verify_token: {
            success: 'لطفا رمز عبور جدید خود را وارد کنید.',
            error  : 'در فرایند تایید توکن مشکلی به وجود آمد. لطفا دوباره تلاش کنید.',
        },
        
        change_password: {
            success: 'رمز عبور با موفقیت تغییر کرد.',
            error  : 'در فرایند تغییر رمز عبور مشکلی به وجود آمد. لطفا دوباره تلاش کنید.',
        },
    },
};
