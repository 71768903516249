/*-----------------------------------------------------------------
- Cart Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Cart Order Add Item
     *
     * @param {...any} args
     * @returns {*}
     */
    myOrderAddItem: (...args) => Resource.post('cart-my-order-add-item', args),
    
    /**
     * Cart Order Add Items
     *
     * @param {...any} args
     * @returns {*}
     */
    myOrderAddItems: (...args) => Resource.post('cart-my-order-add-items', args),
    
    /**
     * Cart Order Refresh
     *
     * @param {...any} args
     * @returns {*}
     */
    myOrderRefresh: (...args) => Resource.get('cart-my-order-refresh', args),
    
    /**
     * Cart Order Remove Item
     *
     * @param {...any} args
     * @returns {*}
     */
    myOrderRemoveItem: (...args) => Resource.delete('cart-my-order-remove-item', args),
    
    /**
     * Cart Order Remove Items
     *
     * @param {...any} args
     * @returns {*}
     */
    myOrderEvacuate: (...args) => Resource.delete('cart-my-order-evacuate', args),
    
    /**
     * Cart Orders List
     *
     * @param {...any} args
     * @returns {*}
     */
    myOrdersList: (...args) => Resource.get('cart-my-orders-list', args),
    
    /**
     * Cart Order Details
     *
     * @param {...any} args
     * @returns {*}
     */
    orderDetails: (...args) => Resource.get('cart-order-details', args),

    /**
     * Cart Payment Paths List
     *
     * @param {...any} args
     * @returns {*}
     */
    paymentPathList: (...args) => Resource.get('cart-payment-paths-list', args),

    /**
     * Cart Payment Transaction Make
     *
     * @param {...any} args
     * @returns {*}
     */
    paymentTransactionMake: (...args) => Resource.post('cart-payment-transaction-make', args),

    /**
     * Cart Payment Offline Transaction
     *
     * @param {...any} args
     * @returns {*}
     */
    paymentOfflineTransactionMake: (...args) => Resource.post('cart-payment-offline-transaction-make', args),

    /**
     * Cart My Order Coupon Inquiry
     *
     * @param {...any} args
     * @returns {*}
     */
    myOrderCouponInquiry: (...args) => Resource.get('cart-my-order-coupon-inquiry', args),

    /**
     * Cart Order Finalize
     *
     * @param {...any} args
     * @returns {*}
     */
    orderFinalize: (...args) => Resource.post('cart-order-finalize', args),

    /**
     * Cart Shipping Suitable Methods
     *
     * @param {...any} args
     * @returns {*}
     */
    shippingSuitableMethods: (...args) => Resource.get('cart-shipping-suitable-methods', args),

};
