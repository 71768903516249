<template>
    <div
        class="button-group"
        :class="classes"
    >
        <!-- @slot Use this slot for buttons -->
        <slot />
    </div>
</template>

<script>
    /**
     * Button Group Component
     */
    export default {
        name: 'ButtonGroup',

        props: {

            /**
             * Block-size Button Group
             */
            block: {
                type   : Boolean,
                Default: false,
            },

            /**
             * Vertical Button Group
             */
            vertical: {
                type   : Boolean,
                Default: false,
            },

        },

        computed: {
            /**
             * Creates a list of all classes
             *
             * @returns {*}
             */
            classes() {
                const classes = [];

                if (this.block) {
                    classes.push('block');
                }

                if (this.vertical) {
                    classes.push('vertical');
                }

                return classes;
            },
        },

    };
</script>
