/*-----------------------------------------------------------------
- Auth translations
-----------------------------------------------------------------*/
export default {
    
    login            : 'Login',
    logout           : 'Logout',
    register         : 'Register',
    remember         : 'Remember Me',
    status           : 'Status',
    forget_link      : 'Forget password?',
    skip_link        : 'Ask me later!',
    verification_code: 'Send Verification Code',
    forbidden        : 'You do not have access',

    alt: {
        register: 'Not a member? Register now!',
        login   : 'Already a member? Login then!',
    },
    
    messages: {
        login: {
            success: 'Welcome Home :)',
            error  : 'A problem occurred when trying to log you in.',
        },
        
        register: {
            success: 'Registration successful.',
            error  : 'A problem occurred during registration.',
        },
        
        request_token: {
            success: {
                email : 'Password recovery token is sent to your mail box.',
                mobile: 'Password recovery token is sent to your cell phone.',
            },
            
            error: 'A problem occurred. Please try again.',
        },
        
        verify_token: {
            success: 'Please enter your new password.',
            error  : 'A problem occurred during sending the token. Please try again.',
        },
        
        change_password: {
            success: 'Password changed!',
            error  : 'A problem occurred when changing the password. Please try again.',
        },
    },
};
