/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/
export default {
    
    post: {
        title: 'مطلب',
        
        create: 'افزودن مطلب جدید',
        edit  : 'ویرایش مطلب',
    },
    
    current_locale: 'زبان فعلی',
    
    confirm: {
        delete   : 'آیا از انتقال این مطلب به زباله‌دان مطمئنید؟',
        unpublish: 'آیا از لغو انتشار این مطلب مطمئنید؟',
        reject   : 'آیا از رد درخواست انتشار این مطلب مطمئنید؟',
    },
    
    publish_date: 'تاریخ انتشار:',
    
    success: {
        publish  : 'مطلب با موفقیت منتشر شد.',
        schedule : 'مطلب با موفقیت در صف انتشار قرار گرفت.',
        delete   : 'مطلب با موفقیت به سطل زباله منتقل شد.',
        unpublish: 'مطلب با موفقیت از حالت انتشار خارج شد.',
        draft    : 'مطلب با موفقیت به عنوان پیش‌نویس ذخیره شد.',
        reject   : 'مطلب با موفقیت رد شد.',
        send     : 'مطلب با موفقیت برای سردبیر ارسال شد.',
    },
    
    reject_title: 'نظر سردبیر:',
    
    status: {
        title    : 'وضعیت: {0}',
        published: 'منتشر شده',
        draft    : 'پیش‌نویس',
        trashed  : 'سطل زباله',
        scheduled: 'صف انتشار',
        rejected : 'رد شده',
        pending  : 'در انتظار تایید',
    },
    
    albums: {
        title: 'آلبوم‌ها',
        empty: 'آلبومی یافت نشد.',
    },

    post_category: {
        no_folder: 'پوشه‌ای یافت نشد.',
        no_label : 'دسته‌ای یافت نشد.',
    },

    post_tag: {
        select_tag: 'تگ‌های خود را انتخاب کنید',
        no_folder : 'پوشه‌ای یافت نشد.',
        no_label  : 'برچسبی یافت نشد.',
    },
};
