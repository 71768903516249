/*-----------------------------------------------------------------
- Profile Routes
-----------------------------------------------------------------*/
const prefix = 'profile';
const namePrefix = 'persons-profile';
const routes = [
    {
        path     : `${prefix}/login-history`,
        name     : `${namePrefix}-login-history`,
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/persons/views/profile/LoginHistory'),
    },
    {
        path     : `${prefix}/change-password`,
        name     : `${namePrefix}-change-password`,
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/persons/views/profile/ChangePassword'),
    },
    {
        path     : `${prefix}/edit`,
        name     : `${namePrefix}-edit`,
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/persons/views/profile/Edit'),
    },
    {
        path     : `${prefix}/preferences`,
        name     : `${namePrefix}-preferences`,
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/persons/views/profile/Preferences'),
    },
    {
        path     : `${prefix}/notifications`,
        name     : `${namePrefix}-notifications`,
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/persons/views/profile/Notifications'),
    },
];

export default routes;
