/*-----------------------------------------------------------------
- Dashboard trans
-----------------------------------------------------------------*/
export default {
    
    sidebar: {
        developerTools: 'ابزارهای توسعه‌دهندگان',
        preview       : 'پیش‌نمایش',
        components    : 'کامپوننت‌ها',
        settings      : 'تنظیمات',
        lab           : 'آزمایشگاه',
        info          : 'اطلاعات مدل‌ها',
        routes        : 'مسیر‌ها',
        
        help: 'فهرست راهنما',
    },
    
    widget: {
        title       : 'ابزارک',
        added       : 'ابزارک با موفقیت اضافه شد.',
        removed     : 'ابزارک با موفقیت حذف شد.',
        updated     : 'ابزارک با موفقیت به‌روزرسانی شد.',
        sorted      : 'ابزارک‌ها با موفقیت به‌روزرسانی شدند.',
        library     : 'کتابخانه ابزارک‌ها',
        need_setting: 'این ابزارک برای استفاده به تنظیمات نیاز دارد.',
    },
    
    widgets: {
        content: {
            empty: 'خالی',
        },
        
        user_finder: {
            form: {
                placeholder: 'جستجوی کاربر...',
                button     : 'جستجو',
            },
            
            result: {
                view_profile: 'مشاهده پروفایل',
                no_result   : 'کاربری ',
                more_result : 'نتایج بیشتر',
            },
        },
        
        org_finder: {
            form: {
                placeholder: 'جستجوی سازمان...',
                button     : 'جستجو',
            },
            
            result: {
                view_profile: 'مشاهده پروفایل',
                no_result   : 'سازمانی ',
                more_result : 'نتایج بیشتر',
            },
        },
        
        order_finder: {
            form: {
                placeholder: 'جستجوی سفارش با کد رهگیری',
                button     : 'جستجو',
                reset      : 'پاک کردن جستجو',
            },
            
            result: {
                view_order: 'مشاهده جزئیات سفارش',
                
                info: {
                    tracking_number: 'کد پیگیری سفارش',
                    order_status   : 'وضعیت سفارش',
                    user_name      : 'نام مشتری',
                    date           : 'تاریخ ثبت سفارش',
                    payable_amount : 'مبلغ قابل‌پرداخت',
                    paid_amount    : 'مبلغ پرداخت‌شده',
                    pay_status     : 'وضعیت پرداخت',
                },
                
                no_result: 'سفارشی ',
            },
        },
        
        welcome: {
            greeting             : 'به کوارکینو خوش آمدید!',
            about_quark          : 'بسیار خرسندیم که راه‌کار کوارکینو را برگزیدید.',
            about_quark_desc     : 'شما هم‌اکنون درحال استفاده از کوارکینو نسخه ۴ محصول شرکت دانش‌بنیان فناوری اطلاعات سورنا (یسناتیم) هستید. برای بهره‌مندی از خدمات و بررسی مشکلات، از طریق دکمه پشتیبانی به سامانه ارتباط با مشتریان یسناتیم مراجعه کنید. برای آشنایی با خدمات و سایر محصولات نیز با استفاده از دکمه اطلاعات بیشتر به وب‌سایت یسناتیم مراجعه نمایید.', // eslint-disable-line
            homepage_link_support: 'پشتیبانی',
            homepage_link_info   : 'اطلاعات بیشتر',
        },
    },
    
};
