/* -------------------------------------------------------------------------- */
/* VALIDATE HELPERS                                                           */
/* -------------------------------------------------------------------------- */

/**
 * Validate digit
 *
 * @param {*} digit
 * @returns {boolean}
 */
export const NotValidDigit = (digit) => digit === null || digit === false || typeof digit === 'undefined';

/**
 * Checks if text is email
 *
 * @param {*} string
 * @returns {boolean}
 */
export const isEmail = (string) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(string).toLowerCase());
};

/**
 * Check if string is a valid url
 *
 * @param {*} s
 * @returns {boolean}
 */
export const isUrl = (s) => {
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
    return regexp.test(s);
};
