/*-----------------------------------------------------------------
- Rate Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Rate Save
     *
     * @access public
     * @param {...any} args
     * @returns {*}
     */
    save: (...args: any[]): any => Resource.post('rate-save', args),

};
