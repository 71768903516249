/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/
export default {
    
    toast: {
        move_to_trash: {
            success: 'Moved to trash.',
            error  : 'A problem occurred during the process.',
        },
        
        restore_from_trash: {
            success: 'Successfully restored.',
            error  : 'A problem occurred during the process.',
        },
        
        permanent_delete: {
            success: 'Successfully deleted.',
            error  : 'A problem occurred during the process.',
        },
        
        fetch: {
            error: 'A problem occurred during the process.',
        },
        
        save: {
            success: 'Successfully saved.',
            error  : 'A problem occurred during the process.',
        },
        
        edit: {
            success: 'Successfully updated.',
            error  : 'A problem occurred during the process.',
        },
        
    },
    
    user: {
        create_user: 'Create New User',
        edit_user  : 'Edit User',
    },

    profile: {
        title: '{0}\'s Profile',

        tabs: {
            login_history: {
                empty: 'No login history is recorded for the user.',
            },
            transactions: {
                empty: 'No transactions are recorded for the user.',
            },
            addresses: {
                empty: 'No addresses are recorded for the user.',
            },
        },
    },
    
    new_user: {
        search: {
            placeholder : 'Search (First Name, Last Name, National Code, Email, Username)',
            button      : 'Search',
            new_search  : 'New Search',
            clear       : 'Clear',
            register    : 'Create New User',
            select      : 'Select',
            empty       : 'No user found.',
            not_searched: 'Search among users or create a new user.',
        },
        create: {
            new_user         : 'Create New User',
            add_selected_user: 'Add User {0}',
        },
    },
    
    history: {
        title       : 'Login History',
        date        : 'Date',
        browser     : 'Browser',
        ip          : 'IP',
        os          : 'OS',
        status      : 'Status',
        successful  : 'Successful',
        unsuccessful: 'Unsuccessful',
    },
    
    organization: {
        user_list: {
            add: {
                title        : 'Add new user to this organization',
                placeholder  : 'Search (First Name, Last Name, Email, Username)',
                button       : 'Add user',
                already_exist: 'Already Exist!',
                empty        : 'No user found.',
                success_msg  : 'Selected user added successfully.',
            },
            
            remove: {
                button     : 'Remove',
                success_msg: 'Selected user added successfully.',
            },
        },
    },
};
