/*-----------------------------------------------------------------
- Notifier Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Notifier Create Form
     *
     * @param {...any} args
     * @returns {*}
     */
    create: (...args) => Resource.get('notifier-create-form', args),
    
    /**
     * Notifier Create new notifier driver
     *
     * @param {...any} args
     * @returns {*}
     */
    save: (...args) => Resource.post('notifier-save', args),
    
    /**
     * Notifier Config Form
     *
     * @param {...any} args
     * @returns {*}
     */
    configForm: (...args) => Resource.get('notifier-config-form', args),
    
    /**
     * Notifier Driver Config
     *
     * @param {...any} args
     * @returns {*}
     */
    driverConfig: (...args) => Resource.post('notifier-driver-config', args),
    
    /**
     * Notifier Driver Get
     *
     * @param {...any} args
     * @returns {*}
     */
    driverGet: (...args) => Resource.get('notifier-driver-get', args),
    
    /**
     * Notifier Driver Trash
     *
     * @param {...any} args
     * @returns {*}
     */
    driverTrash: (...args) => Resource.delete('notifier-driver-trash', args),
    
    /**
     * Notifier Driver Restore
     *
     * @param {...any} args
     * @returns {*}
     */
    driverRestore: (...args) => Resource.put('notifier-driver-restore', args),
    
    /**
     * Notifier Driver Destroy
     *
     * @param {...any} args
     * @returns {*}
     */
    driverDestroy: (...args) => Resource.delete('notifier-driver-destroy', args),

    /**
     * Notifier notifications count
     *
     * @param {...any} args
     */
    userNotificationsCount: (...args) => Resource.get('notifier-user-notifications-count', args),

    /**
     * Notifier notifications list
     *
     * @param {...any} args
     */
    userNotificationsList: (...args) => Resource.get('notifier-user-notifications-list', args),

    /**
     * Profile Notifications List
     *
     * @param {...any} args
     */
    myNotifications: (...args) => Resource.get('notifier-my-notifications', args),

    /**
     * Profile Notifications Change
     *
     * @param {...any} args
     */
    userNotificationManagement: (...args) => Resource.post('notifier-user-notification-management', args),

    /**
     * Get the Browser paths list
     *
     * @param {...any} args
     */
    browserPathsList: (...args) => Resource.get('notifier-browser-paths-list', args),
};
