<template>
    <div
        class="form-amount"
        :class="classes"
    >
        <y-form-link
            v-if="isLink"
            type="link"
            :element="element"
        >
            <span v-html="valueContent" />
        </y-form-link>
        <span
            v-else
            v-html="valueContent"
        />
    </div>
</template>

<script>

    import { digits } from '@nodes/helpers/number';

    import ComponentClassesMixin from '@/mixins/ComponentClasses';

    export default {

        name: 'YFormAmount',

        components: {
            YFormLink: () => import('@deps/form/elements/Link'),
        },

        mixins: [ComponentClassesMixin],

        props: {
            /**
             * Element
             */
            element: Object,
        },

        computed: {

            /**
             * Check if type is link
             *
             * @returns {boolean}
             */
            isLink() {
                return typeof this.element.link === 'object' && !Array.isArray(this.element.link);
            },

            /**
             * Return parsed value
             */
            value() {
                const decimal = this.element.decimal >= 0 ? this.element.decimal : 2;
                return parseFloat(this.element.value || 0).toFixed(decimal);
            },

            /**
             * Return parsed value
             */
            link() {
                if (!this.element.link || Array.isArray(this.element.link)) {
                    return null;
                }

                const { link } = this.element;
                link.label = this.valueContent;

                return link;
            },

            /**
             * Return parsed value
             */
            valueContent() {
                let content = Number(this.value).toLocaleString('en');

                content = `<span class="amount-value">${digits(content, this.$i18n.locale)}</span>`;

                if (this.$i18n.locale === 'fa') {
                    content = content.replace('.', '/');
                }

                return this.prefix + content + this.postfix;
            },

            /**
             * Return prefix
             */
            prefix() {
                return this.element.prefix ? `<span>${this.element.prefix} </span>` : '';
            },

            /**
             * Return postfix
             */
            postfix() {
                return this.element.postfix ? `<span> ${this.element.postfix}</span>` : '';
            },

            /**
             * Return classes of element
             *
             * @returns {*}
             */
            classes() {
                const classes = [];

                if (this.element.colorful) {
                    if (this.value > 0) {
                        classes.push('green');
                    } else if (this.value < 0) {
                        classes.push('red');
                    }
                }

                if (this.element.color_negatives && this.value < 0) {
                    classes.push('red');
                }

                if (this.link) {
                    classes.push('with-link');
                }

                return classes;
            },

        },

    };
</script>
