/*-----------------------------------------------------------------
- Data Trader Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Save Data Trades Request
     *
     * @param {...any} args
     * @returns {*}
     */
    dataTradesSave: (...args) => Resource.post('data-trader-data-trades-save', args),

    /**
     * Save Data Trades Request
     *
     * @param {...any} args
     * @returns {*}
     */
    downloadFile: (...args) => Resource.get('data-trader-download-file', args),
};
