<template>
    <div>
        <input
            v-if="isSimple"
            :id="labelFor"
            ref="input"
            type="text"
            :placeholder="placeholder"
            class="number-input"
            :class="[classes]"
            :readonly="!!readonly"
            :disabled="!!disabled"
            :value="valueShow"
            @keypress="isValidKey"
            @input="updateSimpleInput"
            @blur="blurField"
            @focus="focusField"
        >

        <the-mask
            v-else
            :id="labelFor"
            ref="input"
            :mask="format"
            :tokens="numberTokens"
            :placeholder="placeholder"
            class="number-input"
            :class="[{error: hasError, bidi : currentLocale.code === 'ar'}]"
            :readonly="!!readonly"
            :disabled="!!disabled"
            :value="value"
            @input="updateMaskedInput"
            @blur="blurField"
            @focus="focusField"
        />
    </div>
</template>

<script>

    import { TheMask } from 'vue-the-mask';

    import FormElementMixin from '@/mixins/FormElement';
    import { digits } from '@nodes/helpers/number';

    /**
     * Input Component
     */
    export default {

        name: 'YFormNumber',

        components: {
            TheMask,
        },

        mixins: [FormElementMixin],

        inject: {
            $validator: '$validator',
        },

        props: {

            /**
             * Is readonly
             */
            readonly: {
                type   : [Boolean, Number, String],
                default: false,
            },

            /**
             * Input placeholder
             */
            placeholder: {
                type: String,
            },

            /**
             * Is disabled
             */
            disabled: {
                type   : [Boolean, Number, String],
                default: false,
            },

            /**
             * Input locale to parse
             * _(Input Number)_
             */
            locale: {
                type: String,
            },

            /**
             * Format (number, phone, separated)
             */
            format: {
                type   : String,
                default: 'number',
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                currentLocale: null,

                simples: [
                    'number',
                    'separated',
                ],

                locales: {
                    fa: {
                        code     : 'fa',
                        separator: '٬',
                        decimal  : '/',
                    },
                    en: {
                        code     : 'en',
                        separator: ',',
                        decimal  : '.',
                    },
                    ar: {
                        code     : 'ar',
                        separator: '٬',
                        decimal  : '/',
                    },
                },

                decimals: ['.', '/'],
                chars   : [
                    '۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹',
                    '٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩',
                    '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
                    '+',
                ],

                numberTokens: {
                    D: {
                        pattern  : /[0-9\u0660-\u0669\u06F0-\u06F9]/,
                        transform: (v) => digits(v, this.currentLocale.code),
                    },
                },
            };
        },

        computed: {

            /**
             * @inheritDoc
             */
            classes() {
                const classes = [this.direction];

                if (this.hasError) {
                    classes.push('error');
                }

                return classes;
            },

            /**
             * Value to show
             */
            valueShow() {
                if (!this.value && this.value !== 0) {
                    return '';
                }

                return this.formatValue(this.cleanValue(this.value));
            },

            /**
             * Check value has already decimal
             */
            hasDecimal() {
                return this.value && (this.value.indexOf('.') > -1 || this.value.indexOf('/') > -1);
            },

            /**
             * Check if format is simple or mask
             */
            isSimple() {
                return this.simples.includes(this.format);
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            const locale = this.locale || this.$i18n.locale;
            if (locale === 'fa' || locale === 'ar') {
                this.currentLocale = this.locales[locale];
                this.direction = 'rtl ta-r';
            } else {
                this.currentLocale = this.locales.en;
                this.direction = 'ltr ta-l';
            }
        },

        methods: {

            /**
             * Check if char is valid
             *
             * @param event
             */
            isValidKey(event) { // eslint-disable-line consistent-return
                const { key, keyCode } = event;

                if (keyCode === 13) {
                    return true;
                }

                // Format is phone, so it should only be from chars
                if ((this.format === 'phone' && !this.isNumber(key))
                    || (this.isDecimal(key) && this.hasDecimal)
                    || (!this.isNumber(key) && !this.isDecimal(key))) {
                    return event.preventDefault();
                }

                return true;
            },

            /**
             * Check char is number
             *
             * @param key
             */
            isNumber(key) {
                return this.chars.indexOf(key) > -1;
            },

            /**
             * Check char is decimal
             *
             * @param key
             */
            isDecimal(key) {
                return this.decimals.indexOf(key) > -1;
            },

            /**
             * Emit Target Value
             *
             * @event input
             * @param event
             */
            updateSimpleInput(event) {
                const value = this.cleanValue(event.target.value);
                this.$emit('input', value);
            },

            /**
             * Update masked input
             *
             * @param event
             */
            updateMaskedInput(event) {
                this.$emit('input', digits(event, 'en'));
            },

            /**
             * Clean value from extra characters
             *
             * @param value
             */
            cleanValue(value) {
                return digits(value).replace(/[,٬\s]/g, '').replace('/', '.');
            },

            /**
             * Format value
             *
             * @param value
             */
            formatValue(value) {
                if (this.format === 'separated') {
                    return this.formatSeparated(value);
                }

                return this.formatNumber(value);
            },

            /**
             * Format number
             *
             * @param value
             */
            formatNumber(value) {
                return digits(value.replace(/[./]/g, this.currentLocale.decimal), this.currentLocale.code);
            },

            /**
             * Format separated
             *
             * @param value
             */
            formatSeparated(value) {
                const arr = value.split('.');
                let result = this.comma(arr[0]);

                if (arr.length === 2) {
                    result += `${this.currentLocale.decimal}${arr[1]}`;
                }

                return this.formatNumber(result);
            },

            /**
             * Convert to comma separated
             *
             * @param value
             * @returns {string}
             */
            comma(value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.currentLocale.separator);
            },

        },

    };

</script>
