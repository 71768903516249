/*-----------------------------------------------------------------
- Job Routes
-----------------------------------------------------------------*/
const prefix = 'job';
const routes = [
    {
        path     : `${prefix}/seekers/:slug/:id`,
        name     : 'job-seekers-timeline',
        component: () => import(/* webpackChunkName: "job" */ '@/modules/job/views/Single'),
        
    },
    {
        path     : `${prefix}/seekers/:slug`,
        name     : 'job-seekers',
        component: () => import(/* webpackChunkName: "job" */ '@/modules/job/views/Seekers'),
        
    },
];

export default routes;
