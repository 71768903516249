/*-----------------------------------------------------------------
- Fields translations
-----------------------------------------------------------------*/
export default {
    
    link_text: 'متن لینک',
    link     : 'لینک',
    
    target_external: 'آیا لینک در تب جدید باز شود؟',
    
    active: 'فعال بودن',
    
};
