<template>
    <div v-if="element && fieldNames">
        <!-- Selects -->
        <div
            v-if="!element.without_selector"
            class="localed-list"
        >
            <label v-if="element.label">{{ element.label }}</label>
            <y-form-field
                v-for="locale in element.locales"
                :key="`checkbox-${element.id}-${locale}`"
                v-model="selectedLocales[locale]"
                type="checkbox"
                :label="$t(`general.locales.${locale}`)"
                :name="`checkbox-${element.id}-${locale}`"
            />
        </div>

        <template v-for="locale in locales">
            <div
                v-if="element.locales.indexOf(locale) > -1"
                :key="`${element.id}-${locale}`"
                class="localed-inputs"
            >
                <div class="locale-title">
                    {{ $t(`general.locales.${locale}`) }}
                </div>
                <y-form
                    v-if="element.children"
                    v-model="model"
                    :params="rename(element.children, locale)"
                />
            </div>
        </template>
    </div>
</template>

<script>

    import { cloneDeep, has } from 'lodash';

    export default {
        name: 'YFormLocaled',

        components: {
            YForm     : () => import('@deps/form/Form'),
            YFormField: () => import('@deps/form/FormField'),
        },

        inject: {
            $validator: '$validator',
        },

        props: {
            /**
             * Element
             */
            element: Object,

            /**
             * Form builder params
             */
            params: {
                type: [Object, Array],
            },

            /**
             * Form default value passed by parent
             */
            value: {
                type   : [String, Number, Object, Array],
                default: null,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model          : this.value || {},
                selectedLocales: {},
                fieldNames     : this.createFieldNames(this.element.children, []),
            };
        },

        computed: {

            /**
             * Return list of locales
             */
            locales() {
                return Object.keys(this.selectedLocales).filter((locale) => this.selectedLocales[locale] === 1);
            },

            /**
             * Return list of locales
             */
            notSelectedLocales() {
                return this.element.locales.filter((locale) => this.selectedLocales[locale] !== 1);
            },

        },

        watch: {
            /**
             * Watch locales to clear extra data
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.model = this.value;
                },
                deep: true,
            },

            /**
             * Watch locales to clear extra data
             *
             * @param newValue
             * @param oldValue
             */
            selectedLocales: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    if (!this.model) {
                        return;
                    }
                    Object.keys(this.model).forEach((item) => {
                        this.notSelectedLocales.forEach((locale) => {
                            if (has(this.model, `${item}.${locale}`)) {
                                delete this.model[item][locale];
                            }
                        });
                    });
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.createSelectedLocales();
        },

        methods: {

            /**
             * Rename elements and add locale
             *
             * @param children
             * @param fieldNames
             */
            createFieldNames(children, fieldNames) {
                const elements = cloneDeep(children);
                elements.forEach((item) => {
                    if (item.name) {
                        fieldNames.push(item.name);
                    }
                    if (item.children) {
                        this.createFieldNames(item.children, fieldNames);
                    }
                });
                return fieldNames;
            },

            /**
             * Rename elements and add locale
             */
            createSelectedLocales() {
                if (this.element.without_selector) {
                    this.element.locales.forEach((item) => this.$set(this.selectedLocales, item, 1));
                    return;
                }
                this.fieldNames.forEach((item) => {
                    if (this.value) {
                        if ( Array.isArray(this.value[item]) && !this.value[item].length ) {
                            // eslint-disable-next-line vue/no-mutating-props
                            this.value[item] = {};
                            return;
                        }
                        if (this.value.hasOwnProperty(item)) {
                            this.setLocale(this.value[item]);
                        }
                    }
                });
            },

            /**
             * Set locales
             *
             * @param field
             */
            setLocale(field) {
                Object.keys(field).forEach((locale) => this.$set(this.selectedLocales, locale, 1));
            },

            /**
             * Rename elements and add locale
             *
             * @param children
             * @param locale
             */
            rename(children, locale) {
                const elements = cloneDeep(children);
                elements.forEach((item, index) => {
                    elements[index].name += `.${locale}`;
                    if (elements[index].children) {
                        elements[index].children = this.rename(elements[index].children, locale);
                    }
                });
                return elements;
            },
        },
    };
</script>
