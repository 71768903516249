/*-----------------------------------------------------------------
- Fields translations
-----------------------------------------------------------------*/
export default {
    username             : 'نام کاربری',
    email                : 'ایمیل',
    code_melli           : 'کد ملی',
    country_code         : 'پیش‌شماره',
    mobile               : 'موبایل',
    mobile_confirmation  : 'تایید موبایل',
    postal_code          : 'کد پستی',
    tel                  : 'تلفن',
    country              : 'کشور',
    province             : 'استان',
    city                 : 'شهر',
    neighborhood         : 'محله',
    name                 : 'نام',
    name_first           : 'نام',
    name_last            : 'نام خانوادگی',
    password             : 'گذرواژه',
    password_confirmation: 'تایید گذرواژه',
    password2            : 'تکرار گذرواژه',
    token                : 'کد بازنشانی گذرواژه',
    remember             : 'مرا به خاطر بسپار',
    home_address         : 'آدرس',
    reset_password       : 'تغییر رمز عبور',
    gender               : 'جنسیت',
    slug                 : 'نامک',
    text                 : 'متن',
    title                : 'عنوان',
    title2               : 'عنوان دوم',
    subtitle             : 'عنوان دوم',
    site_title           : 'عنوان سایت',
    search               : 'جستجو',
    categories           : 'دسته‌بندی‌ها',
    type                 : 'نوع',
    choose               : 'انتخاب کنید',
    order                : 'چیدمان',
    sort                 : 'مرتب‌سازی',
    desc                 : 'نزولی',
    asc                  : 'صعودی',
    activation_code      : 'کد فعال‌سازی',
    role                 : 'نقش کاربری',
    organization         : 'سازمان',
    status               : 'وضعیت',
    question             : 'پرسش',
    answer               : 'پاسخ',
    category             : 'دسته‌بندی',
    logo                 : 'لوگو',
    created_at           : 'ایجاد در',
    created_by           : 'ایجاد‌کننده',
    published_at         : 'زمان انتشار',
    published_by         : 'منتشرکننده',
    updated_at           : 'به‌روزشده در',
    updated_by           : 'به‌روزشده توسط',
    birthday             : 'تاریخ تولد',
    date                 : 'تاریخ',
    verify_code          : 'تایید کد',
    currency             : 'ارز',
    code                 : 'کد',
    message              : 'پیام',
    row                  : 'ردیف',
    website              : 'وبسایت',
    icon                 : 'نمایه',

    endpoint: 'Endpoint',
    method  : 'نوع درخواست',
    version : 'نسخه',
    mock    : 'آزمایشی',

    image: 'تصویر',

    specs: {
        active: 'بله',
    },

    latitude: 'عرض جغرافیایی',
    lat_help: '( راهنمایی: این عدد در بازه‌ی -۹۰ تا +۹۰ قراردارد. S یا N )',

    longitude: 'طول جغرافیایی',
    long_help: '( راهنمایی: این عدد در بازه‌ی -۱۸۰ تا +۱۸۰ قراردارد. E یا W )',

    transaction_type: 'نوع تراکنش',
    amount          : 'مبلغ',
    register_payment: 'ثبت اطلاعات پرداخت',
    tracking_number : 'کد پیگیری',
    payment_path    : 'مسیر پرداخت',
    is_private      : 'محرمانه',
    description     : 'توضیح',
};
