<template>
    <y-panel id="page-head">
        <div class="top">
            <div class="right">
                <h5 class="title">
                    <i
                        v-if="icon"
                        class="icon"
                        :class="[icon]"
                    />
                    {{ title }}
                </h5>
                <div
                    v-if="desc"
                    class="desc"
                >
                    {{ desc }}
                </div>
            </div>

            <div class="left">
                <slot name="actions" />
            </div>
        </div>

        <div
            v-if="$slots.tabs"
            class="tabs"
        >
            <slot name="tabs" />
        </div>
    </y-panel>
</template>

<script>

    export default {

        name: 'PageHead',

        props: {
            title: String,
            icon : String,
            desc : String,
        },

    };
</script>
