/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    menu: {
        list: 'منوساز',
        edit: 'مدیریت منو',
    },
    
};
