/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    market: {
        page : 'Markets',
        units: 'Units',
        ware : {
            create: 'Add New Product Item',
            edit  : 'Edit Product Item',
        },
    },
    campaigns: {
        list  : 'Campaigns',
        create: 'Create new campaign',
        edit  : 'Edit campaign',
    },
};
