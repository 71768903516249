/*-----------------------------------------------------------------
- Uploader Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Uploader Read File
     *
     * @param {...any} args
     * @returns {*}
     */
    read: (...args) => Resource.get('uploader-read', args),
    
    /**
     * Uploader  Upload File
     *
     * @param {...any} args
     * @returns {*}
     */
    upload: (...args) => Resource.post('uploader-upload', args),
    
};
