<template>
    <span
        v-if="element.text && element.text !== '-'"
        class="form-label"
        :class="classes"
    >

        <y-form-link
            v-if="isLink"
            type="link"
            :element="element"
            class="label"
            :class="classes"
        >
            <i
                v-if="icon"
                class="icon"
                :class="[icon]"
            />
            {{ element.text }}
        </y-form-link>

        <span
            v-else
            class="label"
            :class="classes"
        >
            <i
                v-if="icon"
                class="icon"
                :class="[icon]"
            />
            {{ element.text }}
        </span>

    </span>
</template>

<script>

    import ComponentClassesMixin from '@/mixins/ComponentClasses';

    export default {
        name: 'YFormLabel',

        components: {
            YFormLink: () => import('@deps/form/elements/Link'),
        },

        mixins: [ComponentClassesMixin],

        props: {
            /**
             * Element
             */
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                colorPrefix: '',
            };
        },

        computed: {

            /**
             * Check if type is link
             *
             * @returns {boolean}
             */
            isLink() {
                return !!(typeof this.element.link === 'object' && !Array.isArray(this.element.link));
            },

            /**
             * Generate class list
             *
             * @returns {Array}
             */
            classes() {
                const classes = [this.theme];

                if (this.element.size) {
                    classes.push(this.element.size);
                }
                if (this.element.color) {
                    classes.push(this.element.color);
                }
                if (this.element.alt) {
                    classes.push('alt');
                }

                return classes;
            },

            /**
             * Return icon
             *
             * @returns {Array}
             */
            icon() {
                return this.element.icon;
            },

        },
    };
</script>
