/*-----------------------------------------------------------------
- Wares trans
-----------------------------------------------------------------*/
export default {
    
    tabs: {
        base     : 'پایه',
        measure  : 'عرضه',
        inventory: 'انبارداری',
        price    : 'قیمت',
        sizes    : 'اندازه‌ها',
        payment  : 'پرداخت',
        settings : 'تنظیمات',
        tagging  : 'ویژگی‌ها',
        specs    : 'مشخصات',
    },

    fl_specs: {
        shapes_title           : 'شکل محصول',
        misc_title             : 'سایر',
        min                    : 'حداقل',
        max                    : 'حداکثر',
        shape_sheet            : 'ورق',
        shape_tape             : 'نوار',
        shape_rode             : 'Rode',
        shape_tube             : 'نوار',
        shape_end_shape        : 'End Shape',
        coefficient_of_friction: 'ضریب اصطکاک',
        tensile_strength       : 'استحکام کششی',
        elongation_at_break    : 'ازدیاد طول در نقطه شکستن',
        density                : 'چگالی',
        fda_approval           : 'تصویب FDA',
        rohs_number            : 'شماره Rohs',
        rohs_compliance        : 'رعایت RoHs',
        wras_approval          : 'تایید Wras',
        thermal                : 'دما',
        length                 : 'طول',
        width                  : 'عرض',
        thickness              : 'قطر',
        od                     : 'قطر دایره',
        id                     : 'شعاع دایره',
    },
};
