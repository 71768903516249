/*-----------------------------------------------------------------
- Default Project configs
-----------------------------------------------------------------*/

module.exports = {
    // Name of the project
    name: process.env.VUE_APP_THEME,

    // Support layouts
    layouts: true,
    
    // Loading default mode
    loadingMode: 1,
    
    // The source template file
    overrideTemplate: false,
    
    // The base URL of your application
    publicPath: '/',
    
    // Version of UI style core
    style: 'v1',
    
    // Check if theme should use token in requests or not
    useToken: true,
    
    // Activate/Deactivate runtime compiler
    runtimeCompiler: true,
    
    // Sass configs to load in vue.config.js
    sass: {},
    
    // List of modules of panel that should be enabled or disabled
    modules: {},

    // Links of the developer
    links: {
        yasna: 'https://yasna.team/',
    },

    // Quarkino Branding
    quarkino: {
        fa: '<span>قدرت گرفته از <a class="typo-subtitle-2" href="https://yasna.team" target="_blank">کوارکینو</a></span>',
        en: '<span> Powered by <a class="typo-subtitle-2" href="https://yasna.team" target="_blank">Quarkino</a></span>',
    },
    
    // Autoprefixer configs
    autoprefixer: ['> 1%', 'last 2 versions'],
    
    // Set if site have more than one direction.
    // It'll used to activate/deactivate postcss-rtl
    multiDirectional: true,
    fromRTL         : true,
    
    // Activate/Deactivate Purgecss and config whitelist.
    purgecss: {
        active   : true,
        whitelist: [
            /^mode/,
            /^slb/,
            /^leaflet/,
            /swiper/,
            /iziToast/,
            /col/,
            /row/,
            /container/,
            /quill/,
            /ql/,
        ],
    },
    
    // manifest.json default options
    manifest: {
        manifest_version                       : 1,
        version                                : '1.0',
        name                                   : 'Groot',
        short_name                             : 'Groot',
        description                            : '',
        dir                                    : 'auto',
        lang                                   : 'fa-IR',
        default_locale                         : 'fa',
        background_color                       : '#FAFAFA',
        theme_color                            : '#0A8083',
        display                                : 'standalone',
        categories                             : [],
        orientation                            : 'any',
        'ms-tile-color'                        : '#FAFAFA',
        'apple-mobile-web-app-capable'         : 'yes',
        'apple-mobile-web-app-status-bar-style': 'black',
        author                                 : 'YasnaTeam',
        related_applications                   : [],
        developer                              : {
            name: 'YasnaTeam',
            url : 'https://yasna.team',
        },
    },

};
