<script>
    export default {
        name: 'Col',

        inject: {
            $validator: '$validator',
        },

        props: {
            /**
             * Tag type to create element
             */
            tag: {
                type   : String,
                default: 'div',
            },

            /**
             * Make col center
             */
            center: Boolean,

            /**
             * size of col. make 'col-3' class
             */
            size: [String, Number],

            /**
             * size of col in xs size
             */
            xs: [String, Number],

            /**
             * size of col in sm size
             */
            sm: [String, Number],

            /**
             * size of col in md size
             */
            md: [String, Number],

            /**
             * size of col in lg size
             */
            lg: [String, Number],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {};
        },

        computed: {
            /**
             * Creates a list of col classes
             *
             * @returns {*}
             */
            classes() {
                const classes = [];

                if (this.size) {
                    classes.push(`col-${this.size}`);
                }

                if (this.center) {
                    classes.push('col-center');
                }

                if (this.xs) {
                    classes.push(`col-xs-${this.xs}`);
                }
                if (this.sm) {
                    classes.push(`col-sm-${this.sm}`);
                }
                if (this.md) {
                    classes.push(`col-md-${this.md}`);
                }
                if (this.lg) {
                    classes.push(`col-lg-${this.lg}`);
                }

                if (!this.size && !this.xs && !this.sm && !this.md && !this.lg) {
                    classes.push('col');
                }

                return classes;
            },

        },

        methods: {
            /**
             * Emit click event
             *
             * @param e
             */
            callback(e) {
                this.$emit('click', e);
            },
        },

        /**
         * @inheritDoc
         */
        render(h) {
            return h(
                this.tag,
                {
                    class: this.classes,
                    on   : {
                        click: this.callback,
                    },
                },
                this.$slots.default,
            );
        },

    };
</script>
