/*-----------------------------------------------------------------
- Stock translations
-----------------------------------------------------------------*/
export default {
    
    item: 'Rate',
    
    rateChart: {
        title: {
            min    : 'Minimum Rate',
            average: 'Average Rate',
            max    : 'Maximum Rate',
        },
        tooltip: {
            price: {
                start  : 'Price at the Start of the Day',
                end    : 'Price at the End of the Day',
                min    : 'Minimum Price',
                max    : 'Maximum Price',
                average: 'Average Price',
            },
        },
    },
    
};
