/*-----------------------------------------------------------------
- Accountant Fields Locales
-----------------------------------------------------------------*/
export default {
    
    activeness : 'فعال و قابل استفاده',
    description: 'توضیحات',
    wallet     : 'کیف پول',
    gate       : 'مسیر پرداخت',
    debtor     : 'بدهکار',
    creditor   : 'بستانکار',
    
    document_desc: 'توضیحات',
    posting_desc : 'شرح',
    
    verification_note: 'شرح تایید',
    rejection_note   : 'شرح رد',
    attachments      : 'فایل‌های ضمیمه',
    group            : 'گروه',
    segregate        : 'تفصیلی',
    ledger           : 'معین',
    is_settlement    : 'سند تسویه',
    document         : 'سند',
    manual_code      : 'کد دستی',
};
