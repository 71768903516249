<template>
    <p
        class="form-paragraph"
        :class="classes"
    >
        <y-form-link
            v-if="isLink"
            type="link"
            :element="element"
        >
            {{ text }}
        </y-form-link>
        <span
            v-else
            v-html="text"
        />
        <y-copy-value
            v-if="copy"
            :value="copy && typeof copy === 'string' ? copy : text"
        />
    </p>
</template>

<script>

    import ComponentClassesMixin from '@/mixins/ComponentClasses';
    import YCopyValue from '@deps/CopyValue';

    export default {

        name: 'YFormParagraph',

        components: {
            YFormLink: () => import('@deps/form/elements/Link'),
            YCopyValue,
        },

        mixins: [ComponentClassesMixin],

        props: {

            /**
             * @property {object} Element object
             */
            element: Object,

            /**
             * @property {string | number} Content text
             */
            text: [Number, String],

            /**
             * @property {(object | Array)} Link object
             */
            link: [Object, Array],

            /**
             * If there should be copy button
             */
            copy: [String, Number, Boolean],

        },

        computed: {

            /**
             * Check if type is link
             *
             * @returns {boolean}
             */
            isLink() {
                return typeof this.link === 'object' && !Array.isArray(this.link);
            },

        },

    };
</script>
