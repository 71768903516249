/*-----------------------------------------------------------------
- Wares trans
-----------------------------------------------------------------*/
export default {
    
    grid: {
        used_count: 'Used Count',
        actions   : {
            copy  : 'Copy',
            share : 'Share',
            qrcode: 'QR Code',
        },
    },

    modal: {
        title        : 'Share QR Code',
        actions_title: 'Share',
    },
    
};
