/*-----------------------------------------------------------------
- Yasnateam Routes
-----------------------------------------------------------------*/
const prefix = 'yasnateam';

const routes = [
    {
        path     : `${prefix}/brief/:id`,
        name     : `${prefix}-brief-timeline`,
        component: () => import(/* webpackChunkName: "yasnateam" */ '@/modules/yasnateam/views/Single'),
    },
];

export default routes;
