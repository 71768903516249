/*-----------------------------------------------------------------
- Find and merge form components
-----------------------------------------------------------------*/
import sectionConfigs from '@nodes/configs/modules';

const { modules } = sectionConfigs;
const components = {};

const importedFiles = {
    theme       : null,
    themeModules: null,
};

// Load files
// TODO: change `@deps` to the `@components` after refactoring all the components in the `@deps` folder.
importedFiles.theme = require.context('@deps/form/elements', true, /\.js$/);
importedFiles.themeModules = require.context('@/modules', true, /\/components\/form\/elements[\S]*\.js$/);

// Merge configs
Object.keys(importedFiles).forEach( (section) => {
    importedFiles[section].keys().forEach((fileName) => {
        let file;
        
        // Check if section is modules
        if (section === 'themeModules') {
            const module = fileName.split('/')[1];
            if (modules.all || modules[module] === true) {
                file = importedFiles[section](fileName).default;
            }
        } else {
            file = importedFiles[section](fileName).default;
        }
        
        if (file) {
            components[file.name] = file;
        }
    });
});

export default components;
