/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/
export default {
    
    title             : 'تنظیمات سایت',
    type              : 'تنظیمات',
    search_placeholder: 'جستجوی تنظیمات...',
    
    categories: {
        general             : 'عمومی',
        template            : 'تنظیمات قالب', // eslint-disable-line @intlify/vue-i18n/no-raw-text
        finance             : 'امور مالی',
        infrastructure      : 'زیرساخت',
        content             : 'محتوا',
        persons             : 'کاربران',
        contact             : 'اطلاعات تماس',
        market              : 'فروشگاه',
        data                : 'داده‌ها',
        gateway             : 'درگاه',
        group               : 'گروه',
        notification_drivers: 'درایورهای اطلاع‌رسانی',
        payment             : 'پرداخت',
    },

    reset_settings: {
        title : 'تنظیمات به مقادیر پیش‌فرض بازنشانی می‌شوند.',
        button: 'بازنشانی تنظیمات',
    },

    messages: {
        reset_success: 'تنظیمات با موفقیت بازنشانی شدند.',
    },
};
