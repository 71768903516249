/*-----------------------------------------------------------------
- Shipping trans
-----------------------------------------------------------------*/
export default {
    
    yasnateam: {
        single: 'درخواست آنلاین',
    },
    
};
