<template>
    <div
        id="auth-layout"
        class="l-auth"
    >
        <y-container>
            <div id="logo-big">
                <img
                    v-if="$settings('site_logos.panel')"
                    :src="$settings('site_logos.panel.link')"
                    :alt="alt"
                >

                <img
                    v-else
                    src="@/assets/images/quarkino-logo.png"
                    :alt="alt"
                >
            </div>

            <slot />
        </y-container>
    </div>
</template>

<script>
    import { YContainer } from '@deps';

    export default {
        name: 'AuthLayout',

        components: {
            YContainer,
        },

        computed: {
            /**
             * Logo alt field from site title
             */
            alt() {
                const alt = this.$settings('site_title.site_title');
                return alt || 'Authentication Page Logo';
            },
        },
    };
</script>
