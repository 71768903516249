<template>
    <!-- eslint-disable vue/no-mutating-props -->
    <y-panel :title="element.title">
        <div>
            <div v-if="showFields">
                <y-form-field
                    v-model="model.lat"
                    type="text"
                    :label="$t('fields.latitude')"
                    :disabled="$wait.is('handle-map')"
                    class="mb10"
                />
                <p class="form-paragraph fz-xs">
                    {{ $t('fields.lat_help') }}
                </p>

                <y-form-field
                    v-model="model.long"
                    type="text"
                    :label="$t('fields.longitude')"
                    :disabled="$wait.is('handle-map')"
                    class="mb10"
                />
                <p class="form-paragraph fz-xs">
                    {{ $t('fields.long_help') }}
                </p>
                <y-button
                    loading-on="handle-map"
                    size="xs"
                    class="mb15 ml5"
                    @click.prevent="clearFields"
                >
                    {{ $t('button.clear') }}
                </y-button>
                <y-button
                    loading-on="handle-map"
                    size="xs"
                    class="mb15"
                    @click.prevent="mapRefresh"
                >
                    {{ $t('button.newCoordinates') }}
                </y-button>
                <y-button
                    size="xs"
                    class="mb15 mr5"
                    @click.prevent="toggleShowFields"
                >
                    {{ $t('button.manualCoordinators.hide') }}
                </y-button>
            </div>
            <y-button
                v-else
                size="xs"
                class="mb15"
                @click.prevent="toggleShowFields"
            >
                {{ $t('button.manualCoordinators.show') }}
            </y-button>
        </div>
        
        <div
            v-if="mapId"
            :id="mapId"
            class="map-element"
            :style="{ height: `${element.height}px` }"
        />
        <div>
            <y-button
                v-if="multiple"
                :color="deleteMode ? 'red' : 'blue'"
                @click.native.prevent="handleDeleteMode"
            >
                {{ $t("button.deleteMapMarker") }}
            </y-button>
            <span v-if="deleteMode">{{
                $t("components.map.deleteMarkerHint")
            }}</span>
        </div>
    </y-panel>
</template>

<script>
    import 'leaflet/dist/leaflet';
    import { generateId } from '@nodes/helpers/string';
    import YFormField from '@deps/form/FormField';

    export default {

        name: 'YFormMap',

        components: {
            YFormField,
        },

        props: {
            /**
             * Element of map
             */
            element: Object,

            /**
             * Input Value
             */
            value: { // eslint-disable-line vue/require-prop-types
                default: () => [],
            },

            /**
             * Enable Multiple Marker feature for components
             */
            multiple: {
                type   : Boolean,
                default: false,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model  : this.multiple ? this.element.model : this.value,
                center : null,
                default: {
                    center: {
                        lat : 35.7001388,
                        long: 51.4033774,
                    },
                },
                deleteMode: false,
                mapId     : null,
                showFields: false,
            };
        },

        computed: {

            /**
             * Marker Position
             *
             * @returns {null|never|string[]}
             */
            markerPosition() {
                return this.element.marker ? this.element.marker.split(',') : null;
            },

            /**
             * Popup layout
             *
             * @returns {*}
             */
            popupLayout() {
                return this.element.this.popup || null;
            },

        },

        watch: {
            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },

            /**
             * Watch model to emit the value
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        beforeMount() {
            this.mapId = generateId();
            this.$wait.end('handle-map');
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.setCenter();
            this.initMap();
        },

        methods: {
            /**
             * Clear coordinates fields
             */
            clearFields() {
                this.model = { lat: '', long: '' };
            },

            /**
             * Toggle show fields
             */
            toggleShowFields() {
                this.showFields = !this.showFields;
            },

            /**
             * Set default value for marker & reset map to default
             */
            resetMarker() {
                // eslint-disable-next-line vue/no-mutating-props
                this.value.lat = this.element.value ? this.element.value.lat : this.default.center.lat;
                // eslint-disable-next-line vue/no-mutating-props
                this.value.long = this.element.value ? this.element.value.long : this.default.center.long;
                setTimeout(() => this.mapRefresh(), 3000);
            },

            /**
             * Refresh map
             */
            mapRefresh() {
                try {
                    this.$wait.start('handle-map');

                    // Set new value for lat & long
                    // eslint-disable-next-line vue/no-mutating-props
                    this.value.lat = this.formatLocation(this.value.lat.toString().trim());
                    // eslint-disable-next-line vue/no-mutating-props
                    this.value.long = this.formatLocation(this.value.long.toString().trim());

                    // Generate new map id
                    const id = generateId();
    
                    // Set new map element
                    document.getElementById(this.mapId).innerHTML = `<div class="map-element" id="${id}" style="height: ${this.element.height}px"></div>`;
    
                    // Assign new map id in global variable
                    this.mapId = id;
        
                    // Initial new map
                    this.setCenter();
                    this.initMap();

                    this.$wait.end('handle-map');
                } catch {
                    this.$wait.end('handle-map');
                    this.$toast.error(this.$t('general.error.invalid'));
                }
            },
            
            /**
             * Format location
             *
             * @param input
             * @returns {string|null}
             */
            formatLocation(input) {
                let type = null;
                if (input) {
                    type = this.coordinatesType(input);
                }
                
                if (type === 'DD') {
                    return input;
                }
                if (type === 'DMS') {
                    return this.ParseDMS(input).toFixed(5);
                }
                return null;
            },

            /**
             * Coordinates type (DMS format should be like this => DD°MM'SS.S"E or DD°MM'SS.S")
             *
             * @param location
             * @returns {string}
             */
            coordinatesType(location) {
                const v = location.toString();
                if ((v.includes('°') || v.toLowerCase().includes('d')) && v.includes('\'') && v.includes('"')) {
                    return 'DMS';
                }
                return 'DD';
            },

            /**
             * Parse DMS
             *
             * @param input
             * @returns {string}
             */
            ParseDMS(input) {
                const parts = input.split(/[^\d\w]+/);
                if (input.includes('.')) {
                    return this.ConvertDMSToDD(parts[0], parts[1], parseFloat(`${parts[2]}.${parts[3]}`, 10), parts[4]);
                }
                return this.ConvertDMSToDD(parts[0], parts[1], parts[2], parts[3]);
            },

            /**
             * Convert DMS to DD
             *
             * @param degrees
             * @param minutes
             * @param seconds
             * @param direction
             */
            ConvertDMSToDD(degrees, minutes, seconds, direction) {
                let dd = parseInt(degrees, 10) + ( parseInt(minutes, 10) + parseFloat(seconds / 60) ) / 60;

                if (direction.toLowerCase() === 's' || direction.toLowerCase() === 'w') {
                    dd *= -1;
                } // Don't do anything for N or E
                
                return dd;
            },

            /**
             * Set Center of the map
             */
            setCenter() {
                if (this.multiple) {
                    this.center = {
                        lat : this.element.value ? this.element.value.lat : this.default.center.lat,
                        long: this.element.value ? this.element.value.long : this.default.center.long,
                    };
                } else {
                    this.center = {
                        lat : (this.value && this.value.lat) ? this.value.lat : this.default.center.lat,
                        long: (this.value && this.value.long) ? this.value.long : this.default.center.long,
                    };
                }
            },

            /**
             * Init map
             */
            initMap() {
                const id = this.mapId;

                const self = this;

                let marker; // eslint-disable-line

                const map = L.map(id).setView(Object.values(this.center), this.element.zoom || 17); // eslint-disable-line no-undef

                const markerImage = L.icon({ // eslint-disable-line no-undef
                    iconUrl   : require('@/assets/images/map/map-marker.png'), // eslint-disable-line global-require
                    iconSize  : [25, 41],
                    iconAnchor: [13, 41],

                });

                /**
                 * Get user location from the browser if there is no marker
                 */
                if (!this.model) {
                    map.locate({ setView: true });
                }

                /**
                 * Remove the clicked marker
                 *
                 * @param e
                 */
                function removeMarker(e) { // eslint-disable-line
                    let index;
                    const item = {
                        lat : this._latlng.lat,
                        long: this._latlng.lng,
                    };
                    if (self.deleteMode) {
                        self.model.map((i) => { // eslint-disable-line
                            if (i.lat === item.lat) {
                                if (i.long === item.long) {
                                    index = self.model.indexOf(i);
                                    return index;
                                }
                            }
                        });
                        self.model.splice(index, 1);
                        map.removeLayer(this);
                    }
                }

                /**
                 * Drag the marker and update its latlng
                 *
                 * @param e
                 */
                function dragMarker(e) {
                    let index;
                    self.model.map((i) => { // eslint-disable-line
                        if (i.id === e.target.options.id) {
                            index = self.model.indexOf(i);
                            self.model[index].lat = e.target._latlng.lat;
                            self.model[index].long = e.target._latlng.lng;
                            return index;
                        }
                    });
                }

                /**
                 * When user location found, pan the map to the user location
                 * (pan: make the user location the center of the map)
                 *
                 * @param e
                 */
                function onLocationFound(e) { // eslint-disable-line
                    map.panTo(new L.LatLng(e.latlng.lat, e.latlng.lng)); // eslint-disable-line
                }

                /**
                 * If user location can't be found or user deny access to the browser location, Shows Error
                 *
                 * @param e
                 */
                function onLocationError(e) { // eslint-disable-line
                    // console.log('We can not access to your location', e);
                }

                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { // eslint-disable-line no-undef
                    attribution: '',
                }).addTo(map);

                if (this.multiple) {
                    /**
                     * Place saved marker on the map
                     */
                    this.element.model.forEach((model) => {
                        const markerPos = {
                            id : model.id,
                            lat: model.lat,
                            lng: model.long,
                        };
                        marker = L.marker(markerPos, { // eslint-disable-line no-undef
                            icon     : markerImage,
                            draggable: true,
                            id       : markerPos.id,
                        }).addTo(map).on('click', removeMarker)
                            .on('dragend', dragMarker);
                    });

                    /**
                     * Add new marker when user clicked on the map
                     */
                    map.on('click', (e) => { // eslint-disable-line
                        marker = L.marker(e.latlng, { // eslint-disable-line no-undef
                            icon     : markerImage,
                            draggable: true,
                        }).addTo(map).on('click', removeMarker).on('dragend', dragMarker);
                        self.model.push(e.latlng);
                    });
                }

                if (!this.multiple) {
                    let markerPos;
                    if (this.model && this.model.length > 0) {
                        markerPos = {
                            lat: this.model.lat,
                            lng: this.model.long,
                        };
                    } else {
                        markerPos = {
                            lat: this.center.lat,
                            lng: this.center.long,
                        };
                    }

                    marker = L.marker(markerPos, { // eslint-disable-line no-undef
                        icon: markerImage,
                    }).addTo(map);

                    map.on('move', () => {
                        marker.setLatLng(map.getCenter());
                    });

                    map.on('dragend', () => {
                        const position = Object.values(marker.getLatLng());
                        this.$emit('input', { lat: position[0], long: position[1] });
                    });
                }

                map.on('locationfound', onLocationFound);
                map.on('locationerror', onLocationError);
            },

            /**
             * Toggle delete mode
             */
            handleDeleteMode() {
                this.deleteMode = !this.deleteMode;
            },

            /**
             * Emit input event on update text
             *
             * @param event
             */
            updateText(event) {
                this.model = {
                    lat : event.target.value[0],
                    long: event.target.value[1],
                };
                this.$emit('input', this.model);
            },

        },

    };
</script>
