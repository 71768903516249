/*-----------------------------------------------------------------
- Slideshows fields
-----------------------------------------------------------------*/
export default {
    
    button_title   : 'Button Title',
    button_link    : 'Button Link',
    button_external: 'Open in a New Tab',
    
    slide: 'Slide Image',
    
    position: 'Position',
    color   : 'Color Theme',
    
    publish_now : 'Publish Now',
    published_at: 'Published at',
    
    slideshow: {
        autoplay      : 'Autoplay',
        pause_on_hover: 'Pause on Mouse Over',
        spaceBetween  : 'Space Between Slides',
        slidesPerView : 'Slides per View',
        loop          : 'Loop',
        effect        : 'Effect',
        direction     : 'Direction',
        speed         : 'Speed (ms)',
        transition    : 'Transition (ms)',
    },
    
    has_content: 'Has Content',
    has_button : 'Has Button',
    
    slideshow_colors: {
        light: 'Light',
        dark : 'Dark',
    },
    
    slideshow_positions: {
        center_top   : 'Center - Top',
        center_center: 'Center - Center',
        center_bottom: 'Center - Bottom',
        right_top    : 'Right - Top',
        right_center : 'Right - Center',
        right_bottom : 'Right - Bottom',
        left_top     : 'Left - Top',
        left_center  : 'Left - Center',
        left_bottom  : 'Left - Bottom',
    },
    
};
