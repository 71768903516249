/*-----------------------------------------------------------------
- Content posts Routes
-----------------------------------------------------------------*/
const prefix = 'content/post/:type';
const routes = [
    {
        path     : `${prefix}/create`,
        name     : 'content-post-create',
        component: () => import(/* webpackChunkName: "content" */ '@/modules/content/views/post/Edit'),
    },
    {
        path     : `${prefix}/:id/edit`,
        name     : 'content-post-edit',
        component: () => import(/* webpackChunkName: "content" */ '@/modules/content/views/post/Edit'),
    },
    {
        path     : `${prefix}`,
        name     : 'content-post-list',
        component: () => import(/* webpackChunkName: "content" */ '@/modules/content/views/post/List'),
    },
];

export default routes;
