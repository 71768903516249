<template>
    <div
        class="select-loadable"
        :class="[{'select-loading': isLoadingActive}]"
    >
        <multiselect
            :custom-label="customLabel({labelField})"
            :allow-empty="allowEmpty"

            :deselect-group-label="trans.deselectGroupLabel"
            :deselect-label="trans.deselectLabel"
            :disabled="!!disabled"
            :group-label="groupLabel"
            :group-select="groupSelect"
            :group-values="groupValues"
            :label="labelField"

            :multiple="multi"
            :options="parsedOptions"
            :placeholder="placeholderValue"

            :select-group-label="trans.selectGroupLabel"
            :select-label="trans.selectLabel"

            :selected-label="trans.selectedLabel"

            :tag-placeholder="trans.tagPlaceholder"
            :taggable="taggable"
            :track-by="labelField"

            :close-on-select="multi ? closeOnSelect : true"

            :value="selectedSet"
            :internal-search="!hasEndpoint"

            @input="setSelected"
            @select="$emit('select', $event)"

            @tag="$emit('tag', $event)"
            @search-change="startSearch"
        >
            <span slot="noResult">
                {{ searched ? $t('general.form_select.no_result') : $t('general.form_select.no_search') }}
            </span>
            <span slot="noOptions">
                {{
                    hasEndpoint ? (hasSearch ? $t('general.form_select.no_options') : $t('general.form_select.no_search')) : $t('general.form_select.no_options')
                }}
            </span>

            <template #singleLabel="props">
                {{ props.option[labelField] }}
            </template>
            <template #option="props">
                <span v-if="props.option.isTag">{{ props.option.label }}</span>
                <span v-else-if="props.option.$isLabel">{{ props.option.$groupLabel }}</span>
                <span v-else-if="props.option.search">{{ props.option.search }}</span>
                <span v-else>
                    {{ props.option[labelField] }}
                </span>
            </template>
            <template #clear>
                <div
                    v-if="hasSelected && allowEmpty"
                    class="multiselect__clear"
                    @mousedown.prevent.stop="clearSelected"
                />
            </template>
        </multiselect>

        <div
            :id="`loading-${generateId()}`"
            class="page-loading"
            :class="[{'active': isLoadingActive}]"
        >
            <div class="spinner-wrapper">
                <div class="spinner" />
            </div>
        </div>
    </div>
</template>

<script>
    import 'vue-multiselect/dist/vue-multiselect.min.css';
    import { generateId } from '@nodes/helpers/string';
    import Multiselect from 'vue-multiselect';
    import { isEqual } from 'lodash';

    import { Resource } from '@nodes/services';

    /**
     * Select Component
     */
    export default {

        name: 'YFormSelect',

        components: {
            Multiselect,
        },

        props: {
            /**
             * Select Value
             */
            value: {
                type   : [Object, Array, String, Number],
                default: '',
            },

            /**
             * Select List Options
             */
            options: {
                type   : [Object, Array],
                default: () => [],
            },

            /**
             * Select Placeholder
             */
            placeholder: {
                type: String,
            },

            /**
             * Group Select Value Field Name
             */
            groupValues: String,

            /**
             * Group Select Label Field Name
             */
            groupLabel: String,

            /**
             * Is Group Select
             */
            groupSelect: Boolean,

            /**
             * Should select close after click
             */
            closeOnSelect: {
                type   : Boolean,
                default: false,
            },

            /**
             * Options List Value Field Name
             */
            valueField: {
                type   : String,
                default: 'value',
            },

            /**
             * Options List Label Field Name
             */
            labelField: {
                type   : String,
                default: 'label',
            },

            /**
             * Is Loading Style
             */
            isLoading: {
                type   : Boolean,
                default: true, // eslint-disable-line vue/no-boolean-default
            },

            /**
             * Is Multi-select
             */
            multi: {
                type   : Boolean,
                default: false,
            },

            /**
             * Is Taggable
             */
            taggable: {
                type   : Boolean,
                default: false,
            },

            /**
             * Select can be empty
             */
            allowEmpty: {
                type   : Boolean,
                default: false,
            },

            /**
             * Is disabled
             */
            disabled: [Boolean, String],

            /**
             * Input name
             */
            name: String,

            /**
             * Endpoint params to get options
             */
            endpoint: Object,

            /**
             * Endpoint search field
             */
            search: {
                type   : String,
                default: 'search',
            },

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                selected   : this.multi ? [] : null,
                selectedSet: this.multi ? [] : null,

                isSelectedSet: false,

                trans: {
                    tagPlaceholder    : this.$t('button.add'),
                    selectLabel       : '',
                    selectGroupLabel  : '',
                    selectedLabel     : '',
                    deselectLabel     : '',
                    deselectGroupLabel: '',
                },

                parsedOptions: this.options || null,

                searched   : false,
                isSearching: false,
                delayTimer : null,
            };
        },

        computed: {

            /**
             * Check if has endpoint
             */
            hasEndpoint() {
                return this.has(this.endpoint, 'name', false);
            },

            /**
             * Check if has search query
             */
            hasSearch() {
                return this.search && this.search !== 'search';
            },

            /**
             * Check if there is a loading
             */
            isLoadingActive() {
                return this.isLoading || this.isSearching;
            },

            /**
             * Return placeholder value
             *
             * @returns {string}
             */
            placeholderValue() {
                const replace = this.hasEndpoint
                    ? this.$t('components.select.placeholderSearch')
                    : this.$t('components.select.placeholder');
                return this.placeholder || replace;
            },

            /**
             * Check if any option is selected
             */
            hasSelected() {
                if (this.multi && this.selected && Array.isArray(this.selected)) {
                    return !!this.selected.length;
                }
                return !!this.selected;
            },
        },

        /**
         * @inheritDoc
         */
        watch: {
            /**
             * Set new selected item when value changes
             *
             * @param value
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(newVal, oldVal) {
                    this.handleValueChange(newVal, oldVal);
                },
                deep: true,
            },

            /**
             * Set new selected item when value changes
             *
             * @param value
             */
            selected: {
                // eslint-disable-next-line require-jsdoc
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$emit('input', newVal);
                    }
                },
                deep: true,
            },

            /**
             * Change selected item when options changes
             *
             * @param newVal
             * @param oldVal
             */
            options(newVal, oldVal) {
                if (!this.isEqual(newVal, oldVal)) {
                    this.parsedOptions = newVal;
                    this.handleValueChange(this.value);
                }
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.handleValueChange(this.value);
            const el = this.$el;
            if (el instanceof Element) {
                if (el.hasAttribute('data-vv-as')) {
                    this.el = el.getAttribute('data-vv-as');
                } else if (this.name !== null) {
                    el.setAttribute('data-vv-as', this.$t(`fields.${this.name}`));
                }
            }
        },

        methods: {
            generateId,
            isEqual,

            /**
             * Handle value and options changes
             *
             * @param newVal
             * @param oldVal
             */
            handleValueChange(newVal, oldVal) {
                if (this.multi && newVal) {
                    const val = !Array.isArray(newVal) ? [newVal] : newVal;
                    if (!this.isEqual(val, oldVal)) {
                        this.selected = val;
                        this.selectedSet = this.parsedOptions.filter((item) => val.includes(item[this.valueField] ?? item.id));
                    }
                } else if (newVal !== oldVal) {
                    this.selected = newVal;
                    if (typeof newVal === 'object') {
                        this.selectedSet = newVal;
                    } else {
                        this.selectedSet = this.parsedOptions.find((item) => (item[this.valueField] ?? item.id) === newVal);
                    }
                }
            },

            /**
             * Returns the label as custom label
             *
             * @param label
             * @param label.label
             * @returns {*}
             */
            customLabel({ label }) {
                return label;
            },

            /**
             * Set selected item
             *
             * @param options
             */
            setSelected(options) {
                this.selectedSet = options;
                if (Array.isArray(options)) {
                    this.selected = options.map((item) => (item[this.valueField] ?? item.id));
                    return;
                }

                if (this.groupSelect || (this.groupValues && this.groupLabel)) {
                    this.selected = options;
                    return;
                }

                this.selected = options[this.valueField] ?? options.id;
            },

            /**
             * Search with string
             *
             * @param q
             */
            startSearch(q) {
                clearTimeout(this.delayTimer);
                this.searched = false;
                if (!this.hasEndpoint || !q) {
                    return;
                }
                this.delayTimer = setTimeout(() => {
                    this.doSearch(q);
                }, 1000);
            },

            /**
             * Run the search
             *
             * @param q
             */
            doSearch(q) {
                const method = (this.endpoint.method || 'get').toLowerCase();
                const version = this.endpoint.version || 1;
                const { name } = this.endpoint;

                let { params } = this.endpoint;
                if (!params || (typeof params === 'object' && Array.isArray(params))) {
                    params = {};
                }

                params[this.search] = q;
                params.including = [this.labelField, this.valueField];
                this.isSearching = true;
                Resource[method](name, [params, version]).then((response) => {
                    this.searched = true;
                    const { results } = response.data;
                    this.$set(this, 'parsedOptions', results);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.isSearching = false;
                });
            },

            /**
             * Clear selected
             */
            clearSelected() {
                this.selected = this.multi ? [] : null;
                this.selectedSet = this.multi ? [] : null;
            },
        },
    };
</script>
