/*-----------------------------------------------------------------
- User Routes
-----------------------------------------------------------------*/
const prefix = 'cart';

const routes = [
    // Mega Menu
    {
        path     : `${prefix}/:slug/`,
        name     : `${prefix}`,
        component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/Wrapper'),
        
        children: [
            // Pre
            {
                path     : 'pre-orders',
                name     : `${prefix}-pre-orders-list`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/pre/List'),
            },
            {
                path     : 'pre-orders/:id/edit',
                name     : `${prefix}-pre-orders-edit`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/Editor'),
            },
            {
                path     : 'pre-orders/create',
                name     : `${prefix}-pre-orders-create`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/Editor'),
            },
    
            // Order
            {
                path     : 'orders',
                name     : `${prefix}-orders-list`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/orders/List'),
            },
            {
                path     : 'orders/:id/edit',
                name     : `${prefix}-orders-edit`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/Editor'),
            },
            {
                path     : 'orders/create',
                name     : `${prefix}-orders-create`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/Editor'),
            },
            
            // Claim
            {
                path     : 'claims',
                name     : `${prefix}-claims-list`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/claims/List'),
            },
            {
                path     : 'claims/process/:id',
                name     : `${prefix}-claims-process`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/claims/Process'),
            },
            {
                path     : 'claims/create/:order?',
                name     : `${prefix}-claims-create`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/claims/Create'),
            },
            {
                path     : 'claims/edit/:id',
                name     : `${prefix}-claims-edit`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/claims/Create'),
            },
    
            // Invoice
            {
                path     : 'invoices',
                name     : `${prefix}-invoices-list`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/invoices/List'),
            },
            {
                path     : 'invoices/:id',
                name     : `${prefix}-invoices-single`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/invoices/Single'),
            },
            {
                path     : 'invoices/:id',
                name     : `${prefix}-invoices-print`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/invoices/Single'),
            },
    
            // Details
            {
                path     : `${prefix}/details/:id`,
                name     : `${prefix}-details`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/Details'),
            },
    
            // Bill
            {
                path     : `${prefix}/bill/:id`,
                name     : `${prefix}-bill`,
                component: () => import(/* webpackChunkName: "cart" */ '@/modules/cart/views/Bill'),
            },
        ],
    },
];

export default routes;
