/*-----------------------------------------------------------------
- Content label Routes
-----------------------------------------------------------------*/
const prefix = 'icib';
const routes = [
    {
        path     : `${prefix}/plans`,
        name     : 'icib-plans-list',
        component: () => import(/* webpackChunkName: "icib" */ '@/modules/icib/views/plans/List'),
    },
    {
        path     : `${prefix}/organizations/:id/followers`,
        name     : 'icib-organizations-followers',
        component: () => import(/* webpackChunkName: "icib" */ '@/modules/icib/views/organizations/Followers'),
    },
    {
        path     : `${prefix}/organizations/:id`,
        name     : 'icib-organizations-single',
        component: () => import(/* webpackChunkName: "icib" */ '@/modules/icib/views/organizations/Single'),
    },
    {
        path     : `${prefix}/organizations`,
        name     : 'icib-organizations-list',
        component: () => import(/* webpackChunkName: "icib" */ '@/modules/icib/views/organizations/List'),
    },
    {
        path     : `${prefix}/invoices`,
        name     : 'icib-invoices-list',
        component: () => import(/* webpackChunkName: "icib" */ '@/modules/icib/views/invoices/List'),
    },
    {
        path     : `${prefix}/invoice/:id`,
        name     : 'icib-invoice-single',
        component: () => import(/* webpackChunkName: "icib" */ '@/modules/icib/views/invoices/Single'),
    },

    {
        path     : `${prefix}/users/:id/followings`,
        name     : 'icib-users-followings',
        component: () => import(/* webpackChunkName: "icib" */ '@/modules/icib/views/users/Followings'),
    },

    {
        path     : `${prefix}/achievements`,
        name     : 'icib-achievements-list',
        component: () => import(/* webpackChunkName: "icib" */ '@/modules/icib/views/achievements/List'),
    },
];

export default routes;
