/*-----------------------------------------------------------------
- Wallet Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * My Wallets
     * Fetch current user wallets
     *
     * @access public
     * @param {...any} args
     * @returns {*}
     */
    myWallets: (...args: any[]): any => Resource.get('wallet-my-wallets', args),

    /**
     * Fetch Online Gates for wallet charge
     *
     * @access public
     * @param {...any} args
     * @returns {*}
     */
    onlineGates: (...args: any[]): any => Resource.get('wallet-online-gates', args),

    /**
     * Charge Wallet Online
     *
     * @access public
     * @param {...any} args
     * @returns {*}
     */
    onlineCharge: (...args: any[]): any => Resource.post('wallet-online-charge', args),
};
