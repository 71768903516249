<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<script>
    // Import registered layouts
    import layouts from '@nodes/layouts/Layouts';

    export default {
        name: 'LayoutWrapper',

        components: {
            ...layouts,
        },

        computed: {
            /**
             * Current layout
             *
             * @returns {string}
             */
            layout() {
                return this.$route.meta.layout ? `${this.$route.meta.layout}Layout` : 'DefaultLayout';
            },
        },
    };
</script>
