<template>
    <div class="field">
        <label>{{ element.label }}</label>
        <y-form-select
            :options="options"
            :value="model"
            :is-loading="false"
            :disabled="element.disabled"
            @input="setValue"
        />
    </div>
</template>

<script>

    import YFormSelect from '@deps/form/elements/Select';

    import { listToTree } from '@nodes/helpers/misc';

    export default {

        name: 'YFormSelectNested',

        components: {
            YFormSelect,
        },

        props: {
            /**
             * Element
             */
            element: Object,

            /**
             * Input Value
             */
            value: [Object, String],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                options: null,
                model  : this.value || null,
            };
        },

        computed: {

            /**
             * Return value field
             */
            valueField() {
                return this.get(this.element, 'valueField', 'id');
            },

            /**
             * Return label field
             */
            labelField() {
                return this.get(this.element, 'labelField', 'label');
            },

        },

        watch: {

            /**
             * Watch value and change model
             *
             * @param val
             */
            value(val) {
                this.$set(this, 'model', val);
            },

        },

        /**
         * @inheritDoc
         */
        created() {
            this.$set(this, 'options', this.makeOptions(listToTree(this.element.options), []));
        },

        methods: {

            /**
             * Set value
             *
             * @param value
             */
            setValue(value) {
                if (value !== this.value) {
                    this.$emit('input', value);
                }
            },

            /**
             * Make select options
             *
             * @param tree
             * @param items
             * @param level
             */
            makeOptions(tree, items, level = 0) {
                const prefix = `${'-'.repeat(level)} `;
                tree.forEach((el) => {
                    items.push({
                        value: el[this.valueField],
                        label: `${prefix}${el[this.labelField]}`,
                    });
                    if (el.children && el.children.length) {
                        this.makeOptions(el.children, items, level + 1);
                    }
                });
                return items;
            },

        },

    };
</script>
