<template>
    <input
        :id="labelFor"
        ref="input"
        :type="type"
        :placeholder="placeholder"
        :class="inputClasses"
        :value="value"
        :readonly="!!readonly"
        :disabled="!!disabled"
        :min="min"
        :max="max"
        :step="step"
        @input="updateText"
        @blur="blurField"
        @focus="focusField"
    >
</template>

<script>

    import direction from '@helpers/direction';
    import FormElementMixin from '@/mixins/FormElement';

    /**
     * Input Component
     */
    export default {

        name: 'YFormInput',

        mixins: [FormElementMixin],

        inject: {
            $validator: '$validator',
        },

        props: {

            /**
             * Input Type:
             * `text, number, password,...`
             */
            type: {
                type   : String,
                default: 'text',
            },

            /**
             * Is readonly
             */
            readonly: {
                type   : [Boolean, Number, String],
                default: false,
            },

            /**
             * Input placeholder
             */
            placeholder: {
                type: String,
            },

            /**
             * Is disabled
             */
            disabled: {
                type   : [Boolean, Number, String],
                default: false,
            },

            /**
             * Input Minimum Value
             * _(Input Number)_
             */
            min: [String, Number],

            /**
             * Input Maximum Value
             * _(Input Number)_
             */
            max: [String, Number],

            /**
             * Input Increase Step Value
             * _(Input Number)_
             */
            step: [String, Number],
        },

        computed: {

            /**
             * Calculate input classes
             */
            inputClasses() {
                const classes = [];

                if (this.hasError) {
                    classes.push('error');
                }

                if (this.type === 'password') {
                    const cls = document.dir === 'rtl' ? 'rtl ta-r' : 'ltr ta-l';
                    classes.push(cls);
                } else if (this.dir === 'auto') {
                    const dir = direction(this.value) || document.dir;
                    const cls = dir === 'rtl' ? 'rtl ta-r' : 'ltr ta-l';
                    classes.push(cls);
                }

                return classes;
            },

        },

    };

</script>
