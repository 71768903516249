/*-----------------------------------------------------------------
- Slideshows Routes
-----------------------------------------------------------------*/
const prefix = 'slideshows';
const routes = [
    {
        path     : `content/${prefix}/:id/:slug?`,
        name     : 'content-themes-slideshows-edit',
        component: () => import(/* webpackChunkName: "content" */ '@/modules/content-themes/views/slideshows/Manage'),
    },
];

export default routes;
