<template>
    <div
        v-if="localesArray && localesArray.length"
        class="y-titles"
    >
        <label v-if="!noLabel">{{ label }}</label>
        <div class="y-titles__container">
            <div class="y-titles__locales-tabs">
                <template v-for="locale in localesArray">
                    <div
                        :key="`${locale}-base-${locale}`"
                        class="y-titles__locales-tab"
                        :class="{ 'active': activeTab === locale }"
                        @click="activeTab = locale"
                    >
                        {{ $t(`general.locales.${locale}`) }}
                    </div>
                </template>
            </div>

            <div class="y-titles__locales-inputs">
                <template v-for="locale in localesArray">
                    <y-form-field
                        v-show="activeTab === locale"
                        :key="`${locale}-base-${locale}`"
                        v-model="model[locale]"
                        class="y-titles__locales-input mb0"
                        type="text"
                        name="title"
                        no-label
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import YFormField from '@deps/form/FormField';

    export default {
        name: 'YFormTitles',

        components: {
            YFormField,
        },

        /**
         * @inheritDoc
         */
        props: {
            /**
             * value
             */
            value: [Array, Object],

            /**
             * Element
             */
            element: {
                type: Object,
            },

            /**
             * List of locales
             */
            locales: Array,

            /**
             * Label of the Field
             */
            label: String,

            /**
             * Hide the label
             */
            noLabel: Boolean,
        },
        
        /**
         * @inheritdoc
         */
        data() {
            return {
                model    : {},
                activeTab: null,
            };
        },

        computed: {
            /**
             * Return the Locales
             */
            localesArray() {
                if (this.locales) {
                    return this.locales;
                }
                return this.element && this.element.locale ? this.element.locale : [];
            },
        },

        watch: {
            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },

            /**
             * Watch model to emit the value
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            // console.log('this.value:', this.value && this.value[this.$i18n.locale]);
            if (this.value && this.value[this.$i18n.locale]) {
                this.$set(this, 'model', this.value);
            } else {
                this.localesArray.forEach((locale) => {
                    this.$set(this.model, `${locale}`, '');
                });
            }
            if (!this.activeTab && this.localesArray && this.localesArray.length) {
                this.$set(this, 'activeTab', this.localesArray[0]);
            }
        },
    };
</script>
