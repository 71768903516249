<template>
    <div class="category-editor-modal">
        <y-row>
            <y-col sm="12">
                <y-form-field
                    v-model="selectedCategory.title"
                    type="text"
                    name="title"
                />
            </y-col>

            <y-col sm="9">
                <y-form-field
                    v-model="selectedCategory.slug"
                    type="text"
                    name="slug"
                />
            </y-col>

            <y-col
                sm="3"
                class="d-f align-items-center"
            >
                <y-form-field-color-picker
                    :key="`${selectedCategory.title}-${selectedCategory.color}`"
                    v-model="selectedCategory.color"
                    component="color-picker"
                    type="color-picker"
                    name="color"
                />
            </y-col>

            <y-col
                v-if="element && element.categories && element.categories.length"
                sm="12"
            >
                <span
                    v-for="item in categories"
                    :key="item.id"
                    class="label ml5 mb5 cur-p"
                    :class="{ active : selectedCategory.id === item.id }"
                    :style="{ backgroundColor: item.color }"
                    @click.prevent="selectedCategory = {...item}"
                >
                    {{ item.title | digits($i18n.locale) }}
                </span>
            </y-col>

            <y-col
                sm="12"
                class="mt20 d-f align-items-center justify-content-end"
            >
                <y-button
                    class="ml10"
                    loading-on="submit-category-save"
                    @click.prevent="selectedCategory = {}"
                >
                    <i class="icon md-lead-pencil" />
                    {{ $t('subscription.empty_fields') }}
                </y-button>

                <y-button
                    class="ml10"
                    loading-on="submit-category-save"
                    @click.prevent="removeCategory"
                >
                    <i class="icon md-delete" />
                    {{ $t('button.delete') }}
                </y-button>

                <y-button
                    loading-on="submit-category-save"
                    @click.prevent="saveCategory"
                >
                    <i class="icon md-check" />
                    {{ $t('button.save') }}
                </y-button>
            </y-col>
        </y-row>
    </div>
</template>

<script>

    import YFormFieldColorPicker from '@deps/form/elements/ColorPicker';
    import YFormField from '@deps/form/FormField';

    export default {
        name: 'YFormSubscriptionCategoryEditor',

        components: {
            YFormFieldColorPicker,
            YFormField,
        },

        props: {
            element: [Object, Array],
            value  : [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                selectedCategory: {},

                model     : this.value,
                categories: [...this.element?.categories],
            };
        },

        watch: {
            value: {
                /**
                 * Watch value and change model
                 *
                 * @param {*} newVal
                 * @param {*} oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        beforeDestroy() {
            this.$root.$emit('refreshGrid', {});
        },

        methods: {
            /**
             * Save category
             */
            saveCategory() {
                this.$wait.start('submit-category-save');
                return this.$services.Subscription.categorySave(this.selectedCategory).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('subscription.categories') }));
                    this.categories = [...this.categories.filter((item) => item.id !== this.selectedCategory.id), this.selectedCategory];
                    this.selectedCategory = {};
                }).catch((errorResponse) => {
                    this.handleError(errorResponse);
                }).finally(() => {
                    this.$wait.end('submit-category-save');
                });
            },

            /**
             * Remove category
             */
            removeCategory() {
                this.$wait.start('submit-category-save');
                return this.$services.trash('subscription-category', this.selectedCategory.id).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('subscription.categories') }));
                    this.categories = this.categories.filter((item) => item.id !== this.selectedCategory.id);
                    this.selectedCategory = {};
                }).catch((errorResponse) => {
                    this.handleError(errorResponse);
                }).finally(() => {
                    this.$wait.end('submit-category-save');
                });
            },
        },
    };

</script>
