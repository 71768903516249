/*-----------------------------------------------------------------
- Address Routes
-----------------------------------------------------------------*/
const prefix = 'persons/users';
const namePrefix = 'persons-users';

const routes = [
    {
        path     : `${prefix}/:id/addresses`,
        name     : `${namePrefix}-addresses`,
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/persons/views/addresses/List'),
    },
];

export default routes;
