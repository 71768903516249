/*-----------------------------------------------------------------
- Roles and permissions Routes
-----------------------------------------------------------------*/
const prefix = 'market';

const routes = [
    {
        path     : `${prefix}/:slug/sale-settings`,
        name     : `${prefix}-sale-settings`,
        component: () => import(/* webpackChunkName: "market" */ '@/modules/market/views/Settings'),
    },
    {
        path     : `${prefix}/units`,
        name     : `${prefix}-units-list`,
        component: () => import(/* webpackChunkName: "market" */ '@/modules/market/views/units/List'),
    },
    {
        path     : `${prefix}/products/:type/create`,
        name     : `${prefix}-product-create`,
        component: () => import(/* webpackChunkName: "market" */ '@/modules/market/views/products/Edit'),
    },
    {
        path     : `${prefix}/products/:type/:id/edit`,
        name     : `${prefix}-product-edit`,
        component: () => import(/* webpackChunkName: "market" */ '@/modules/market/views/products/Edit'),
    },
    {
        path     : `${prefix}/products/:type/:id/wares`,
        name     : `${prefix}-wares-list`,
        component: () => import(/* webpackChunkName: "market" */ '@/modules/market/views/wares/Manage'),
    },
    {
        path     : `${prefix}/products/:type/:id/wares/create`,
        name     : `${prefix}-wares-create`,
        component: () => import(/* webpackChunkName: "market" */ '@/modules/market/views/wares/Edit'),
    },
    {
        path     : `${prefix}/products/:type/:id/wares/:wareid/edit`,
        name     : `${prefix}-wares-edit`,
        component: () => import(/* webpackChunkName: "market" */ '@/modules/market/views/wares/Edit'),
    },
    {
        path     : `${prefix}/products/:type`,
        name     : `${prefix}-product-list`,
        component: () => import(/* webpackChunkName: "market" */ '@/modules/content/views/post/List'),
    },
    {
        path     : `${prefix}/campaigns/create`,
        name     : `${prefix}-campaigns-create`,
        component: () => import(/* webpackChunkName: "market" */ '@/modules/market/views/campaigns/Edit'),
    },
    {
        path     : `${prefix}/campaigns/:id/edit`,
        name     : `${prefix}-campaigns-edit`,
        component: () => import(/* webpackChunkName: "market" */ '@/modules/market/views/campaigns/Edit'),
    },
];

export default routes;
