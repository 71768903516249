<script>
    export default {
        name: 'Row',

        inject: {
            $validator: '$validator',
        },

        props: {
            /**
             * Tag type to create element
             */
            tag: {
                type   : String,
                default: 'div',
            },

            /**
             * Gutter size of row
             */
            size: String,

            /**
             * direction of flex
             */
            direction: String,

            /**
             * set direction of flex to row
             */
            row: Boolean,

            /**
             * set direction of flex to row-reverse
             */
            rowReverse: Boolean,

            /**
             * set direction of flex to column
             */
            column: Boolean,

            /**
             * set direction of flex to column-reverse
             */
            columnReverse: Boolean,

            /**
             * add flex-wrap class to row
             */
            wrap: Boolean,

            /**
             * add flex-nowrap class to row
             */
            noWrap: Boolean,

            /**
             * add flex-wrap-reverse class to row
             */
            wrapReverse: Boolean,

            /**
             * add flex-fill class to row
             */
            fill: Boolean,

            /**
             * set justify-content
             */
            justify: String,

            /**
             * set align-items
             */
            alignItems: String,

            /**
             * set align-content
             */
            alignContent: String,

            /**
             * set align-self
             */
            alignSelf: String,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {};
        },

        computed: {
            /**
             * Create a list of classes
             *
             * @returns {Array}
             */
            classes() {
                const classes = ['row'];

                if (this.size) {
                    classes.push(this.size);
                }

                if (this.direction) {
                    classes.push(`flex-${this.direction}`);
                }
                if (this.row) {
                    classes.push('flex-row');
                }
                if (this.rowReverse) {
                    classes.push('flex-row-reverse');
                }
                if (this.column) {
                    classes.push('flex-column');
                }
                if (this.columnReverse) {
                    classes.push('flex-column-reverse');
                }

                if (this.wrap) {
                    classes.push('flex-wrap');
                }
                if (this.noWrap) {
                    classes.push('flex-nowrap');
                }
                if (this.wrapReverse) {
                    classes.push('flex-wrap-reverse');
                }

                if (this.fill) {
                    classes.push('flex-fill');
                }

                if (this.justify) {
                    classes.push(`justify-content-${this.justify}`);
                }
                if (this.alignContent) {
                    classes.push(`align-content-${this.alignContent}`);
                }
                if (this.alignItems) {
                    classes.push(`align-items-${this.alignItems}`);
                }
                if (this.alignSelf) {
                    classes.push(`align-self-${this.alignSelf}`);
                }

                return classes;
            },

        },

        methods: {
            /**
             * Emit click event
             *
             * @param e
             */
            callback(e) {
                this.$emit('click', e);
            },
        },

        /**
         * @inheritDoc
         */
        render(h) {
            return h(
                this.tag,
                {
                    class: this.classes,
                    on   : {
                        click: this.callback,
                    },
                },
                this.$slots.default,
            );
        },

    };
</script>
