/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {

    organizations    : 'Organizations',
    organization_view: 'Organization Profile',
    invoice_single   : 'Invoice',

};
