<template>
    <y-form-field-wrapper v-bind="fieldWrapperProps">
        <div class="dp">
            <input
                :id="`datepicker-${keyHelper}`"
                type="text"
                :placeholder="placeholder"
                :value="parseDateValue(model)"
                autocomplete="off"
                :disabled="disabled"
                :readonly="readonly"
                @focus="open"
            >

            <date-picker
                :value="model"
                :max="toString(max)"
                :min="toString(min)"
                :locale="calendarOptions.join(',')"
                :type="pickerType"
                :disabled="disabled || readonly"
                format="X"
                :element="`datepicker-${keyHelper}`"
                :show="show"
                :multiple="multi"
                :jump-minute="jumpMinute"
                :round-minute="roundMinute"
                @input="updateDate"
                @close="close"
            />

            <span
                v-if="model && !disabled && !readonly"
                class="clear-btn"
            >
                <span
                    @click.prevent="clearValue"
                    v-html="'&times'"
                />
            </span>
        </div>
    </y-form-field-wrapper>
</template>

<script>
    import DatePicker from 'vue-persian-datetime-picker';
    import moment from 'moment-jalaali';
    import { digits } from '@nodes/helpers/number';
    import { generateId } from '@nodes/helpers/string';

    import FormElementMixin from '@/mixins/FormElement';

    // Add turkish locale
    import trLocale from './locales/tr';

    /**
     * Date Picker Input
     */
    export default {
        name: 'YFormDatePicker',

        components: {
            DatePicker,
        },

        mixins: [FormElementMixin],

        inject: {
            $validator: '$validator',
        },

        props: {

            component: {
                type   : String,
                default: 'date-picker',
            },

            /**
             * Datepicker Input Placeholder
             */
            placeholder: {
                type: String,
            },

            /**
             * Datepicker Value
             */
            value: {
                type: [String, Number, Array],
            },

            /**
             * DatePicker Type:
             * `date, time, datetime`
             */
            type: {
                type   : String,
                default: 'date',
            },

            /**
             * Calendar types
             */
            calendar: {
                type: Array,
            },

            /**
             * Input Value Format
             */
            format: {
                type   : String,
                default: 'YYYY/M/D',
            },

            /**
             * Max datetime
             */
            max: [String, Number],

            /**
             * Min datetime
             */
            min: [String, Number],

            /**
             * Multiple selection
             */
            multi: {
                type   : Boolean,
                default: false,
            },

            /**
             * String seperator, used in multi mode
             */
            seperator: {
                type   : String,
                default: ' - ',
            },

            /**
             * Help text
             */
            help: String,

            /**
             * Change minutes by step
             *
             * @default 1
             */
            jumpMinute: Number,

            /**
             * Round minutes when jumpMinute is grater than 1
             *
             * @example when jumpMinute = 15 this will result: 13:00, 13:15, 13:30, 13:45 ...
             * @default false
             */
            roundMinute: Boolean,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model    : this.multi ? this.value : Number(this.value),
                show     : false,
                keyHelper: generateId(),

                formats: {
                    fa: {
                        date    : 'DD MMMM YYYY',
                        time    : 'HH:mm',
                        datetime: 'DD MMMM YYYY - HH:mm',
                    },
                    en: {
                        date    : 'MMMM DD, YYYY',
                        time    : 'HH:mm',
                        datetime: 'MMMM DD, YYYY - HH:mm',
                    },
                },

                defaultCalendar: this.$i18n.locale === 'fa' ? ['fa'] : ['en'],
            };
        },

        computed: {

            /**
             * Return locales of datepicker
             */
            calendarOptions() {
                if (!this.calendar) {
                    return this.defaultCalendar;
                }
                return this.calendar.map((el) => (el === 'jalaali' ? 'fa' : 'en')) || this.defaultCalendar;
            },

            /**
             * Return type of picker
             */
            pickerType() {
                return this.multi
                    ? 'date'
                    : this.type || 'date';
            },

        },

        watch: {
            /**
             * Watch for value change and set model
             *
             * @param val
             */
            value(val) {
                if (this.model !== val) {
                    this.model = this.modelAdapter(val);
                }
            },

            /**
             * Emit model change
             *
             * @param val
             */
            model(val) {
                this.$emit('input', val);
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            if (this.$i18n.locale === 'tr') {
                moment.updateLocale('en', trLocale);
            }
        },

        methods: {

            /**
             * Open datepicker
             */
            open() {
                this.show = true;
            },

            /**
             * Close datepicker
             */
            close() {
                this.show = false;
            },

            /**
             * Return date value parsed with format
             *
             * @param date
             * @returns {string|*}
             */
            parseDateValue(date) {
                if (!date) {
                    return '';
                }

                const value = digits(date);
                const { locale } = this.$i18n;

                let format = this.format || this.formats[locale][this.pickerType];
                if (this.calendarOptions[0] === 'fa') {
                    format = format.replace(/([Y|M|D])\1{0,}/g, (w) => `j${w}`);
                }
                return this.multi
                    ? date.map((d) => digits(moment(d, 'X').locale(locale).format(format), locale)).join(this.seperator)
                    : digits(moment(value, 'X').locale(locale).format(format), locale);
            },

            /**
             * Emit date value
             *
             * @param date
             */
            updateDate(date) {
                this.model = this.modelAdapter(date);
            },

            /**
             * Adapt model according to new value
             *
             * @param {Array} newValue
             */
            modelAdapter(newValue) {
                if (this.multi) {
                    return newValue.map((date) => Number(digits(date)));
                }
                return Number(digits(newValue));
            },

            /**
             * To string
             *
             * @param value
             */
            toString(value) {
                if (!value) {
                    return value;
                }

                return digits(value).toString();
            },

            /**
             * Clear value
             */
            clearValue() {
                this.model = null;
            },
        },
    };
</script>
