<template>
    <swiper-slide
        class="slide-item"
        :class="position"
    >
        <slot />
    </swiper-slide>
</template>

<script>

    import { SwiperSlide } from 'vue-awesome-swiper';

    export default {
        name: 'Slide',

        components: {
            SwiperSlide,
        },

        props: {
            /**
             * Caption position in each slide
             */
            position: {
                type   : String,
                default: 'center-center',
            },
        },
    };
</script>
