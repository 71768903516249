/*-----------------------------------------------------------------
- Developer tools Routes
-----------------------------------------------------------------*/
const prefix = 'fluorocarbon';
const routes = [
    {
        path     : 'consultations',
        name     : `${prefix}-consultations`,
        component: () => import(/* webpackChunkName: "fluorocarbon" */ '@/modules/fluorocarbon/views/Calendar'),
    },
];

export default routes;
