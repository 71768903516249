/*-----------------------------------------------------------------
- Button translations
-----------------------------------------------------------------*/

export default {
    
    submit           : 'ارسال',
    save             : 'ذخیره',
    confirm          : 'تایید',
    cancel           : 'انصراف',
    close            : 'بستن',
    create           : 'افزودن',
    edit             : 'ویرایش',
    update           : 'به‌روزرسانی',
    delete           : 'حذف',
    trash            : 'انتقال به زباله‌دان',
    insert           : 'ثبت',
    add              : 'افزودن',
    sure             : 'مطمئنم!',
    activate         : 'فعال‌سازی',
    deactivate       : 'غیرفعال‌سازی',
    back             : 'بازگشت',
    search           : 'جستجو کنید',
    submit_comment   : 'ثبت نظر',
    submit_newsletter: 'تایید',
    action           : 'عملیات',
    mass_actions     : 'عملیات دسته‌جمعی',
    filter           : 'فیلترکردن',
    draft            : 'ذخیره در پیش‌نویس‌های من',
    send             : 'ارسال به سردبیر',
    unpublish        : 'لغو انتشار',
    moderate         : 'ارزیابی',
    reject           : 'رد کردن',
    publish          : 'انتشار',
    block            : 'مسدودسازی',
    unblock          : 'رفع مسدودی',
    manage           : 'مدیریت',
    setting          : 'تنظیمات',
    back_to_list     : 'بازگشت به فهرست',
    download         : 'دانلود فایل',
    refresh          : 'بارگذاری دوباره',
    more             : 'بیشتر',
    print            : 'چاپ',
    excel            : 'خروجی اکسل',
    copy             : 'کپی',
    reset            : 'بازنشانی',
    restore          : 'بازیافت',

    // Panel
    help              : 'مشاهده راهنما',
    addWidget         : 'افزودن ابزارک',
    manageWidgets     : 'مدیریت ابزارک‌ها',
    deleteMapMarker   : 'حذف مکان‌ها',
    details           : 'جزئیات',
    exit_impersonate  : 'بازگشت به خویشتن',
    newCoordinates    : 'نمایش مختصات، بر روی نقشه',
    clear             : 'خالی کردن فیلد‌ها',
    manualCoordinators: {
        show: 'وارد کردن مختصات',
        hide: 'مخفی کردن',
    },
};
