/*-----------------------------------------------------------------
- Button translations
-----------------------------------------------------------------*/

export default {
    
    specs: {
        add_group: 'افزودن گروه',
        add_spec : 'افزودن مشخصه',
    },
    
};
