/*-----------------------------------------------------------------
- Shipping Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    /**
     * Fetch Shipping Editor form
     *
     * @param {...any} args
     */
    methodFetch: (...args) => Resource.get('shipping-method-fetch', args),

    /**
     * Save Shipping Editor form
     *
     * @param {...any} args
     */
    methodSave: (...args) => Resource.post('shipping-method-save', args),

    /**
     * Shipping method rule settings fetch
     *
     * @param {...any} args
     */
    ruleFetch: (...args) => Resource.get('shipping-rule-fetch', args),

    /**
     * Shipping method rule settings save
     *
     * @param {...any} args
     */
    ruleSave: (...args) => Resource.post('shipping-rule-save', args),

    /**
     * Shipping method rule settings remove
     *
     * @param {...any} args
     */
    ruleRemove: (...args) => Resource.delete('shipping-rule-remove', args),

};
