<template>
    <y-modal
        ref="Modal"
        size="lg"
        @close="reset"
    >
        <div slot="header">
            <div v-if="content">
                {{ content.title }}
            </div>
        </div>

        <div slot="body">
            <y-loading :active="!content">
                <p
                    v-if="content"
                    v-html="content.text"
                />
            </y-loading>
        </div>
    </y-modal>
</template>

<script>
    import { YModal } from '@deps';

    export default {
        name: 'YHelpModal',

        components: {
            YModal,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                content: null,
            };
        },

        methods: {

            /**
             * Get help content
             *
             * @param id
             */
            fetch(id) {
                return this.$services.Content.postSingle({ id }).then((response) => {
                    this.content = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                    this.$refs.Modal.close();
                });
            },

            /**
             * Open modal
             *
             * @param id
             * @param content
             */
            open(id, content) {
                if (!id && content) {
                    this.content = content;
                } else if (id) {
                    this.fetch(id);
                } else {
                    this.handleError();
                }
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
            },

            /**
             * Reset
             */
            reset() {
                this.content = null;
            },
        },
    };
</script>
