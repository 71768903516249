/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {

    organizations    : 'سازمان‌ها',
    organization_view: 'پروفایل سازمان',
    invoice_single   : 'مشاهده فاکتور',

};
