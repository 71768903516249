/**
 * @mixin
 */
export default {
    props: {
        /**
         * No Label view
         */
        noLabel: {
            type   : Boolean,
            default: false,
        },
        
        /**
         * Alternative Style
         */
        alt: {
            type   : Boolean,
            default: false,
        },
        
        /**
         * Inline Style
         */
        inline: {
            type   : Boolean,
            default: false,
        },
        
        /**
         * Color Name
         */
        color: {
            type: String,
        },
        
        /**
         * Size:
         * `xs, sm, lg`
         */
        size: {
            type: String,
        },
        
        /**
         * Disable Input
         */
        disabled: {
            type   : [Boolean, Number, String],
            default: false,
        },
        
        /**
         * Switch Style
         */
        switch: {
            type   : Boolean,
            default: false,
        },
        
        /**
         * Circular Switch
         * _(Use alongside switch prop)_
         */
        circular: {
            type   : Boolean,
            default: false,
        },
        
    },
    
    computed: {
        /**
         * Classes of switch input
         *
         * @returns {Array}
         */
        classes() {
            const classes = [];
            
            if (this.alt) {
                classes.push('alt');
            }
            
            if (this.inline) {
                classes.push('inline');
            }
            
            if (this.color) {
                classes.push(this.color);
            }
            
            if (this.size) {
                classes.push(this.size);
            }
            
            if (this.switch) {
                classes.push('switch');
            }
            
            if (this.circular) {
                classes.push('circular');
            }
            
            return classes;
        },
        
        /**
         * Check if it's switch
         *
         * @returns {boolean}
         */
        isSwitch() {
            return !!this.switch;
        },
        
    },
};
