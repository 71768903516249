/*-----------------------------------------------------------------
- Toast translations
-----------------------------------------------------------------*/
export default {
    assign: {
        success: 'دسترسی جدید با موفقیت ایجاد شد.',
    },
    
    resign: {
        success: 'حذف دسترسی کاربر با موفقیت انجام شد.',
    },
    
    block: {
        success: 'مسدودسازی دسترسی کاربر با موفقیت انجام شد.',
    },
    
    unblock: {
        success: 'رفع مسدودی دسترسی کاربر با موفقیت انجام شد.',
    },
    
    permitSave: {
        success: 'ذخیره دسترسی‌‌های کاربر با موفقیت انجام شد.',
    },
};
