<template>
    <div
        class="panel empty-state"
        :style="{height: height + 'px'}"
    >
        <img
            v-if="!noImage"
            src="~@/assets/images/empty.png"
            alt="empty"
        >

        <div class="desc">
            <div
                class="title"
                v-html="content"
            />
            <div
                v-if="btnLink"
                class="action"
            >
                <div class="button">
                    {{ btnTitle }}
                </div>
            </div>
        </div>

        <slot />
    </div>
</template>

<script>
    export default {

        name: 'EmptyState',

        props: {

            /**
             * Height of empty state
             */
            height: {
                type   : [String, Number],
                default: 400,
            },

            /**
             * Message of empty state
             */
            message: {
                type: String,
            },

            /**
             * Title of empty state
             */
            title: {
                type: String,
            },

            /**
             * Title of action button
             */
            btnTitle: String,

            /**
             * Link of action button
             */
            btnLink: [String, Object],

            /**
             * Color of action button
             */
            btnColor: String,

            /**
             * True if image should be hidden
             */
            noImage: Boolean,
        },

        computed: {
            /**
             * Return content of empty state
             */
            content() {
                if (this.message) {
                    return this.message;
                }

                return this.title
                    ? this.$t('general.empty.msgFull', { title: this.title })
                    : this.$t('general.empty.msg');
            },
        },
    };
</script>
