/*-----------------------------------------------------------------
- Breadcrumb mixin
-----------------------------------------------------------------*/
export default () => ({
    
    /**
     * @inheritDoc
     */
    beforeCreate() {
        const options = this.$options;
    
        if (!options.breadcrumbs) {
            return false;
        }
        
        if (typeof options.breadcrumbs === 'function') {
            // Initialize with function
            options.breadcrumbs = options.breadcrumbs.call(this);
        }
        
        return this.$store.dispatch('breadcrumbs/set', options.breadcrumbs);
    },
    
});
