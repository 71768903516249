/*-----------------------------------------------------------------
- Specification translations
-----------------------------------------------------------------*/
export default {
    
    title  : 'Specs',
    sidebar: 'Group',
    
    empty: {
        group: 'No group found.',
        spec : 'No specs item found.',
    },
    
    group: {
        create_modal: 'Add Group',
        edit_modal  : 'Edit Group',
        locale_title: '{locale} Title',
    },
    
    spec_item: {
        create_modal: 'Add Item',
        edit_modal  : 'Edit Item',
        locale_title: '{locale} Title',
    },
    
    types: {
        text    : 'Text',
        textarea: 'Textarea',
        boolean : 'Yes/No',
    },
    
    delete: {
        title_group: 'Delete Group',
        title_spec : 'Delete Item',
        group      : 'Are you sure to delete this group?',
        spec       : 'Are you sure to delete this item?',
    },
    
    messages: {
        save: 'Saved successfully.',
        
        group: {
            add   : 'New group added successfully.',
            update: 'Group updated successfully.',
            delete: 'Group deleted successfully.',
        },
        
        spec: {
            add   : 'New item added successfully.',
            update: 'Item updated successfully',
            delete: 'Item deleted successfully',
        },
    
        title: 'Title(s) is required',
    },
    
    feature: {
        title : 'Product Specification',
        select: {
            label      : 'Select Specs Form',
            placeholder: 'Select',
        },
        empty_form: 'Specification ',
    },
    
    form: {
        id: 'Id',
    },
    
    items: 'Items',
    
    lang: 'Lang',
    
};
