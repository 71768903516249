/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    setting: {
        title: 'تنظیمات سایت',
    },
    
};
