/*-----------------------------------------------------------------
- Layout for Vuex
-----------------------------------------------------------------*/
/* eslint no-shadow: 0, no-param-reassign: 0, consistent-return: 0, no-confusing-arrow: 0 */
import Vue from 'vue';

/**
 * Make state object
 *
 * @param data
 */
const makeState = (data) => {
    const object = {
        hasSearch      : data.has_search,
        footer         : data.footer,
        navbar         : data.navbar,
        profile        : data.profile,
        sidebar        : data.sidebar,
        sidebarSettings: data.settings,
        createButton   : data.create_button,

    };
    return object;
};

const state = {
    isLayoutLoaded  : false,
    layout          : {},
    temp            : {},
    manageDashboard : false,
    modules         : null,
    sidebarCollapsed: localStorage.getItem('sidebarCollapsed'),
};
const getters = {
    state           : (state) => state,
    isLayoutLoaded  : (state) => state.isLayoutLoaded,
    hasSearch       : (state) => state.isLayoutLoaded ? state.layout.hasSearch : false,
    footer          : (state) => state.isLayoutLoaded ? state.layout.footer : false,
    navbar          : (state) => state.isLayoutLoaded ? state.layout.navbar : false,
    profile         : (state) => state.isLayoutLoaded ? state.layout.profile : false,
    sidebar         : (state) => state.isLayoutLoaded ? state.layout.sidebar : false,
    sidebarSettings : (state) => state.isLayoutLoaded ? state.layout.sidebarSettings : false,
    createButton    : (state) => state.isLayoutLoaded ? state.layout.createButton : false,
    manageDashboard : (state) => state.manageDashboard,
    sidebarCollapsed: (state) => state.sidebarCollapsed,
};
const mutations = {
    
    /**
     * Set modules
     *
     * @param state
     * @param data
     */
    setModules(state, data) {
        Vue.set(state, 'modules', data);
    },
    
    /**
     * Update layout mutation
     *
     * @param state
     * @param data
     */
    update(state, data) {
        Vue.set(state, 'isLayoutLoaded', true);
        Vue.set(state, 'layout', { ...makeState(data) });
    },
    
    /**
     * Preview layout mutation
     *
     * @param state
     * @param data
     */
    preview(state, data) {
        Vue.set(state, 'temp', { ...state.layout });
        Vue.set(state, 'layout', { ...makeState(data) });
    },
    
    /**
     * Reset preview layout mutation
     *
     * @param state
     */
    resetPreview(state) {
        Vue.set(state, 'layout', { ...state.temp });
        Vue.set(state, 'temp', {});
    },
    
    /**
     * Reset preview layout mutation
     *
     * @param state
     */
    reload(state) {
        Vue.set(state, 'isLayoutLoaded', false);
    },

    /**
     * Toggle sidebar collapse mode 
     *
     * @param state
     * @param status
     */
    toggleSidebar(state, status) {
        state.sidebarCollapsed = status;
        if (status) {
            localStorage.setItem('sidebarCollapsed', status);
        } else {
            localStorage.removeItem('sidebarCollapsed');
        }
    },
};
const actions = {
    
    /**
     * Update layout action
     *
     * @param commit
     * @param commit.commit
     * @param data
     */
    update({ commit }, data) {
        commit('update', data);
    },
    
    /**
     * Set modules
     *
     * @param commit
     * @param commit.commit
     * @param data
     */
    setModules({ commit }, data) {
        commit('setModules', data);
    },
    
    /**
     * Preview layout action
     *
     * @param commit
     * @param commit.commit
     * @param data
     */
    preview({ commit }, data) {
        commit('preview', data);
    },
    
    /**
     * Reset preview layout action
     *
     * @param commit
     * @param commit.commit
     */
    resetPreview({ commit }) {
        commit('resetPreview');
    },
    
    /**
     * Reload layout
     *
     * @param commit
     * @param commit.commit
     */
    reload({ commit }) {
        commit('reload');
    },

    /**
     * Toggle sidebar collapse mode
     *
     * @param root0
     * @param root0.commit
     * @param status
     */
    toggleSidebar({ commit }, status) {
        commit('toggleSidebar', status);
    },
};

export default {
    
    namespaced: true,
    
    state,
    getters,
    mutations,
    actions,
};
