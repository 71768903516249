/*-----------------------------------------------------------------
- Fetch Settings Mixin
-----------------------------------------------------------------*/
import { setOptions, bootstrap } from 'vue-gtag';

export default {
    name: 'SettingsMixin',

    watch: {
        '$route.params.locale': {
            /**
             * Watch resolving route
             *
             * @param {*} val
             */
            handler(val) {
                if (val) {
                    this.initiate();
                }
            },
            immediate: true,
        },
    },

    /**
     * @inheritDoc
     */
    created() {
        if (this.$i18n.availableLocales.length === 1 || process.env.VUE_APP_PANEL === 'true') {
            this.initiate();
        }
    },

    methods: {
        /**
         * Get settings
         *
         * @returns {Promise<void>}
         */
        async fetchSettings() {
            const params = {
                entries: this.$configs.settings.entries,
                locale : this.$i18n.locale,
            };

            if (this.$configs.settings.getRecaptcha) {
                params.entries.push('recaptcha');
            }

            if (!this.$store.getters['settings/isLoaded']) {
                const settings = await this.$services.Settings.get(params);
                await this.$store.dispatch('settings/set', settings.data.results);
                if (this.$settings('seo.google_analytics_id')) {
                    this.initGoogleAnalytics();
                }
            }
        },

        /**
         * Fetch menus based on `base.menus` config and save on the Vuex store
         */
        fetchMenus() {
            const slugs = this.$configs.settings.menuSlugs;
            if (Array.isArray(slugs) && slugs.length) {
                const filteredSlugs = slugs.map((slug) => slug.replace('$', this.$i18n.locale));
                const including = ['menus', 'slug'];
                this.$services.MenuMaker.getLocationMenus({
                    slugs: filteredSlugs,
                    including,
                }).then((response) => {
                    const locations = {};
                    response.data.results.forEach((item) => {
                        locations[item.slug] = item.menus;
                    });
                    this.$store.dispatch('menu/set', locations);
                });
            }
        },

        /**
         * Set google analytics configs
         */
        initGoogleAnalytics() {
            setOptions({
                config: { id: this.$settings('seo.google_analytics_id') },
            });
            bootstrap().then(() => {});
        },

        /**
         * Initiate app functions
         */
        initiate() {
            this.fetchSettings();
            this.fetchMenus();
        },
    },
};
