/*-----------------------------------------------------------------
- Fluorocarbon translations
-----------------------------------------------------------------*/
export default {
    calendar: {
        title: {
            calendar  : 'Consultations',
            setting   : 'Calendar Setting',
            day_booked: 'Books of the {0}',
        },

        booked: {
            info_form_title: 'Book Details',
            button         : 'Book',

            days: {
                sunday   : 'Sunday',
                monday   : 'Monday',
                tuesday  : 'Tuesday',
                wednesday: 'Wednesday',
                thursday : 'Thursday',
                friday   : 'Friday',
                saturday : 'Saturday',
            },
        },

        setting: {
            message: {
                success: 'Settings is saved successfully.',
            },
        },

        message: {
            success: {
                free: 'Booked successfully freed.',
                book: 'The desired time slot is successfully booked.',
            },
        },
    },
};
