/*-----------------------------------------------------------------
- Specs Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Specs Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    specsFormsGrid: (...args) => Resource.get('specs-specs-forms-grid', args),
    
    /**
     * Specs fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    fetch: (...args) => Resource.get('specs-fetch', args),
    
    /**
     * Specs save
     *
     * @param {...any} args
     * @returns {*}
     */
    save: (...args) => Resource.post('specs-save', args),
    
    /**
     * Specs get values form
     *
     * @param {...any} args
     * @returns {*}
     */
    valuesForm: (...args) => Resource.get('specs-values-form', args),

};
