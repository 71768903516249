<template>
    <div class="qrcode">
        <template v-if="codeData">
            <vue-qrcode
                id="my-img"
                class="qrcode__image"
                :value="codeData"
                :options="options"
                :tag="codeTag"
            />
        
            <p
                v-if="label && !!codeData && !isLink"
                class="qrcode__label"
            >
                {{ codeData }}
            </p>

            <a
                v-if="label && !!codeData && isLink"
                :href="codeData"
                target="_blank"
                rel="noopener noreferrer"
                class="qrcode__link"
            >
                <span class="d-b dir-ltr">{{ codeData }}</span>
            </a>

            <div
                v-if="!!codeData"
                class="qrcode__actions d-f justify-content-center align-items-center"
            >
                <y-button
                    v-if="copy"
                    :color="CopyBtnTheme"
                    :size="BtnSize"
                    @click.native.prevent="doCopy"
                >
                    {{ CopyBtnText }}
                </y-button>

                <y-button
                    v-if="downloadBtn"
                    :color="BtnTheme"
                    :size="BtnSize"
                    @click.native.prevent="downloadFile"
                >
                    {{ BtnText }}
                </y-button>
            </div>
        </template>

        <template v-else>
            <img
                width="150"
                :src="require('@/assets/images/failed-qr.png')"
            >
        </template>
    </div>
</template>

<script>

    import VueQrcode from '@chenfengyuan/vue-qrcode';

    /**
     * QrCode Component
     */
    export default {

        name: 'YFormQrCode',

        components: {
            VueQrcode,
        },

        props: {

            /**
             * Element data
             */
            element: {
                type: [Object, Array],
            },

            /**
             * Value of the QrCode
             */
            value: {
                type: [Number, String, Object],
            },

            /**
             * Width of the QrCode
             */
            width: {
                type   : [String, Number],
                default: 'md',
            },

            /**
             * Code Tag
             */
            tag: String,

            /**
             * If there should be download button
             */
            download: {
                type   : Boolean,
                default: false,
            },

            /**
             * Label
             */
            label: {
                type   : Boolean,
                default: false,
            },

            /**
             * copy
             */
            copy: {
                type   : Boolean,
                default: false,
            },
        },

        computed: {
            /**
             * Options of the qr code
             */
            options() {
                return {
                    width      : this.codeWidth,
                    maskPattern: this.element && this.element.mask_pattern ? this.element.mask_pattern : 1,
                    small      : this.element && this.element.small ? this.element.small : false,
                };
            },

            /**
             * Code Tag
             */
            codeTag() {
                return this.element && this.element.tag ? this.element.tag : this.tag;
            },

            /**
             * Wheter there should be download or not
             */
            downloadBtn() {
                return this.element && this.element.download ? this.element.download : this.download;
            },

            /**
             * Download Button Text
             */
            BtnText() {
                return this.element && this.element.download_button_text ? this.element.download_button_text : this.$t('button.download');
            },

            /**
             * Download Button Theme
             */
            BtnTheme() {
                return this.element && this.element.download_button_theme ? this.element.download_button_theme : '';
            },

            /**
             * Download Button Size
             */
            BtnSize() {
                return this.element && this.element.download_button_size ? this.element.download_button_size : '';
            },

            /**
             * Download Button Text
             */
            CopyBtnText() {
                return this.element && this.element.copy_button_text ? this.element.copy_button_text : this.$t('button.copy');
            },

            /**
             * Download Button Text
             */
            CopyBtnTheme() {
                return this.element && this.element.copy_button_theme ? this.element.copy_button_theme : '';
            },

            /**
             * Value of the QrCode
             */
            codeData() {
                return (typeof this.value === 'string' || typeof this.value === 'number') ? this.value : this.element.value;
            },

            /**
             * Width of the QrCode
             */
            codeWidth() {
                const prop = this.width ? this.width : this.element.width;
                let width = 200;
                if (prop === 'sm') {
                    width = 100;
                }
                if (prop === 'lg') {
                    width = 300;
                }
                return width;
            },

            /**
             * Check if the value is link
             */
            isLink() {
                return !!this.codeData.startsWith('http');
            },

        },

        methods: {

            /**
             * Download Qr Code
             */
            downloadFile() {
                const imgSrc = document.getElementById('my-img').src;
                this.$emit('src', imgSrc);
                fetch(imgSrc)
                    .then((res) => res.blob())
                    .then((blob) => {
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'qrcode';
                        // eslint-disable-next-line require-jsdoc
                        const clickHandler = () => {
                            setTimeout(() => {
                                URL.revokeObjectURL(url);
                                a.removeEventListener('click', clickHandler);
                            }, 150);
                        };
                        a.addEventListener('click', clickHandler, false);
                        a.click();
                        return a;
                    });
            },

            /**
             * Copy Link
             */
            doCopy() {
                this.$copyText(this.element.value).then(() => {
                    this.$toast.success(this.$t('general.copy_value.copied'));
                }).catch(() => {
                    this.$toast.success(this.$t('general.copy_value.error'));
                });
            },
        },

    };

</script>
