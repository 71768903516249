export default {
    email    : 'Email:',
    mobile   : 'Mobile:',
    resume   : 'Resume:',
    user_note: 'Note',
    item     : 'Changes',
    note     : 'Note',
    view     : 'View Resume',
    
    user: 'Job Seeker Details',
    
    applied_at: 'Applied At: ',
    
    change_status: 'Change Status',
    user_history : 'User History',
};
