/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    tagging: {
        folders: 'مدیریت پوشه‌ها',
        single : 'دسته‌بندی محتوا',
    },
    
};
