/*-----------------------------------------------------------------
- Wares trans
-----------------------------------------------------------------*/
export default {
    
    tabs: {
        base     : 'پایه',
        measure  : 'عرضه',
        inventory: 'انبارداری',
        price    : 'قیمت',
        sizes    : 'اندازه‌ها',
        payment  : 'پرداخت',
        settings : 'تنظیمات',
        tagging  : 'ویژگی‌ها',
    },
    
    settings: {
        tax           : 'مالیات',
        code          : 'تنظیمات کالا',
        post          : 'مشخصات محصول',
        auto_ware_code: 'کد کالا به صورت خودکار تولید می شود',
    },

    payment: {
        price              : 'قیمت',
        price_with_discount: 'قیمت پس از تخفیف',
        tax                : 'مالیات',
        sale_price         : 'قیمت فروش',
    },
    
    tax: {
        value_added : 'ارزش افزوده ({0}٪)',
        withholding : 'مالیات تکلیفی ({0}٪)',
        municipality: 'عوارض شهرداری ({0}٪)',
        separate    : 'افزودن به قیمت',
        on          : 'شامل قیمت',
    },
    
};
