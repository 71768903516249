/*-----------------------------------------------------------------
- Accountant Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Accountant Wallets Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    walletsGrid: (...args) => Resource.get('accountant-wallets-grid', args),

    /**
     * Accountant Wallets Blocked Amounts Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    walletsBlockedAmountsGrid: (...args) => Resource.get('accountant-wallets-blocked-amounts-grid', args),

    /**
     * Accountant Gate Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    gatesGrid: (...args) => Resource.get('accountant-gates-grid', args),

    /**
     * Accountant Documents Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    documentsGrid: (...args) => Resource.get('accountant-documents-grid', args),
    
    /**
     * Accountant Document Fetch
     *
     * @param args
     * @returns {*}
     */
    documentFetch: (...args) => Resource.get('accountant-document-fetch', args),

    /**
     * Accountant Document Save
     *
     * @param {...any} args
     * @returns {*}
     */
    documentSave: (...args) => Resource.post('accountant-document-save', args),

    /**
     * Accountant Document Single
     *
     * @param {...any} args
     * @returns {*}
     */
    documentSingle: (...args) => Resource.get('accountant-document-single', args),

    /**
     * Accountant Document Single
     *
     * @param {...any} args
     * @returns {*}
     */
    documentPrint: (...args) => Resource.get('accountant-document-print', args),

    /**
     * Accountant Postings Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    postingsGrid: (...args) => Resource.get('accountant-postings-grid', args),

    /**
     * Accountant Posting Save
     *
     * @param {...any} args
     * @returns {*}
     */
    postingSave: (...args) => Resource.post('accountant-posting-save', args),

    /**
     * Accountant Groups List
     *
     * @param {...any} args
     * @returns {*}
     */
    groupsList: (...args) => Resource.get('accountant-groups-list', args),

    /**
     * Accountant Groups Save Orders
     *
     * @param {...any} args
     * @returns {*}
     */
    groupsSaveOrders: (...args) => Resource.put('accountant-groups-save-orders', args),

    /**
     * Accountant Group Save
     *
     * @param {...any} args
     * @returns {*}
     */
    groupSave: (...args) => Resource.post('accountant-group-save', args),

    /**
     * Accountant Document Verify All Posts
     *
     * @param args
     * @returns {*}
     */
    documentVerifyPostings: (...args) => Resource.post('accountant-document-verify-postings', args),
    
    /**
     * Accountant Document Finalize
     *
     * @param args
     * @returns {*}
     */
    documentFinalize: (...args) => Resource.post('accountant-document-finalize', args),
    
    /**
     * Accountant Posting Quick Save
     *
     * @param args
     * @returns {*}
     */
    postingQuickSave: (...args) => Resource.post('accountant-posting-quick-save', args),
    
    /**
     * Accountant Change Status Form
     *
     * @param args
     * @returns {*}
     */
    postingChangeStatusForm: (...args) => Resource.get('accountant-posting-change-status-form', args),
    
    /**
     * Accountant Change Status
     *
     * @param args
     * @returns {*}
     */
    postingChangeStatus: (...args) => Resource.post('accountant-posting-change-status', args),
    
    /**
     * Accountant Groups Options
     *
     * @param args
     * @returns {*}
     */
    groupsOptions: (...args) => Resource.get('accountant-groups-options', args),
    
    /**
     * Accountant Groups Options
     *
     * @param args
     * @returns {*}
     */
    segregateClassesCombo: (...args) => Resource.get('accountant-segregate-classes-combo', args),
    
    /**
     * Accountant Groups Options
     *
     * @param args
     * @returns {*}
     */
    groupSegregatesCombo: (...args) => Resource.get('accountant-group-segregates-combo', args),
    
    /**
     * Accountant Groups Options
     *
     * @param args
     * @returns {*}
     */
    documentSettlementQuickSave: (...args) => Resource.post('accountant-document-settlement-quick-save', args),
    
    /**
     * Accountant Groups Options
     *
     * @param args
     * @returns {*}
     */
    searchGroup: (...args) => Resource.get('accountant-search-group', args),
    
    /**
     * Accountant Groups Options
     *
     * @param args
     * @returns {*}
     */
    searchSegregate: (...args) => Resource.get('accountant-search-segregate', args),
    
    /**
     * Accountant Groups Options
     *
     * @param args
     * @returns {*}
     */
    groupsSegregateCheck: (...args) => Resource.post('accountant-groups-segregates-check', args),

};
