/*-----------------------------------------------------------------
- Content fields
-----------------------------------------------------------------*/
export default {
    moderation_note: 'Moderation Note',
    synopsis       : 'Synopsis',

    content_type: {
        slug    : 'Slug',
        title   : 'Title',
        order   : 'Order',
        template: 'Template', // eslint-disable-line @intlify/vue-i18n/no-raw-text
        feature : 'Features',
        icon    : 'Icon',
    },
};
