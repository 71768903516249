/* -----------------------------------------------------------------
- Cart trans
----------------------------------------------------------------- */
export default {

    title: 'Cart',

    megamenu: {
        title: 'Cart {0}',
    },

    order: {
        title: 'Orders',
        name : 'Order',

        create: 'Add New Order',
        edit  : 'Edit Order',

        details              : 'Order Process',
        details_panel        : 'Order Details',
        details_finance_panel: 'Order Finance Details',

        status: {
            title         : 'Change Order Status',
            status        : 'Order Status',
            current_status: 'Current Order Status',
            new_status    : 'New Order Status',
        },

        message: {
            close: {
                success: 'Order closed successfully.',
            },
            
            reopen: {
                success: 'Order successfully reopened.',
            },
        },
    },
    pre: {
        title: 'Pre-Orders',
        name : 'Pre-Order',

        create: 'Add New Pre-Order',
        edit  : 'Edit Pre-Order',

        details      : 'Pre-Order Process',
        details_panel: 'Pre-Order Details',

        status: {
            title         : 'Change Pre-Order Status',
            status        : 'Pre-Order Status',
            current_status: 'Current Pre-Order Status',
            new_status    : 'New Pre-Order Status',
        },

        message: {
            convert: {
                success: 'The pre-order was successfully ordered',
            },
        },
    },

    claim: {
        title: 'Claims',
        name : 'Claim',

        create: 'Add New Claim',
        edit  : 'Edit Claim',

        process          : 'Claim Process',
        change_status    : 'Change Claim Status',
        claim_date       : 'Claim Date: {0}',
        claim_item       : 'Claim Items',
        empty_claim_items: 'There are no items for this claim.',
        un_claimable     : 'Claim can not be set for this order.',

        inventory_process: {
            title        : 'Ware Process',
            select_code  : 'Select the ware code(s).',
            returned_code: 'Returned Wares Code',
            replaced_code: 'Replaced Wares Code',
            no_code      : 'Code not found.',

            alert: {
                count   : 'The number of confirmed items should not exceed the number of complaints. (Complained items: {0})',
                returned: 'The number of returned items should not exceed the number of approved items.',
                replaced: 'The number of replacement items should not exceed the number of return items.',
            },
        },

        close: {
            title : 'Claim Finalization',
            text  : 'Are you sure you want to finalize the claim?',
            button: 'Finalize',
        },

        search: {
            first_use: 'You can register a claim, by searching for an order.',
            result   : 'Search results for: ',
            again    : 'Search again',
            no_result: 'Order not found with this order code!',
        },

        message: {
            change_status: {
                prompt : 'Are you sure you want to change the status of the claim to {0}?',
                success: 'The status of the claim was changed successfully.',
            },

            change_claim_payment: {
                success: 'Claim Payment status changed from {0} to {1}',
            },

            close: {
                success: 'The complaint was successfully closed.',
            },
        },
    },

    invoice: {
        title : 'Invoices',
        prompt: {
            generate: {
                title: 'Create an Invoice',
                text : 'Are you sure you want to create an invoice for this order?',
            },
            regenerate: {
                title: 'Invoice Regenerate',
                text : 'Are you sure you want to regenerate the invoice for this order?',
            },
        },

        message: {
            generate  : 'Invoice generated successfully.',
            regenerate: 'Invoice regenerated successfully.',
            error     : 'A problem occurred when performing the action. Please try again.',
        },
    },

    bill: {
        title: 'Order Bill',
    },
    
    timeline: {
        title  : 'History',
        details: 'Details',
        empty  : 'There is no history for this cart.',
        by     : 'By',
        filter : 'History Filter',
        all    : 'Show All',
    },

    panel: {
        comment: {
            title: 'Attached note',

            message: {
                success: 'Your message has been successfully sent.',
                error  : 'There was a problem sending the message.',
            },
        },

        address: {
            name  : 'Address',
            create: 'Add an Address',
        },

        user: {
            title : 'Customer',
            name  : 'Customer',
            create: 'Add New Customer',
        },

        shipping: {
            title              : 'Shipping',
            no_shipping_methods: 'No shipping method is available.',
        },

        ware: {
            title         : 'Wares',
            search_ware   : 'Search and select ware',
            empty_currency: 'To add a ware, first select the currency.',
            empty_ware    : 'Add the first item via the Add button.',
        },
    },

    coupon_processing: {
        title     : 'Coupons Processing',
        new_coupon: 'New Coupon',

        message: {
            add_coupon: {
                success: 'Coupons added successfully.',
            },
            remove_coupon: {
                success: 'Coupons successfully removed.',
            },
        },
    },

    payment_management: {
        title: 'Payments Management',

        wallet_balance : 'Wallet Balance',
        no_transactions: 'There is no transaction for this order.',
        
        method: {
            path  : 'Path',
            wallet: 'Wallet',
        },
        
        method_type: {
            online : 'Online',
            offline: 'Offline',
        },
        
        type: {
            in : 'Receive from customer',
            out: 'Payment to the customer',
        },

        message: {
            success: 'The transaction was successfully registered.',
        },
    },

    inventory_process: {
        title: 'Inventory Processing',

        not_enough_error : 'Inventory is not enough for the following wares',
        inventory_details: '(Inventory: {0} - Request: {1})',
        ok               : 'Inventory is sufficient for the following wares and will be deducted automatically',
        no_code          : 'Code not found.',
        not_enough       : '{0} other code(s) must be selected.',
        not_available    : 'Some selected items have been sold.',
        
        available_codes: 'Available Codes',
        selected       : 'Selected ({0})',

        to_inventory_link: 'Go to Inventory',
    },

    shipping_processing: {
        title         : 'Shipping Management',
        address_empty : 'Customer address not completed.',
        change_invoice: 'By changing the shipping method, the invoice for this order will be updated.',

        message: {
            success: 'Shipping method changed successfully.',
        },
    },

    table: {
        row            : 'Row',
        ware           : 'Ware',
        dependent_wares: 'Dependent Wares',
        unique_wares   : 'Unique Wares',
        other_wares    : 'Other Wares',
        codes          : 'Selected codes',
        count          : 'count',
        raised_at      : 'Raised At',
        delivered_at   : 'Delivered At',
        effected_at    : 'Effected At',
        title          : 'Title',
        order_number   : 'Order Number',
        tracking_number: 'Tracking Number',
        receipt        : 'Receipt',
        status         : 'Status',
        actions        : 'Actions',

        customer: {
            name: 'Customer Name',
        },
        
        claim: {
            count_on       : 'Count in Claim',
            count_confirmed: 'Confirmed Count',
            reason         : 'Reason',
            ok             : 'Allowed',
            not_ok         : 'Not Allowed',
            raised_at      : 'Raised At',
        },

        payment: {
            method          : 'Method',
            method_type     : 'Method Type',
            amount          : 'Amount',
            original_price  : 'Price',
            unit_price      : 'Unit Price',
            total_amount    : 'Total Amount',
            paid            : 'Paid Amount',
            payable         : 'Payable Amount',
            paid_to_customer: 'Paid to the customer',
            tax             : 'Tax',
            tax_total       : 'Total Tax',
            unit_tax        : 'Unit Tax',
            discount        : 'Discount',
            unit_discount   : 'Unit Discount',
            total_discount  : 'Total Discount',
            overall_discount: 'Overall Discount',
            coupon_discount : 'Coupon Discount',
            diff            : 'Dispute',
            currency        : 'Currency',
            at              : 'Paid At',
            status          : 'Payment Status',
            type            : 'Payment Type',
            order_total     : 'Order Amount',
            shipping        : 'Shipping Amount',
            wares_discount  : 'Wares Discount',
            coupons_discount: 'Coupons Total Discount',
            coupons         : {
                code    : 'Coupon',
                discount: 'Discount Amount',
            },
            shipping_cost: 'Shipping Cost',
        },

        coupon: {
            rulebook: 'Rulebook',
            code    : 'Code',
            discount: 'Discount',
        },
    },

    widget: {
        empty: 'The widget will be installed in this place soon! 😬',
    },

    actions: {
        details            : 'Details',
        edit               : 'Edit',
        print              : 'Print',
        change_status      : 'Change Status',
        inventory          : 'Inventory Process',
        order_close        : 'Close Order',
        order_reopen       : 'Reopen Order',
        claim_close        : 'Finalize Claim',
        payment            : 'Payment Management',
        convert_to_order   : 'Convert to Order',
        coupon_processing  : 'Coupon Process',
        add_coupon         : 'Add Coupon',
        shipping_processing: 'Shipping Process',
        ware_process       : 'Ware Process',
        view_receipt       : 'View Receipt',
        invoice            : {
            generate    : 'Generate Invoice',
            regenerate  : 'Regenerate Invoice',
            view        : 'View/Print Invoice',
            view_invoice: 'View Invoice',
        },
    },

    prompt: {
        close_caption  : 'Are you sure want to close the order?',
        open_caption   : 'Are you sure want to open the order?',
        convert_caption: 'Pre-Order will be converted to order',
        convert_title  : 'Convert to Order',
    },

    message: {
        change_status: {
            success: 'Status successfully changed to {0}.',
        },
        convert: {
            success: 'Pre-Order converted to order successfully.',
        },
    },

    nodes: {
        order_edited: {
            payable_amount  : 'Payable amount has been changed from {0} to {1}.',
            overall_discount: 'Overall discount has been changed from {0} to {1}',
            title_changed   : 'Title has been changed from "{0}" to "{1}"',
            item_added      : 'Item "{0}" has been added to the order',
            item_removed    : 'Item "{0}" has been removed from the order',
            item_edit       : 'Number of "{0}" has been changed from {1} to {2}',
            exp_date_changed: 'Expiration date has been changed from "{0}" to "{1}"',
        },
        claim_edited: {
            item_counts_changed: 'Number of the claimed items changed from {0} to {1}.',
            item_added         : '"{0}" added.',
            item_removed       : '"{0}" removed.',
            item_edit_count    : 'Number of {0} changed from {1} to {2}.',
            item_edit_reason   : 'Claim "{0}" reason changed from {0} to {2}.',
        },
        payment_paid: {
            text: 'The transaction of type "{0}" for {1} {2}, from "{3}" path has done.',
        },
        order_invoice: {
            generated  : 'Invoice number {0} is generated.',
            regenerated: 'Invoice number {0} is regenerated.',
            returned   : 'Invoice number {0} is returned.',
            canceled   : 'Invoice number {0} is canceled.',
            documented : 'Invoice document number {0} is documented.',
        },
        coupon: {
            used              : 'The coupon code "{0}" from "{1}" scenario has been applied.',
            unused            : 'The coupon code "{0}" from "{1}" scenario has been removed.',
            coupon_discount   : 'Coupon Discount',
            old_payable_amount: 'Previous Payable Amount',
            new_payable_amount: 'New Payable Amount',
            old_payable_status: 'Previous Payment Status',
            new_payable_status: 'New Payment Status',
        },

        shipping_used: {
            text: 'Shipping method "{0}" with the amount of "{1}" for "{2}" was registered.',
        },

        undefined_node: 'There are no ninety {0} for this order.',
    },
    
    details: {
        customer: {
            name    : 'Customer Name',
            tel     : 'Customer Phone',
            address : 'Customer Address',
            city    : '{0}, ',
            province: '{0}, ',
        },
        
        order: {
            status     : 'Order Status',
            create_date: 'Order Date',
        },
        
        pre: {
            status     : 'Pre-Order Status',
            create_date: 'Creation Date',
            expire_date: 'Expire Date',
        },
        
        change_status_date         : 'Last Change Status Date',
        change_status_address_empty: 'The method of shipping is not selected. Please select the shipping method first.',

        shipping: {
            delivery_date         : 'Delivery Deadline',
            delivery_interval     : 'Delivery Interval',
            delivery_interval_mask: '{0} to {1}',
        },
    },
};
