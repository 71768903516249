/*-----------------------------------------------------------------
- Load locales and register it
-----------------------------------------------------------------*/
import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Load Locales
import appLocales from '@configs/locales';

import path from 'path';
import deepMerge from 'deepmerge';

const locales = (process.env.VUE_APP_PANEL === 'true' && process.env.VUE_APP_LOCALES)
    ? process.env.VUE_APP_LOCALES.split(',')
    : appLocales.locales;

// Use VueI18n
Vue.use(VueI18n);

/**
 * Make locales empty object
 * 
 * @returns {object}
 */
const makeLocalesObj = () => {
    const obj = {};
    locales.map((locale) => { // eslint-disable-line
        obj[locale] = {};
    });
    return obj;
};

// Default messages object
let messages = makeLocalesObj();
type files = {
  core: any | undefined,
  modules: any | undefined,
  rogues: any | undefined,
  components: any | undefined,
};
const importedFiles: files = {
    core      : null,
    modules   : null,
    rogues    : null,
    components: null,
};

// Load files
importedFiles.core = require.context('@locales', true, /\.(js|ts)$/);
importedFiles.modules = require.context('@modules', true, /\/locales[\S]*\.(js|ts)$/);
importedFiles.rogues = require.context('@rogues', true, /\/locales[\S]*\.(js|ts)$/);
importedFiles.components = require.context('@components', true, /\/locales[\S]*\.(js|ts)$/);

/**
 * Get nth occurrence of string
 *
 * @param {*} string
 * @param {*} subString
 * @param {*} index
 * @returns {number}
 */
const getPosition = (string, subString, index) => string.split(subString, index).join(subString).length;

// Merge messages
Object.keys(importedFiles).forEach((section) => {
    const importedMessages = makeLocalesObj();
    importedFiles[section].keys().forEach((fileName) => {
        let locale:any = null;
        let file:any = null;
        const content = importedFiles[section](fileName).default;
    
        if (section.includes('modules') || section.includes('rogues') || section.includes('components')) {
            locale = fileName.substr(getPosition(fileName, '/', 3) + 1, 2);
            file = path.basename(fileName, fileName.includes('.ts') ? '.ts' : '.js');
    
            if (locales.includes(locale)) {
                importedMessages[locale][file] = deepMerge(importedMessages[locale][file] || {}, content);
            }
        } else {
            locale = fileName.substr(2, 2);
            file = fileName.substring(5).replace('.js', '').replace('.ts', '');
            
            if (locales.includes(locale)) {
                importedMessages[locale][file] = deepMerge(importedMessages[locale][file] || {}, content);
            }
        }
    });
    messages = deepMerge(importedMessages, messages, { arrayMerge: (destinationArray, sourceArray) => sourceArray });
});

// Defaults
const locale = locales[0] || 'fa';
const fallbackLocale = locales[0] || 'fa';

// Register messages
const I18n = new VueI18n({
    locale,
    fallbackLocale,
    messages,
    silentTranslationWarn: true,
});

export default I18n;
