/* -------------------------------------------------------------------------- */
/* MISC HELPERS                                                               */
/* -------------------------------------------------------------------------- */

/**
 * Convert list to tree
 *
 * @param {*} arr
 * @returns {Array}
 */
export const listToTree = (arr) => {
    const tree = [];
    const mappedArr = {};
    let arrElem;
    let mappedElem;
    
    // First map the nodes of the array to an object -> create a hash table.
    for (let i = 0, len = arr.length; i < len; i += 1) {
        arrElem = arr[i];
        mappedArr[arrElem.id] = arrElem;
        mappedArr[arrElem.id].children = [];
    }
    
    Object.keys(mappedArr).map((id) => {
        if (Object.prototype.hasOwnProperty.call(mappedArr, id)) {
            mappedElem = mappedArr[id];
            if (mappedElem.parent_id && Object.prototype.hasOwnProperty.call(mappedArr, mappedElem.parent_id)) {
                mappedArr[mappedElem.parent_id].children.push(mappedElem);
            } else {
                tree.push(mappedElem);
            }
        }
        return true;
    });
    return tree;
};

/**
 * Convert tree to list
 *
 * @param {*} arr
 * @param {*} list
 * @param {*} parent
 * @returns {Array}
 */
export const treeToList = (arr, list = [], parent = null) => {
    arr.forEach((item) => {
        list.push({
            id       : item.id,
            parent_id: parent,
        });
        if (item.children && Array.isArray(item.children) && item.children.length) {
            treeToList(item.children, list, item.id);
        }
    });
    return list;
};
