/*-----------------------------------------------------------------
- Load all settings and share it into Vue instance
-----------------------------------------------------------------*/

// Export plugin
export default {
    
    /**
     * Install plugin
     *
     * @param {*} Vue
     */
    install(Vue: any) {
        // eslint-disable-next-line
        Vue.prototype.$settings = function(slug, defaultValue) {
            return this.$store.getters['settings/get'](slug, defaultValue);
        };
    },
};
