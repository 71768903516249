/*-----------------------------------------------------------------
- Validations Configs
-----------------------------------------------------------------*/
export default {
    rules: [
        'after',
        'alpha',
        'alpha_dash',
        'alpha_num',
        'between',
        'confirmed',
        'decimal',
        'digits',
        'email',
        'included',
        'length',
        'max',
        'max_value',
        'min',
        'min_value',
        'excluded',
        'numeric',
        'regex',
        'required',
        'required_if',
        'size',
        'url',
    ],
};
