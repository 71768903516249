<template>
    <div>
        <span class="seo-inspector-preview__url">
            {{ url ? url : $t('seo.sample_url') }}
        </span>

        <span class="seo-inspector-preview__title">
            {{ title ? title : postTitle && postTitle.length ? $t('seo.empty_preview') : $t('seo.post_title_empty') }}
        </span>

        <span class="seo-inspector-preview__desc">
            {{ desc ? desc : metaDescription && metaDescription.length ? $t('seo.empty_preview') : $t('seo.meta_description_empty') }}
        </span>
    </div>
</template>

<script>
    import { date } from '@nodes/helpers/date';
    import { digits } from '@nodes/helpers/number';

    export default {
        name: 'SeoInspectorPreview',

        props: {
            element              : Object,
            value                : Object,
            postTitle            : Array,
            metaDescription      : Array,
            lang                 : String,
            customTextTitle      : String,
            customTextDescription: String,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                title     : null,
                desc      : null,
                setDefault: false,

                static: [
                    'separator',
                    'site_title',
                    'author_first_name',
                    'author_last_name',
                    'author_full_name',
                    'published_date',
                    'categories',
                    'category',
                    'tags',
                    'current_date',
                    'current_month',
                    'current_year',
                    'current_day',
                ],
            };
        },

        computed: {
            /**
             * Return the URL of the post
             */
            url() {
                return this.element && this.element.post_url;
            },
        },

        watch: {
            value: {
                /**
                 * Watch value and change model
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (oldVal && newVal && this.setDefault) {
                        this.currentTitle();
                        this.currentDesc();
                    }
                },
                deep: true,
            },

            postTitle: {
                /**
                 * Watch postTitle and change model
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (oldVal && newVal && this.setDefault) {
                        this.currentTitle();
                    }
                },
                deep: true,
            },

            customTextTitle: {
                /**
                 * Watch customTextTitle and change model
                 */
                handler() {
                    this.currentTitle();
                },
                deep: true,
            },

            metaDescription: {
                /**
                 * Watch metaDescription and change model
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (oldVal && newVal && this.setDefault) {
                        this.currentDesc();
                    }
                },
                deep: true,
            },

            customTextDescription: {
                /**
                 * Watch customTextDescription and change model
                 */
                handler() {
                    this.currentDesc();
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        mounted() {
            if (!this.setDefault) {
                this.createDefault();
            }
        },

        methods: {
            /**
             * Create Default in create mode
             */
            createDefault() {
                if (this.element && this.element.post_title && this.element.post_title.length) {
                    let title = '';
                    this.element.post_title.forEach((item) => {
                        title += `${digits(item.value, this.$i18n.locale)} `;
                    });
                    this.$set(this, 'title', title);
                    this.$emit('titleCount', typeof title === 'string' ? title.length : 0);
                }
                if (this.element && this.element.meta_description && this.element.meta_description.length) {
                    let desc = '';
                    this.element.meta_description.forEach((item) => {
                        desc += `${digits(item.value, this.$i18n.locale)} `;
                    });
                    this.$set(this, 'desc', desc);
                    this.$emit('descCount', typeof desc === 'string' ? desc.length : 0);
                }
                this.setDefault = true;
            },

            /**
             * Return the current title
             */
            currentTitle() {
                let title = '';
                if (this.postTitle && this.postTitle.length) {
                    Object.keys(this.postTitle).forEach((key) => {
                        if (this.static.includes(this.postTitle[key])) {
                            title += this.staticParams('title', this.postTitle[key]);
                        } else if (this.postTitle[key] === 'custom_post_title') {
                            title += `${this.customTextTitle} `;
                        } else {
                            title += this.value[this.postTitle[key]] ? `${this.value[this.postTitle[key]]} ` : '- ';
                        }
                    });
                }
                this.$set(this, 'title', title);
                this.$emit('titleCount', typeof title === 'string' ? title.length : 0);
            },

            /**
             * Return the current description
             */
            currentDesc() {
                let desc = '';
                if (this.metaDescription && this.metaDescription.length) {
                    Object.keys(this.metaDescription).forEach((key) => {
                        if (this.static.includes(this.metaDescription[key])) {
                            desc += this.staticParams('desc', this.metaDescription[key]);
                        } else if (this.metaDescription[key] === 'custom_meta_description') {
                            desc += `${this.customTextDescription} `;
                        } else {
                            desc += this.value[this.metaDescription[key]] ? `${this.value[this.metaDescription[key]]} ` : '- ';
                        }
                    });
                }
                this.$set(this, 'desc', desc);
                this.$emit('descCount', typeof desc === 'string' ? desc.length : 0);
            },

            /**
             * Generate static params
             *
             * @param type
             * @param param
             */
            staticParams(type, param) {
                const currentDate = Math.round(new Date().getTime() / 1000);

                if (param === 'separator') {
                    return ' | ';
                }
                if (param === 'site_title') {
                    return `${this.$settings('site_title.site_title')} `;
                }

                if (['author_full_name', 'author_first_name', 'author_last_name'].includes(param)) {
                    const find = this.element.post_title.find((item) => item.slug === param);
                    if (find) {
                        return `${digits(find.value, this.$i18n.locale)} `;
                    }
                    return `${this.$t(`seo.parameters.${param}`)} `;
                }
                if (param === 'published_date') {
                    if (this.value.published_at) {
                        return `${digits(`${this.dateHelper(this.value.published_at, 'YYYY-MM-DD', this.lang)}`, this.$i18n.locale)}`;
                    }
                    return `${this.$t('seo.parameters.published_date')} `;
                }
                if (param === 'category') {
                    if (this.getPreDefinedData(type, param)) {
                        return this.getPreDefinedData(type, param);
                    }
                    return `${this.$t('seo.parameters.category')} `;
                }
                if (param === 'categories') {
                    if (this.getPreDefinedData(type, param)) {
                        return this.getPreDefinedData(type, param);
                    }
                    return `${this.$t('seo.parameters.categories')} `;
                }
                if (param === 'tags') {
                    return `${this.$t('seo.parameters.tags')} `;
                }
                if (param === 'current_month' || param === 'current_date' || param === 'current_year' || param === 'current_day') {
                    if (this.getPreDefinedData(type, param)) {
                        return this.getPreDefinedData(type, param);
                    }
                    if (param === 'current_month') {
                        return `${this.dateHelper(currentDate, 'MM', this.lang)} `;
                    }
                    if (param === 'current_date') {
                        return `${this.dateHelper(currentDate, 'YYYY-MM-DD', this.lang)} `;
                    }
                    if (param === 'current_year') {
                        return `${this.dateHelper(currentDate, 'YYYY', this.lang)} `;
                    }
                    if (param === 'current_day') {
                        return `${this.dateHelper(currentDate, 'DD', this.lang)} `;
                    }
                }
                return '';
            },

            /**
             * Get pre-defined data
             *
             * @param type
             * @param param
             */
            getPreDefinedData(type, param) {
                let title = null;
                const t = type === 'title' ? 'post_title' : 'meta_description';
                if (this.element && this.element[t] && this.element[t].length) {
                    Object.keys(this.element[t]).forEach((key) => {
                        if (this.element[t][key].slug === param) {
                            title = this.element[t][key].value;
                        }
                    });
                }
                return this.digitHelper(title);
            },

            /**
             * Digit helper for using in inline condition
             *
             * @param value
             */
            digitHelper(value) {
                return digits(value, this.$i18n.locale);
            },

            /**
             * Date helper for using in inline condition
             *
             * @param value
             * @param format
             * @param locale
             */
            dateHelper(value, format = 'YYYY/MM/DD', locale = this.$i18n.locale) {
                return date(value, format, locale);
            },
        },
    };
</script>
