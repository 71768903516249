/*-----------------------------------------------------------------
- Content label Routes
-----------------------------------------------------------------*/
const prefix = 'content/tagging';
const routes = [
    {
        path     : `${prefix}/:slug`,
        name     : 'content-tagging-manage',
        component: () => import(/* webpackChunkName: "tagging" */ '@/modules/tagging/views/Manage'),
    },
    {
        path     : `${prefix}`,
        name     : 'content-tagging-folders',
        component: () => import(/* webpackChunkName: "tagging" */ '@/modules/tagging/views/Folders'),
    },
];

export default routes;
