/*-----------------------------------------------------------------
- Accounts Routes
-----------------------------------------------------------------*/
const prefix = 'stock';
const routes = [
    {
        path     : `${prefix}`,
        name     : `${prefix}-grid`,
        component: () => import(/* webpackChunkName: "payments" */ '@/modules/stock/views/List'),
        
    },
    {
        path     : `${prefix}/prices/:id`,
        name     : `${prefix}-prices-grid`,
        component: () => import(/* webpackChunkName: "payments" */ '@/modules/stock/views/Prices'),
        
    },
];

export default routes;
