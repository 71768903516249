/*-----------------------------------------------------------------
- Fields translations
-----------------------------------------------------------------*/
export default {
    
    link_text: 'Link Text',
    link     : 'Link',
    
    target_external: 'Open in new tab?',
    
    active: 'Active',
    
};
