/*-----------------------------------------------------------------
- Content buttons
-----------------------------------------------------------------*/
export default {
    
    set_publish_date   : 'Set publish Date',
    change_publish_date: 'Change Publish Date',
    publish_now        : 'Publish Now!',
    publish_scheduled  : 'Publish scheduled',
    
};
