/*-----------------------------------------------------------------
- Notifications translations
-----------------------------------------------------------------*/
export default {
    filters: {
        all   : 'All',
        unread: 'Unread',
    },
    empty: 'No results found.',
};
