/*-----------------------------------------------------------------
- Accountant Fields Locales
-----------------------------------------------------------------*/
export default {
    
    activeness : 'Active and usable',
    description: 'Description',
    wallet     : 'Wallet',
    gate       : 'Gate',
    debtor     : 'Debtor',
    creditor   : 'Creditor',
    
    document_desc: 'Description',
    posting_desc : 'Description',
    
    verification_note: 'Verification Note',
    rejection_note   : 'Rejection Note',
    attachments      : 'Attachments',
    group            : 'Group',
    segregate        : 'Segregate',
    ledger           : 'Ledger',
    is_settlement    : 'Settlement Document',
    document         : 'Document',
    manual_code      : 'Manual Code',
};
