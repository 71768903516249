<template>
    <li
        class="menu-item"
        :class="[itemClasses]"
        @click.stop="refresh"
    >
        <router-link
            v-if="isLink"
            :to="makeLinkObject"
            class="link router-link"
            @click.native="onLinkClick"
        >
            <i
                v-if="item.icon && level === 0"
                class="icon"
                :class="item.icon"
                :aria-label="item.icon"
            />
            <span class="menu-title">
                {{ item.label || item.id }}
            </span>
        </router-link>

        <div
            v-else-if="isHeading"
            class="heading"
        >
            {{ item.label || item.title || item.id }}
        </div>

        <hr
            v-else-if="isSeparator"
            class="separator"
        >

        <template v-else>
            <a
                href="#"
                class="link"
                @click.prevent="toggleSub"
            >
                <i
                    v-if="item.icon && level === 0"
                    class="icon"
                    :class="item.icon"
                    :aria-label="item.icon"
                />
                <span class="menu-title">
                    {{ item.label || item.id }}
                </span>
            </a>

            <a
                v-if="hasChild"
                href="#"
                aria-label="Toggle menu item"
                class="menu-toggle icon md-chevron-down"
                @click.prevent="toggleSub"
            />

            <slide-up-down
                v-if="hasChild"
                :active="open"
            >
                <ul
                    class="sub-level"
                    :class="['level-'+level]"
                >
                    <panel-menu-item
                        v-for="(child, index) in item.children"
                        :key="index"
                        :item="child"
                        :level="level+1"
                    />
                </ul>
            </slide-up-down>
        </template>
    </li>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';

    export default {

        name: 'PanelMenuItem',

        /**
         * Start `loading-page` before updating route
         */
        beforeRouteUpdate() {
            // this.generateKey();
        },

        props: {
            item : Object,
            level: {
                type   : Number,
                default: 0,
            },
            sidebarCollapsed: Boolean,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                open     : false,
                keyHelper: generateId(),
            };
        },

        computed: {

            /**
             * Return type of item
             *
             * @returns {*|null}
             */
            type() {
                return this.item.type || null;
            },

            /**
             * Check if type is heading
             *
             * @returns {boolean}
             */
            isHeading() {
                return this.type === 'heading';
            },

            /**
             * Check if type is separator
             *
             * @returns {boolean}
             */
            isSeparator() {
                return this.type === 'separator';
            },

            /**
             * Check if type is link
             *
             * @returns {boolean}
             */
            isLink() {
                return !!(typeof this.item.link === 'object' && !Array.isArray(this.item.link) && this.item.link.name);
            },

            /**
             * Get link name
             */
            linkName() {
                if (!this.isLink) {
                    return null;
                }
                return this.item.link.name;
            },

            /**
             * Check if item has any child
             */
            hasChild() {
                return this.item && this.item.children && this.item.children.length;
            },

            /**
             * Is link empty
             */
            isEmptyLink() {
                return Array.isArray(this.item.link) || this.item.link === '#' || this.item.link.url === '#';
            },

            /**
             * Make link object to use in router
             */
            makeLinkObject() {
                if (Array.isArray(this.item.link) || typeof this.item.link === 'string') {
                    return '#';
                }
                return this.item.link;
            },

            /**
             * 
             */
            hasActiveChild() {
                // TODO: change the procedure to indicate how to determine sub-items route matching or force back-end to provice unique name for every single item.link.name
                return this.hasChild
                    ? !!this.item.children.find((item) => {
                        if (this.$route.name === item.link.name) {
                            if (!item.link.params) {
                                return true;
                            }
                            return Object.keys(item.link.params).every((param) => {
                                if (param === 'version') {
                                    return true;
                                }
                                return this.$route.params.hasOwnProperty(param) && this.$route.params[param] === item.link.params[param];
                            });
                        }
                        return false;
                    })
                    : false;
            },

            /**
             * Classes of item
             *
             * @returns {Array}
             */
            itemClasses() {
                const classes = [];

                if (this.hasChild) {
                    classes.push('has-child');
                }
                if (this.open) {
                    classes.push('is-open');
                }
                if (this.isEmptyLink) {
                    classes.push('empty-item');
                }
                if (this.hasActiveChild) {
                    classes.push('has-active-child');
                }
                if (this.isLink && this.isCurrentRoute && this.haveRouteParams && this.haveRouteQuery) {
                    classes.push('router-link-active');
                    classes.push('router-link-exact-active');
                }

                return classes.join(' ');
            },

            /**
             * Check item has current route name
             */
            isCurrentRoute() {
                return this.item.link.name === this.$route.name;
            },

            /**
             * Check route has all params from current item
             */
            haveRouteParams() {
                if (!this.item.link.params) {
                    return true;
                }
                const params = Object.keys(this.item.link.params);
                return params.every((item) => this.$route.params.hasOwnProperty(item) && String(this.$route.params[item]) === String(this.item.link.params[item]));
            },

            /**
             * Check that if current item has all the queries in current route
             *
             * @returns {boolean}
             */
            haveRouteQuery() {
                if (!this.item.link.query) {
                    return true;
                }
                const query = Object.keys(this.item.link.query);
                return query.every((item) => this.$route.query.hasOwnProperty(item) && String(this.$route.query[item]) === String(this.item.link.query[item]));
            },
        },

        watch: {
            /**
             * watch for sidebarCollapsed changes to close sub menu
             */
            sidebarCollapsed() {
                if (this.hasChild && this.sidebarCollapsed) {
                    this.closeSub();
                }
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            if (this.hasActiveChild && !this.sidebarCollapsed) {
                this.open = true;
            }
            if (this.hasChild) {
                /**
                 * close opened sidebar menus when sidebar being closed in collapsed mode
                 */
                this.$root.$on('closeSidebarMenus', () => {
                    if (this.open) {
                        this.closeSub();
                    }
                });
                /**
                 * close sub-menu when another sub-menu being opened in collapsed mode
                 */
                this.$root.$on('closeOtherSidebarMenus', (a) => {
                    if (this.open && a.ignore !== this.keyHelper) {
                        this.closeSub();
                    }
                });
            }
        },

        methods: {

            /**
             * Toggle sub menu
             */
            toggleSub() {
                if (!this.open && this.sidebarCollapsed) {
                    this.$root.$emit('closeOtherSidebarMenus', { ignore: this.keyHelper });
                }
                this.open = !this.open;
            },

            /**
             * Close sub menu
             */
            closeSub() {
                this.open = false;
            },

            /**
             * Refresh component if needed
             */
            refresh() {
                if (this.linkName === this.$route.name && this.haveRouteParams) {
                    const to = this.set(this.cloneDeep(this.makeLinkObject), 'query.r', this.keyHelper);
                    this.$router.replace(to).catch(() => {});
                }
            },

            /**
             * Close other opened sub-menus in collapse mode
             */
            onLinkClick() {
                if (this.isLink && this.sidebarCollapsed) {
                    this.$root.$emit('closeSidebarMenus');
                }
            },

        },
    };
</script>
