/*-----------------------------------------------------------------
- Settings Routes
-----------------------------------------------------------------*/
const prefix = 'settings';
const routes = [
    {
        path     : `${prefix}/notifier`,
        name     : 'settings-notifier',
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/settings/views/Notifier'),
    },
    {
        path     : `${prefix}`,
        name     : 'settings-list',
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/settings/views/List'),
    },
];

export default routes;
