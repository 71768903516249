/*-----------------------------------------------------------------
- MenuMaker Routes
-----------------------------------------------------------------*/
const prefix = 'menu-maker';
const routes = [
    {
        path     : `${prefix}/:slug/edit`,
        name     : `${prefix}-edit`,
        component: () => import(/* webpackChunkName: "menu-maker" */ '@/modules/menu-maker/views/Manage'),
    },
    {
        path     : `${prefix}`,
        name     : `${prefix}-list`,
        component: () => import(/* webpackChunkName: "menu-maker" */ '@/modules/menu-maker/views/List'),
    },
];

export default routes;
