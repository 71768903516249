/*-----------------------------------------------------------------
- Subscription Routes
-----------------------------------------------------------------*/
const prefix = 'subscription';

const routes = [
    {
        path     : `${prefix}/general-settings`,
        name     : `${prefix}-general-settings`,
        component: () => import(/* webpackChunkName: "subscription" */ '@/modules/subscription/views/GeneralSettings'),
    },
    // Create
    {
        path     : `${prefix}/create/:id?`,
        name     : `${prefix}-package-create`,
        component: () => import(/* webpackChunkName: "subscription" */ '@/modules/subscription/views/Edit'),
    },
    // Edit
    {
        path     : `${prefix}/:id/edit`,
        name     : `${prefix}-package-edit`,
        component: () => import(/* webpackChunkName: "subscription" */ '@/modules/subscription/views/Edit'),
    },
];

export default routes;
