/*-----------------------------------------------------------------
- Roles and permissions Routes
-----------------------------------------------------------------*/
const prefix = 'persons/users';
const namePrefix = 'persons-users';
const routes = [
    {
        path     : `${prefix}/roles`,
        name     : `${prefix}-roles`,
        component: () => import(/* webpackChunkName: "persons" */ '@/modules/persons/views/roles/List'),
    },
    {
        path     : `${prefix}/roles/:slug/fields`,
        name     : `${namePrefix}-roles-fields`,
        component: () => import(/* webpackChunkName: "persons" */ '@/modules/persons/views/roles/Fields'),
    },
    {
        path     : `${prefix}/:id/permissions`,
        name     : `${namePrefix}-permissions`,
        component: () => import(/* webpackChunkName: "persons" */ '@/modules/persons/views/permissions/Edit'),
    },
];

export default routes;
