/*-----------------------------------------------------------------
- Errors translations
-----------------------------------------------------------------*/
export default {

    404: {
        title : 'The page you are looking for does not exist.',
        button: 'Back to Home',
    },

    500: {
        title : 'Oops, A problem occurred in our End!',
        button: 'Reload',
    },

    upload: {
        common: 'Oops, A problem occurred in our End!',
    },
};
