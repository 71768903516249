/*-----------------------------------------------------------------
- Stock translations
-----------------------------------------------------------------*/
export default {
    
    item: 'نرخ',
    
    rateChart: {
        title: {
            min    : 'پایین ترین نرخ روز',
            average: 'نوسانات نرخ',
            max    : 'بالاترین نرخ روز',
        },
        tooltip: {
            price: {
                start  : 'قیمت شروع روز',
                end    : 'قیمت پایان روز',
                min    : 'حداقل قیمت',
                max    : 'حداکثر قیمت',
                average: 'میانگین قیمت',
            },
        },
    },
    
};
