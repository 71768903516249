/*-----------------------------------------------------------------
- Content Themes Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Content Themes fetch slideshows
     *
     * @param {...any} args
     * @returns {*}
     */
    slideshowsFetch: (...args) => Resource.get('content-themes-slideshows-fetch', args),
    
    /**
     * Content Themes fetch slideshows
     *
     * @param {...any} args
     * @returns {*}
     */
    slideshowsSave: (...args) => Resource.post('content-themes-slideshows-save', args),
    
    /**
     * Content Themes fetch grid
     *
     * @param {...any} args
     * @returns {*}
     */
    faqGrid: (...args) => Resource.get('content-themes-faq-grid', args),
    
    /**
     * Content Themes fetch faqs
     *
     * @param {...any} args
     * @returns {*}
     */
    faqFetch: (...args) => Resource.get('content-themes-faq-fetch', args),
    
    /**
     * Content Themes save faqs
     *
     * @param {...any} args
     * @returns {*}
     */
    faqSave: (...args) => Resource.post('content-themes-faq-save', args),
    
    /**
     * Content Themes save order
     *
     * @param {...any} args
     * @returns {*}
     */
    faqSaveOrder: (...args) => Resource.post('content-themes-faq-save-order', args),
 
};
