/*-----------------------------------------------------------------
- Comment Routes
-----------------------------------------------------------------*/
const prefix = 'commenting';
const routes = [
    {
        path     : `${prefix}`,
        name     : 'commenting-list',
        component: () => import(/* webpackChunkName: "commenting" */ '@/modules/commenting/views/List'),
        
    },
    {
        path     : `${prefix}/:id`,
        name     : 'commenting-single',
        component: () => import(/* webpackChunkName: "commenting" */ '@/modules/commenting/views/Single'),
        
    },
];

export default routes;
