var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.element)?_c('div',{staticClass:"img-wrapper",class:_vm.classes,style:({
        width: _vm.px(_vm.element.width),
        height: _vm.px(_vm.element.height),

        minWidth: _vm.px(_vm.element.min_width),
        minHeight: _vm.px(_vm.element.min_height),

        maxWidth: _vm.px(_vm.element.max_width),
        maxHeight: _vm.px(_vm.element.max_height),
    })},[_c('div',{staticClass:"img-ratio",style:(_vm.aspectRatio)}),_c('img',{style:(_vm.grayScaleStyle),attrs:{"src":_vm.element.src,"alt":_vm.element.alt}})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }