/*-----------------------------------------------------------------
- Verify Modal trans
-----------------------------------------------------------------*/
export default {
    
    label: {
        current    : 'وضعیت فعلی شرکت',
        status     : 'وضعیت شرکت را انتخاب کنید',
        description: 'دلیل: ',
        confirmed  : 'تایید شده',
        rejected   : 'رد شده',
    },
    
    timeline: {
        label      : 'تاریخچه',
        date       : 'تاریخ',
        status     : 'وضعیت شرکت به {0} تغییر کرد.',
        description: 'علت: {0}',
    },
    
};
