/*-----------------------------------------------------------------
- Commenting Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Commenting  List
     *
     * @param {...any} args
     * @returns {*}
     */
    list: (...args) => Resource.get('commenting-list', args),
    
    /**
     * Commenting  Get Form Config
     *
     * @param {...any} args
     * @returns {*}
     */
    getFormConfig: (...args) => Resource.get('commenting-get-form-config', args),
    
    /**
     * Commenting  Comment Save
     *
     * @param {...any} args
     * @returns {*}
     */
    commentSave: (...args) => Resource.post('commenting-comment-save', args),
    
};
