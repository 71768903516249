/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/

export default {
    
    // News
    news: {
        title        : 'اخبار',
        subtitle     : 'آخرین خبرها از فعالیت‌های ما',
        category     : 'دسته‌ها',
        archive      : 'بایگانی',
        comment_reply: 'دیدگاه شما',
        show_comment : 'نمایش دیدگاه',
    },
    
    // Pages
    pages: 'برگه‌ها',
    
    // Blog
    blog: {
        title        : 'وبلاگ',
        subtitle     : 'آخرین مطالب از وبلاگ ما',
        category     : 'دسته‌ها',
        archive      : 'بایگانی',
        comment_reply: 'دیدگاه شما',
    },
    
    // Faq
    faq: {
        title: 'پرسش‌های متداول',
    },
    
    read_more: 'ادامه مطلب',
    
    read_rather: 'بیشتر بخوانید:',
    
    source: 'منبع',
    
    tag: {
        1: 'برچسب',
        s: 'برچسب‌ها',
    },
    
    author: 'نویسنده',
    
    subscribe_newsletter: 'عضویت در خبرنامه',
    
    enter_email: 'ایمیل خود را وارد کنید',
    
    social_network: {
        instagram: 'اینستاگرام',
        aparat   : 'آپارات',
        linkedin : 'لینکدین',
        twitter  : 'توییتر',
        facebook : 'فیسبوک',
        telegram : 'تلگرام',
    },
    
    terms_conditions: 'قوانین و مقررات',
    
    advertisement: 'تبلیغات',
    about_us     : 'درباره ما',
    contact_us   : 'تماس با ما ',
    
};
