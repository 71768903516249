/*-----------------------------------------------------------------
- Settings configs
-----------------------------------------------------------------*/

const settings = {
    entries     : ['site_title', 'socials', 'contact_details', 'content', 'site_logos', 'seo', 'copy_right', 'favicon'],
    getRecaptcha: false,
    menuSlugs   : [],
};

export default settings;
