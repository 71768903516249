/*-----------------------------------------------------------------
- General trans
-----------------------------------------------------------------*/
export default {
    
    sidebar: {
        developerTools: 'Developer Tools',
        preview       : 'Preview',
        components    : 'Components',
        settings      : 'Settings',
        lab           : 'Lab',
        info          : 'Model info',
        routes        : 'Routes',
        
        help: 'Help',
    },
    
    widget: {
        title       : 'Widget',
        added       : 'Widget added successfully',
        removed     : 'Widget deleted successfully',
        updated     : 'Widget updated successfully',
        sorted      : 'Widgets updated successfully',
        library     : 'Widgets Library',
        need_setting: 'You need to setting widget for using it',
    },
    
    widgets: {
        content: {
            empty: 'Empty',
        },
        
        user_finder: {
            form: {
                placeholder: 'Search User...',
                button     : 'Search',
            },
            
            result: {
                view_profile: 'View Profile',
                no_result   : 'User ',
                more_result : 'More Results',
            },
        },
        
        org_finder: {
            form: {
                placeholder: 'Search Organization...',
                button     : 'Search',
            },
            
            result: {
                view_profile: 'View Profile',
                no_result   : 'Organization ',
                more_result : 'More Results',
            },
        },
        
        order_finder: {
            form: {
                placeholder: 'Search Order by Tracking Number',
                button     : 'Search',
                reset      : 'Reset Search',
            },
            
            result: {
                view_order: 'View Order',
                
                info: {
                    tracking_number: 'Order Tracking Number',
                    order_status   : 'Order Status',
                    user_name      : 'Customer Name',
                    date           : 'Created Date',
                    payable_amount : 'Payable Amount',
                    paid_amount    : 'Paid Amount',
                    pay_status     : 'Pay Status',
                },
                
                no_result: 'Orders ',
            },
        },
        
        welcome: {
            greeting             : 'Welcome to Quarkino!',
            about_quark          : 'We are glad that you decided to choose Quark Solution.',
            about_quark_desc     : 'You are currently using the 4th version of Quarkino, produced by knowledge enterprise Information Technology Company of Sorena (YasnaTeam) In order to benefit from the services and checking the problems, refer to the YasnaTeam customer relationship system through the Support button. To get acquainted with other services and products, refer to the YasnaTeam website using the More Information button.', // eslint-disable-line
            homepage_link_support: 'Support',
            homepage_link_info   : 'More info',
        },
    },
    
};
