<template>
    <component
        :is="componentType"
        v-slot="clonerRow"
        :label="element.label"
        :rows="model"
        :hide-action="!isMultiPhone"
    >
        <div class="row">
            <div
                v-if="countryCodes && countryCodes.length"
                class="col-2"
            >
                <y-form-field
                    v-model="model[clonerRow.index].country_code"
                    type="select"
                    :options="countryCodes"
                    :placeholder="element.placeholder ? element.placeholder : $t('fields.country_code')"
                    :label="countryCodesLabel"
                    :no-label="isMultiPhone"
                    :validation="element.validation"
                    allow-empty
                />
            </div>
            <div :class="countryCodes && countryCodes.length ? 'col-10' : 'col-12'">
                <y-form-field
                    v-model="model[clonerRow.index].phone"
                    type="number"
                    :placeholder="element.placeholder ? element.placeholder : ''"
                    :label="element.label"
                    :no-label="isMultiPhone"
                    :format="countryCodes && countryCodes.length ? 'DD DDDD DDDD' : 'DDD DDDD DDDD'"
                    :validation="element.validation"
                />
            </div>
        </div>
    </component>
</template>

<script>
    import ClonerMixin from '@/mixins/Cloner';
    import YFormField from '@deps/form/FormField';

    export default {
        name: 'YFormPhone',

        components: {
            YFormField,
        },

        mixins: [
            ClonerMixin,
        ],

        inject: {
            $validator: '$validator',
        },

        props: {
            /**
             * Element data
             */
            element: {
                type: [Object, Array],
            },

            /**
             * Value of the component
             */
            value: {
                type: Array,
            },

            /**
             * If Country code should be displayed
             */
            hasCountryCode: Boolean,

            /**
             * Country Codes
             */
            countryCodes: {
                type   : Array,
                default: () => [],
            },

        },
        
        /**
         * @inheritdoc
         */
        data() {
            return {
                model: {},
            };
        },

        computed: {
            /**
             * Return if multi phone is enabled
             */
            isMultiPhone() {
                return this.element && this.element.multi_phone;
            },

            /**
             * Return Cloner type according to the MultiPhone prop
             */
            componentType() {
                if (this.isMultiPhone) {
                    return 'YClonerPanel';
                }
                return 'YClonerWrapper';
            },

            /**
             * Check if country code should be displayed
             */
            hasCode() {
                return this.element && this.element.has_code ? this.element.has_code : this.hasCountryCode;
            },

            /**
             * Country Code Label
             */
            countryCodesLabel() {
                return this.element && this.element.country_code_label ? this.element.country_code_label : this.$t('fields.country_code');
            },
        },

        watch: {
            /**
             * Watch value to set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.model = this.value;
                },
                deep: true,
            },

            /**
             * Watch model to emit the value
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            this.model = this.value;
        },
    };
</script>
