<template>
    <div>
        <div class="status-current">
            <span class="status-current__title">
                {{ $t('verify.label.current') }}
            </span>
            <span
                class="status-current__status"
                :class="{'confirmed': current.status === 'organization-status-verified', 'rejected': current.status === 'organization-status-rejected'}"
            >
                {{ current.status_title }}
            </span>
        </div>
        <y-form-field
            v-model="model.status"
            type="select"
            :label="$t('verify.label.status')"
            :options="selectOptions"
            label-field="name"
            value-field="value"
            name="orgStatus"
        />
        <y-form-field
            v-if="model.status === 0"
            v-model="model.description"
            type="textarea"
            :label="$t('verify.label.description')"
            name="rejectMsg"
        />

        <div
            v-if="timeline.length > 0"
            class="timeline"
        >
            <div class="timeline-label">
                <span>{{ $t('verify.timeline.label') }}</span>
            </div>
            <div
                v-for="(item, index) in timeline"
                :key="index"
                class="timeline-item"
                :class="{'confirmed': item.status === 'organization-status-verified', 'rejected': item.status === 'organization-status-rejected'}"
            >
                <div class="timeline-wrapper">
                    <span class="timeline-content">
                        <span class="timeline-status">{{ $t('verify.timeline.status', [item.status_title]) }}</span>
                        <span
                            v-if="item.description"
                            class="timeline-desc"
                        >{{ item.description }}</span>
                    </span>
                    <span class="timeline-date">{{ item.date | date('HH:mm - YYYY/MM/DD', $i18n.locale) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import YFormField from '@deps/form/FormField';

    export default {
        name: 'YFormIcibVerifyOrganization',

        components: {
            YFormField,
        },

        props: {
            value  : [String, Object],
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {
                    id         : this.value.id,
                    status     : null,
                    description: '',
                },
                current      : [],
                timeline     : [],
                selectOptions: [],
                statusOptions: [
                    {
                        name : this.$t('verify.label.confirmed'),
                        slug : 'organization-status-verified',
                        value: 1,
                    },
                    {
                        name : this.$t('verify.label.rejected'),
                        slug : 'organization-status-rejected',
                        value: 0,
                    },
                ],
            };
        },

        watch: {
            /**
             * Watch model and emit on change
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.getTimeline();
        },

        methods: {
            /**
             * fetch timeline data
             */
            getTimeline() {
                if (this.value.id) {
                    const params = {
                        id: this.value.id,
                    };
                    return this.$services.Icib.organizationVerifyTimeline(params).then((response) => {
                        this.timeline = response.data.results;
                        this.current = response.data.metadata;
                        this.selectItems(response.data.metadata.status);
                    }).catch((error) => {
                        this.handleError(error);
                    });
                }
                return false;
            },

            /**
             * Remove current status from select
             *
             * @param status
             */
            selectItems(status) {
                this.statusOptions.forEach((i) => {
                    if (i.slug !== status) {
                        this.selectOptions.push(i);
                    }
                });
            },
        },
    };

</script>
