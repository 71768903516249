/*-----------------------------------------------------------------
- Organization Routes
-----------------------------------------------------------------*/
const prefix = 'persons';
const namePrefix = 'persons-organizations';

const routes = [
    {
        path     : `${prefix}/organizations`,
        name     : `${namePrefix}-list`,
        component: () => import(/* webpackChunkName: "panel" */ '@/modules/persons/views/organizations/List'),
    },
];

export default routes;
