/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/
export default {
    
    post: {
        title: 'Post',
        
        create: 'New Post',
        edit  : 'Edit Post',
    },
    
    current_locale: 'Current Language',
    
    confirm: {
        delete   : 'Are you sure to move this post to trash?',
        unpublish: 'Are you sure to unpublish this post?',
        reject   : 'Are you sure to reject this post to be published?',
    },
    
    publish_date: 'Publish Date:',
    
    success: {
        publish  : 'Post published.',
        schedule : 'Post scheduled.',
        delete   : 'Post moved to trash.',
        unpublish: 'Post unpublished.',
        draft    : 'Post saved as draft.',
        reject   : 'Post rejected.',
        send     : 'Post sent to the moderator.',
    },
    
    reject_title: 'Moderation Note:',
    
    status: {
        title    : 'Status: {0}',
        published: 'Published',
        draft    : 'Draft',
        trashed  : 'Trashed',
        scheduled: 'Scheduled',
        rejected : 'Rejected',
        pending  : 'Pending',
    },
    
    albums: {
        title: 'Albums',
        empty: 'No album found.',
    },

    post_category: {
        no_folder: 'No label folder found.',
        no_label : 'No label found.',
    },

    post_tag: {
        select_tag: 'Select your tags',
        no_folder : 'No label folder found.',
        no_label  : 'No label found.',
    },
};
