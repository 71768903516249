/*-----------------------------------------------------------------
- Toast translations
-----------------------------------------------------------------*/

export default {
    
    // Auth
    login: {
        success: 'Welcome :)',
        error  : 'A problem occurred saving {type}.',
    },
    
    // General
    save: {
        confirm    : 'Sure to save this type ({type})?',
        confirmFull: 'Sure to save <b>«{title}»</b>?',
        success    : 'Saving {type} was successful.',
        error      : 'A problem occurred saving {type}.',
    },
    
    update: {
        confirm    : 'Sure to update this type ({type})?',
        confirmFull: 'Sure to update <b>«{title}»</b>?',
        success    : 'Updating {type} was successful.',
        error      : 'A problem occurred updating {type}.',
    },
    
    trash: {
        confirm    : 'Sure to trash this type ({type})?',
        confirmFull: 'Sure to trash <b>«{title}»</b>?',
        success    : 'Trashing {type} was successful.',
        error      : 'A problem occurred trashing {type}.',
    },
    
    restore: {
        confirm    : 'Sure to restore this type ({type})?',
        confirmFull: 'Sure to restore <b>«{title}»</b>?',
        success    : 'Restoring {type} was successful.',
        error      : 'A problem occurred restoring {type}.',
    },
    
    destroy: {
        confirm    : 'Sure to destroy this type ({type})? This is permanent.',
        confirmFull: 'Sure to destroy <b>«{title}»</b>? This is permanent.',
        success    : 'Destroying {type} was successful.',
        error      : 'A problem occurred destroying {type}.',
    },
    
    delete: {
        confirm    : 'Sure to delete this type ({type})?',
        confirmFull: 'Sure to delete <b>«{title}»</b>?',
        success    : 'Deleting {type} was successful.',
        error      : 'A problem occurred deleting {type}.',
    },
    
    activate: {
        confirm    : 'Sure to activate this type ({type})?',
        confirmFull: 'Sure to activate <b>«{title}»</b>?',
        success    : 'Activating {type} was successful.',
        error      : 'A problem occurred activating {type}.',
    },
    
    deactivate: {
        confirm    : 'Sure to deactivate this type ({type})?',
        confirmFull: 'Sure to deactivate <b>«{title}»</b>?',
        success    : 'Deactivating {type} was successful.',
        error      : 'A problem occurred deactivating {type}.',
    },

    operation: {
        success: 'Operation has been done successfully.',
        error  : 'A problem occurred during operation.',
    },
    
    change_password: {
        success: 'Password changed!',
        error  : 'A problem occurred when changing the password. Please try again.',
    },
    
    cloner: {
        confirm_delete    : 'Are you sure you want to delete this?',
        confirm_delete_all: 'Are you sure you want to delete all items?',
    },
    
};
