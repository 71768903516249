<template>
    <div>
        <y-form-field
            v-model="model.tax_enabled"
            type="checkbox"
            name="tax_enabled"
        />
        <template v-if="model.tax_enabled">
            <y-form-field
                v-model="model.tax_should_add_to_price"
                type="checkbox"
                name="tax_should_add_to_price"
            />
            <y-form-field
                v-model="model.tax_value_added"
                type="number"
                name="tax_value_added"
                format="separated"
            />
            <y-form-field
                v-model="model.tax_withholding"
                type="number"
                name="tax_withholding"
                format="separated"
            />
            <y-form-field
                v-model="model.tax_municipality"
                type="number"
                name="tax_municipality"
                format="separated"
            />

            <y-form-field
                v-if="cats && cats.length"
                v-model="model.tax_specific_cats"
                type="select"
                :options="cats"
                name="tax_specific_cats"
                allow-empty
                multi
                label-field="title"
                value-field="id"
            />
        </template>
    </div>
</template>

<script>

    export default {

        name: 'YFormSaleTaxRule',

        components: {
            YFormField: () => import('@deps/form/FormField'),
        },

        inject: {
            $validator: '$validator',
        },

        props: {
            value  : Object,
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || null,
                cats : null,
            };
        },

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

        },

        /**
         * @inheritDoc
         */
        created() {
            if (+this.model.tax_enabled === 0) {
                this.model.tax_enabled = 0;
            }
            this.fetch();
        },

        methods: {

            /**
             * Fetch categories
             */
            fetch() {
                const params = {
                    slug     : this.element.main_folder_slug,
                    including: ['title'],
                };
                return this.$services.Tagging.labels(params).then((response) => {
                    this.cats = response.data.results;
                }).catch((error) => error);
            },

        },

    };

</script>
