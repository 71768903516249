/*-----------------------------------------------------------------
- Document Locales
-----------------------------------------------------------------*/
export default {
    
    item  : 'سند',
    create: 'ساخت سند حسابداری ',
    edit  : 'مشاهده/ویرایش سند حسابداری',
    print : 'سند حسابداری',
    
    print_button : 'چاپ سند حسابداری',
    edit_button  : 'ذخیره سند',
    create_button: 'ساخت سند',
    
    create_empty: 'برای مدیریت زیرسندها، ابتدا سند را ذخیره کنید.',
    
    balance: {
        change_status: 'چون سند تراز نیست وضعیت سند به یادداشت تغییر کرد.',
        set_status   : 'چون سند تراز نیست امکان انتخاب وضعیت {0} وجود ندارد.',
    },
    
    status: {
        new       : 'جدید',
        finalized : 'نهایی',
        balanced  : 'باز، تراز شده',
        unbalanced: 'باز، تراز نشده',
    },
    
    final_price: 'مبلغ مؤثر:',
    sum_all    : 'جمع کل',
    
    details: 'مشخصات سند',
    
    idx           : 'شماره سند:',
    turning_number: 'شماره عطف:',
    create_system : 'سیستم صادر‌کننده',
    create_user   : 'شخص صادر‌کننده',
    
    finalize: {
        title     : 'بستن سند',
        full_title: 'نهایی‌سازی و بستن سند',
        confirm   : 'بستن یک سند به معنای نهایی‌سازی همه‌ی زیرسندهای آن است. این کار قابل بازگشت نیست. مطمئنید؟',
        success   : 'سند با موفقیت بسته شد.',
    },
    
    info: {
        description  : 'شرح سند',
        status       : 'وضعیت',
        number       : 'شماره سند',
        turningNumber: 'شماره عطف',
        sub_number   : 'شماره فرعی',
        date         : 'تاریخ سند',
        currency     : 'ارز',
    },
    
    posting: {
        title: 'زیرسندها',
        item : 'زیرسند',
        
        empty: 'زیرسندی وجود ندارد. اولین را بسازید.',
        
        quick_not_found: 'زیرسندی با این مشخصات یافت نشد.',
        
        columns: {
            row              : 'ردیف',
            group            : 'کد معین',
            segregate        : 'کد تفصیلی',
            description      : 'شرح',
            debtor           : 'بدهکار',
            creditor         : 'بستانکار',
            manual_code      : 'شماره پیگیری',
            manual_date      : 'تاریخ پیگیری',
            status           : 'وضعیت',
            action           : 'عملیات',
            row_count        : 'تعداد کل ردیف‌ها: {0}',
            debtor_sum       : 'جمع بدهکار: ',
            creditor_sum     : 'جمع بستانکار: ',
            amount_difference: 'اختلاف سند: ',
        },
        
        add   : 'افزودن زیرسند',
        remove: 'حذف زیرسند‌های انتخاب شده',
        
        status: {
            pending : 'در انتظار تایید',
            verified: 'تایید شده',
            rejected: 'رد شده',
        },
        
        desc: {
            wallet: 'کیف پول:',
            gate  : 'مسیر پرداخت:',
            group : 'گروه:',
            date  : 'تاریخ:',
        },
        
        quick_posting : 'ایجاد سریع',
        quick_view    : 'مشاهده زیرسند',
        quick_view_btn: 'مشاهده',
        change_status : 'تغییر وضعیت',
        
        wallet_short_placeholder: 'کیف پول',
        wallet_placeholder      : 'جستجو از طریق شرکت، کاربر، نام کاربری یا شماره حساب',
        price_title             : 'مبلغ ({0})',
        debtor_title            : 'بدهکار ({0})',
        
        edit_modal              : 'ویرایش زیرسند',
        create_modal            : 'افزودن زیرسند',
        verifying_document_title: 'تایید کل زیر سندها',
        verifying_document_modal: 'آیا مایل به تایید کردن کل زیر سندها هستید؟',
        
        tooltip: {
            select_group_first : 'لطفا ابتدا کد معین را انتخاب کنید.',
            has_no_segregate   : 'این کد معین کد تفصیلی ندارد.',
            not_valid_segregate: 'کد تفصیلی معتبر نیست',
            not_valid_group    : 'کد معین معتبر نیست',
        },
        
        table: {
            title: {
                row        : 'ردیف',
                description: 'شرح',
                debtor     : 'بدهکار',
                creditor   : 'بستانکار',
                group_code : 'کد گروه',
                group_label: 'گروه',
                post_code  : 'کد زیرسند',
            },
        },
    },
    
    code_selector: {
        placeholder: 'جستجوی کد یا شرح',
        code       : 'کد',
        desc       : 'شرح',
        empty      : 'جستجو نتیجه ای در بر‌نداشت.',
        search     : 'برای جستجو از فیلد بالا استفاده کنید.',
        modal_title: {
            group    : 'انتخاب کد معین',
            segregate: 'انتخاب کد تفصیلی',
        },
    },
};
