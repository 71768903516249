<template>
    <div v-if="model">
        <template v-if="value">
            <div class="status-current">
                <span class="status-current__title">
                    {{ $t('fields.currency') }}
                </span>
                <span class="status-current__status blue label">
                    {{ value.currency.title }}
                </span>
            </div>
            <div class="status-current">
                <span class="status-current__title">
                    {{ $t('wallet.balance') }}
                </span>
                <span class=" label">
                    {{ addFloutingPoint(value.balance) | digits }}
                </span>
                <span class="status-current__title">
                    {{ $t('wallet.blocked_amount') }}
                </span>
                <span class="status-current__status label">
                    {{ addFloutingPoint(value.blocked_amount) | digits }}
                </span>
            </div>
        </template>
        <y-form-field
            v-model="model.type"
            type="select"
            name="transaction_type"
            :options="transactionTypes"
        />

        <y-form-field
            v-model="model.amount"
            type="number"
            name="amount"
            :format="'separated'"
        />

        <y-form-field
            v-model="model.description"
            type="textarea"
            name="description"
        />

        <template v-if="value && value.payment">
            <y-form-field
                v-model="model.with_payment"
                type="checkbox"
                name="register_payment"
            />

            <!-- TODO: add validation -->
            <y-form-field
                v-if="model.with_payment"
                v-model="model.path_id"
                type="select"
                name="payment_path"
                :options="value.paths"
                value-field="id"
                label-field="title"
            />

            <y-form-date-picker
                v-if="model.with_payment"
                v-model="model.effected_at"
                type="datetime"
                name="date"
            />

            <y-form-field
                v-if="model.with_payment"
                v-model="model.tracking_number"
                type="text"
                name="tracking_number"
            />
        </template>
    </div>
</template>

<script>
    import YFormField from '@deps/form/FormField';
    import YFormDatePicker from '@deps/form/elements/DatePicker';

    export default {
        name: 'YFormWalletProcessing',

        components: {
            YFormField,
            YFormDatePicker,
        },

        props: {
            element: [Object, Array],
            value  : [Object, Array],
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                model: {},

                transactionTypes: [
                    {
                        label: this.$t('wallet.types.deposit'),
                        value: 'in',
                    },
                    {
                        label: this.$t('wallet.types.withdrawal'),
                        value: 'out',
                    },
                ],
            };
        },

        computed: {
            /**
             * Check whether if payment module is enabled
             */
            paymentModuleEnabled() {
                return this.value && this.value.payment;
            },
        },

        watch: {
            model: {
                /**
                 * Dispatch model changes to parent y-form
                 */
                handler() {
                    if (this.value) {
                        this.$emit('input', Object.assign(this.value, this.model));
                    }
                },
                deep: true,
            },
        },

        methods: {
            /**
             * format number
             *
             * @param v
             */
            addFloutingPoint(v) {
                return v.toString()
                    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            },
        },
    };
</script>
