/*-----------------------------------------------------------------
- Combo Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Combo  Get all data
     *
     * @param {...any} args
     * @returns {*}
     */
    dump: (...args) => Resource.get('combo-dump', args),
  
    /**
     * Combo  Options
     *
     * @param {...any} args
     * @returns {*}
     */
    options: (...args) => Resource.get('combo-options', args),

};
