/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/
export default {

    list: {
        title : 'راهنما',
        search: {
            placeholder: 'جستجو در راهنما‌...',
        },
        table: {
            title: {
                no      : '#',
                name    : 'عنوان',
                action  : 'عملیات',
                category: 'دسته‌بندی',
            },
            view      : 'مشاهده',
            noCategory: '-',
        },
    },

};
