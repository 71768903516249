/*-----------------------------------------------------------------
- ICIB General Locales
-----------------------------------------------------------------*/
export default {

    plans: {
        plural: 'Plans',
    },

    organization: {
        view_organization: 'View Organization',

        type: {
            state  : 'State',
            private: 'Private',
        },

        activity_field: {
            financial: 'Financial',
            apparel  : 'Apparel',
        },
    },
    
    invoice: {
        view_invoice : 'View Invoice',
        title        : 'Invoice',
        number       : 'Number',
        date         : 'Date',
        seller_title : 'Seller Information',
        buyer_title  : 'Buyer Information',
        product_title: 'Products Information',
        name         : 'Company',
        eco_no       : 'Economical No.',
        reg_no       : 'Register No.',
        national_no  : 'International ID',
        address_full : 'Full Address',
        address      : 'Address',
        state        : 'State',
        county       : 'County',
        city         : 'City',
        zipcode      : 'Zip Code',
        phone        : 'Phone',
        product      : {
            row_no        : 'No.',
            name          : 'Product',
            number        : 'Quantity',
            unit_price    : 'Price',
            total_fee     : 'Total',
            discount      : 'Discount',
            tax           : 'Tax',
            total_plus_tax: 'Total Plus Tax',
            total_text    : '(In Word)',
            total         : 'Total',
        },
        seller_sign: 'Seller Signature',
        buyer_sign : 'Buyer Signature',
        desc       : 'Description',
        unit       : 'Rial',
    
        edit_discount: 'Edit',
        save_discount: 'Save',
        cancel_edit  : 'Cancel',
        print        : 'Print',
    },
};
