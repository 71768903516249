<template>
    <div class="share-modal">
        <div
            v-if="link"
            id="qrcode"
        >
            <y-form-qr-code
                ref="qrCode"
                :value="link"
                tag="img"
                width="md"
                label
                @src="qrCodeSrc"
            />
        </div>

        <div class="share-modal__icons">
            <p class="title">
                {{ $t('forwarder.modal.actions_title') }}
            </p>

            <div
                v-if="link"
                class="share-modal__icons__links"
            >
                <a
                    :href="`https://www.facebook.com/sharer.php?u=${link}`"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i class="icon md-facebook" />
                </a>
                <a
                    :href="`https://twitter.com/intent/tweet?url=${link}&text=${title}`"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i class="icon md-twitter" />
                </a>
                <a
                    :href="`https://t.me/share/url?url=${link}&text=${title}`"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i class="icon md-telegram" />
                </a>
                <a @click.prevent="printCode">
                    <i class="icon md-printer" />
                </a>
                <a @click.prevent="$refs.qrCode.downloadFile()">
                    <i class="icon md-cloud-download-outline" />
                </a>
            </div>
        </div>

        <div class="share-modal__copy-link d-f justify-content-around align-items-center">
            <y-button @click.prevent.native="doCopy(link)">
                {{ $t('forwarder.grid.actions.copy') }}
            </y-button>
            <y-form-field
                v-model="link"
                type="text"
                no-label
                readonly
                class="mb0"
            />
        </div>
    </div>
</template>

<script>
    import YFormQrCode from '@deps/form/elements/QrCode';
    import YFormField from '@deps/form/FormField';

    export default {
        name: 'YFormShareQrCode',
        
        components: {
            YFormQrCode,
            YFormField,
        },

        props: {
            element: [Object, Array],
            value  : [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                src: null,
            };
        },

        computed: {
            /**
             * Link
             */
            link() {
                return this.value.link;
            },

            /**
             * Title
             */
            title() {
                return this.value.title;
            },
        },

        methods: {
            /**
             * Set src
             *
             * @param src
             */
            qrCodeSrc(src) {
                this.$set(this, 'src', src);
            },

            /**
             * Copy Link
             *
             * @param value
             */
            doCopy(value) {
                this.$copyText(value).then(() => {
                    this.$toast.success(this.$t('general.copy_value.copied'));
                }).catch(() => {
                    this.$toast.success(this.$t('general.copy_value.error'));
                });
            },

            /**
             * Print Section
             */
            printCode() {
                const body = document.getElementById('qrcode').innerHTML;
                const WinPrint = window.open('', '_blank', 'top=0,right=0,width=1600,height=794,toolbar=0,scrollbars=0,status=0,fullscreen=1');
                WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                    <title>${this.title}</title>
                  </head>
                  <body class="print print__document">
                    ${body}
                  </body>
                </html>`);
                setTimeout(() => {
                    WinPrint.focus();
                    WinPrint.print();
                }, 1000);
            },
        },
    };
</script>
