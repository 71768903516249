/*-----------------------------------------------------------------
- Comments translations
-----------------------------------------------------------------*/
export default {
    
    single: {
        title: 'View Comment',
        
        reply: {
            title        : 'Reply to Comment',
            status       : 'Comment Status',
            placeholder  : 'Write your comment here...',
            success_msg  : 'Comment sent successfully.',
            error_msg    : 'There was a problem sending the comment. Try again.',
            confirmed    : 'Confirm',
            close        : 'Close',
            change_status: {
                placeholder: 'Select the comment status',
                success_msg: 'Comment status changed successfully.',
                error_msg  : 'There was a problem changing the status of the Comment. Try again.',
            },
        },
        
        info: {
            title       : 'Comment Details',
            user_name   : 'User Name',
            user_email  : 'Email',
            user_mobile : 'Mobile',
            user_website: 'Website',
            user_ip     : 'User IP',
            related_post: 'Received on',
            related_type: 'Belongs to',
            created_at  : 'Created at',
            status      : 'Status',
            private     : 'Private',
            empty       : 'There are no details to display.',
        },
        
        files: {
            title   : 'Attached files',
            no_files: 'No files attached.',
        },
    },
    
};
