export default {
    email    : 'ایمیل:',
    mobile   : 'موبایل:',
    resume   : 'رزومه:',
    user_note: 'یادداشت',
    item     : 'تغییرات',
    note     : 'یادداشت',
    view     : 'مشاهده رزومه',
    
    user: 'مشخصات کارجو',
    
    applied_at: 'تاریخ اقدام: ',
    
    change_status: 'تغییر وضعیت',
    user_history : 'سوابق کارجو',
};
