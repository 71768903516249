/*-----------------------------------------------------------------
- Stock Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Stocks Posts Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    postsGrid: (...args) => Resource.get('stock-posts-grid', args),
    
    /**
     * Stocks Prices Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    pricesGrid: (...args) => Resource.get('stock-prices-grid', args),
    
    /**
     * Stocks Prices Save
     *
     * @param {...any} args
     * @returns {*}
     */
    pricesSave: (...args) => Resource.post('stock-prices-save', args),
    
    /**
     * Stocks Prices
     *
     * @param {...any} args
     * @returns {*}
     */
    prices: (...args) => Resource.get('stock-prices', args),

};
