<template>
    <div
        class="form-heading"
        v-html="title"
    />
</template>

<script>

    /**
     * Heading Component
     */
    export default {

        name: 'YFormHeading',

        props: {

            /**
             * Header Title
             */
            title: String,

        },

    };

</script>
