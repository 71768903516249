<template>
    <div class="form-post-album">
        <y-panel
            :title="$t('content.albums.title')"
            icon="md-image-album"
        >
            <template v-if="albums && albums.length">
                <div
                    v-for="album in albums"
                    :key="`album-${album.name}`"
                    class="post-album"
                >
                    <!-- Simple -->
                    <y-simple-album
                        v-if="isSimple(album)"
                        v-model="model[album.name]"
                        :album="album"
                    />

                    <y-detailed-album
                        v-else
                        v-model="model[album.name]"
                        :album="album"
                    />
                </div>
            </template>
            <y-empty-state
                v-else
                :message="$t('content.albums.empty')"
            />
        </y-panel>
    </div>
</template>

<script>

    import YEmptyState from '@deps/EmptyState';

    export default {

        name: 'YFormPostAlbum',

        components: {
            YSimpleAlbum  : () => import('./Simple'),
            YDetailedAlbum: () => import('./Detailed'),
            YEmptyState,
        },

        inject: {
            $validator: '$validator',
        },

        props: {
            value  : Object,
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},

                newRow: {
                    id: null,
                },
            };
        },

        computed: {

            /**
             * List of albums
             */
            albums() {
                return this.get(this.element, 'albums', []);
            },

        },

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

        },

        /**
         * @inheritDoc
         */
        created() {
            this.albums.forEach((album) => {
                if (!this.model.hasOwnProperty(album.name)) {
                    this.$set(this.model, album.name, []);
                }
            });
        },

        methods: {

            /**
             * Check if album is simple or have inputs
             *
             * @param album
             */
            isSimple(album) {
                return !album.hyperlink_input
                    && !album.description_input
                    && !album.title_input;
            },

        },

    };

</script>
