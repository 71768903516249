/*-----------------------------------------------------------------
- Buttons
-----------------------------------------------------------------*/
export default {
    
    load_all        : 'نمایش تمام کالاها',
    add_ware        : 'افزودن کالای جدید',
    back_to_product : 'بازگشت به محصول',
    inventory_change: 'ویرایش انبار',
    inventory_grid  : 'انبارداری',
    back_to_wares   : 'بازگشت به مدیریت کالا‌ها',
    clone           : 'نسخه‌برداری',
};
