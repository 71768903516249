/*-----------------------------------------------------------------
- Cart trans
-----------------------------------------------------------------*/
export default {
    
    cart: {
        cart: 'سبد خرید',
        
        order: {
            title : 'سفارش‌ها',
            create: 'ثبت سفارش جدید',
            edit  : 'ویرایش سفارش',
        },
        pre: {
            title : 'پیش‌سفارش‌ها',
            create: 'ثبت پیش‌سفارش جدید',
            edit  : 'ویرایش پیش‌سفارش',
        },
        claim: {
            title  : 'شکایت‌ها',
            create : 'ثبت شکایت جدید',
            edit   : 'ویرایش شکایت',
            process: 'پردازش شکایت',
        },

        bill: {
            title: 'صورتحساب سفارش',
        },
    },
    
};
