<template>
    <div
        :ref="`DropdownMenu-${keyHelper}`"
        class="dropdown no-caret"
        :class="classes"
    >
        <div
            class="dropdown-toggle"
            @click="toggle"
        >
            <!-- @slot Use this slot for dropdown toggle button. -->
            <slot name="toggle" />
        </div>
        <div
            class="menu"
            :class="menuStatus"
            @click.stop
        >
            <!-- @slot Use this slot for list of dropdown items. -->
            <slot />
        </div>
    </div>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';

    /**
     * The dropdown component
     * A wrapper for dropdown button and list
     */
    export default {
        name: 'Dropdown',

        props: {
            /**
             * Specifies dropdown position according to toggle button
             */
            position: {
                type   : String,
                default: 'bottom-right',
            },

            /**
             * Controls default display of dropdown
             */
            show: {
                type   : Boolean,
                default: false,
            },

            /**
             * Controls dropdown is disabled or not
             */
            disabled: {
                type   : Boolean,
                default: false,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                /**
                 * Controls dropdown display
                 */
                isOpen: this.show,

                keyHelper: generateId(),
            };
        },

        computed: {
            /**
             * Merges dropdown classes
             *
             * @returns {string}
             */
            classes() {
                const classes = [];

                classes.push(this.position);

                return classes.join(' ');
            },

            /**
             * Gets menu status class
             *
             * @returns {string}
             */
            menuStatus() {
                return this.isOpen ? 'open' : '';
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.setListenerForClosing();

            this.$on('close', () => {
                this.isOpen = false;
            });
        },

        /**
         * @inheritDoc
         */
        beforeDestroy() {
            document.removeEventListener('click', this.documentClick);
        },

        methods: {

            /**
             * Opens dropdown list and emit relevant event
             */
            open() {
                if (this.disabled) {
                    return;
                }

                if (!this.isOpen) {
                    this.isOpen = true;
                    this.$emit('open');
                }
            },

            /**
             * Closes dropdown list and emit relevant event
             */
            close() {
                if (this.disabled) {
                    return;
                }

                if (this.isOpen) {
                    this.$emit('close');
                    this.isOpen = false;
                }
            },

            /**
             * Toggles dropdown list and emit relevant event
             */
            toggle() {
                if (this.isOpen) {
                    this.close();
                } else {
                    this.open();
                }
            },

            /**
             * Sets event listener on document to close dropdown
             * list when anywhere out of dropdown is clicked
             */
            setListenerForClosing() {
                document.addEventListener('click', this.documentClick);
            },

            /**
             * @inheritDoc
             */
            documentClick(e) {
                const el = this.$refs[`DropdownMenu-${this.keyHelper}`];
                if (el !== e.target && !el.contains(e.target)) {
                    this.close();
                }
            },

        },

    };
</script>
