<template>
    <div>
        <nav id="topbar">
            <div class="right">
                <ul
                    v-if="breadcrumbs"
                    class="breadcrumb"
                >
                    <li
                        v-for="(item, index) in breadcrumbs"
                        :key="`breadcrumb-item-${index}`"
                    >
                        <span v-if="typeof item === 'string'">{{ item }}</span>
                        <router-link
                            v-else
                            :to="item.link"
                        >
                            {{ item.label }}
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="left">
                <!-- Manage dashboard -->
                <div
                    v-if="$route.name === 'dashboard'"
                    class="manage-dashboard"
                >
                    <y-button
                        v-if="manageDashboard"
                        size="xs"
                        class="color-green"
                        @click="$refs.widgetsLibraryModal.open()"
                    >
                        {{ $t('button.addWidget') }}
                    </y-button>

                    <y-button
                        size="xs"
                        :class="[{active: manageDashboard}]"
                        @click.native="toggleManageDashboard"
                    >
                        {{ $t('button.manageWidgets') }}
                    </y-button>
                </div>

                <!-- Helps -->
                <y-button
                    v-if="shouldShowHelp && help"
                    size="xs"
                    :class="{'button-pulse': !showHelp}"
                    @click="openModal"
                >
                    {{ $t('button.help') }}
                </y-button>
            </div>
        </nav>

        <!-- Widgets library -->
        <y-widgets-library-modal
            v-if="$route.name === 'dashboard'"
            ref="widgetsLibraryModal"
        />

        <!-- Help Modal -->
        <y-help-modal
            v-if="help"
            ref="helpModal"
        />
    </div>
</template>

<script>

    import YWidgetsLibraryModal from '@blocks/dashboard/WidgetsLibraryModal';
    import YHelpModal from '@deps/layout/HelpModal';

    export default {

        name: 'TopBar',

        components: {
            YWidgetsLibraryModal,
            YHelpModal,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                manageDashboard: false,
                shouldShowHelp : false,
                help           : null,
                showHelp       : false,
            };
        },

        computed: {
            /**
             * Breadcrumbs items
             *
             * @returns {*}
             */
            breadcrumbs() {
                return this.$store.getters['breadcrumbs/items'];
            },
        },

        watch: {

            /**
             * Get Help after route changed
             */
            '$route.name': function () { // eslint-disable-line func-names
                if (this.shouldShowHelp) {
                    this.getHelp();
                }
            },

            /**
             * Watch modules
             *
             * @param modules
             */
            '$store.state.layout.modules': function (modules) { // eslint-disable-line func-names
                if (modules.includes('PanelHelp')) {
                    this.shouldShowHelp = true;
                    this.getHelp();
                }
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.$store.commit('dashboard/toggleManage', false);
        },

        methods: {
            /**
             * Toggle manage dashboard
             */
            toggleManageDashboard() {
                this.manageDashboard = !this.manageDashboard;
                this.$store.commit('dashboard/toggleManage', this.manageDashboard);
            },

            /**
             * Open Help Modal
             */
            openModal() {
                this.$refs.helpModal.open(null, this.help);
            },

            /**
             * Get help of this page
             */
            getHelp() {
                const params = {
                    slug     : this.$route.name,
                    type     : 'panel-help',
                    including: ['id', 'title', 'tagging_labels', 'text'],
                };
                this.$services.Content.postSingle(params).then((response) => {
                    this.help = response.data.results;
                    this.isFirstVisit(this.help.id);
                }).catch(() => {
                    this.help = null;
                });
            },

            /**
             * Check if the user visit this site for first time.
             *
             * @param id
             */
            isFirstVisit(id) {
                const params = {
                    id,
                };
                this.$services.Panel.helpIsSeen(params).then((response) => {
                    this.showHelp = response.data.results.seen;
                }).catch(() => {
                    this.showHelp = true;
                });
            },
        },
    };
</script>
