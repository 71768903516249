/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    accountant: {
        create    : 'New group created successfully.',
        update    : 'Group updated successfully',
        save_order: 'Groups sorted successfully',
    },
    
    trashed: {
        confirm: 'Sure to move this posting to trash?',
        success: 'Moving {type} to trash was successful.',
        error  : 'A problem occurred moving {type} to trash.',
    },
    
};
