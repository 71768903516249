/* -----------------------------------------------------------------
- Arena trans
----------------------------------------------------------------- */
export default {
    title: {
        list   : 'سفارش‌ها',
        details: 'جزئیات سفارش حمل',
        create : 'ویرایش مشاوره حمل و نقل',
        edit   : 'ویرایش مشاوره حمل و نقل',
    },

    details: {
        title : 'جزئیات سفارش شماره {0}',
        button: {
            close_order: 'بستن سفارش',
            open_order : 'باز کردن سفارش',
            arrival    : 'اعلامیه ورود/تخلیه کالا',
            border     : 'اعلامیه ورود به مرز',
            edit       : 'اعلامیه حمل کالا',
        },
        order: {
            title        : 'جزئیات سفارش حمل',
            user_name    : 'نام مشتری',
            date         : 'تاریخ',
            status       : 'وضعیت',
            shipping_type: 'نوع حمل',
            ref_no       : 'شماره پرونده',
            email        : 'ایمیل',
            effected_at  : 'تاریخ',

            status_label: {
                open : 'باز',
                close: 'بسته',
            },
        },
        comment: {
            title: 'پیام',
        },
        msg: {
            comment: 'پیام با موفقیت ارسال شد.',
            status : 'وضعیت سفارش با موفقیت تغییر کرد.',
        },
    },

    node: {
        ref_no             : 'REF No.',
        truck_no           : 'Truck No.',
        container_no       : 'Container No.',
        shipper            : 'Shipper',
        shipped_at         : 'Shipped At',
        shipped_type       : 'Shipped Type',
        loading_place_title: 'Place of Loading',
        loading_place      : '{0} of loading',
        arrival_place      : '{0} of arrival / Discharge',
        packages_number    : 'No. Of Packages',
        weight             : 'Weight/Kgs',
        l_c_no             : 'L/C No.',
        b_l_no             : 'B/L No.',
        arrived_at         : 'Arrive Date',
        border_name        : 'Border Name',
        destination_custom : 'Destination Custom',

        container      : 'Container',
        truck          : 'Truck',
        place          : 'Place',
        port           : 'Port',
        price          : 'Price',
        ac_price_second: 'AC Price Second',
        ac_price       : 'AC Price',
    },

    forms: {
        date                     : 'Date',
        consignee                : 'Consignee',
        shipping_type_placeholder: 'Choose Shipping Type',
        person_placeholder       : 'جستجو بر اساس نام کاربری',
        name_first               : 'نام و نام خانوادگی، نام کمپانی',

        arrival: {
            title: 'گزارش ورود/تخلیه',
        },

        border: {
            title: 'اعلامیه ورود به مرز',
        },

        edit: {
            title: 'اعلامیه حمل کالا',
        },

        get_excel_file: 'دریافت فایل اکسل',

        fields: {
            ref_no            : 'REF No.',
            truck_no          : '{0} No.',
            container_no      : 'Container No.',
            shipper           : 'Shipper',
            loading_place     : '{0} of loading',
            arrival_place     : '{0} of arrival / Discharge',
            packages_number   : 'No. Of Packages',
            weight            : 'Weight/Kgs',
            l_c_no            : 'L/C No.',
            b_l_no            : 'B/L No.',
            arrived_at        : 'Arrive Date',
            border_name       : 'Border Name',
            destination_custom: 'Destination Custom',
            shipping_type     : 'Shipping Type',
            shipped_date      : 'Shipped Date',
            consignee_name    : 'Consignee Name',
            participants      : 'Participants',
            loaded_at         : 'Loading Date',

            container: 'Container',
            truck    : 'Truck',
            place    : 'Place',
            port     : 'Port',
        },

        footer: {
            ac_description:
                'لطفا جهت دریافت ترخیصیه از ساعت ۹ الی ۱۳ و ۱۴ الی ۱۶ و پنجشنبه‌ها از ساعت ۹ الی ۱۲، ضمن همراه داشتن معرفینامه و اصل بارنامه به این شرکت مراجعه فرمائید.',
            at_description:
                'لطفا جهت دریافت قبض انبار از ساعت ۹ الی ۱۶ و پنجشنبه‌ها از ساعت ۹ الی ۱۳ با همراه داشتن معرفی‌نامه و اصل بارنامه به این شرکت مراجعه فرمائید.',
            ac_cost:
                'هزینه صدور ترخیصیه، THC، جواز ورود موقت، اظهارنامه، جابجائی',
            at_cost: 'هزینه صدور قبض انبار طبق تعرفه مبلغ',
            rial   : 'ریال میباشد.',

            cheque: {
                on_cost    : 'چک به مبلغ',
                rial       : 'ریال',
                description:
                    'چکها با تاریخ و بدون قید بابت و ... و فقط در وجه شرکت آرنا مهر صادر گردد.',
            },
        },

        notification: {
            form     : 'فعال‌سازی اطلاع‌رسانی‌های مربوط به فرم',
            transit  : 'فعال‌سازی اطلاع‌رسانی‌های مربوط به ترانزیت',
            documents: 'فعال‌سازی اطلاع‌رسانی‌های مربوط به ارسال مدارک',
        },
    },
    shipper_option: {
        AT: 'Land transport',
        AR: 'Air transport',
        AC: 'Sea transport',
    },
    no_user      : 'کاربر مورد نظر یافت نشد.',
    excel_massage: 'فایل اکسل با موفیت ایجاد شد.',
};
