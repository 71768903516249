/*-----------------------------------------------------------------
- List modules
-----------------------------------------------------------------*/

const project = require('@configs/project.ts');

type file = {
    modules: {[k: string]: any},
    project: {[k: string]: any},
}

let envEnableModules = {};
let envDisableModules = {};
const sectionConfigs: file = {
    modules: {},
    project: {},
};

// Env Modules
if (process.env.VUE_APP_ENABLE_MODULES && process.env.VUE_APP_ENABLE_MODULES !== '') {
    envEnableModules = process.env.VUE_APP_ENABLE_MODULES.split(',').reduce((acc, item) => {
        acc[item] = true;
        return acc;
    }, {});
}
if (process.env.VUE_APP_DISABLE_MODULES && process.env.VUE_APP_DISABLE_MODULES !== '') {
    envDisableModules = process.env.VUE_APP_DISABLE_MODULES.split(',').reduce((acc, item) => {
        acc[item] = false;
        return acc;
    }, {});
}

sectionConfigs.modules = {
    ...envEnableModules,
    ...envDisableModules,
};

sectionConfigs.project = {
    ...project,
};

export default sectionConfigs;
