<script>
    export default {
        name: 'Container',

        props: {
            /**
             * Tag type to create element
             */
            tag: {
                type   : String,
                default: 'div',
            },

            /**
             * Make container fluid
             */
            fluid: Boolean,
        },

        computed: {
            /**
             * @inheritDoc
             */
            classes() {
                const classes = [];

                if (this.fluid) {
                    classes.push('container-fluid');
                } else {
                    classes.push('container');
                }

                return classes;
            },

        },

        methods: {
            /**
             * Emit click event
             *
             * @param e
             */
            callback(e) {
                this.$emit('click', e);
            },
        },

        /**
         * @inheritDoc
         */
        render(h) {
            return h(
                this.tag,
                {
                    class: this.classes,
                    on   : {
                        click: this.callback,
                    },
                },
                this.$slots.default,
            );
        },

    };
</script>
