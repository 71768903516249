/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    fluorocarbon: {
        calendar: 'مشاوره‌ها',
    },
    
};
