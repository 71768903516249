/*-----------------------------------------------------------------
- Content Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Content List of Posts
     *
     * @param {...any} args
     * @returns {*}
     */
    postList: (...args) => Resource.get('content-post-lists', args),
    
    /**
     * Content Post Single
     *
     * @param {...any} args
     * @returns {*}
     */
    postSingle: (...args) => Resource.get('content-post-single', args),

};
