/*-----------------------------------------------------------------
- Dashboard trans
-----------------------------------------------------------------*/
export default {
    transactions: {
        row            : 'Row',
        title          : 'Title',
        date           : 'Date',
        claimed_at     : 'Created At',
        confirmed_at   : 'Confirmed At',
        path           : 'Path',
        gateway        : 'Gateway',
        amount         : 'Amount',
        type           : 'Type',
        method         : 'Method',
        status         : 'Status',
        tracking_number: 'Tracking Number',
        online         : 'Online',
        offline        : 'Offline',
    },
};
