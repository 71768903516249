/*-----------------------------------------------------------------
- Endpoint Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Endpoint List
     *
     * @param {...any} args
     * @returns {*}
     */
    list: (...args) => Resource.get('endpoint-list', args),
    
    /**
     * Endpoint Refresh Token
     *
     * @param {...any} args
     * @returns {*}
     */
    refreshToken: (...args) => Resource.post('endpoint-refresh-token', args),
    
};
