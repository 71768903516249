/*-----------------------------------------------------------------
- ICIB Fields Locales
-----------------------------------------------------------------*/
export default {
    
    organization_name         : 'نام سازمان',
    organization_type         : 'نوع سازمان',
    organization_registered_no: 'شماره ثبت',
    organization_registered_at: 'تاریخ ثبت',
    economical_code           : 'کد اقتصادی',
    ceo_phone_number          : 'موبایل مدیرعامل',
    activity_field            : 'دسته فعالیت',
    organization_username     : 'نام کاربری سازمان',
    official_newspaper        : 'روزنامه رسمی',
    unique_id                 : 'شناسه‌ی ملی',
    
};
