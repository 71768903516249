<template>
    <div
        ref="intersect"
        class="intersect-target"
    >
        <slot />
    </div>
</template>

<script>

    export default {
        name: 'Intersect',

        /**
         * @inheritDoc
         */
        mounted() {
            const observer = new IntersectionObserver(this.onIntersect);
            observer.observe(this.$refs.intersect);
        },

        methods: {
            /**
             * handle  intersection observer callback
             *
             * @param intersectingElements
             */
            onIntersect(intersectingElements) {
                const [target] = intersectingElements;
                if (target.isIntersecting && target.intersectionRatio > 0) {
                    this.$emit('intersect');
                }
            },
        },

    };

</script>
