/*-----------------------------------------------------------------
- Document Locales
-----------------------------------------------------------------*/
export default {
    
    item  : 'Document',
    create: 'New Document',
    edit  : 'View/Edit Document',
    print : 'Accountant Document',
    
    print_button : 'Print Document',
    edit_button  : 'Save Document',
    create_button: 'New Document',
    
    create_empty: 'To manage postings, save the document first.',
    
    balance: {
        change_status: 'Because the document is unbalanced, the status of the document changed to a note.',
        set_status   : 'Because the document is unbalanced, it is not possible to select status {0}.',
    },
    
    status: {
        new       : 'New',
        finalized : 'Finalized',
        balanced  : 'Open, Balanced',
        unbalanced: 'Open, Unbalanced',
    },
    
    final_price: 'Effective Amount:',
    sum_all    : 'Total',
    
    details: 'Document Details',
    
    idx           : 'Document Number:',
    turning_number: 'Turning Number:',
    create_system : 'Client Module',
    create_user   : 'Creator',
    
    finalize: {
        title     : 'Finalize',
        full_title: 'Finalize and Close the Document',
        confirm   : 'This cannot be undone. Are you sure?',
        success   : 'Document finalized.',
    },
    
    info: {
        description  : 'Description',
        status       : 'Status',
        number       : 'Document Number',
        turningNumber: 'Turning Number',
        sub_number   : 'Sub Number',
        date         : 'Date',
        currency     : 'Currency',
    },
    
    posting: {
        title: 'Postings',
        item : 'Posting',
        
        empty: 'No posting yet. Create the first one!',
        
        quick_not_found: 'Not Found!',
        
        columns: {
            row              : 'Row',
            group            : 'Group',
            segregate        : 'Segregate',
            description      : 'Description',
            debtor           : 'Debtor',
            creditor         : 'Creditor',
            manual_code      : 'Manual Code',
            manual_date      : 'Manual Date',
            status           : 'Status',
            action           : 'Action',
            row_count        : 'Row Count: ',
            debtor_sum       : 'Sum of Debtor: ',
            creditor_sum     : 'Sum of Creditor: ',
            amount_difference: 'Amount Difference: ',
        },
        
        add   : 'New Posting',
        remove: 'Remove Selected Posting',
        
        status: {
            pending : 'Pending',
            verified: 'Verified',
            rejected: 'Rejected',
        },
        
        desc: {
            wallet: 'Wallet:',
            gate  : 'Gate:',
            group : 'Group:',
            date  : 'Date:',
        },
        
        quick_posting : 'Quick Create',
        quick_view    : 'View Posting',
        quick_view_btn: 'View',
        change_status : 'Change Status',
        
        wallet_short_placeholder: 'Wallet',
        wallet_placeholder      : 'Search by Organization, Username or Account Number',
        price_title             : 'Amount ({0})',
        debtor_title            : 'Debtor ({0})',
        
        edit_modal              : 'Edit Posting',
        create_modal            : 'Create Posting',
        verifying_document_title: 'Verify All Postings',
        verifying_document_modal: 'Are you sure to verify all postings at once?',
        
        tooltip: {
            select_group_first : 'Please select a group first.',
            has_no_segregate   : 'This code has no segregate.',
            not_valid_segregate: 'Not a valid segregate',
            not_valid_group    : 'Not a valid group',
        },
        
        table: {
            title: {
                row        : 'Row',
                description: 'Description',
                debtor     : 'Debtor',
                creditor   : 'Creditor',
                group_code : 'Group Code',
                group_label: 'Group',
                post_code  : 'Posting Code',
            },
        },
    },
    
    code_selector: {
        placeholder: 'Search Code or Description',
        code       : 'Code',
        desc       : 'Description',
        empty      : 'The search no results.',
        search     : 'Use the field above to search.',
        modal_title: {
            group    : 'Select Group Code',
            segregate: 'Select Segregate Code',
        },
    },
};
