/*-----------------------------------------------------------------
- Permissions trans
-----------------------------------------------------------------*/
export default {
    
    title: 'دسترسی‌های کاربری',
    
    create     : 'افزودن دسترسی جدید',
    impersonate: 'لاگین به جای ایشان',
    
    delete: {
        title  : 'حذف دسترسی کاربر',
        message: 'آیا از حذف این دسترسی اطمینان دارید؟',
    },
    
    block: {
        title  : 'مسدودسازی کاربر',
        message: 'آیا از مسدودسازی این دسترسی اطمینان دارید؟',
    },
    
    unblock: {
        title  : 'رفع مسدودی کاربر',
        message: 'آیا از رفع مسدودی این دسترسی اطمینان دارید؟',
    },
    
    alert: {
        title  : 'اخطار!',
        message: 'شما تغییراتی در دسترسی‌ها ایجاد کرده‌اید. در صورت انصراف از ذخیره، تغییرات شما حذف خواهد شد. می‌خواهید تغییرات را ذخیره کنید؟',
    },
    
    row: {
        as          : 'به عنوان',
        in          : 'در سازمان',
        has         : 'دارد.',
        status      : 'وضعیت',
        role        : 'نقش کاربری',
        organization: 'سازمان',
    },
    
};
