/*-----------------------------------------------------------------
- Seo translations
-----------------------------------------------------------------*/
export default {
    inspector_preview        : 'پیش‌نمایش',
    post_title               : 'عنوان مطلب',
    post_title_empty         : 'برای ساخت عنوان پست از تگ‌ها استفاده کنید.',
    meta_description         : 'توضیحات متا',
    meta_description_empty   : 'برای ساخت توضیحات متا از تگ‌ها استفاده کنید.',
    empty_preview            : 'تگ‌های انتخاب شده را در ویرایشگر تکمیل کنید.',
    sample_url               : 'http://yourwebsite.com',
    publish_hint             : 'بعضی از تگ‌ها بعد از انتشار پست تکمیل خواهند شد.',
    count                    : 'تعداد {0} از {1} کاراکتر پیشنهادی',
    canonical_url            : 'Canonical URL',
    canonical_url_placeholder: 'آدرس دلخواه را وارد کنید.',
    custom_text_placeholder  : 'متن دلخواه را وارد کنید.',

    parameters: {
        custom_meta_description: 'متن دلخواه',
        custom_post_title      : 'متن دلخواه',
        author_first_name      : 'نام نویسنده',
        author_last_name       : 'نام خانوادگی نویسنده',
        author_full_name       : 'نویسنده',
        published_date         : 'تاریخ انتشار',
        current_month          : 'ماه جاری',
        current_date           : 'تاریخ جاری',
        current_year           : 'سال جاری',
        current_day            : 'روز جاری',
        categories             : 'دسته‌بندی‌ها',
        site_title             : 'عنوان سایت',
        separator              : 'جداکننده',
        category               : 'دسته‌بندی',
        synopsis               : 'متن خلاصه',
        title                  : 'عنوان',
        tags                   : 'تگ‌ها',
    },
};
