/* eslint-disable no-param-reassign */
// DEPRECATED
/**
 * Fill model based on form fields names
 *
 * @param {*} form
 * @param {*} results
 * @returns {*}
 */
const checkName = (form, results) => {
    let model = {};
    
    // eslint-disable-next-line array-callback-return
    form.map((element) => {
        if (element.children && element.children.length) {
            model = { ...checkName(element.children, results), ...model };
            return;
        }
        
        if (element.name.includes('.')) {
            const names = element.name.split('.');
            
            if (names[0] in results) {
                model[names[0]] = results[names[0]] || {};
            }
        } else if (element.name in results) {
            model[element.name] = results[element.name];
        }
    });
    
    return model;
};

/**
 * Filters form results and return only ones that has form element
 *
 * @param {*} response
 * @returns {*}
 */
export const filterModel = (response) => {
    const { results } = response.data;
    const { form } = response.data.metadata;
    
    return checkName(form, results);
};

/**
 * Get model by its name
 *
 * @param {*} model
 * @param {*} fieldName
 * @returns {*}
 */
export const getModel = (model, fieldName) => {
    if (fieldName.includes('.')) {
        const names = fieldName.split('.');
        model[names[0]] = model[names[0]] || {};
        return model[names[0]][names[1]];
    }
    return model[fieldName];
};

/**
 * Update model value by its name
 *
 * @param {*} model
 * @param {*} fieldName
 * @param {*} value
 * @returns {*}
 */
export const setModel = (model, fieldName, value) => {
    if (!model) {
        model = {};
    }
    if (fieldName.includes('.')) {
        const names = fieldName.split('.');
        
        model[names[0]] = model[names[0]] || {};
        model[names[0]][names[1]] = value;
        return model;
    }
    model[fieldName] = value;
    return model;
};
