/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    divisions: {
        list: 'Administrative Divisions',
    },
    
};
