<template>
    <div class="inventory-change">
        <y-form-field
            v-model="model.ware_id"
            type="select"
            name="product_select"
            :options="wareOptions"
            search="search"
            :endpoint="wareFindEndpoint"
            label-field="full_title"
            value-field="id"
            validation="required"
            :disabled="disableWareSelect"
            @select="wareInfo($event)"
        />

        <y-form-field
            v-if="configs && configs.kinds"
            v-model="model.kind"
            type="select"
            name="kind_inventory"
            :options="configs.kinds"
            label-field="label"
            value-field="value"
            validation="required"
            :disabled="!model.ware_id"
        />

        <y-form-field
            v-if="model.kind === 3"
            v-model="model.reason_id"
            type="select"
            name="reason_inventory"
            :options="configs.reasons"
            label-field="label"
            value-field="value"
            :disabled="!model.ware_id"
            validation="required"
        />

        <!-- Independent -->
        <div
            v-if="hasKind && selectedWare && selectedWare.supply_type === 'independent'"
            class="field inventory-change__wares"
        >
            <y-form-field
                v-model="model.alter"
                type="number"
                name="alter"
            />
            <y-form-field
                v-model="model.fee"
                type="number"
                format="separated"
                :name="priceLabel"
                :placeholder="selectedPathCurrency"
            />
        </div>

        <!-- Related -->
        <div
            v-else-if="hasKind && selectedWare && selectedWare.supply_type === 'dependent'"
            class="field inventory-change__wares"
        >
            <y-row>
                <y-col>
                    <y-form-field
                        v-model="model.alter"
                        type="number"
                        name="alter"
                    />
                </y-col>
                <y-col>
                    <y-form-field
                        v-model="model.fee"
                        type="number"
                        format="separated"
                        :name="priceLabel"
                        :placeholder="selectedPathCurrency"
                    />
                </y-col>
            </y-row>
            <hr
                v-if="hasDependencyWares"
                class="gray-lightest"
            >
            <template v-if="hasDependencyWares">
                <div
                    v-for="(item, index) in selectedWare.dependent_wares_titles"
                    :key="`${item.id}`"
                    class="mb10"
                >
                    <y-form-field
                        v-model="selectedDependentWare[index]"
                        type="checkbox"
                        class="mb5"
                        name="active"
                        :label="item.title"
                        @input="createWareModel(index, item.id)"
                    />
                    <div
                        v-if="selectedDependentWare[index]"
                        class="row"
                    >
                        <div class="col-6">
                            <y-form-field
                                v-model="model.dependent_wares[index].alter"
                                type="number"
                                class="mb0"
                                name="alter"
                                size="sm"
                            />
                        </div>
                        <div class="col-6">
                            <y-form-field
                                v-model="model.dependent_wares[index].fee"
                                type="number"
                                format="separated"
                                class="mb0"
                                :name="priceLabel"
                                size="sm"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <!-- Unique -->
        <div
            v-else-if="hasKind && selectedWare && selectedWare.supply_type === 'unique independent'"
            class="field inventory-change__wares"
        >
            <y-form-field
                v-if="model.kind === 1"
                id="unique_ware"
                v-model="model.unique_wares"
                type="array"
                name="unique_wares"
                :label="$t('market.inventory_unique_ware')"
            />

            <template v-else-if="model.kind === 5 || model.kind === 3">
                <form
                    class="field"
                    @submit.prevent="fetchCode"
                >
                    <y-form-field
                        v-model="search"
                        type="text"
                        size="sm"
                        class="mb0"
                        name="wares"
                        :placeholder="$t('fields.search_code')"
                    />
                </form>

                <template v-if="searched">
                    <div
                        v-if="codes && codes.length"
                        class="field inventory-change__unique"
                    >
                        <div class="inventory-change__unique__wrapper">
                            <span class="inventory-change__unique__title">{{ $t('market.inventory_unique_code.searched') }}</span>
                            <div class="inventory-change__unique__searched">
                                <a
                                    v-for="code in codes"
                                    :key="`code-${code.value}`"
                                    href="#"
                                    class="button xs mb5"
                                    :class="{disabled: isCodeAlreadySelected(code.label)}"
                                    @click.prevent="addToSelected(code.value.toString())"
                                >
                                    {{ code.label | digits }}
                                </a>
                            </div>
                        </div>

                        <div class="inventory-change__unique__wrapper">
                            <span class="inventory-change__unique__title">{{ $t('market.inventory_unique_code.selected') }}</span>
                            <div class="inventory-change__unique__selected">
                                <template v-for="item in model.unique_wares">
                                    <a
                                        :key="`selected-code-${item}`"
                                        href="#"
                                        class="button xs mb5"
                                        @click.prevent="removeFromSelected(item)"
                                    >
                                        {{ item | digits }}
                                    </a>
                                </template>
                            </div>
                        </div>
                    </div>

                    <y-empty-state
                        v-else
                        no-image
                        class="mb0"
                        height="100px"
                        :message="$t('order.process.no_code')"
                    />
                </template>
            </template>

            <y-form-field
                v-model="model.fee"
                type="number"
                format="separated"
                :name="priceLabel"
                :placeholder="selectedPathCurrency"
                validation="required"
            />
        </div>

        <y-form-field
            v-if="configs.paths && configs.paths.length > 0"
            v-model="model.path_id"
            type="select"
            name="inventory_payment_path"
            :options="configs.paths"
            label-field="title"
            value-field="id"
            :disabled="!model.ware_id"
            validation="required"
            @select="pathSelect($event)"
        />
    </div>
</template>

<script>

    import YFormField from '@deps/form/FormField';
    import YEmptyState from '@deps/EmptyState';

    export default {
        name: 'YFormInventoryChange',

        components: {
            YFormField,
            YEmptyState,
        },

        props: {
            value  : [String, Object, Array],
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                configs : [],
                search  : null,
                codes   : [],
                searched: false,

                wareOptions     : [],
                wareFindEndpoint: {
                    name  : 'market-inventory-form-ware-find',
                    params: {
                        slug: this.$route.params.slug,
                    },
                },
                walletSearch         : '',
                selectedWare         : [],
                selectedDependentWare: null,
                selectedPathCurrency : null,

                model: {},
            };
        },

        computed: {
            /**
             * Check if there is dependency wares
             */
            hasDependencyWares() {
                return this.selectedWare.dependent_wares_titles && this.selectedWare.dependent_wares_titles.length > 0;
            },

            /**
             * Price Label
             */
            priceLabel() {
                if (this.model.kind === 1) {
                    return 'inventory_supply_price';
                }
                if (this.model.kind === 5) {
                    return 'inventory_return_price';
                }
                if (this.model.kind === 3) {
                    return 'inventory_damage_price';
                }
                return 'inventory_price';
            },

            /**
             * Check if kind is filed
             */
            hasKind() {
                let kind = false;
                if (this.model.kind && this.model.kind !== 3) {
                    kind = true;
                }
                if (this.model.kind && this.model.kind === 3 && this.model.reason_id) {
                    kind = true;
                }
                return kind;
            },

            /**
             * Check if whether ware combo select should be disabled
             */
            disableWareSelect() {
                return !!(this.element && this.element.wareCombo) || !!this.value.post_id;
            },
        },

        watch: {

            /**
             * Handle changing supply_type
             */
            'selectedWare.supply_type': {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    if (val === 'dependent') {
                        this.mapDependentWare(this.selectedWare.dependent_wares_titles);
                    }
                    if (val === 'unique independent') {
                        this.$set(this.model, 'unique_wares', []);
                    }
                },
            },

            /**
             * Fetch codes if model is unique and kind is return or damage
             */
            'model.kind': {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    if (this.model.kind === 5 || this.model.kind === 3) {
                        if (this.codes.length === 0) {
                            this.fetchCode();
                        }
                    }
                    if (this.model.kind === 1) {
                        this.model.unique_wares = [];
                    }
                },
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.fetchConfigs();
            this.set(this.model, 'dependent_wares', []);
            this.$set(this.model, 'unique_wares', []);
            this.set(this.selectedWare, 'supply_type', '');

            if (this.element && this.element.wareCombo) {
                this.setWareCombo(this.element.wareCombo);
            } else if (this.value.ware_id) {
                this.fetchWareCombo();
            }
        },

        methods: {
            /**
             * Fetch Types
             */
            fetchConfigs() {
                return this.$services.Market.inventoryFetchFormConfigs().then((response) => {
                    this.configs = response.data.results;
                });
            },

            /**
             * Ware Data
             *
             * @param ware
             */
            wareInfo(ware) {
                this.selectedWare = ware;
            },

            /**
             * Map Dependent Wares
             *
             * @param items
             */
            mapDependentWare(items) {
                Object.entries(items).forEach((key) => {
                    this.resetDependencyWare(key[0], key[1].id);
                });
            },

            /**
             * Map the array of the checked items in Dependent Wares
             *
             * @param index
             * @param id
             */
            createWareModel(index, id) {
                if (this.selectedDependentWare[index]) {
                    this.set(this.model, `dependent_wares[${index}].selected`, true);
                } else {
                    this.resetDependencyWare(index, id);
                }
            },

            /**
             * Reset Dependency Ware Object
             *
             * @param index
             * @param id
             */
            resetDependencyWare(index, id) {
                this.set(this, `selectedDependentWare[${index}]`, 0);
                this.set(this.model, `dependent_wares[${index}].id`, id);
                this.set(this.model, `dependent_wares[${index}].fee`, '');
                this.set(this.model, `dependent_wares[${index}].alter`, '');
                this.set(this.model, `dependent_wares[${index}].selected`, false);
            },

            /**
             * Fetch Unique Codes
             */
            fetchCode() {
                const params = {
                    id: this.model.ware_id,
                };
                if (this.search) {
                    params.code = this.search;
                }
                this.$services.Market.inventoryUniquesCombo(params).then((response) => {
                    this.codes = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.searched = true;
                });
            },

            /**
             * Add item to selected
             *
             * @param code
             */
            addToSelected(code) {
                this.model.unique_wares.push(code);
            },

            /**
             * Remove item from selected
             *
             * @param code
             */
            removeFromSelected(code) {
                this.$set(this.model, 'unique_wares', this.model.unique_wares.filter((el) => el !== code));
            },

            /**
             * Check if code is already selected
             *
             * @param codeLabel
             */
            isCodeAlreadySelected(codeLabel) {
                return this.model.unique_wares.find((el) => el === codeLabel);
            },

            /**
             * fetch ware combo model
             */
            fetchWareCombo() {
                const params = {
                    post_id: this.value.post_id,
                    id     : this.value.ware_id,
                };
                this.$services.Market.wareFetch(params).then((response) => {
                    const wareCombo = response.data.metadata.inventory_wares_combo;
                    this.setWareCombo(wareCombo);
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * update component's ware model and wareOptions
             *
             * @param wareCombo
             */
            setWareCombo(wareCombo) {
                if (wareCombo.id && wareCombo.full_title) {
                    this.wareOptions = [{
                        id        : wareCombo.id,
                        full_title: wareCombo.full_title,
                    }];
                    this.$set(this.model, 'ware_id', wareCombo.id);
                }
                if (wareCombo.dependent_wares_titles) {
                    this.mapDependentWare(wareCombo.dependent_wares_titles);
                }
                if (wareCombo.supply_type) {
                    this.$set(this.selectedWare, 'supply_type', wareCombo.supply_type);
                }
            },

            /**
             * Get Selected Path data
             *
             * @param {object} item - Object of the selected path
             */
            pathSelect(item) {
                this.$set(this, 'selectedPathCurrency', item.first_currency);
            },
        },
    };

</script>
