/*-----------------------------------------------------------------
- Resources
-----------------------------------------------------------------*/
import Http from './Http';
import { apiActionUrl } from './helpers';

export default {
    
    /**
     * Return get request of axios
     *
     * @param {string} endpoint
     * @param {...args} args
     * @returns {*}
     */
    get(endpoint, args) {
        const params = args[0] || {};
        const version = args[1] || undefined;
        const mock = args[2] || false;
        const config = args[3] || {};
        return Http.get(apiActionUrl(endpoint, version, mock), { params, ...config });
    },
    
    /**
     * Return post request of axios
     *
     * @param {string} endpoint
     * @param {...args} args
     * @returns {*}
     */
    post(endpoint, args) {
        const params = args[0] || {};
        const version = args[1] || undefined;
        const mock = args[2] || false;
        const config = args[3] || undefined;
        return Http.post(apiActionUrl(endpoint, version, mock), params, config);
    },
    
    /**
     * Return put request of axios
     *
     * @param {string} endpoint
     * @param {...args} args
     * @returns {*}
     */
    put(endpoint, args) {
        const params = args[0] || {};
        const version = args[1] || undefined;
        const mock = args[2] || false;
        const config = args[3] || undefined;
        return Http.put(apiActionUrl(endpoint, version, mock), params, config);
    },
    
    /**
     * Return patch request of axios
     *
     * @param {string} endpoint
     * @param {...args} args
     * @returns {*}
     */
    patch(endpoint, args) {
        const params = args[0] || {};
        const version = args[1] || undefined;
        const mock = args[2] || false;
        const config = args[3] || undefined;
        return Http.patch(apiActionUrl(endpoint, version, mock), params, config);
    },
    
    /**
     * Return delete request of axios
     *
     * @param {string} endpoint
     * @param {...args} args
     * @returns {*}
     */
    delete(endpoint, args) {
        const params = args[0] || {};
        const version = args[1] || undefined;
        const mock = args[2] || false;
        const config = args[3] || {};
        return Http.delete(apiActionUrl(endpoint, version, mock), { params, ...config });
    },
    
    /**
     * Default Axios instance to use directly
     */
    Http,
    
    /**
     * Master CRUD info
     *
     * @param {*} model
     * @param {string} version
     * @param {*} mock
     * @returns {Promise}
     */
    info(model, version, mock) {
        const args = [
            {
                model,
            },
            version,
            mock,
        ];
        return this.get('endpoint-model-info', args);
    },
    
    /**
     * Master CRUD list
     *
     * @param {*} model
     * @param {*} params
     * @param {string} version
     * @param {*} mock
     * @returns {Promise}
     */
    list(model, params, version, mock) {
        const args = [
            {
                model,
                ...params,
            },
            version,
            mock,
        ];
        return this.get('endpoint-model-list', args);
    },
    
    /**
     * Master CRUD single
     *
     * @param {*} model
     * @param {string | number} id
     * @param {*} params
     * @param {string} version
     * @param {*} mock
     * @returns {Promise}
     */
    single(model, id, params, version, mock) {
        const args = [
            {
                model,
                id,
                ...params,
            },
            version,
            mock,
        ];
        return this.get('endpoint-model-single', args);
    },
    
    /**
     * Master CRUD trash
     *
     * @param {*} model
     * @param {string | number} id
     * @param {string} version
     * @param {*} mock
     * @returns {Promise}
     */
    trash(model, id, version, mock) {
        const args = [
            {
                model,
                id,
            },
            version,
            mock,
        ];
        return this.delete('endpoint-model-trash', args);
    },
    
    /**
     * Master CRUD restore
     *
     * @param {*} model
     * @param {string | number} id
     * @param {string} version
     * @param {*} mock
     * @returns {Promise}
     */
    restore(model, id, version, mock) {
        const args = [
            {
                model,
                id,
            },
            version,
            mock,
        ];
        return this.patch('endpoint-model-restore', args);
    },
    
    /**
     * Master CRUD destroy
     *
     * @param {*} model
     * @param {string | number} id
     * @param {string} version
     * @param {*} mock
     * @returns {Promise}
     */
    destroy(model, id, version, mock) {
        const args = [
            {
                model,
                id,
            },
            version,
            mock,
        ];
        return this.delete('endpoint-model-destroy', args);
    },
    
};
