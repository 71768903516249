/*-----------------------------------------------------------------
- ICIB Fields Locales
-----------------------------------------------------------------*/
export default {
    
    organization_name         : 'Organization Name',
    organization_type         : 'Organization Type',
    organization_registered_no: 'Registration Number',
    organization_registered_at: 'Registration Date',
    economical_code           : 'Economical Code',
    ceo_phone_number          : 'CEO Phone Number',
    activity_field            : 'Activity Field',
    organization_username     : 'Organization Username',
    official_newspaper        : 'Official Newspaper',
    unique_id                 : 'National Id',
    
};
