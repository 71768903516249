<template>
    <y-panel
        :title="title"
        class="mpl"
    >
        <div>
            <multiselect
                :options="options"
                :multiple="true"
                group-values="group_data"
                group-label="group"
                :close-on-select="false"
                label="title"
                :placeholder="$t('components.multi_pick_list.placeholder')"
                :select-label="$t('components.multi_pick_list.select_label')"
                @search-change="startSearch"
                @select="addItemToModel"
                @close="resetOptions"
            >
                <span slot="noResult">
                    <div v-if="isSearching">
                        {{ $t('components.multi_pick_list.is_searching') }}
                    </div>
                    <template v-else>
                        {{ searched ? $t('general.form_select.no_result') : $t('general.form_select.no_search') }}
                    </template>
                </span>

                <span slot="noOptions">
                    {{ $t('general.form_select.no_options') }}
                </span>
            </multiselect>
        </div>

        <div
            v-if="items && items.length"
            class="mpl__tags"
        >
            <span
                v-for="item in items"
                :key="item.id"
                class="label badge"
            >
                <i
                    class="icon md-close"
                    @click="removeItem(item.id)"
                />
                {{ `${item.title}${groupTitle(item.group_title)}` }}
            </span>
        </div>
    </y-panel>
</template>

<script>
    import { Resource } from '@nodes/services';
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'YFormMultiPickList',

        components: {
            Multiselect,
        },

        props: {
            /**
             * Element of the component
             */
            element: Object,

            /**
             * Input Value
             */
            value: [Object, Array],
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                model  : [],
                items  : [],
                options: [],

                searched   : false,
                isSearching: false,
                delayTimer : null,
            };
        },

        computed: {
            /**
             * Title of the component
             */
            title() {
                return this.element && this.element.label ? this.element.label : this.$t('components.multi_pick_list.title');
            },

            /**
             * Endpoint of the component
             */
            endpoint() {
                return this.element && this.element.endpoint ? this.element.endpoint : null;
            },

            /**
             * Check if there is a loading
             */
            isLoadingActive() {
                return this.isSearching;
            },
        },

        watch: {
            /**
             * Watch model to emit the value
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            this.createModel(this.value);
        },

        methods: {
            /**
             * Create Model
             * 
             * @param {Array} items - Value of the component
             */
            async createModel(items) {
                await this.$set(this, 'items', this.value);
                const newModel = [];
                this.model = null;
                items.forEach((item) => {
                    newModel.push(item.id);
                });
                this.$set(this, 'model', newModel);
            },

            /**
             * Add item to model
             * 
             * @param {object} item - Selected item
             */
            addItemToModel(item) {
                let duplicate = false;
                Object.keys(this.model).forEach((key) => {
                    if (this.model[key] === item.id) {
                        duplicate = true;
                    }
                });
                if (!duplicate) {
                    this.items.push(item);
                    this.model.push(item.id);
                } else {
                    this.$toast.error(this.$t('components.multi_pick_list.duplicate_error'));
                }
            },

            /**
             * Remove Item from model
             * 
             * @param {string} id - Id of the deleted item
             */
            removeItem(id) {
                this.model.splice(this.model.indexOf(id), 1);
                let itemIndex = null;
                Object.keys(this.items).forEach((key) => {
                    if (this.items[key].id === id) {
                        itemIndex = key;
                    }
                });
                if (itemIndex) {
                    this.items.splice(itemIndex, 1);
                }
            },

            /**
             * Search with string
             *
             * @param q
             */
            startSearch(q) {
                clearTimeout(this.delayTimer);
                this.searched = false;
                if (!this.endpoint || !q) {
                    return;
                }
                this.delayTimer = setTimeout(() => {
                    this.doSearch(q);
                }, 1000);
            },

            /**
             * Run the search
             *
             * @param q
             */
            doSearch(q) {
                const method = (this.endpoint.method || 'get').toLowerCase();
                const version = this.endpoint.version || 1;
                const { name } = this.endpoint;

                const params = {};

                params.search = q;
                this.isSearching = true;
                Resource[method](name, [params, version]).then((response) => {
                    this.searched = true;
                    const { results } = response.data;
                    this.$set(this, 'options', results);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.isSearching = false;
                });
            },

            /**
             * Reset options
             */
            resetOptions() {
                this.$set(this, 'options', []);
            },

            /**
             * Return group title
             *
             * @param title
             */
            groupTitle(title) {
                if (title) {
                    return ` (${title})`;
                }
                return '';
            },
        },
    };
</script>
