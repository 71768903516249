<template>
    <div
        id="default-layout"
        class="l-default"
    >
        <!-- Navbar -->
        <y-navbar
            :collapsed="isSidebarCollapsed"
            @refresh="fetchLayout"
        />

        <div id="main-container">
            <!-- Notifications Panel -->
            <y-notifications-panel />

            <!-- Sidebar -->
            <y-sidebar :collapsed="isSidebarCollapsed" />


            <!-- Main content -->
            <div
                id="main-content-wrapper"
                class="pos-r"
            >
                <y-loading
                    v-show="$wait.is('loading-page')"
                    mode="2"
                />

                <!-- Topbar -->
                <y-topbar />

                <slot />
            </div>
        </div>

        <y-global-modals />
    </div>
</template>

<script>
    import { YLoading } from '@deps';
    import YNavbar from '@deps/layout/Navbar';
    import YTopbar from '@deps/layout/Topbar';
    import YSidebar from '@deps/layout/Sidebar';
    import YNotificationsPanel from '@deps/layout/NotificationsPanel';
    import YGlobalModals from '@deps/GlobalModals';

    export default {
        name: 'DefaultLayout',

        components: {
            YLoading,
            YNavbar,
            YTopbar,
            YSidebar,
            YGlobalModals,
            YNotificationsPanel,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                isSidebarCollapsed: this.$store.state.layout.sidebarCollapsed,
            };
        },

        watch: {
            /**
             * watch for sidebar collapse changes
             *
             * @param newValue
             */
            '$store.state.layout.sidebarCollapsed': function (newValue) { // eslint-disable-line func-names
                this.isSidebarCollapsed = newValue;
            },

            /**
             * Watch for route changes
             */
            '$route.name': function () { // eslint-disable-line func-names
                if (localStorage.getItem('impersonate_refresh') === 'exitImpersonate') {
                    window.location.reload();
                    localStorage.removeItem('impersonate_refresh');
                } else if (!localStorage.getItem('impersonate_refresh') && localStorage.getItem('admin_token')) {
                    localStorage.setItem('impersonate_refresh', true);
                    window.location.reload();
                }
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            const storage = JSON.parse( localStorage.getItem( this.$configs.store.key ) );
            if (storage?.auth?.isLoggedIn) {
                this.fetchLayout();
                this.fetchModules();
            } else {
                this.$router.push({ name: 'auth-login' });
            }
        },

        /**
         * @inheritDoc
         */
        mounted() {
            if (window.innerWidth < 768) {
                this.$root.$emit('toggleSidebar', true);
                this.isSidebarCollapsed = true;
            }

            this.$root.$on('toggleSidebar', () => {
                const newSidebarCollapsedValue = !this.isSidebarCollapsed;
                this.isSidebarCollapsed = newSidebarCollapsedValue;
                this.$store.dispatch('layout/toggleSidebar', newSidebarCollapsedValue);
            });

            // Refresh layout
            this.$root.$on('refreshLayout', () => {
                this.fetchLayout();
            });
        },

        methods: {
            /**
             * Fetch Panel Layout
             */
            fetchLayout() {
                this.$store.dispatch('layout/reload');
                this.$services.Panel.layout().then((response) => {
                    this.$store.dispatch('layout/update', response.data.results);
                }).catch((error) => {
                    if (this.get(error, 'response.data.errorCode') === 'endpoint-403') {
                        if (this.$route.name !== 'auth-login') {
                            this.$router.push({ name: 'auth-login' });
                        }
                    } else {
                        this.handleError(error);
                    }
                });
            },

            /**
             * Fetch list of modules
             */
            fetchModules() {
                this.$services.Panel.modules().then((response) => {
                    this.$store.dispatch('layout/setModules', response.data.results);
                });
            },
        },
    };
</script>
