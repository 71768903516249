import Vue from 'vue';
import './registerServiceWorker';
import Init from './init';
import App from './App.vue';

Vue.config.productionTip = false;

// eslint-disable-next-line vue/require-name-property
new Vue({
    ...Init,
    render: (h) => h(App),
}).$mount('#app');
