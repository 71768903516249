/*-----------------------------------------------------------------
- Seo translations
-----------------------------------------------------------------*/
export default {
    inspector_preview        : 'Preview',
    post_title               : 'Post Title',
    post_title_empty         : 'Use tags to make post title.',
    meta_description         : 'Meta Description',
    meta_description_empty   : 'Use tags to make meta descriptions.',
    empty_preview            : 'Complete the selected tags in the editor.',
    sample_url               : 'http://yourwebsite.com',
    publish_hint             : 'Some tags will be completed after the post published.',
    count                    : '{0} of {1} recommended character',
    canonical_url            : 'Canonical URL',
    canonical_url_placeholder: 'Enter your desired link.',
    custom_text_placeholder  : 'Enter your custom placeholder.',

    parameters: {
        custom_meta_description: 'Custom Text',
        custom_post_title      : 'Custom Text',
        author_first_name      : 'Author First Name',
        author_last_name       : 'Author Last Name',
        author_full_name       : 'Author',
        published_date         : 'Published Date',
        current_month          : 'Current Month',
        current_date           : 'Current Date',
        current_year           : 'Current Year',
        current_day            : 'Current Day',
        categories             : 'Categories',
        site_title             : 'Site Title',
        separator              : 'Separator',
        category               : 'Category',
        synopsis               : 'Synopsis',
        title                  : 'Title',
        tags                   : 'Tags',
    },
};
