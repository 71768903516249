<template>
    <div class="grid-modals">
        <template v-if="list && Object.keys(list).length">
            <y-modal
                v-for="(modal, id) in list"
                :key="modalRef(id)"
                :ref="modalRef(id)"
                :title="modal.title"
                :size="modal.size"
                @close="closeModal('direct')"
                @open="fetchForm(modal)"
            >
                <!-- Modal Body -->
                <template slot="body">
                    <form id="panel-grid-model-form">
                        <y-form
                            v-if="modal.body && modal.body.length"
                            :key="`grid-modal-body-${modal.id}-${keyHelper}`"
                            v-model="model"
                            :params="modal.body"
                        />

                        <y-loading
                            v-if="modal.fetch"
                            height="100px"
                            :active="!form"
                        >
                            <y-form
                                v-model="model"
                                :params="form"
                            />
                        </y-loading>
                    </form>
                </template>

                <!-- Modal Footer -->
                <template
                    v-if="modal.buttons && modal.buttons.length"
                    slot="footer"
                >
                    <y-button
                        v-for="button in modal.buttons"
                        :key="`grid-modal-button-${button.id}-${keyHelper}`"
                        :color="button.color"
                        :loading-on="`button-loading-${button.id}`"
                        @click.prevent="handleAction(button)"
                    >
                        {{ button.title }}
                    </y-button>
                </template>
            </y-modal>
        </template>
    </div>
</template>

<script>

    import { mapState } from 'vuex';
    import { generateId } from '@nodes/helpers/string';
    import FormMixin from '@/mixins/Form';
    import { YModal, YForm } from '@deps';

    export default {

        name: 'Modals',

        components: {
            YModal,
            YForm,
        },

        mixins: [FormMixin],

        inject: {
            $validator: '$validator',
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                form        : null,
                model       : null,
                params      : null,
                calledButton: null,

                keyHelper: generateId(),
            };
        },

        computed: {
            ...mapState('modals', ['list', 'active']),
        },

        watch: {
            /**
             * @param value
             */
            active(value) {
                return value ? this.openModal(value) : null;
            },
        },

        methods: {

            /**
             * Create modal ref
             *
             * @param id
             */
            modalRef(id) {
                return `grid-modal-${id}`;
            },

            /**
             * Open grid modal
             *
             * @param data
             */
            openModal(data) {
                this.$set(this, 'params', data.params);
                this.$set(this, 'model', data.params);
                this.$refs[this.modalRef(data.id)][0].open();
            },

            /**
             * Handle close modal event
             *
             * @param type
             */
            closeModal(type = null) {
                if (type !== 'direct') {
                    const modal = this.$store.getters['modals/active'];
                    if (modal) {
                        this.$refs[this.modalRef(modal.id)][0].close();
                    }
                }
                this.$set(this, 'form', null);
                this.$set(this, 'model', null);
                this.$set(this, 'params', null);
                this.$store.dispatch('modals/close');
            },

            /**
             * Handle action of button
             *
             * @param button
             */
            handleAction(button) {
                const { type } = button.action;
                if (type === 'callEndpoint' || type === 'callSubmit') {
                    this.submit(() => this.callEndpointAction(button));
                } else if (type === 'closeModal') {
                    this.closeModal();
                } else {
                    this.$root.$emit(type);
                }
            },

            /**
             * Call endpoint action
             *
             * @param button
             */
            callEndpointAction(button) {
                let { params } = button.action.options;
                const {
                    name, method, version, mock, success, error,
                } = button.action.options;

                if (button.action.type === 'callSubmit') {
                    params = { ...this.model, ...this.params };
                }

                this.$wait.start(`button-loading-${button.id}`);

                return this.$services[method](name, [params, version, mock]).then((response) => {
                    const { redirect } = response.data.metadata;
                    if (redirect) {
                        this.handleLocationRedirect(redirect);
                    }
                    if (success && success.length) {
                        success.forEach((action) => {
                            this.handleResponseAction({
                                action,
                                response,
                                type: 'success',
                                button,
                                params,
                            });
                        });
                    }
                }).catch((response) => {
                    if (error && error.length) {
                        error.forEach((action) => {
                            this.handleResponseAction({
                                action,
                                response,
                                type: 'error',
                                button,
                                params,
                            });
                        });
                    }
                }).finally(() => {
                    this.$wait.end(`button-loading-${button.id}`);
                });
            },

            /**
             * Fetch modal form if exists
             *
             * @param modal
             */
            fetchForm(modal) {
                if (modal.fetch) {
                    const {
                        name, version, mock, success, error,
                    } = modal.fetch;

                    const params = { ...modal.fetch.params, ...this.params };

                    this.$wait.start(`form-loading-${modal.id}`);

                    this.$services.get(name, [params, version, mock]).then((response) => {
                        this.form = response.data.metadata.form;
                        this.model = { ...response.data.results, ...this.model };
                        if (success && success.length) {
                            success.forEach((action) => {
                                this.handleResponseAction({
                                    action,
                                    response,
                                    type: 'success',
                                    modal,
                                });
                            });
                        }
                    }).catch((response) => {
                        if (error && error.length) {
                            error.forEach((action) => {
                                this.handleResponseAction({
                                    action,
                                    response,
                                    type: 'error',
                                    modal,
                                });
                            });
                        }
                    }).finally(() => {
                        this.$wait.end(`form-loading-${modal.id}`);
                    });
                } else {
                    this.keyHelper = generateId();
                }
            },

            /**
             * Handle actions of response
             *
             * @param args
             */
            handleResponseAction(args) {
                const { params } = args;
                const { type, area } = args.action;
                if (type === 'closeModal') {
                    this.closeModal();
                } else if (type === 'toast') {
                    return args.type === 'success'
                        ? this.$toast.success(args.action.message)
                        : this.handleError(args.response, args.action.message);
                } else if (type === 'refresh') {
                    if (Array.isArray(area)) {
                        area.forEach((item) => this.handleRefreshAction(item, params));
                    } else {
                        this.handleRefreshAction(area, params);
                    }
                /* TODO: This part could be removed, since handleLocationRedirect method, handles redirection.
                         we should hold this statement until back-end team, removes 'redirect' actions from every modals in panel.
                */
                } else if (type === 'redirect') {
                    this.$router.push(args.action.link);
                }

                return true;
            },

            /**
             * Handle refresh action
             *
             * @param area
             * @param params
             */
            handleRefreshAction(area, params) {
                const payload = { refresh: area };

                if (area === 'row') {
                    payload.refresh = params.id;
                }

                if (area === 'layout') {
                    return this.$root.$emit('refreshLayout');
                }

                return this.$root.$emit('refreshGrid', payload);
            },

            /**
             * Sets event listener on document to close dropdown
             * list when anywhere out of dropdown is clicked
             */
            setListenerForClosing() {
                const vm = this;
                document.addEventListener('click', () => {
                    vm.close();
                });
            },

            /**
             * Redirect to external locations
             * 
             * @param options
             */
            handleLocationRedirect(options) {
                const { target } = options;
                if (target === '_blank') {
                    // external links
                    const { url } = options;
                    const anchorLink = document.createElement('a');
                    anchorLink.href = url;
                    anchorLink.target = '_blank';
                    anchorLink.rel = 'noreferrer noopener';
                    anchorLink.click();
                } else {
                    // internal links
                    this.$router.push(options);
                }
            },

        },

    };
</script>
