/*-----------------------------------------------------------------
- Load and register all stores
-----------------------------------------------------------------*/
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import sharedMutations from 'vuex-shared-mutations';
import Cookies from 'js-cookie';
import path from 'path';
import deepMerge from 'deepmerge';
import storeConfig from '@configs/store';

// Default object
let all = {};
type files = {
  core: any | undefined,
  modules: any | undefined,
};
const importedFiles: files = {
    core   : null,
    modules: null,
};

// Load files
importedFiles.core = require.context('@/store', true, /\.(js|ts)$/);
importedFiles.modules = require.context('@modules', true, /\/store[\S]*\.(js|ts)$/);

// Merge services
Object.keys(importedFiles).forEach((section) => {
    const importedStores = {};
    importedFiles[section].keys().forEach((fileName) => {
        const file = path.basename(fileName).replace('.js', '').replace('.ts', '');
        importedStores[file] = importedFiles[section](fileName).default;
    });
    all = deepMerge(all, importedStores, { arrayMerge: (destinationArray, sourceArray) => sourceArray });
});

// Use Vuex
Vue.use(Vuex);

type pluginKeys = {
    key: string | null | any,
    modules: Array<string>,
    storage: Storage | null | any,
    restoreState: ((key: string, storage?: Storage | undefined) => unknown) | undefined | any,
    saveState: ((key: string, state: {}, storage?: Storage | undefined) => void | Promise<void>) | undefined | any,
}
const plugins: Array<any> = [];

// Vuex persist
if (storeConfig.use) {
    const persistConfigs: pluginKeys = {
        key         : null,
        modules     : [],
        storage     : null,
        restoreState: null,
        saveState   : null,
    };
    persistConfigs.key = storeConfig.key || process.env.VUE_APP_THEME;
    persistConfigs.modules = storeConfig.modules || Object.keys(all);
    
    if (storeConfig.storage === 'cookie') {
        persistConfigs.restoreState = (key) => Cookies.getJSON(key);
        persistConfigs.saveState = (key, state) => Cookies.set(key, state, { expires: storeConfig.expires || 7 });
    } else {
        persistConfigs.storage = window.localStorage;
    }
    
    const vuexLocal = new VuexPersistence(persistConfigs);
    
    plugins.push(vuexLocal.plugin);
}

// Shared mutations
if (storeConfig.sharedMutations) {
    plugins.push(sharedMutations({
        predicate: ['auth/login', 'auth/logout', 'layout/toggleSidebar'],
    }));
}

// Export Vuex Store
export default new Vuex.Store({
    modules: all,
    plugins,
});
