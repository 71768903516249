/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/

export default {
    
    // News
    news: {
        title        : 'News',
        subtitle     : 'Our Recent Activities',
        category     : 'Categories',
        archive      : 'Archive',
        comment_reply: 'Your Comment',
        show_comment : 'Show Comment',
    },
    
    // Pages
    pages: 'Pages',
    
    // Blog
    blog: {
        title        : 'Weblog',
        subtitle     : 'Our Recent Blog Posts',
        category     : 'Categories',
        archive      : 'Archive',
        comment_reply: 'Your Comment',
    },
    
    // Faq
    faq: {
        title: 'Frequently Asked Questions',
    },
    
    read_more: 'Read More',
    
    read_rather: 'Read More:',
    
    source: 'Source',
    
    tag: {
        1: 'Tag',
        s: 'Tags',
    },
    
    author: 'Author',
    
    subscribe_newsletter: 'Subscribe',
    
    enter_email: 'Submit your email address',
    
    social_network: {
        instagram: 'Instagram',
        aparat   : 'Aparat',
        linkedin : 'LinkedIn',
        twitter  : 'Twitter',
        facebook : 'Facebook',
        telegram : 'Telegram',
    },
    
    terms_conditions: 'Terms and Conditions',
    
    advertisement: 'Advertisement',
    about_us     : 'About Us',
    contact_us   : 'Contact Us',
    
};
