<template>
    <div>
        <template v-for="(element, index) in parameters">
            <component
                :is="themeComponentName(element.component_name)"
                v-if="isRegisteredComponent(element.component_name)"
                :key="'element-'+element.id"
                v-validate="getValidation(element.validation)"
                :element="element"
                v-bind="formatParams(element, element.component_name)"
                :data-vv-name="element.name || `${element.component_name}_${generateId()}`"
                :value="getModel(element)"
                @input="setModel($event, element.name || null, element.ignore_name)"
            />

            <y-form-field
                v-else
                :key="'element-'+index"
                v-bind="formatParams(element)"
                :name="element.name"
                :value="getModel(element)"
                @input="setModel($event, element.name)"
            />
        </template>
    </div>
</template>

<script>
    import YFormField from '@deps/form/FormField';
    import YPanel from '@deps/Panel';
    import { getModel, setModel } from '@nodes/helpers/form';
    import { toPascalCase, toCamelCase, generateId } from '@nodes/helpers/string';
    import CoreComponents from '@deps/form';

    const Components = { ...CoreComponents };

    export default {
        name: 'Form',

        components: {
            YFormField,
            YPanel,
            ...Components,
        },

        inject: {
            $validator: '$validator',
        },

        props: {
            /**
             * Form builder params
             */
            params: {
                type: [Object, Array],
            },

            value: { // eslint-disable-line vue/require-prop-types
                default: null,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model     : this.value || {},
                parameters: [],
            };
        },

        watch: {
            /**
             * Watch value set new model
             */
            value: {
                handler(val) { // eslint-disable-line require-jsdoc
                    this.$set(this, 'model', val);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.parameters = this.sortParams(this.params);
        },

        methods: {
            generateId,

            /**
             * Get model by its name
             *
             * @param {*} element
             * @returns {null|*}
             */
            getModel(element) {
                const { name } = element;
                const ignoreName = element.ignore_name;
                if (name && !ignoreName) {
                    const result = ((getModel(this.model, name) || getModel(this.model, name) === 0) ? getModel(this.model, name) : (element.value || element.default || null));
                    return result;
                }

                return (this.model || this.model === 0) ? this.model : (element.value || element.default || {});
            },

            /**
             * Update model value by its name
             *
             * @param {*} value
             * @param {string} name
             * @param {boolean} ignoreName
             */
            setModel(value, name, ignoreName = false) {
                if (name && !ignoreName) {
                    this.$set(this, 'model', setModel(this.model, name, value));
                } else {
                    Object.keys(value).forEach((key) => {
                        this.$set(this, 'model', setModel(this.model, key, value[key]));
                    });
                }
                this.emitInputEvent();
            },

            /**
             * Emit input event to parent component
             */
            emitInputEvent() {
                this.$emit('input', this.model);
            },

            /**
             * Check if component is in theme|panel
             *
             * @param name
             */
            isRegisteredComponent(name) {
                if (!name) {
                    return false;
                }

                const formFieldComponents = ['select', 'color-picker', 'radio', 'checkbox-list', 'checkbox', 'array', 'textarea', 'number', 'text'];
                if (formFieldComponents.includes(name)) {
                    return false;
                }
                return Components.hasOwnProperty(`YForm${toPascalCase(name)}`);
            },

            /**
             * Return theme component
             *
             * @param name
             */
            themeComponentName(name) {
                return `y-form-${name}`;
            },

            /**
             * Sort parameters of form
             *
             * @param params
             */
            sortParams(params) {
                if (!Array.isArray(params) || params.length < 2) {
                    return params;
                }
                const clone = this.cloneDeep(params);
                return clone.sort((a, b) => a.order - b.order);
            },

            /**
             * Compare method for sort
             *
             * @param a
             * @param b
             */
            compare(a, b) {
                let comparison = 0;
                if (a.order > b.order) {
                    comparison = 1;
                } else if (a.order < b.order) {
                    comparison = -1;
                }
                return comparison;
            },

            /**
             * Get validation
             *
             * @param values
             */
            getValidation(values) {
                if (!values) {
                    return '';
                }

                const validations = values.split('|');
                const results = validations.filter((item) => {
                    const rule = item.match(/^([^:]+)/);
                    return rule ? this.$config('validations.rules').includes(rule[1]) : false;
                });
                return results.join('|');
            },

            /**
             * Format params to camelCase
             *
             * @param params
             * @param component
             */
            formatParams(params, component = '') {
                const baseProps = Object.keys(this.get(Components, `YForm${toPascalCase(component)}.props`, {}));
                const mixins = this.get(Components, `YForm${toPascalCase(component)}.mixins`, []);
                const props = mixins.reduce((acc, mixin) => {
                    // eslint-disable-next-line no-param-reassign
                    acc = acc.concat(Object.keys(mixin.props || {}));
                    return acc;
                }, baseProps);

                return Object.entries(params).reduce((acc, [key, value]) => {
                    if (!component || props.includes(toCamelCase(key))) {
                        acc[key.replace(/_/g, '-')] = value;
                    }
                    return acc;
                }, { component });
            },
        },
    };
</script>
