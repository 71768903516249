/*-----------------------------------------------------------------
- Load all services and share it into Vue instance
-----------------------------------------------------------------*/
import path from 'path';
import deepMerge from 'deepmerge';
import Resource from './Resource';
import Http from './Http';

// Default object
let all = {};
// Load files
const importedFiles = {
    resources: require.context('@nodes/services/resources', true, /\.(js|ts)$/),
    core     : require.context('@/services', true, /\.(js|ts)$/),
    modules  : require.context('@/modules', true, /\/services[\S]*\.(js|ts)$/),
};

// Merge services
Object.keys(importedFiles).forEach((section) => {
    const importedServices = {};
    importedFiles[section].keys().forEach((fileName) => {
        let file = path.basename(fileName, '.js').replace(/(\.\/|Services)/g, '');
        file = path.basename(file, '.ts').replace(/(\.\/|Services)/g, '');
        importedServices[file] = importedFiles[section](fileName).default;
    });
    all = deepMerge(all, importedServices, { arrayMerge: (destinationArray, sourceArray) => sourceArray });
});

// Export all services to use directly
const services = {
    ...all,
    ...Resource,
};
export {
    services,
    Resource,
};

// Export plugin
export default {
    
    /**
     * Install plugin
     * 
     * @param {*} Vue
     */
    install(Vue) {
        Vue.prototype.$http = Http; // eslint-disable-line
        Vue.prototype.$services = services; // eslint-disable-line
    },
};
