/*-----------------------------------------------------------------
- Specification translations
-----------------------------------------------------------------*/
export default {
    
    title  : 'مشخصات فنی محصول',
    sidebar: 'گروه‌ها',
    
    empty: {
        group: 'گروهی وجود ندارد',
        spec : 'مشخصه‌ای وجود ندارد',
    },
    
    group: {
        create_modal: 'افزودن گروه',
        edit_modal  : 'ویرایش گروه',
        locale_title: 'عنوان {locale}',
    },
    
    spec_item: {
        create_modal: 'افزودن مشخصه',
        edit_modal  : 'ویرایش مشخصه',
        locale_title: 'عنوان {locale}',
    },
    
    types: {
        text    : 'متنی',
        textarea: 'متن چند خطی',
        boolean : 'بله/خیر',
    },
    
    delete: {
        title_group: 'حذف گروه',
        title_spec : 'حذف مشخصه',
        group      : 'آیا از حذف گروه <b>{title}</b> مطمئنید؟',
        spec       : 'آیا از حذف مشخصه <b>{title}</b> مطمئنید؟',
    },
    
    messages: {
        save: 'ذخیره با موفقیت انجام شد.',
        
        group: {
            add   : 'گروه با موفقیت اضافه شد.',
            update: 'گروه با موفقیت بروزرسانی شد.',
            delete: 'گروه با موفقیت حذف شد.',
        },
        
        spec: {
            add   : 'مشخصه با موفقیت اضافه شد.',
            update: 'مشخصه با موفقیت بروزرسانی شد.',
            delete: 'مشخصه با موفقیت حذف شد.',
        },
        
        title: 'وارد کردن عنوان‌(ها) الزامی است',
    },
    
    feature: {
        title : 'مشخصات محصول',
        select: {
            label      : 'انتخاب فرم مشخصات محصول',
            placeholder: 'انتخاب کنید',
        },
        empty_form: 'مشخصه ای ',
    },
    
    form: {
        id: 'شناسه',
    },
    
    items: 'آیتم‌ها',
    
    lang: 'زبان',
    
    
};
