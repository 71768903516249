/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/
export default {
    
    page_head: 'Form Maker',
    type     : 'form',
    
    preview: {
        button          : 'Preview',
        page_head       : 'Preview Form',
        close_preview   : 'Are you sure you want to close the preview page?',
        page_head_title : 'Form Preview',
        send_success_msg: 'Form successfully sent.',
    },

    wizard: {
        button: {
            next  : 'Next',
            back  : 'Back',
            finish: 'Send Form',
        },
    },

    setting: {
        title: 'Setting',
        form : {
            module: 'System',
        },
    },

    section: {
        empty    : 'No section has been added for this form.',
        section  : 'Section',
        component: 'Question',
        title    : 'Title',
        option   : 'Option',

        delete: {
            title     : 'Delete {0}',
            text      : 'Are you sure you want to delete this {0}?',
            has_answer: 'Note: This item has answers.',
        },
        add: {
            title: 'Add a new section',
        },
        edit: {
            title: 'Edit section title',
        },
        toolbar: {
            add_question: 'Add question',
            add_title   : 'Add title',
            add_option  : 'Add option',
        },
    },

    question: {
        empty : 'No questions have been added for this section.',
        fields: {
            label      : 'Question title',
            type       : 'Type of question',
            description: 'Description',
            required   : 'This question is required',

            date: {
                ask_type : 'Answer Type',
                time     : 'Time',
                date     : 'Date',
                date_time: 'Date and Time',
                date_type: 'Calendar Type',
                gregorian: 'Gregorian',
                jalali   : 'Jalali',
                automatic: 'Based on the site',
            },

            range: {
                min_amount: 'Minimum Amount',
                min_title : 'Minimum Title',
                max_amount: 'Maximum Amount',
                max_title : 'Maximum Title',
            },

            radio: {
                clear_selection: 'Clear Selection',
            },

            upload_rule: 'Uploader Rule',
        },
    },

    options: {
        title : 'Question options',
        empty : 'There are no options for this question.',
        fields: {
            label: 'Option title',
        },
    },

    responses: {
        title     : 'Responses',
        desc      : 'Count of Responses: {0}',
        empty_form: 'No response has been recorded for this form.',

        tabs: {
            summary   : 'Summary',
            questions : 'Questions',
            individual: 'Individual Response',
        },

        buttons: {
            delete_all             : 'Delete All Responses',
            delete_user_answer     : 'Delete User Response',
            download_excel         : 'Download Excel File',
            download_exported_excel: 'Download the Last Exported File',
            disable_form           : 'Disable the Form',
            enable_form            : 'Enable the Form',
            download_chart         : 'Download Chart Image',
        },

        questions: {
            list: 'List of Questions',
        },

        individual: {
            users_list: 'Responser List',
            user_info : {
                title : 'Responser Info',
                name  : 'Name',
                mobile: 'Mobile',
                email : 'Email',
            },
            empty_response: 'There is no response for this question',
        },

        delete: {
            form_title : 'Delete All Responses',
            form_text  : 'Are you sure of removing all the responses?',
            batch_title: 'Remove User Response',
            batch_text : 'Are you sure of removing this user\'s response?',
        },

        messages: {
            download            : 'Your request was recorded.You can download the file form inputs and outputs tool.',
            delete_response_all : 'Removing all the responses successfully completed.',
            delete_response_user: 'Removing this user\'s response successfully completed.',
            change_activity     : 'The status of the form successfully changed.',
            copy_chart          : 'The chart was successfully copied.',
        },
    },

    export: {
        form     : 'Form Name',
        component: 'Question',
        batch    : 'Responser',
    },

};
