<template>
    <div>
        <hr
            v-if="!title"
            :class="classes"
        >

        <div
            v-else
            class="divider"
            :class="classes"
            v-html="title"
        />
    </div>
</template>

<script>

    /**
     * Divider Component
     */
    export default {

        name: 'YFormDivider',

        props: {
            /**
             * Line Style: Dashed
             */
            dashed: {
                type   : Boolean,
                default: false,
            },

            /**
             * Line Style: Dotted
             */
            dotted: {
                type   : Boolean,
                default: false,
            },

            /**
             * Line Style: Inverted
             */
            inverted: {
                type   : Boolean,
                default: false,
            },

            /**
             * Hidden Divider
             */
            hidden: {
                type   : Boolean,
                default: false,
            },

            /**
             * Divider Color
             */
            color: String,

            /**
             * Divider Size
             */
            size: String,

            /**
             * Divider Title
             */
            title: String,

            /**
             * Divider with Icon Only
             */
            onlyIcon: {
                type   : Boolean,
                default: false,
            },

        },

        computed: {
            /**
             * List of divider classes
             *
             * @returns {*}
             */
            classes() {
                const classes = [];

                if (this.dashed) {
                    classes.push('dashed');
                }
                if (this.dotted) {
                    classes.push('dotted');
                }
                if (this.inverted) {
                    classes.push('inverted');
                }
                if (this.hidden) {
                    classes.push('hidden');
                }
                if (this.onlyIcon) {
                    classes.push('onlyIcon');
                }
                if (this.size) {
                    classes.push(this.size);
                }
                if (this.color) {
                    classes.push(this.color);
                }

                return classes;
            },

        },

    };

</script>
