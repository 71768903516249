/*-----------------------------------------------------------------
- FormMaker Routes
-----------------------------------------------------------------*/
const prefix = 'form-maker';
const routes = [
    {
        path     : `${prefix}/create`,
        name     : `${prefix}-create`,
        component: () => import(/* webpackChunkName: "form-maker" */ '@/modules/form-maker/views/Editor'),
    },
    {
        path     : `${prefix}/edit/:id`,
        name     : `${prefix}-edit`,
        component: () => import(/* webpackChunkName: "form-maker" */ '@/modules/form-maker/views/Editor'),
    },
    {
        path     : `${prefix}/preview/:id`,
        name     : `${prefix}-preview`,
        component: () => import(/* webpackChunkName: "form-maker" */ '@/modules/form-maker/views/Preview'),
    },
    {
        path     : `${prefix}/responses/:id`,
        name     : `${prefix}-responses`,
        component: () => import(/* webpackChunkName: "form-maker" */ '@/modules/form-maker/views/Responses'),
    },
];

export default routes;
