<template>
    <div class="form-adder">
        <template v-if="rows.length">
            <!-- Cloner Rows -->
            <div
                v-for="(field, index) in rows"
                :key="'row-'+ id + '-'+ index"
                class="cloner-row flex-wrap"
            >
                <!-- Row Remove Prompt -->
                <div
                    v-if="isPromptActive(index)"
                    class="w-100 d-f flex-wrap justify-content-end mb10"
                >
                    <y-form-alert
                        type="warning"
                        :content="$t('messages.cloner.confirm_delete')"
                    />

                    <!-- Prompt Dismiss Button -->
                    <y-button @click.prevent="cancelRemovePrompt(index)">
                        {{ $t('button.cancel') }}
                    </y-button>

                    <!-- Row Remove Button -->
                    <y-button
                        class="mx5"
                        color="orange"
                        @click.prevent="removeInput(index)"
                    >
                        {{ $t('button.confirm') }}
                    </y-button>
                </div>

                <!-- Row -->
                <div class="cloner-form-col">
                    <slot
                        :row="field"
                        :index="index"
                    >
                        {{ field }}
                    </slot>
                </div>

                <!-- Row Remove Button -->
                <div
                    v-if="!hideAction"
                    class="cloner-form-action"
                >
                    <y-button
                        v-if="!isPromptActive(index)"
                        icon="md-close"
                        color="red"
                        inverted
                        size="sm"
                        @click.prevent="onRemoveInput(index)"
                    />

                    <!-- Add Row Button -->
                    <y-button
                        v-if="index + 1 === rows.length && index + 1 < max"
                        color="green"
                        icon="md-plus mdi-plus"
                        size="sm"
                        inverted
                        @click.prevent="addInput"
                    />
                </div>
            </div>
        </template>
        <template v-else>
            <!-- Cloner Empty State -->
            <y-panel>
                <div class="row justify-content-center">
                    <div
                        class="col col-sm-12 col-md-6 ta-c"
                    >
                        <p>{{ $t('general.empty.msg') }}</p>
                        <!-- Add Row Button -->
                        <y-button
                            color="green"
                            @click.prevent="addInput"
                        >
                            {{ $t('button.add') }}
                        </y-button>
                    </div>
                </div>
            </y-panel>
        </template>
    </div>
</template>

<script>

    import ClonerWrapperMixin from '@/mixins/ClonerWrapper';

    export default {

        name: 'ClonerWrapper',

        mixins: [ClonerWrapperMixin],

    };
</script>
