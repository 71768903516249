/*-----------------------------------------------------------------
- Component classes mixin
-----------------------------------------------------------------*/
export default {
    
    name: 'ComponentClassesMixin',
    
    /**
     * @inheritDoc
     */
    data() {
        return {
            defaultThemeColor: '',
            supportFontSize  : true,
            supportColor     : false,
            colorPrefix      : 'color-',
            themeTypes       : {
                danger : 'red',
                warning: 'orange',
                success: 'green',
                info   : 'blue',
                default: 'gray',
            },
            
            sizeTypes: ['xs', 'sm', 'lg', 'xl'],
        };
    },
    
    computed: {
        
        /**
         * Return theme of paragraph
         *
         * @returns {Array}
         */
        theme() {
            const types = {
                danger : 'red',
                warning: 'orange',
                success: 'green',
                info   : 'blue',
            };
            const color = this.get(types, this.element.theme, this.defaultThemeColor);
            return this.supportColor ? color : `${this.colorPrefix}${color}`;
        },
        
        /**
         * Return classes of element
         *
         * @returns {*}
         */
        classes() {
            const classes = [];
            
            if (this.element.theme) {
                classes.push(this.convertThemeClass(this.element.theme));
            }
            if (this.element.size && this.supportFontSize) {
                classes.push(this.convertFontSizeClass(this.element.size));
            }
            
            return classes;
        },
        
    },
    
    methods: {
        
        /**
         * Convert theme class to color
         *
         * @param theme
         */
        convertThemeClass(theme) {
            if (Object.keys(this.themeTypes).indexOf(theme) > -1) {
                const color = this.themeTypes[theme];
                return this.supportColor ? color : `${this.colorPrefix}${color}`;
            }
            
            return '';
        },
        
        /**
         * Check font size class
         *
         * @param size
         */
        convertFontSizeClass(size) {
            if (this.sizeTypes.indexOf(size) > -1) {
                return `fz-${size}`;
            }
            
            return '';
        },
        
    },
    
};
