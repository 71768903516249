/*-----------------------------------------------------------------
- Person Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Persons  Login
     *
     * @param {...any} args
     * @returns {*}
     */
    login: (...args) => Resource.post('persons-login', args),
    
    /**
     * Persons Social  Login
     *
     * @param {...any} args
     * @returns {*}
     */
    socialLogin: (...args) => Resource.post('persons-social-login', args),

    /**
     * Persons Login request Otp
     *
     * @param {...any} args
     * @returns {*}
     */
    loginRequestOtp: (...args) => Resource.get('persons-login-request-Otp', args),

    /**
     * Persons Login send Otp
     *
     * @param {...any} args
     * @returns {*}
     */
    loginSendOtp: (...args) => Resource.post('persons-login-send-otp', args),

    /**
     * Persons Login with Otp
     *
     * @param {...any} args
     * @returns {*}
     */
    loginWithOtp: (...args) => Resource.post('persons-login-with-otp', args),
    
    /**
     * Persons  Login Form
     *
     * @param {...any} args
     * @returns {*}
     */
    loginForm: (...args) => Resource.get('persons-login-form', args),
    
    /**
     * Persons  Login Configs
     *
     * @param {...any} args
     * @returns {*}
     */
    loginConfigs: (...args) => Resource.get('persons-login-configs', args),
    
    /**
     * Persons   Password recovery request
     *
     * @param {...any} args
     * @returns {*}
     */
    passwordRecoveryRequest: (...args) => Resource.post('persons-password-recovery-request', args),
    
    /**
     * Persons   Password recovery verify token
     *
     * @param {...any} args
     * @returns {*}
     */
    passwordRecoveryVerifyToken: (...args) => Resource.post('persons-password-recovery-verify-token', args),
    
    /**
     * Persons   Password recovery
     *
     * @param {...any} args
     * @returns {*}
     */
    passwordRecovery: (...args) => Resource.post('persons-password-recovery', args),
    
    /**
     * Panel Login with token
     *
     * @param {...any} args
     * @returns {*}
     */
    loginSecondFactor: (...args) => Resource.put('persons-login-second-factor', args),
    
    /**
     * Panel Checking Token
     *
     * @param {...any} args
     * @returns {*}
     */
    checkToken: (...args) => Resource.get('persons-check-token', args),
    
    /**
     * Panel Logout
     *
     * @param {...any} args
     * @returns {*}
     */
    logout: (...args) => Resource.post('persons-logout', args),
    
    /**
     * Persons Address Save
     *
     * @param {...any} args
     * @returns {*}
     */
    addressSave: (...args) => Resource.post('persons-address-save', args),
    
    /**
     * Persons Address List
     *
     * @param {...any} args
     * @returns {*}
     */
    addressList: (...args) => Resource.get('persons-address-list', args),
    
    /**
     * Persons User Register Form
     *
     * @param {...any} args
     * @returns {*}
     */
    userRegisterForm: (...args) => Resource.get('persons-user-register-form', args),
    
    /**
     * Persons  Register
     *
     * @param {...any} args
     * @returns {*}
     */
    register: (...args) => Resource.post('persons-user-register', args),
    
    /**
     * Persons User edit profile fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    userEditProfileFetch: (...args) => Resource.get('persons-user-edit-profile-fetch', args),
    
    /**
     * Persons User edit profile save
     *
     * @param {...any} args
     * @returns {*}
     */
    userEditProfileSave: (...args) => Resource.post('persons-user-edit-profile-save', args),
    
    /**
     * Persons  Change Password Form
     *
     * @param {...any} args
     * @returns {*}
     */
    changePasswordForm: (...args) => Resource.get('persons-change-password-form', args),
    
    /**
     * Persons  Change Password
     *
     * @param {...any} args
     * @returns {*}
     */
    changePassword: (...args) => Resource.post('persons-change-password', args),
    
    /**
     * Persons  Address Mass Delete
     *
     * @param {...any} args
     * @returns {*}
     */
    addressMassDelete: (...args) => Resource.delete('persons-address-mass-delete', args),
    
    /**
     * Persons  Make the Address Default
     *
     * @param {...any} args
     * @returns {*}
     */
    addressMarkDefault: (...args) => Resource.post('persons-address-mark-default', args),
    
    /**
     * Persons  Make the Address Default
     *
     * @param {...any} args
     * @returns {*}
     */
    passwordRecoveryConfig: (...args) => Resource.get('persons-password-recovery-config', args),
    
};
