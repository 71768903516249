/*-----------------------------------------------------------------
- Market trans
-----------------------------------------------------------------*/
export default {
    
    settings    : 'Sale Rule for {0}',
    settings_msg: 'Sale Rules',
    
    product_tagging: {
        title       : 'Select Taggings',
        empty_option: 'Feature not found!',
        empty_levels: 'Category not found!',
    },
    
    product: {
        title: 'Product',
        
        create         : 'New product',
        createWithTitle: 'New product: {0}',
        edit           : 'Edit product: {0}',
        
        availability_status     : 'Availability Status',
        wares_count             : 'Product Items Count',
        ware_available_count    : 'Available Product Items Count',
        ware_not_available_count: 'Not Available Product Items count',
        
        availability_true : 'Available',
        availability_false: 'Unavailable',

        salable  : 'Ready for sale',
        unsalable: 'Unsalable',
        
        is_available_toast: 'Availability of the product: {0} changed successfully.',
    },
    
    wares: {
        manage      : 'Manage Product Items',
        subtitle    : 'Product: {0}',
        empty       : 'No Product Items found. Create the first one.',
        create      : 'New Product Item',
        edit        : 'Edit Product Item: {0}',
        edit_title  : 'Edit Product Item',
        create_modal: 'Add New Product Item',
        edit_modal  : 'Edit Product Item',
        type        : 'Product Item',
        sort        : 'Sort saved successfully.',
        change_price: 'Edit Price',
        
        current_inventory      : 'Stock:',
        sale_count             : 'Sales count:',
        purchase_price_average : 'Average Purchase Price:',
        original_price_average : 'Average Original Price',
        original_price         : 'Original Price',
        original_price_with_tax: 'Original Price With Tax',
        sale_price             : 'Sale Price',
        sale_price_with_tax    : 'Sale Price With Tax',
        sale_price_expires_at  : 'Expires Date',
        supply_type            : 'Supply Type',
        unit                   : 'Supply Unit',
        currency               : 'Currency',
        
        unique_wares: {
            label: 'Unique code',
            alert: 'To record the specifications of unique goods, first, specify inventory.',
        },
        
        cloner: {
            ware : 'Choose product item.',
            count: 'Count',
            fee  : 'Price',
        },

        message: {
            trash  : 'The product item successfully trashed.',
            restore: 'The product item successfully restored.',
        },
    },
    
    units: {
        gr: 'g',
        cm: 'cm',
    },
    
    inventory_type: {
        standalone: 'Standalone',
        related   : 'Dependent',
        unique    : 'Unique',
    },
    
    inventory_unique_ware: 'Unique product item Code',
    inventory_unique_code: {
        searched: 'Available Code',
        selected: 'Selected Codes',
    },
    
    seller_info_button: {
        text  : 'Complete Seller Info',
        button: 'Seller Info',
    },
    
};
