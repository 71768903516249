/*-----------------------------------------------------------------
- General trans
-----------------------------------------------------------------*/
export default {
    
    changeStatus: {
        full: 'Status changed from {0} to {1}.',
        new : 'Status changed to {0}',
    },
    
};
