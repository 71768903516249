/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/
export default {
    
    page_head: 'فرم‌ساز',
    type     : 'فرم',

    preview: {
        button          : 'پیش‌نمایش',
        page_head       : 'پیش‌نمایش فرم',
        close_preview   : 'آیا از بستن صفحه پیش‌نمایش مطمئن هستید؟',
        page_head_title : 'پیش‌نمایش فرم',
        send_success_msg: 'فرم با موفقیت ارسال شد.',
    },

    wizard: {
        button: {
            next  : 'مرحله بعدی',
            back  : 'مرحله قبلی',
            finish: 'ارسال فرم',
        },
    },

    setting: {
        title: 'تنظیمات',
        form : {
            module: 'سیستم',
        },
    },

    section: {
        empty    : 'هیچ بخشی برای این فرم ثبت نشده است.',
        section  : 'بخش',
        component: 'سوال',
        title    : 'عنوان',
        option   : 'گزینه',
        
        delete: {
            title     : 'حذف {0}',
            text      : 'آیا از حذف این {0} مطمئن هستید؟',
            has_answer: 'این بخش دارای پاسخ است.',
        },
        add: {
            title: 'اضافه کردن بخش جدید',
        },
        edit: {
            title: 'ویرایش عنوان بخش',
        },
        toolbar: {
            add_question: 'اضافه کردن سوال جدید',
            add_title   : 'اضافه کردن عنوان',
            add_option  : 'اضافه کردن گزینه جدید',
        },
    },

    question: {
        empty : 'هیچ سوالی برای این بخش ثبت نشده است.',
        fields: {
            label      : 'عنوان سوال',
            type       : 'نوع سوال',
            description: 'توضیح',
            required   : 'این سوال اجباری باشد',
            
            date: {
                ask_type : 'نوع پاسخ دریافتی',
                time     : 'ساعت',
                date     : 'تاریخ',
                date_time: 'تاریخ و ساعت',
                date_type: 'نوع تقویم',
                gregorian: 'تاریخ میلادی',
                jalali   : 'تاریخ شمسی',
                automatic: 'بر اساس زبان سایت',
            },

            range: {
                min_amount: 'مقدار حداقل',
                min_title : 'عنوان مقدار حداقل',
                max_amount: 'مقدار حداکثر',
                max_title : 'عنوان مقدار حداکثر',
            },

            radio: {
                clear_selection: 'خالی کردن انتخاب',
            },

            upload_rule: 'قانون آپلود',
        },
    },

    options: {
        title : 'گزینه‌های سوال',
        empty : 'هیچ گزینه‌ای برای این سوال ثبت نشده است.',
        fields: {
            label: 'عنوان گزینه',
        },
    },

    responses: {
        title     : 'جواب‌ها',
        desc      : 'تعداد جواب‌ها: {0}',
        empty_form: 'هیچ پاسخی برای این فرم ثبت نشده است.',

        tabs: {
            summary   : 'خلاصه',
            questions : 'سوال‌ها',
            individual: 'پاسخ فردی',
        },

        buttons: {
            delete_all             : 'حذف تمامی پاسخ‌ها',
            delete_user_answer     : 'حذف پاسخ کاربر',
            download_excel         : 'دانلود فایل اکسل',
            download_exported_excel: 'دانلود آخرین فایل ایجاد شده',
            disable_form           : 'غیرفعال کردن فرم',
            enable_form            : 'فعال کردن فرم',
            download_chart         : 'دانلود عکس نمودار',
        },

        questions: {
            list: 'لیست سوالات',
        },

        individual: {
            users_list: 'لیست پاسخ‌دهندگان',
            user_info : {
                title : 'اطلاعات پاسخ‌دهنده',
                name  : 'نام',
                mobile: 'موبایل',
                email : 'ایمیل',
            },
            empty_response: 'هیچ پاسخی برای این سوال ثبت نشده است.',
        },

        delete: {
            form_title : 'حذف تمام پاسخ‌های فرم',
            form_text  : 'آیا از حذف تمام پاسخ‌ها مطمئن هستید؟',
            batch_title: 'حذف پاسخ این کاربر',
            batch_text : 'آیا از حذف پاسخ این کاربر مطمئن هستید؟',
        },

        messages: {
            download            : 'درخواست شما ثبت شد. می‌توانید از بخش ورودی‌ها و خروجی‌ها فایل را دانلود کنید.',
            delete_response_all : 'حذف تمام پاسخ‌ها با موفقیت انجام شد.',
            delete_response_user: 'حذف پاسخ این کاربر با موفقیت انجام شد.',
            change_activity     : 'وضعیت فرم با موفقیت تغییر کرد.',
            copy_chart          : 'نمودار با موفقیت کپی شد.',
        },
    },

    export: {
        form     : 'نام فرم',
        component: 'سوال',
        batch    : 'پاسخ‌دهنده',
    },

};
