<template>
    <div>
        <y-form-field
            v-model="model.slug"
            type="text"
            name="slug"
            :label="$t('fields.content_type.slug')"
            validation="required"
        />

        <y-form-titles
            v-model="model.titles"
            :locales="locales"
            :value="model.titles"
            :label="$t('fields.content_type.title')"
        />

        <y-form-field
            v-model="model.order"
            type="number"
            name="order"
            :label="$t('fields.content_type.order')"
        />

        <y-form-field
            v-model="model.template"
            :label="$t('fields.content_type.template')"
            type="select"
            name="template"
            :options="templates"
            @select="selectTemplate"
        />

        <y-tree-select
            v-if="features && features.length"
            ref="treeSelect"
            v-model="model.features"
            :disabled="!model.template"
            :options="sortedFeatures"
            :label="$t('fields.content_type.feature')"
            multiple
            name="feature"
            option-component="YFeatureOptionSlot"
            show-count
            value-consists-of="LEAF_PRIORITY"
            sort-value-by="INDEX"
            :placeholder="$t('fields.choose')"
            :normalizer="normalizer"
        />

        <y-form-field
            v-model="model.icon"
            type="number"
            name="icon"
            :label="$t('fields.content_type.icon')"
        />
    </div>
</template>

<script>
    import Vue from 'vue';
    import { YTreeSelect } from '@groot-components/tree-select';
    import YFormField from '@deps/form/FormField';
    import YFormTitles from '@deps/form/elements/Titles';
    import YFeatureOptionSlot from './FeatureOptionSlot.vue';

    Vue.component('YFeatureOptionSlot', YFeatureOptionSlot);

    export default {
        name: 'YFormContentTypeEditor',

        components: {
            YTreeSelect,
            YFormField,
            YFormTitles,
        },

        props: {
            value  : Object,
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {
                    titles: {},
                },

                sortedFeatures: null,
            };
        },

        computed: {
            /**
             * Return the locales array
             */
            locales() {
                return this.element && this.element.locales ? this.element.locales : [];
            },

            /**
             * Return the templates array
             */
            templates() {
                return this.element && this.element.templates ? this.element.templates : [];
            },

            /**
             * Return the features
             */
            features() {
                return this.element && this.element.features ? this.element.features : [];
            },
        },

        watch: {

            value: {
                /**
                 * Watch value set new model
                 *
                 * @param val
                 */
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },

            model: {
                /**
                 * Watch model to emit changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            if (this.value) {
                this.$set(this, 'model', this.value);
            }
            this.sortFeatures(this.features);
        },

        methods: {
            /**
             * Get default features of the template
             *
             * @param template
             */
            selectTemplate(template) {
                if (!this.model.features) {
                    this.$set(this.model, 'features', []);
                }
                this.model.features.push(this.template_features[template.value]);
            },

            /**
             * Sort the features
             *
             * @param features
             */
            sortFeatures(features) {
                Object.keys(features).forEach((key) => {
                    const sortedFeatures = features[key].children.sort((a, b) => ((a.order > b.order) ? 1 : -1));
                    this.$set(features[key], 'sorted', sortedFeatures);
                });
                this.$set(this, 'sortedFeatures', features);
            },
            
            /**
             * Normalize the Tree-Select items
             *
             * @param node
             */
            normalizer(node) {
                return {
                    id        : node.slug,
                    label     : node.label,
                    children  : node.sorted,
                    isDisabled: this.template ? this.defaultFeatures(this.template, node.slug) : false,
                };
            },

            /**
             * Return the defualt item of the selected template
             *
             * @param template
             * @param slug
             */
            defaultFeatures(template, slug) {
                return this.template_features[template].includes(slug);
            },

        },
    };
</script>
