/*-----------------------------------------------------------------
- Coupons Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Coupons Rulebook Save
     *
     * @param {...any} args
     * @returns {*}
     */
    rulebookSave: (...args) => Resource.post('coupons-rulebook-save', args),
    
    /**
     * Coupons Rulebook Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    rulebookFetch: (...args) => Resource.get('coupons-rulebook-fetch', args),
    
    /**
     * Coupons Code Generate
     *
     * @param {...any} args
     * @returns {*}
     */
    codeGenerate: (...args) => Resource.get('coupons-code-generate', args),

    /**
     * Coupons Rule Fetch
     *
     * @param {...any} args
     */
    ruleFetch: (...args) => Resource.get('coupons-rule-fetch', args),

    /**
     * Coupons Rule Save
     *
     * @param {...any} args
     */
    ruleSave: (...args) => Resource.post('coupons-rule-save', args),

    /**
     * Coupon Rule Remove
     *
     * @param {...any} args
     */
    ruleRemove: (...args) => Resource.delete('coupons-rule-remove', args),

    /**
     * Coupon Code Tracking
     *
     * @param {...any} args
     */
    codeTracking: (...args) => Resource.get('coupons-code-tracking', args),
};
