<template>
    <div
        class="loading-wrapper"
        :style="{ minHeight: minHeight }"
        :class="{ 'is-hide': (!active && hideInactive) }"
    >
        <slot v-if="!active" />

        <transition name="fade">
            <div
                class="loading-inner"
                :style="{ height: height }"
                :class="{ active: status }"
            >
                <div
                    :id="`loading-${key_helper}`"
                    class="page-loading"
                    :class="[`mode-${loadingMode}`, { 'fullscreen': fullscreen } ]"
                    :style="{ background: bg }"
                >
                    <div
                        class="spinner-wrapper"
                        :class="{ 'has-title': title }"
                    >
                        <template v-if="img">
                            <img
                                v-if="img && storage === 'core'"
                                class="d-i"
                                :src="require(`@/assets/images/${img}`)"
                                :width="imageWidth"
                                :height="imageHeight"
                            >

                            <img
                                v-else-if="img && storage !== 'core'"
                                :src="require(`@/assets/images/${img}`)"
                                :width="imageWidth"
                                :height="imageHeight"
                            >
                        </template>

                        <template v-else-if="contentImg">
                            <img
                                v-if="contentImg"
                                class="d-i"
                                :src="contentImg"
                                :width="imageWidth"
                                :height="imageHeight"
                            >
                        </template>

                        <template v-else>
                            <div
                                class="spinner"
                                :style="{ backgroundColor: color }"
                            >
                                <div class="spinner-wrapper">
                                    <div
                                        class="spinner"
                                        v-html="spinnerEl"
                                    />
                                </div>
                            </div>
                        </template>

                        <p
                            v-if="title"
                            class="mt10 mb0"
                            :style="{ color: titleColor }"
                        >
                            {{ title }}
                        </p>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';

    // TODO: Remove loading directive after refactor is completed.
    export default {

        name: 'Loading',

        inject: {
            $validator: '$validator',
        },

        props: {

            /**
             * Active status
             */
            active: Boolean,

            /**
             * Wait slug to use
             */
            for: String,

            /**
             * Transition name
             */
            transition: {
                type   : String,
                default: 'fade',
            },

            /**
             * Transition mode
             */
            mode: [Number, String],

            /**
             * Transition duration
             */
            duration: {
                type: {
                    type   : [Number, String],
                    default: 1000,
                },
            },

            /**
             * Transition enter-active-class
             */
            enterActiveClass: String,

            /**
             * Fullscreen option
             */
            fullscreen: Boolean,

            /**
             * Loading type
             */
            type: {
                type   : [Number, String],
                default: 1,
            },

            /**
             * Background
             */
            bg: String,

            /**
             * Color
             */
            color: String,

            /**
             * Height of loading
             */
            height: [Number, String],

            /**
             * Title of loading
             */
            title: [Number, String],

            /**
             * Title Class for Styling
             */
            titleClass: String,

            /**
             * Title Class for Styling
             */
            titleColor: String,

            /**
             * Image for loading
             */
            img: String,

            /**
             * Get image for loading from panel
             */
            contentImg: String,

            /**
             * Loading Image Width
             */
            imageWidth: {
                type   : String,
                default: '55px',
            },

            /**
             * Loading Image Height
             */
            imageHeight: {
                type   : String,
                default: '55px',
            },

            /**
             * The Folder that image is stored
             */
            storage: {
                type   : String,
                default: 'core',
            },

            /**
             * Hide Loading when it is deactive
             */
            hideInactive: Boolean,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                key_helper: generateId(),
            };
        },

        computed: {
            /**
             * Active status
             */
            status() {
                return this.for ? this.for : this.active;
            },

            /**
             * Loading mode
             */
            loadingMode() {
                return this.mode || this.$config('theme.loadingMode') || 1;
            },

            /**
             * Wrapper min height
             */
            minHeight() {
                return this.active ? this.height : 0;
            },

            /**
             * Return the path of the image
             */
            imagePath() {
                return this.storage === 'core' ? '' : '/';
            },

            /**
             * Create spinner elements
             */
            spinnerEl() {
                const mode = this.loadingMode;
                const modes = {
                    2 : 2,
                    3 : 5,
                    4 : 2,
                    6 : 2,
                    7 : 3,
                    8 : 12,
                    9 : 9,
                    10: 12,
                    11: 4,
                };
                let content = '';
                const count = modes[mode];
                if (modes.hasOwnProperty(mode)) {
                    for (let i = 1; i < count + 1; i += 1) {
                        content += `<div class="el el${i}" style="background-color: ${this.color}"></div>`;
                    }
                }
                return content;
            },
        },

    };
</script>
