<template>
    <y-loading
        :active="!model"
        height="200px"
    >
        <div
            v-if="model"
            class="post-quick-view"
        >
            <!-- Title -->
            <div class="field d-f align-items-center post-quick-view__header">
                <div class="post-quick-view__image">
                    <img
                        v-if="model.images && model.images.length && (model.images[0].src || model.images[0].thumbnail)"
                        :src="model.images[0].thumbnail ? model.images[0].thumbnail : model.images[0].src"
                        :alt="model.title"
                    >

                    <i
                        v-else
                        class="icon md-file-document-box-outline"
                    />
                </div>
                <div>
                    <div class="form-title mb0">
                        <router-link :to="generateButtonRoute()">
                            {{ model.title }}
                        </router-link>
                    </div>
                    <div
                        v-if="model.categories && model.categories.length"
                        class="color-gray mt10"
                    >
                        <span
                            v-for="tag in model.categories"
                            :key="tag.id"
                            class="label"
                        >
                            {{ tag }}
                        </span>
                    </div>
                </div>
            </div>

            <!-- Synopsis -->
            <div
                v-if="model.synopsis"
                class="detail-item"
            >
                <label class="title">{{ $t('fields.synopsis') }}</label>
                <span
                    class="value"
                    v-html="model.synopsis"
                />
            </div>

            <!-- Created -->
            <div
                v-if="model.created_at"
                class="row"
            >
                <div
                    v-if="model.created_at"
                    class="col"
                >
                    <div class="field detail-item">
                        <label class="title">{{ $t('fields.created_at') }}</label>
                        <span class="value ltr d-ib">{{ model.created_at | date('YYYY/M/D - HH:mm', $i18n.locale) }}</span>
                    </div>
                </div>
                <div
                    v-if="model.created_name"
                    class="col"
                >
                    <div class="field detail-item">
                        <label class="title">{{ $t('fields.created_by') }}</label>
                        <span class="value">{{ model.created_name }}</span>
                    </div>
                </div>
            </div>

            <!-- Published -->
            <div
                v-if="model.published_at && !model.is_draft"
                class="row"
            >
                <div
                    v-if="model.published_at"
                    class="col"
                >
                    <div class="field detail-item">
                        <label class="title">{{ $t('fields.published_at') }}</label>
                        <span class="value ltr d-ib">{{ model.published_at | date('YYYY/M/D - HH:mm', $i18n.locale) }}</span>
                    </div>
                </div>
                <div
                    v-if="model.published_name"
                    class="col"
                >
                    <div class="field detail-item">
                        <label class="title">{{ $t('fields.published_by') }}</label>
                        <span class="value">{{ model.published_name }}</span>
                    </div>
                </div>
            </div>

            <!-- Updated -->
            <div
                v-if="model.updated_at && model.updated_name"
                class="row"
            >
                <div
                    v-if="model.updated_at"
                    class="col"
                >
                    <div class="field detail-item">
                        <label class="title">{{ $t('fields.updated_at') }}</label>
                        <span class="value ltr d-ib">{{ model.updated_at | date('YYYY/M/D - HH:mm', $i18n.locale) }}</span>
                    </div>
                </div>
                <div
                    v-if="model.updated_name"
                    class="col"
                >
                    <div class="field detail-item">
                        <label class="title">{{ $t('fields.updated_by') }}</label>
                        <span class="value">{{ model.updated_name }}</span>
                    </div>
                </div>
            </div>
        </div>
    </y-loading>
</template>

<script>
    export default {
        name: 'YFormPostQuickView',

        props: {
            value  : Object,
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: null,
            };
        },

        /**
         * @inheritDoc
         */
        created() {
            this.fetch();
        },

        /**
         * @inheritDoc
         */
        beforeDestroy() {
            this.model = null;
        },

        methods: {

            /**
             * @inheritDoc
             */
            fetch() {
                const params = {
                    id: this.value.id,
                };
                this.$services.Content.postQuickView(params).then((response) => {
                    this.model = response.data.results;
                });
            },

            /**
             * Make date element
             *
             * @param value
             */
            makeDateElement(value) {
                return {
                    prefix : '',
                    postfix: '',
                    value,
                };
            },

            /**
             * Generate Route
             */
            generateButtonRoute() {
                return {
                    name  : 'content-post-edit',
                    params: {
                        id: this.value.id,
                    },
                };
            },

        },
    };
</script>
