/*-----------------------------------------------------------------
- Tagging Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Tagging  list of labels
     *
     * @param {...any} args
     * @returns {*}
     */
    labels: (...args) => Resource.get('tagging-labels', args),
    
};
