/*-----------------------------------------------------------------
- Menus's vuex module
-----------------------------------------------------------------*/
/* eslint no-shadow: 0, no-param-reassign: 0, consistent-return: 0, no-confusing-arrow: 0 */
import { get } from 'lodash/object';
import i18n from '@nodes/locales';

const state = {
    all: [],
};

const getters = {
    state   : (state: any) => state,
    all     : (state: any) => state.all,
    isLoaded: (state: any) => state.all.length,
    get     : (state: any) => (slug: string) => {
        const filtered = slug.replace('$', i18n.locale);
        return get(state.all, filtered, null);
    },
};

const mutations = {
    
    /**
     * Set menus data
     *
     * @param {*} state
     * @param {Array} menus
     */
    set(state: any, menus: Array<any>) {
        state.all = menus;
    },
    
};

const actions = {
    /**
     * Set menus data
     *
     * @param {any} commit
     * @param {Array} menus
     */
    set({ commit }: any, menus: Array<any>) {
        commit('set', menus);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
