/*-----------------------------------------------------------------
- Market Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Market  Units grid
     *
     * @param {...any} args
     * @returns {*}
     */
    unitsGrid: (...args) => Resource.get('market-units-grid', args),
    
    /**
     * Market List of Products
     *
     * @param {...any} args
     * @returns {*}
     */
    productList: (...args) => Resource.get('market-product-list', args),
    
    /**
     * Market Product Destroy
     *
     * @param {...any} args
     * @returns {*}
     */
    productDestroy: (...args) => Resource.delete('market-product-destroy', args),
    
    /**
     * Market Post Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    postFetch: (...args) => Resource.get('market-post-fetch', args),
    
    /**
     * Market Post Save
     *
     * @param {...any} args
     * @returns {*}
     */
    postSave: (...args) => Resource.post('market-post-save', args),
    
    /**
     * Market Product Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    productGrid: (...args) => Resource.get('market-product-grid', args),
    
    /**
     * Market Product wares
     *
     * @param {...any} args
     * @returns {*}
     */
    productWares: (...args) => Resource.get('market-product-wares', args),
    
    /**
     * Market Ware save
     *
     * @param {...any} args
     * @returns {*}
     */
    wareSave: (...args) => Resource.post('market-ware-save', args),
    
    /**
     * Market Ware fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    wareFetch: (...args) => Resource.get('market-ware-fetch', args),
    
    /**
     * Market Wares save order
     *
     * @param {...any} args
     * @returns {*}
     */
    waresSaveOrder: (...args) => Resource.post('market-wares-save-order', args),
    
    /**
     * Market Ware availability
     *
     * @param {...any} args
     * @returns {*}
     */
    wareAvailability: (...args) => Resource.post('market-ware-availability', args),
    
    /**
     * Market Product availability
     *
     * @param {...any} args
     * @returns {*}
     */
    productAvailability: (...args) => Resource.post('market-product-availability', args),
    
    /**
     * Market Ware Form Configs
     *
     * @param {...any} args
     * @returns {*}
     */
    wareFormConfigs: (...args) => Resource.get('market-ware-form-configs', args),
    
    /**
     * Market Ware Form Configs
     *
     * @param {...any} args
     * @returns {*}
     */
    dependableWares: (...args) => Resource.get('market-dependable-wares', args),
    
    /**
     * Market Inventory Fetch Form Configs
     *
     * @param {...any} args
     * @returns {*}
     */
    inventoryFetchFormConfigs: (...args) => Resource.get('market-inventory-fetch-form-configs', args),
    
    /**
     * Market Inventory Fetch Form Configs
     *
     * @param {...any} args
     * @returns {*}
     */
    inventoryFormWareFind: (...args) => Resource.get('market-inventory-form-ware-find', args),
    
    /**
     * Market Inventory Fetch Form Configs
     *
     * @param {...any} args
     * @returns {*}
     */
    inventorySave: (...args) => Resource.post('market-inventory-save', args),
    
    /**
     * Accountant Wallets Blocked Amounts Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    wareEditorWalletsCombo: (...args) => Resource.get('market-ware-editor-wallets-combo', args),
    
    /**
     * Accountant Wallets Blocked Amounts Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    wareCurrencies: (...args) => Resource.get('market-ware-currencies', args),
    
    /**
     * Market  Inventory Uniques Combo
     *
     * @param {...any} args
     * @returns {*}
     */
    inventoryUniquesCombo: (...args) => Resource.get('market-inventory-uniques-combo', args),
    
    /**
     * Market  Settings Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    settingsFetch: (...args) => Resource.get('market-sale-settings-fetch', args),
    
    /**
     * Market  Settings Save
     *
     * @param {...any} args
     * @returns {*}
     */
    settingsSave: (...args) => Resource.put('market-sale-settings-save', args),

    /**
     * Fetch Campaign Editor form
     *
     * @param {...any} args
     */
    campaignFetch: (...args) => Resource.get('market-campaign-fetch', args),

    /**
     * Save Campaign Editor form
     *
     * @param {...any} args
     */
    campaignSave: (...args) => Resource.post('market-campaign-save', args),

    /**
     * Market Campaign categories fetch
     *
     * @param {...any} args
     */
    campaignCategories: (...args) => Resource.get('market-campaign-categories', args),

    /**
     * Market Campaign products fetch
     *
     * @param {...any} args
     */
    campaignProducts: (...args) => Resource.get('market-campaign-products', args),
};
