/*-----------------------------------------------------------------
- Divisions Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Division List
     *
     * @param {...any} args
     * @returns {*}
     */
    list: (...args) => Resource.get('divisions-list', args),
    
    /**
     * Division Get Cities
     *
     * @param {...any} args
     * @returns {*}
     */
    cities: (...args) => Resource.get('divisions-cities', args),
    
    /**
     * Division Get Provinces
     *
     * @param {...any} args
     * @returns {*}
     */
    provinces: (...args) => Resource.get('divisions-provinces', args),

    /**
     * Division get all countries
     *
     * @param {...any} args
     * @returns {*}
     */
    countries: (...args) => Resource.get('divisions-countries', args),

};
