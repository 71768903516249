/*-----------------------------------------------------------------
- Form components to use in Form in core
-----------------------------------------------------------------*/
import Components from '@/mixins/FormComponents';

import YFormAlert from './form/elements/Alert';
import YFormAmount from './form/elements/Amount';
import YFormCloner from './form/elements/Cloner';
import YFormColorPicker from './form/elements/ColorPicker';
import YFormConditional from './form/elements/Conditional';
import YFormDate from './form/elements/DatePicker';
import YFormDivider from './form/elements/Divider';
import YFormDivision from './form/elements/Division';
import YFormDropdown from './form/elements/Dropdown';
import YFormEditor from './form/elements/Editor';
import YFormHeading from './form/elements/Heading';
import YFormHtml from './form/elements/Html';
import YFormImage from './form/elements/Image';
import YFormImages from './form/elements/Images';
import YFormIcon from './form/elements/Icon';
import YFormLabel from './form/elements/Label';
import YFormLink from './form/elements/Link';
import YFormLocaled from './form/elements/Localed';
import YFormPanel from './form/elements/Panel';
import YFormParagraph from './form/elements/Paragraph';
import YFormRow from './form/elements/Row';
import YFormTable from './form/elements/Table';
import YFormTitle from './form/elements/Title';
import YFormUploader from './form/elements/Uploader';
import YFormQrCode from './form/elements/QrCode';
import YFormMobile from './form/elements/Mobile';
import YFormPhone from './form/elements/Phone';

// export default Components;

export default {
    ...Components,
    YFormAlert,
    YFormAmount,
    YFormCloner,
    YFormColorPicker,
    YFormConditional,
    YFormDate,
    YFormTime    : YFormDate,
    YFormDatetime: YFormDate,
    YFormDivider,
    YFormDivision,
    YFormDropdown,
    YFormHeading,
    YFormEditor,
    YFormHtml,
    YFormImage,
    YFormImages,
    YFormIcon,
    YFormLabel,
    YFormLink,
    YFormLocaled,
    YFormPanel,
    YFormParagraph,
    YFormRow,
    YFormTable,
    YFormTitle,
    YFormUploader,
    YFormQrCode,
    YFormMobile,
    YFormPhone,
};
