/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/
export default {
    
    title             : 'Site Settings',
    type              : 'Setting',
    search_placeholder: 'Search in Settings...',
    
    categories: {
        general             : 'General',
        template            : 'Template', // eslint-disable-line @intlify/vue-i18n/no-raw-text
        finance             : 'Finance',
        infrastructure      : 'Infrastructure',
        content             : 'Content',
        persons             : 'Persons',
        contact             : 'Contact',
        market              : 'Market',
        data                : 'Data',
        gateway             : 'Gateway',
        group               : 'Group',
        notification_drivers: 'Notification Drivers',
        payment             : 'Payment',
    },

    reset_settings: {
        title : 'Settings will be reset to the default value.',
        button: 'Reset Settings',
    },

    messages: {
        reset_success: 'Settings have been reset successfully.',
    },
};
