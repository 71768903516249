/*-----------------------------------------------------------------
- MenuMaker Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * MenuMaker  get location menus
     *
     * @param {...any} args
     * @returns {*}
     */
    getLocationMenus: (...args) => Resource.get('menu-maker-get-location-menus', args),
    
};
