<template>
    <span
        class="button icon md-bell notification"
        @click="onOpenNotifsPanel"
    >
        <span
            v-if="notificationsCount > 0"
            class="label badge red rounded"
        >
            {{ newNotifsCount }}
        </span>
    </span>
</template>

<script>

    import { digits } from '@nodes/helpers/number';

    export default {
        name: 'NotificationsButton',

        /**
         * @inheritdoc
         */
        data() {
            return {
                notificationsCount: null,
                intervalId        : null,
            };
        },
        
        computed: {
            /**
             * Return notifications count in proper fromat
             */
            newNotifsCount() {
                return digits(this.notificationsCount, this.$i18n.locale);
            },
        },

        /**
         * @inheritdoc
         */
        mounted() {
            this.checkForNewNotifications();
            this.intervalId = setInterval(this.checkForNewNotifications, (5 * 60 * 1000));

            this.$root.$on('checkForNewNotifications', this.checkForNewNotifications);
        },

        /**
         * @inheritdoc
         */
        beforeDestroy() {
            // remove interval and events that run checkForNewNotifications method
            clearInterval(this.intervalId);
            this.$root.$off('checkForNewNotifications');
        },

        methods: {
            /**
             * Check for new notifications every 5 minutes
             */
            checkForNewNotifications() {
                this.$services.Notifier.userNotificationsCount().then((response) => {
                    const { count } = response.data.results;
                    if (count >= 0) {
                        this.notificationsCount = count;
                    }
                });
            },

            /**
             * Fetch notifications and then open the notifications panel
             */
            onOpenNotifsPanel() {
                this.$services.Notifier.userNotificationsList({ unread: true }).then((response) => {
                    const notifications = response.data.results;
                    this.$root.$emit('openNotifs', notifications);
                }).catch((errorResponse) => {
                    this.handleError(errorResponse);
                });
            },
        },
    };

</script>
