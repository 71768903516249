/*-----------------------------------------------------------------
- Accountant Routes
-----------------------------------------------------------------*/
const prefix = 'accountant';
const routes = [
    {
        path     : `${prefix}/documents`,
        name     : 'accountant-documents-list',
        component: () => import(/* webpackChunkName: "accountant" */ '@/modules/accountant/views/documents/List'),
    },
    {
        path     : `${prefix}/postings`,
        name     : 'accountant-postings-list',
        component: () => import(/* webpackChunkName: "accountant" */ '@/modules/accountant/views/postings/List'),
    },
    {
        path     : `${prefix}/documents/create`,
        name     : 'accountant-documents-create',
        component: () => import(/* webpackChunkName: "accountant" */ '@/modules/accountant/views/documents/Edit'),
    },
    {
        path     : `${prefix}/documents/:id/edit`,
        name     : 'accountant-documents-edit',
        component: () => import(/* webpackChunkName: "accountant" */ '@/modules/accountant/views/documents/Edit'),
    },
    {
        path     : `${prefix}/documents/:id/print`,
        name     : 'accountant-documents-print',
        component: () => import(/* webpackChunkName: "accountant" */ '@/modules/accountant/views/documents/Print'),
    },
];

export default routes;
