<template>
    <div class="conditional form-conditional">
        <!-- Condition: Select -->
        <y-form-field
            v-if="element.type === 'select'"
            v-model="model[name]"
            :label="element.label"
            type="select"
            :name="name"
            :options="element.options"
        />

        <!-- Condition: Radio -->
        <y-form-field
            v-if="element.type === 'radio'"
            v-model="model[name]"
            type="radio"
            :label="element.label"
            inline
            :options="flatOptions"
        />

        <!-- Condition: Checkbox -->
        <y-form-field
            v-if="element.type === 'checkbox'"
            v-model="model[name]"
            type="checkbox-list"
            :label="element.label"
            inline
            :options="flatOptions"
        />

        <!-- Options form -->
        <template v-for="(params, key) in element.children">
            <y-form
                v-if="isActive(key)"
                :key="`${element.id}-${key}`"
                v-model="model"
                :params="params"
            />
        </template>
    </div>
</template>

<script>

    export default {
        name: 'YFormConditional',

        components: {
            YForm     : () => import('@deps/form/Form'),
            YFormField: () => import('@deps/form/FormField'),
        },

        inject: {
            $validator: '$validator',
        },

        props: {
            /**
             * Element
             */
            element: Object,

            /**
             * Form default value passed by parent
             */
            value: { // eslint-disable-line vue/require-prop-types
                default: null,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },

        computed: {

            /**
             * Return element name
             */
            name() {
                return this.element.name;
            },

            /**
             * Return flat options
             */
            flatOptions() {
                const flat = {};
                this.element.options.forEach((el) => {
                    flat[el.value] = el.label;
                });
                return flat;
            },

        },

        watch: {
            /**
             * Watch locales to clear extra data
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.model = this.value;
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            if (!this.get(this.value, this.element.name) && this.element.value) {
                this.$set(this.model, this.element.name, this.element.value);
            }
        },

        methods: {

            /**
             * Check if form is active
             *
             * @param key
             */
            isActive(key) {
                return Array.isArray(this.model[this.name])
                    ? this.model[this.name].indexOf(key) > -1
                    : this.model[this.name] === key;
            },

        },
    };
</script>
