/*-----------------------------------------------------------------
- Tagging Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Tagging  folders Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    foldersGrid: (...args) => Resource.get('tagging-folders-grid', args),
    
    /**
     * Tagging  save orders of labels
     *
     * @param {...any} args
     * @returns {*}
     */
    saveOrders: (...args) => Resource.post('tagging-save-orders', args),
    
    /**
     * Tagging  save label
     *
     * @param {...any} args
     * @returns {*}
     */
    saveLabel: (...args) => Resource.post('tagging-save-label', args),
    
    /**
     * Tagging  fetch single form
     *
     * @param {...any} args
     * @returns {*}
     */
    fetchLabel: (...args) => Resource.get('tagging-fetch-label', args),
    
    /**
     * Tagging  fetch new label form
     *
     * @param {...any} args
     * @returns {*}
     */
    fetchNewLabel: (...args) => Resource.get('tagging-fetch-new-label', args),
    
    /**
     * Tagging  fetch content component
     *
     * @param {...any} args
     * @returns {*}
     */
    fetchContentComponent: (...args) => Resource.get('tagging-fetch-content-component', args),
    
};
