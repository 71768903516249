<template>
    <div class="radios">
        <div
            v-for="(val, key) in options"
            :key="name + '-' + key"
            :class="[{radio: !isSwitch}, ...classes]"
        >
            <input
                :id="name+'-'+key"
                type="radio"
                :name="name"
                :value="key"
                :disabled="disabled"
                :checked="optionIsChecked(value, key)"
                @change="updateText"
            >
            <label :for="name+'-'+key">
                {{ val }}
            </label>
        </div>
    </div>
</template>

<script>

    import FormElementMixin from '@/mixins/FormElement';
    import SwitchInputMixin from '@/mixins/SwitchInput';

    /**
     * Radio Component
     */
    export default {

        name: 'YFormRadio',

        mixins: [FormElementMixin, SwitchInputMixin],

        props: {

            /**
             * List of Radio Options
             */
            options: [Array, Object],
        },

        computed: {
            /**
             * Get label value
             *
             * @returns {*}
             */
            labelValue() {
                return this.label || this.el || (this.name === undefined || this.name === 'undefined' ? '' : this.$t(`fields.${this.name}`));
            },
        },

        methods: {
            /**
             * Checks which option is selected
             *
             * @param value1
             * @param value2
             * @returns {boolean}
             */
            optionIsChecked(value1, value2) {
                if (value1) {
                    // eslint-disable-next-line no-param-reassign
                    value1 = value1.toString();
                }
                if (value2) {
                    // eslint-disable-next-line no-param-reassign
                    value2 = value2.toString();
                }
                return value1 === value2 ? 'checked' : false;
            },
        },

    };

</script>
