/*-----------------------------------------------------------------
- Cloner Mixin
-----------------------------------------------------------------*/
import { generateId } from '@nodes/helpers/string';
import YClonerWrapper from '@deps/form/ClonerWrapper';
import YClonerPanel from '@deps/form/ClonerPanel';
import { convertToSelect } from '@nodes/helpers/object';

export default {
    
    name: 'ClonerMixin',
    
    components: {
        YForm     : () => import('@deps/form/Form'),
        YFormField: () => import('@deps/form/FormField'),
        YClonerWrapper,
        YClonerPanel,
    },
    
    props: {
        /**
         * Array of form elements
         */
        children: {
            type: Array,
        },
        
        /**
         * Cloner value
         */
        value: {
            default: null,
        },

        /**
         * Determine whether to allow cloner to be empty
         */
        allowEmpty: {
            type   : Boolean,
            default: false,
        },

        /**
         * YPanel's title prop
         */
        label: String,

        /**
         * YPanel's title condition and Cloner type (with or without panel)
         */
        noLabel: {
            type   : Boolean,
            default: false,
        },

        /**
         * Prevent Cloner from using prompt for every single remove action
         */
        withoutRemovalWarning: {
            type   : Boolean,
            default: false,
        },

        /**
         * Maximum number of rows
         */
        max: {
            type   : Number,
            default: 15,
        },
    },
    
    inject: {
        $validator: '$validator',
    },
    
    /**
     * @inheritDoc
     */
    data() {
        return {
            id   : generateId(),
            model: this.value || [],
            
            defaultRow: {},

            /**
             * Index of the row that is going to be removed.
             */
            showRemoveIndexPrompt: null,

            /**
             * Determine whether to show remove all prompt
             */
            showRemoveAllPrompt: false,
        };
    },
    
    /**
     * @inheritDoc
     */
    mounted() {
        this.initClonerRows();
    },
    
    watch: {
        /**
         * Watch roes to emit
         *
         * @param value
         */
        model(value) {
            this.$emit('input', value);
        },
    },
    
    methods: {
        /**
         * Add new empty row
         */
        addInput() {
            const row = this.cloneDeep(this.defaultRow) || {};
            
            this.model.push(row);
        },

        /**
         * Handle before input removal
         *
         * @param {number} index
         */
        onRemoveInput(index) {
            if (this.withoutRemovalWarning) {
                this.removeInput(index);
            } else {
                this.showRemoveIndexPrompt = index;
            }
        },

        /**
         * Remove a row
         *
         * @param {number} index
         */
        removeInput(index) {
            this.cancelRemovePrompt();
            this.model.splice(index, 1);
            this.initClonerRows();
        },

        /**
         * Show prompt for removing all rows
         */
        onRemoveAll() {
            if (this.withoutRemovalWarning) {
                this.removeAll();
            } else {
                this.showRemoveAllPrompt = true;
            }
        },

        /**
         * Remove all rows
         */
        removeAll() {
            this.$set(this, 'model', []);
            this.cancelRemoveAllPrompt();
            this.cancelRemovePrompt();
        },

        /**
         * Clear remove prompt
         */
        cancelRemovePrompt() {
            this.showRemoveIndexPrompt = null;
        },

        /**
         * Clear remove all prompt
         */
        cancelRemoveAllPrompt() {
            this.showRemoveAllPrompt = false;
        },

        /**
         * Init Cloner rows and state
         */
        initClonerRows() {
            if (this.model.length === 0 && !this.allowEmpty) {
                this.addInput();
                this.id = generateId();
            }
        },

        /**
         * Check if a prompt is active, using its row's index
         *
         * @param {number} index
         */
        isPromptActive(index) {
            return this.showRemoveIndexPrompt === index;
        },
        
        convertToSelect,
    },
    
};
