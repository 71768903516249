/*-----------------------------------------------------------------
- General trans
-----------------------------------------------------------------*/
export default {
    
    filters: 'فیلترها',
    
    total: '{perPage} از {total} مورد',
    
};
