/*-----------------------------------------------------------------
- Panel Modals Store
-----------------------------------------------------------------*/
/* eslint no-shadow: 0, no-param-reassign: 0, consistent-return: 0, no-confusing-arrow: 0 */
import Vue from 'vue';

const state = {
    list  : {},
    active: null,
};
const getters = {
    state : (state) => state,
    list  : (state) => state.list,
    active: (state) => state.active,
};
const mutations = {
    
    /**
     * Register modals
     *
     * @param state
     * @param data
     */
    register(state, data) {
        Vue.set(state, 'list', data);
    },
    
    /**
     * Clear all modules
     *
     * @param state
     */
    clear(state) {
        Vue.set(state, 'active', null);
        Vue.set(state, 'list', {});
    },
    
    /**
     * Open a modal
     *
     * @param state
     * @param data
     */
    open(state, data) {
        Vue.set(state, 'active', data);
    },
    
    /**
     * Close modal
     *
     * @param state
     */
    close(state) {
        Vue.set(state, 'active', null);
    },
    
};
const actions = {
    
    /**
     * Register modals
     *
     * @param commit
     * @param commit.commit
     * @param data
     */
    register({ commit }, data) {
        commit('register', data);
    },
    
    /**
     * Clear all modals
     *
     * @param commit
     * @param commit.commit
     */
    clear({ commit }) {
        commit('clear');
    },
    
    /**
     * Open a modal
     *
     * @param commit
     * @param commit.commit
     * @param data
     */
    open({ commit }, data) {
        commit('open', data);
    },
    
    /**
     * Open a modal
     *
     * @param commit
     * @param commit.commit
     */
    close({ commit }) {
        commit('close');
    },
    
};

export default {
    
    namespaced: true,
    
    state,
    getters,
    mutations,
    actions,
};
