/*-----------------------------------------------------------------
- Breadcrumbs trans
-----------------------------------------------------------------*/
export default {

    dashboard: 'پیشخوان',
    settings : 'تنظیمات',
    preview  : 'پیش‌نمایش',
    404      : '۴۰۴',
    help     : 'فهرست راهنما',

};
