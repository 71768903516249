/*-----------------------------------------------------------------
- Date translations
-----------------------------------------------------------------*/

export default {
    relative: {
        second: {
            single  : 'لحظاتی',
            multiple: 'لحظاتی',
        },
        minute: {
            single  : '۱ دقیقه',
            multiple: '{0} دقیقه',
        },
        hour: {
            single  : '۱ ساعت',
            multiple: '{0} ساعت',
        },
        day: {
            single  : '۱ روز',
            multiple: '{0} روز',
        },
        month: {
            single  : '۱ ماه',
            multiple: '{0} ماه',
        },
        year: {
            single  : '۱ سال',
            multiple: '{0} سال',
        },
    },
    prefix: {
        future: '',
        past  : '',
    },
    postfix: {
        future: 'بعد',
        past  : 'قبل',
    },

    days: {
        saturday : 'شنبه',
        sunday   : 'یک‌شنبه',
        monday   : 'دوشنبه',
        tuesday  : 'سه‌شنبه',
        wednesday: 'چهارشنبه',
        thursday : 'پنج‌شنبه',
        friday   : 'جمعه',
    },
};
