<template>
    <div>
        <y-form-field
            v-model="model.form_id"
            type="select"
            name="form_select"
            :options="forms"
            label-field="title"
            value-field="id"
            :label="$t('form-maker.export.form')"
            @select="fetchFormData($event)"
        />

        <template v-if="options">
            <y-form-field
                v-if="model.form_id"
                v-model="model.component_id"
                type="select"
                name="form_select"
                :options="options.components"
                label-field="title"
                value-field="id"
                :label="$t('form-maker.export.component')"
            />

            <y-form-field
                v-if="model.form_id"
                v-model="model.batch_id"
                type="select"
                name="form_select"
                :options="options.batches"
                label-field="responder_title"
                value-field="id"
                :label="$t('form-maker.export.batch')"
            />
        </template>
    </div>
</template>

<script>

    import YFormField from '@deps/form/FormField';

    export default {
        name: 'YFormFormExport',

        components: {
            YFormField,
        },

        props: {
            value  : [String, Object, Array],
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model  : {},
                options: null,
            };
        },

        computed: {
            /**
             * Form Options
             */
            forms() {
                return this.element && this.element.forms ? this.element.forms : [];
            },
        },

        watch: {
            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        methods: {
            /**
             * Fetch Unique Codes
             * 
             * @param {object} form - Object of the form
             */
            fetchFormData(form) {
                this.$services.single('form-maker-form', form.id, { including: ['batches', 'components'] }).then((response) => {
                    this.$set(this, 'options', response.data.results);
                });
            },
        },
    };

</script>
