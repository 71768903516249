/*-----------------------------------------------------------------
- MenuMaker Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * MenuMaker  Locations grid
     *
     * @param {...any} args
     * @returns {*}
     */
    locationsGrid: (...args) => Resource.get('menu-maker-locations-grid', args),
    
    /**
     * MenuMaker  save a menu
     *
     * @param {...any} args
     * @returns {*}
     */
    menusSave: (...args) => Resource.post('menu-maker-menus-save', args),
    
    /**
     * MenuMaker  get a menu
     *
     * @param {...any} args
     * @returns {*}
     */
    menusGet: (...args) => Resource.get('menu-maker-menus-get', args),
    
};
