/*-----------------------------------------------------------------
- Slideshows translations
-----------------------------------------------------------------*/
export default {
    
    preview   : 'پیش‌نمایش',
    page_title: 'مدیریت اسلایدها',
    slide     : 'اسلاید',
    addItem   : 'اسلاید جدیدی به لیست اضافه شد.',
    empty     : 'اسلایدی یافت نشد.',
    delete    : {
        title  : 'حذف اسلاید',
        message: 'آیا از حذف این اسلاید اطمینان دارید؟',
    },
    empty_preview    : 'اسلایدی برای پیش‌نمایش یافت نشد.',
    style_differences: 'ظاهر اسلایدشو ممکن است در تم واقعی، متفاوت باشد.',
};
