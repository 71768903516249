/*-----------------------------------------------------------------
- Amin Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Amin  Members Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    membersGrid: (...args) => Resource.get('amin-members-grid', args),

    /**
     * Amin  Wallets Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    walletsGrid: (...args) => Resource.get('amin-wallets-grid', args),

    /**
     * Amin  Records Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    recordsGrid: (...args) => Resource.get('amin-records-grid', args),

    /**
     * Amin  Meetings Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    meetingsGrid: (...args) => Resource.get('amin-meetings-grid', args),

    /**
     * Amin  Costs Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    costsGrid: (...args) => Resource.get('amin-costs-grid', args),
};
