/*-----------------------------------------------------------------
- Shipping trans
-----------------------------------------------------------------*/
export default {

    title: {
        single       : 'Online Brief',
        details      : 'Brief Details',
        items        : 'Brief Features',
        change_status: 'Change Status',
    },

    details: {
        company_size : 'Company Size',
        favorite_site: 'Favorite Websites',
        phone        : 'Phone',
        status       : 'Status',
        user_position: 'User Position',
        website      : 'Website',
    },

    change_status: {
        message: 'Change Status',
    },
};
