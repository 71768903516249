/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    tagging: {
        folders: 'Manage Folders',
        single : 'Content Tagging',
    },
    
};
