/*-----------------------------------------------------------------
- Market Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * market  Products Filters
     *
     * @param {...any} args
     * @returns {*}
     */
    productsFilters: (...args) => Resource.get('market-products-filters', args),

    /**
     * market  Products Filters
     *
     * @param {...any} args
     * @returns {*}
     */
    productWares: (...args) => Resource.get('market-product-wares', args),

    /**
     * market  Availability Alert
     *
     * @param {...any} args
     * @returns {*}
     */
    availabilityAlert: (...args: any[]): any => Resource.post('market-availability-alert', args),

};
