<template>
    <y-panel
        :title="element.label"
        class="content-tagging"
    >
        <template slot="functions">
            <y-button
                size="sm"
                aria-label="Refresh"
                icon="md-refresh"
                @click.native.prevent="fetch"
            />
        </template>

        <y-loading
            :active="$wait.is('loading-post-tag')"
            height="150px"
        >
            <template v-if="folders && folders.length">
                <y-panel
                    v-for="(folder, index) in folders"
                    :key="`tagging-folder-${folder.slug}-${index}`"
                    class="tagging-folder"
                    :title="folder.title"
                    icon="md-tag"
                >
                    <template slot="functions">
                        <y-button
                            v-if="element.has_setting_button"
                            size="xs"
                            tag="router-link"
                            :to="manageLink(folder.slug)"
                            icon="md-settings"
                        />
                    </template>

                    <template v-if="folder.labels && folder.labels.length">
                        <y-form-field
                            :key="(folder.labels.length + Math.floor((Math.random() * 10000000)))"
                            v-model="model[folder.slug]"
                            type="select"
                            value-field="id"
                            label-field="title"
                            multi
                            :taggable="folder.quick === 0 ? false : true"
                            no-label
                            :placeholder="$t('content.post_tag.select_tag')"
                            :options="folder.labels"
                            allow-empty
                            @tag="customTag(folder.quick, folder.labels, model[folder.slug], $event)"
                        />
                    </template>

                    <template v-else-if="folder.labels && !folder.labels.length">
                        <y-form-field
                            :key="(folder.labels.length + Math.floor((Math.random() * 10000000)))"
                            v-model="model[folder.slug]"
                            type="select"
                            value-field="id"
                            label-field="title"
                            multi
                            :taggable="folder.quick === 0 ? false : true"
                            no-label
                            :placeholder="$t('content.post_tag.select_tag')"
                            :options="[]"
                            allow-empty
                            @tag="customTag(folder.quick, folder.labels, model[folder.slug], $event)"
                        />
                    </template>

                    <!-- Show empty state -->
                    <y-empty-state
                        v-else
                        no-image
                        :message="$t('content.post_tag.no_label')"
                        height="70"
                        class="tagging-folder-empty"
                    />
                </y-panel>
            </template>

            <!-- Show empty state -->
            <y-empty-state
                v-else
                :message="$t('content.post_tag.no_folder')"
                height="250"
                class="mb0"
            />
        </y-loading>
    </y-panel>
</template>

<script>

    import YEmptyState from '@deps/EmptyState';
    import { listToTree } from '@nodes/helpers/misc';

    export default {

        name: 'YFormPostTagPicker',

        components: {
            YFormField: () => import('@deps/form/FormField'),
            YEmptyState,
        },

        props: {
            /**
             * Element of slug
             */
            element: Object,

            /**
             * Input Value
             */
            value: { // eslint-disable-line vue/require-prop-types
                default: null,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},

                folders      : null,
                replaceResult: true,
                postTag      : [],
            };
        },

        watch: {

            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },

            /**
             * Watch value set new model
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.fetch();
        },

        methods: {
            /**
             * Fetch Method
             */
            fetch() {
                this.fetchData('postTag', 0, this.element.type_id);
            },

            /**
             * Fetch Tag Data
             *
             * @param target {String}
             * @param nested {Number} 1 | 0
             * @param typeId {String}
             */
            fetchData(target, nested, typeId) {
                this.$wait.start('loading-post-tag');
                const params = {
                    id  : this.$route.params.id || null,
                    type: typeId,
                    nested,
                };
                this.$services.Tagging.fetchContentComponent(params).then((response) => {
                    this.$set(this, `${target}`, response);

                    if (this.replaceResult) {
                        this.$set(this, 'model', response.data.results);
                        this.replaceResult = false;
                    }
                    this.folders = response.data.metadata.folders;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('loading-post-tag');
                });
            },

            /**
             * Return manage link
             *
             * @param slug
             */
            manageLink(slug) {
                return {
                    name  : 'content-tagging-manage',
                    params: {
                        slug,
                    },
                };
            },

            listToTree,

            /**
             * Add Custom tag
             *
             * @param canQuickAdd
             * @param array
             * @param model
             * @param event
             */
            customTag(canQuickAdd, array, model, event) {
                if (canQuickAdd) {
                    const tag = {
                        id   : event,
                        title: event,
                    };
                    array.push(tag);
                    model.push(tag.id);
                }
            },

        },

    };
</script>
