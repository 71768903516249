/*-----------------------------------------------------------------
- Wallet trans
-----------------------------------------------------------------*/
export default {
    
    types: {
        deposit   : 'واریز',
        withdrawal: 'برداشت',
    },
    
    balance       : 'موجودی',
    blocked_amount: 'مبالغ مسدودی',
};
