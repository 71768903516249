/*-----------------------------------------------------------------
- General trans
-----------------------------------------------------------------*/
export default {
    
    changeStatus: {
        full: 'وضعیت از {0} به {1} تغییر کرد.',
        new : 'وضعیت به {0} تغییر کرد.',
    },
    
};
