/*-----------------------------------------------------------------
- Content fields
-----------------------------------------------------------------*/
export default {
    moderation_note: 'دلیل رد',
    synopsis       : 'متن خلاصه',

    content_type: {
        slug    : 'نامک',
        title   : 'عنوان',
        order   : 'ترتیب',
        template: 'قالب', // eslint-disable-line @intlify/vue-i18n/no-raw-text
        feature : 'ویژگی‌ها',
        icon    : 'نمایه',
    },
};
