<template>
    <div class="organization-user-list-modal">
        <div>
            <!-- Add from -->
            <div>
                <h6>{{ $t('person.organization.user_list.add.title') }}</h6>
                <form
                    id="user-search-form"
                    class="persons-user-search d-f align-items-end"
                    @submit.prevent="addUser"
                >
                    <y-form-field
                        v-model="selectedUser"
                        :endpoint="searchEndpoint"
                        value-field="id"
                        label-field="name_full"
                        :options="[]"
                        search="search"
                        type="select"
                        :placeholder="$t('person.organization.user_list.add.placeholder')"
                        no-label
                        :disabled="$wait.is('submitting-add-user')"
                        name="search"
                        class="mb0 ml5"
                    />

                    <div
                        v-if="isUserAlreadyInList(selectedUser)"
                        class="ml5"
                    >
                        <y-button
                            color="gray"
                            name="already_exist"
                            alt
                            :disabled="true"
                        >
                            {{ $t('person.organization.user_list.add.already_exist') }}
                        </y-button>
                    </div>
                    <y-button
                        v-else
                        color="blue"
                        class="ml5"
                        :disabled="isAddButtonDisabled()"
                        loading-on="submitting-add-user"
                        form="user-search-form"
                    >
                        {{ $t('person.organization.user_list.add.button') }}
                    </y-button>
                </form>
                <hr>
            </div>
            <!-- User List -->
            <div
                v-if="usersList && usersList.length"
                class="finder-result table-wrapper mt10"
            >
                <table class="table">
                    <thead>
                        <tr>
                            <th
                                v-for="column in tableHeader"
                                :key="column.id"
                            >
                                {{ column.title }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="row in usersList"
                            :key="row.id"
                        >
                            <td class="invoice-block-value">
                                {{ row.name_full }}
                            </td>
                            <td class="invoice-block-value">
                                {{ row.email }}
                            </td>
                            <td class="invoice-block-value">
                                <y-button
                                    color="red"
                                    :loading="removingUser === row.id"
                                    size="xs"
                                    @click.prevent.native="removeUser(row)"
                                >
                                    {{ $t('person.organization.user_list.remove.button') }}
                                </y-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

    import YFormField from '@deps/form/FormField';

    export default {
        name: 'YFormOrganizationUsersList',

        components: {
            YFormField,
        },

        props: {
            element: [Object, Array],
            value  : [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                searchEndpoint: {
                    name: 'fluorocarbon-organization-user-search',
                },
                id          : '',
                usersList   : [],
                selectedUser: '',
                removingUser: null,
                tableHeader : [
                    {
                        title: this.$t('fields.name'),
                        id   : 'name',
                    },
                    {
                        title: this.$t('fields.email'),
                        id   : 'email',
                    },
                    {
                        title: this.$t('button.action'),
                        id   : 'action',
                    },
                ],
            };
        },

        watch: {
            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.mapValue(newVal);
                    }
                },
                immediate: true,
                deep     : true,
            },
        },

        methods: {
            /**
             * Check is add button disability
             *
             * @returns {boolean}
             */
            isAddButtonDisabled() {
                // if no selectedUser
                if (!this.selectedUser || this.selectedUser === '') {
                    return true;
                }

                // if already selected
                if (this.isUserAlreadyInList(this.selectedUser)) {
                    return true;
                }
                return false;
            },

            /**
             * Check is user is already in list
             *
             * @param {string} userId - selected user id
             * @returns {boolean}
             */
            isUserAlreadyInList(userId) {
                return this.usersList.findIndex((user) => user.id === userId) !== -1;
            },

            /**
             * Map props.value to get organization id and user's list
             *
             * @param {object} value
             */
            mapValue(value) {
                if (value) {
                    const {
                        id,
                        ...objList
                    } = value;
                    this.id = id;
                    this.usersList = Object.values(objList);
                }
            },

            /**
             * Remove User from organization
             *
             * @param {object} selectedUser - selected user
             */
            removeUser(selectedUser) {
                if (selectedUser) {
                    const params = {
                        organization_id: this.id,
                        id             : selectedUser.id,
                    };
                    this.removingUser = selectedUser.id;
                    this.$services.Fluorocarbon.organizationUserRemove(params).then((response) => {
                        if (response && !response.isAxiosError) {
                            this.usersList = this.usersList.filter((user) => user.id !== selectedUser.id);
                            this.$toast.success(this.$t('person.organization.user_list.remove.success_msg'));
                        }
                    }).catch((error) => {
                        this.handleError(error);
                    }).finally(() => {
                        this.removingUser = null;
                    });
                }
            },

            /**
             * Add User to organization
             */
            addUser() {
                if (this.selectedUser && this.selectedUser !== '') {
                    const params = {
                        organization_id: this.id,
                        id             : this.selectedUser,
                    };
                    this.$wait.start('submitting-add-user');
                    this.$services.Fluorocarbon.organizationUserAdd(params).then((response) => {
                        if (response && !response.isAxiosError) {
                            this.selectedUser = '';
                            if (response.data.metadata && response.data.metadata.added_user) {
                                this.usersList = [...this.usersList, response.data.metadata.added_user];
                            }
                            this.$toast.success(this.$t('person.organization.user_list.add.success_msg'));
                        }
                    }).catch((error) => {
                        this.handleError(error);
                    }).finally(() => {
                        this.$wait.end('submitting-add-user');
                    });
                }
            },
        },
    };

</script>
