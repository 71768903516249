/*-----------------------------------------------------------------
- FAQs Locales
-----------------------------------------------------------------*/
export default {
    
    title: 'پرسش‌های متداول',
    type : 'پرسش',
    
    edit_modal  : 'ویرایش پرسش',
    create_modal: 'افزودن پرسش',
    
    categories   : 'دسته‌ها',
    uncategorized: 'دسته‌بندی‌نشده',
    
    messages: {
        sort: 'مرتب‌سازی با موفقیت ذخیره شد.',
    },
    
    empty: 'پرسشی در این دسته وجود ندارد.',
    
};
