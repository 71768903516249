<template>
    <div>
        <div class="seo-item-selector__parameters mb5">
            <span
                v-for="(param, index) in parameters.filter((item) => type === 'post_title' ? item !== 'custom_meta_description' : item !== 'custom_post_title')"
                :key="`${param}-${index}`"
                class="seo-item-selector__parameter ml5 mb5"
                @click="addTag(param)"
            >
                <i class="icon md-plus ml5 mr0" />
                {{ $t(`seo.parameters.${param}`) }}
            </span>
        </div>
        <div class="seo-item-selector__selected-box pa5">
            <template v-if="model && model.parameters && model.parameters.length">
                <span
                    v-for="(param, index) in model.parameters"
                    :key="index"
                    class="seo-item-selector__parameter is-added ml5 mb5"
                    @click="removeTag(param, index)"
                >
                    {{ $t(`seo.parameters.${param}`) }}
                    <i class="icon md-close mr5 ml0" />
                </span>
            </template>

            <template v-else>
                <span class="seo-item-selector__empty">
                    {{ $t(`seo.${type}_empty`) }}
                </span>
            </template>
        </div>
        <div class="d-f align-items-center justify-content-between">
            <span class="seo-item-selector__hint">
                {{ $t('seo.publish_hint') }}
            </span>
            <span
                class="seo-item-selector__hint"
                :class="{ 'is-overchar': checkOverCount }"
            >
                {{ $t('seo.count', [count, type === 'post_title' ? 80 : 160]) | digits }}
            </span>
        </div>
        <div
            v-if="model.parameters && model.parameters.length && model.parameters.includes(type === 'post_title' ? 'custom_post_title': 'custom_meta_description')"
            class="seo-item-selector__custom-text"
        >
            <y-form-field
                v-model="model.custom_text"
                type="text"
                :placeholder="$t('seo.custom_text_placeholder')"
                no-label
                name="custom_text_placeholder"
                class="mb0"
            />
        </div>
    </div>
</template>

<script>
    import YFormField from '@deps/form/FormField';

    export default {
        name: 'SeoItemSelector',

        components: {
            YFormField,
        },
        
        props: {
            type      : String,
            count     : Number,
            parameters: Array,
            element   : [Object, Array],
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                default: {
                    post_title: [
                        'title',
                        'separator',
                        'site_title',
                    ],

                    meta_description: [],
                },

                model: {
                    parameters : [],
                    custom_text: '',
                },
            };
        },

        computed: {
            /**
             * Check if the character count is over the limit
             */
            checkOverCount() {
                const limit = this.type === 'post_title' ? 80 : 160;
                return this.count > limit;
            },
        },

        watch: {
            model: {
                /**
                 * Watch model and emit on change
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

            element: {
                /**
                 * Watch value set new model
                 */
                handler() {
                    this.$set(this.model, 'custom_text', this.element[`custom_${this.type}`]);
                },
                deep     : true,
                immediate: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            this.defaultMetaDescription();
            this.defaultModel();
        },

        methods: {
            /**
             * Default Meta Description
             */
            defaultMetaDescription() {
                if (this.parameters.includes('synopsis') && !this.$route.params.id) {
                    this.default.meta_description.push('synopsis');
                }
            },

            /**
             * Create Default model
             */
            defaultModel() {
                let model = [];
                if (this.element && this.element[this.type] && this.element[this.type].length) {
                    this.element[this.type].forEach((item) => {
                        model.push(item.slug);
                    });
                } else {
                    model = this.default[this.type];
                }
                this.$set(this.model, 'parameters', model);
            },

            /**
             * Add tag to the model
             * 
             * @param {string} param - Param of the Selected Tag
             */
            addTag(param) {
                if (param) {
                    this.model.parameters.push(param);
                }
            },

            /**
             * Remove tag from the model
             * 
             * @param {string} param - Param of the Removed Tag
             * @param index
             */
            removeTag(param, index) {
                if (param) {
                    this.model.parameters.splice(index, 1);
                }
            },
        },
    };
</script>
