/*-----------------------------------------------------------------
- Fluorocarbon Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Fluorocarbon Add user to Persons organization
     *
     * @param {...any} args
     * @returns {*}
     */
    organizationUserAdd: (...args) => Resource.post('fluorocarbon-organization-user-add', args),
    
    /**
     * Fluorocarbon Remove user from Persons organization
     *
     * @param {...any} args
     * @returns {*}
     */
    organizationUserRemove: (...args) => Resource.post('fluorocarbon-organization-user-remove', args),
    
    /**
     * Fluorocarbon Fetch Calendar Setting
     *
     * @param {...any} args
     * @returns {*}
     */
    consultationSettingsFetch: (...args) => Resource.get('fluorocarbon-consultation-settings-fetch', args),
    
    /**
     * Fluorocarbon Save Calendar Setting
     *
     * @param {...any} args
     * @returns {*}
     */
    consultationSettingsSave: (...args) => Resource.post('fluorocarbon-consultation-settings-save', args),
    
    /**
     * Fluorocarbon Fetch Calendar Days
     *
     * @param {...any} args
     * @returns {*}
     */
    consultationDays: (...args) => Resource.get('fluorocarbon-consultation-days', args),
    
    /**
     * Fluorocarbon Fetch Day times
     *
     * @param {...any} args
     * @returns {*}
     */
    consultationTimes: (...args) => Resource.get('fluorocarbon-consultation-times', args),
    
    /**
     * Fluorocarbon Add Time to Day
     *
     * @param {...any} args
     * @returns {*}
     */
    consultationTimeBook: (...args) => Resource.post('fluorocarbon-consultation-time-book', args),
    
    /**
     * Fluorocarbon Ware Editor Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    wareFetch: (...args) => Resource.get('fluorocarbon-ware-fetch', args),
    
    /**
     * Fluorocarbon Ware Editor Save
     *
     * @param {...any} args
     * @returns {*}
     */
    wareSave: (...args) => Resource.post('fluorocarbon-ware-save', args),
    
};
