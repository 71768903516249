<template>
    <div>
        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('grid-loading')"
            height="calc( 100vh - 121px )"
        >
            <template v-if="grid">
                <!-- Header -->
                <y-grid-header
                    v-if="get(grid, 'header')"
                    :grid="grid"
                    :value="filters"
                    :filters-form="filtersForm"
                    @set-filters-form="setFiltersForm"
                    @input="refresh($event)"
                />

                <main>
                    <!-- Top part -->
                    <y-form
                        v-if="get(grid, 'top')"
                        :params="grid.top"
                    />

                    <!-- Show table and pagination if available -->
                    <y-loading
                        :active="$wait.is('list-loading')"
                        height="300px"
                    >
                        <!-- Filters results -->
                        <y-grid-filters
                            :grid="grid"
                            :filters="filters"
                            :filters-form="filtersForm"
                            @refresh="refresh($event)"
                        />

                        <!-- Grid Table -->
                        <y-grid-table
                            v-if="hasResults"
                            :key="`grid-table-${key_helper}`"
                            :grid="grid"
                            @refresh="refreshRow($event)"
                            @paginate="refresh({ refresh: 'list' })"
                        />

                        <!-- Show empty state -->
                        <y-empty-state v-else />
                    </y-loading>

                    <!-- Bottom part -->
                    <y-form
                        v-if="get(grid, 'bottom')"
                        :params="grid.bottom"
                    />
                </main>
            </template>

            <y-error-not-found v-else-if="error === 400" />
            <y-error-server
                v-else-if="error >= 500"
                :message="errorMessage"
                @refresh="refresh({})"
            />
        </y-loading>
    </div>
</template>

<script>
    import { truncate, generateId } from '@nodes/helpers/string';
    import GlobalMixin from '@/mixins/Global';
    import YForm from '@deps/form/Form';
    import YEmptyState from '@deps/EmptyState';
    import YErrorNotFound from '@deps/Error404';
    import YErrorServer from '@deps/Error500';

    export default {

        name: 'YPanelGrid',

        components: {
            YGridHeader : () => import('@deps/grid/GridHeader'),
            YGridFilters: () => import('@deps/grid/GridFilters'),
            YGridTable  : () => import('@deps/grid/GridTable'),
            YEmptyState,
            YForm,
            YErrorNotFound,
            YErrorServer,
        },

        mixins: [
            GlobalMixin,
        ],

        props: {

            /**
             * Grid service to call
             */
            service: Function,

            /**
             * Grid response
             */
            response: Object,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                key_helper: generateId(),

                filters: {
                    ...this.$route.query,
                    refresh: 'grid',
                    sort   : this.$route.query.sort || 'created_at',
                    order  : this.$route.query.order || 'desc',
                },

                grid       : null,
                modals     : null,
                filtersForm: [],

                breadcrumbs: null,

                error       : null,
                errorMessage: null,
            };
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.get(this.grid, 'pageTitle', ''),
            };
        },

        computed: {

            /**
             * Return refresh
             */
            refreshType() {
                if (this.filters.refresh === 'grid' || this.filters.refresh === 'list') {
                    return this.filters.refresh;
                }
                return 'row';
            },

            /**
             * Return loading type
             */
            loadingType() {
                if (this.refreshType === 'row') {
                    return `row-${this.filters.refresh}`;
                }
                return this.refreshType;
            },

            /**
             * Check if results is loaded
             */
            hasResults() {
                return this.grid.list
                    && this.grid.selected
                    && !Array.isArray(this.grid.list)
                    && Object.keys(this.grid.list).length
                    && Object.keys(this.grid.selected).length;
            },

            /**
             * Make filters ready for query
             */
            queryFilters() {
                const filters = {};
                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key] && typeof this.filters[key] === 'object' && !Array.isArray(this.filters[key])) {
                        filters[key] = this.filters[key].value || this.filters[key].id;
                    } else {
                        filters[key] = this.filters[key];
                    }
                });
                return filters;
            },

            /**
             * Return loading slug
             */
            loading() {
                return `${this.loadingType}-loading`;
            },

        },

        watch: {

            $route: {
                /**
                 * Watch route params change
                 *
                 * @param newValue
                 * @param oldValue
                 */
                handler(newValue, oldValue) {
                    if (newValue.query.page !== oldValue.query.page) {
                        this.$set(this.filters, 'refresh', 'list');
                    }
                    if (this.get(newValue, 'query.r') !== this.get(oldValue, 'query.r')) {
                        this.handleWatchRoute(newValue);
                    }
                    this.fetch();
                },

                deep: true,
            },

            breadcrumbs: {
                /**
                 * Watch Breadcrumbs
                 */
                handler() {
                    if (this.breadcrumbs) {
                        this.$store.dispatch('breadcrumbs/set', this.setBreadcrumb());
                    }
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            if (this.response) {
                this.handleAfterRefresh(this.response);
            } else {
                this.fetch('grid');
            }

            // Check events
            this.$root.$on('refreshGrid', (payload) => {
                this.handleWatchRoute(payload);
                this.fetch();
            });
        },

        /**
         * @inheritDoc
         */
        beforeDestroy() {
            this.$root.$off('refreshGrid');
        },

        methods: {
            /**
             * Set breadcrumb
             */
            setBreadcrumb() {
                const map = this.breadcrumbs.map((i) => this.$bc(i.link, i.label));
                return map;
            },

            /**
             * Handle watch route event
             *
             * @param value
             */
            handleWatchRoute(value) {
                this.$set(this, 'filters', {
                    ...this.$route.query,
                    refresh: value.refresh || this.$route.query.refresh || 'grid',
                    sort   : this.$route.query.sort || 'created_at',
                    order  : this.$route.query.order || 'desc',
                });
            },

            /**
             * Set filters form
             *
             * @param $event
             */
            setFiltersForm($event) {
                this.$set(this, 'filtersForm', $event);
            },

            /**
             * Remove unused filters
             */
            removeUnused() {
                const filters = { ...this.$route.query, ...this.queryFilters };
                Object.keys(filters).forEach((key) => {
                    if (!filters[key]) {
                        delete filters[key];
                    }
                });
                this.$set(this, 'filters', filters);
                return filters;
            },

            /**
             * Refresh grid
             *
             * @param $event
             */
            refresh($event) {
                this.$set(this, 'filters', {
                    ...$event,
                    r    : generateId(),
                    sort : $event.sort || this.$route.query.sort || 'created_at',
                    order: $event.order || this.$route.query.order || 'desc',
                    tab  : $event.tab || this.$route.query.tab || null,
                    page : $event.page || this.$route.query.page || 1,
                });
                this.$router.replace({ query: this.removeUnused() }).catch(() => {});
            },

            /**
             * Refresh grid
             *
             * @param $event
             */
            refreshRow($event) {
                this.$set(this.filters, 'refresh', $event.refresh);
                this.fetch($event.refresh);
            },

            /**
             * Fetch grid
             *
             * @param refresh
             * @returns {*}
             */
            fetch(refresh) {
                this.error = null;
                this.$wait.end('list-loading');
                const loadingSlug = this.loading;
                this.$wait.start(loadingSlug);

                const params = { ...this.$route.params, ...this.queryFilters, ...this.$route.query };

                // Fix page changes
                params.page = this.$route.query.page || 1;

                if (refresh) {
                    params.refresh = refresh;
                }

                return this.service(params).then((response) => {
                    this.$set(this, 'breadcrumbs', response.data.metadata.breadcrumbs);
                    this.handleAfterRefresh(response.data);
                }).catch((error) => {
                    if (error && error.response) {
                        this.error = error.response.status;
                        this.errorMessage = this.get(error.response.data, 'userMessage', this.$t('errors.500.title'));
                        this.handleError(error);
                    }
                }).finally(() => {
                    this.$wait.end(loadingSlug);
                });
            },

            /**
             * Make select list options
             */
            makeSelectList() {
                if (this.grid && this.grid.list && !Array.isArray(this.grid.list) && Object.keys(this.grid.list).length) {
                    const selected = {};
                    Object.keys(this.grid.list).forEach((id) => {
                        selected[id] = 0;
                    });
                    this.$set(this.grid, 'selected', selected);
                }
            },

            /**
             * Handle refresh action
             *
             * @param response
             */
            handleAfterRefresh(response) {
                if (this.refreshType === 'grid') {
                    // Set grid
                    this.$set(this, 'grid', response.metadata.grid);
                    this.$set(this.grid, 'list', response.results);
                    this.$set(this.grid, 'service', this.service);
                    this.$set(this.grid, 'paginationModel', response.metadata);
                    this.$set(this.grid, 'fetch', this.fetch);
                    this.makeSelectList();
                } else if (this.refreshType === 'list') {
                    this.$set(this.grid, 'list', response.results);
                    this.grid.paginationModel = response.metadata;
                    this.makeSelectList();
                } else if (Object.keys(response.results).length > 0) {
                    const list = { ...this.grid.list, ...response.results };
                    this.$set(this.grid, 'list', list);
                } else {
                    const { [this.filters.refresh]: omitted, ...list } = this.grid.list;
                    this.$set(this.grid, 'list', list);
                }

                // Set modals
                this.setModals(response.metadata.modals);

                this.grid.pageTitle = response.metadata.page_title || '';
                this.key_helper = generateId();
            },

            /**
             * Set modals to the panel modals
             *
             * @param modals
             */
            setModals(modals) {
                let currentModals = this.$store.getters['modals/list'];
                if (modals && !Array.isArray(modals)) {
                    currentModals = { ...currentModals, ...modals };
                }
                this.registerGlobalModals(currentModals);
            },

            truncate,

        },

    };
</script>
