/*-----------------------------------------------------------------
- Cart trans
-----------------------------------------------------------------*/
export default {
    
    coupon: {
        base: {
            title: 'عنوان',
        },
        
        amount: {
            radio         : 'میزان تخفیف',
            fee_number    : 'حداکثر مبلغ تخفیف',
            percent_number: 'درصد',
            percent_max   : 'تا سقف',
        },
        
        group: {
            radio      : 'افراد مجاز',
            search_user: 'انتخاب کاربرها',
            role       : 'انتخاب نقش‌ها',
        },
        
        limitation: {
            radio: 'محدودیت استفاده',
            many : 'تعداد دفعات قابل استفاده',
        },
    },
    
};
