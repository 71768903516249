/*-----------------------------------------------------------------
- Fields translations
-----------------------------------------------------------------*/
export default {
    username             : 'Username',
    email                : 'Email',
    code_melli           : 'National Code',
    country_code         : 'Country Code',
    mobile               : 'Mobile',
    mobile_confirmation  : 'Mobile Confirmation',
    postal_code          : 'Postal Code',
    tel                  : 'Phone',
    country              : 'Country',
    province             : 'Province',
    city                 : 'City',
    neighborhood         : 'Neighborhood',
    name                 : 'Name',
    name_first           : 'First name',
    name_last            : 'Last name',
    password             : 'Password',
    password_confirmation: 'Password Confirmation',
    password2            : 'Repeat Password',
    token                : 'Password Recovery Token',
    remember             : 'Remember Me',
    home_address         : 'Address',
    reset_password       : 'Reset Password',
    gender               : 'Gender',
    slug                 : 'Slug',
    text                 : 'Text',
    title                : 'Title',
    title2               : 'Second Title',
    subtitle             : 'Subtitle',
    site_title           : 'Site Title',
    search               : 'Search',
    categories           : 'Categories',
    type                 : 'Type',
    choose               : 'Choose',
    order                : 'Order',
    sort                 : 'Sort',
    desc                 : 'Descending',
    asc                  : 'Ascending',
    activation_code      : 'Activation Code',
    role                 : 'User Role',
    organization         : 'Organization',
    status               : 'Status',
    question             : 'Question',
    answer               : 'Answer',
    category             : 'Category',
    logo                 : 'Logo',
    created_at           : 'Created At',
    created_by           : 'Created By',
    published_at         : 'Publish Time',
    published_by         : 'Publisher',
    updated_at           : 'Updated At',
    updated_by           : 'Updated By',
    date                 : 'Date',
    birthday             : 'Birthday',
    verify_code          : 'Verify code',
    currency             : 'Currency',
    code                 : 'Code',
    message              : 'Message',
    row                  : 'Row',
    website              : 'Website',
    icon                 : 'Icon',

    endpoint: 'Endpoint',
    method  : 'Method',
    version : 'Version',
    mock    : 'Mock',

    image: 'Image',

    specs: {
        active: 'Yes',
    },

    latitude: 'Latitude',
    lat_help: '(Hint: This number is in the range of -90 to +90. S or N)',

    longitude: 'Longitude',
    long_help: '(Hint: This number is in the range of -180 to +180. E or W)',

    transaction_type: 'Transaction type',
    amount          : 'Amount',
    register_payment: 'Register the payment transaction',
    tracking_number : 'Tracking number',
    payment_path    : 'Payment path',
    is_private      : 'private',
    description     : 'Description',
};
