<template>
    <div class="page-builder-grid-cell">
        <y-grid-cell-item :element="element" />
    </div>
</template>

<script>
    import YGridCellItem from './Item.vue';

    export default {
        name: 'YFormPageBuilderGridCell',

        components: {
            YGridCellItem,
        },

        props: {
            element: Object,
        },
    };
</script>
