/*-----------------------------------------------------------------
- Router
-----------------------------------------------------------------*/
import Vue from 'vue';
import Router from 'vue-router';
import deepMerge from 'deepmerge';
import { YLayoutWrapper } from '@nodes/layouts';
import { configs } from '@nodes/configs';
import GlobalGuards from '@/guards/Global';

// Default object
const { locales } : any = configs;
let all: any[] = [];
let routes: any[] = [];

type files = {
    core: any | undefined;
    modules: any | undefined;
};

const importedFiles: files = {
    core   : null,
    modules: null,
};

// Load files

importedFiles.core = require.context('@/router', true, /\.(js|ts)$/);
importedFiles.modules = require.context('@modules', true, /\/routes[\S]*\.(js|ts)$/);

// Merge configs
Object.keys(importedFiles).forEach((section) => {
    importedFiles[section].keys().forEach((fileName) => {
        all = deepMerge(all, importedFiles[section](fileName).default);
    });
});

// Register 404 page to end of routes list
all.push({
    path     : '*',
    name     : '404',
    component: () => import('@/views/404.vue'),
});

// Create routes
let prefix = process.env.VUE_APP_ROUTE_PREFIX || '';
prefix = prefix.replace(/^\/?/, '/').replace(/\/?$/, '/');

if (locales && locales.locales && locales.locales.length > 1 && process.env.VUE_APP_PANEL === 'false') {
    routes = [
        {
            path    : '/',
            redirect: `${prefix}${locales.locales[0]}`,
        },
        {
            path    : prefix,
            redirect: `${prefix}${locales.locales[0]}`,
        },
        {
            path     : `${prefix}:locale`,
            component: YLayoutWrapper,
            children : all,
        },
    ];
} else {
    routes = [
        {
            path     : prefix,
            component: YLayoutWrapper,
            children : all,
        },
    ];
    if (prefix !== '/') {
        routes.unshift({
            path    : '/',
            redirect: prefix,
        });
    }
}

// Use Router
Vue.use(Router);

// Create Router instance and register routes;
const router = new Router({
    mode: 'history',
    routes,

    /**
     * Handle scroll behavior
     *
     * @param {*} to
     * @param {*} from
     * @param {*} savedPosition
     * @returns {{x: number, y: number}|{selector: *}|*}
     */
    scrollBehavior(to, from, savedPosition) {
        if (to.hash && to.hash !== '#') {
            return { selector: to.hash };
        }
        if (savedPosition) {
            return savedPosition;
        }
        return {
            x: 0,
            y: 0,
        };
    },
});

// Set global guards
router.beforeEach((to, from, next) => GlobalGuards.beforeEach(to, from, next));
router.afterEach((to, from) => GlobalGuards.afterEach(to, from));

// Export router
export default router;
