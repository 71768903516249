/*-----------------------------------------------------------------
- Shipping trans
-----------------------------------------------------------------*/
export default {
    
    title: {
        short_name: 'Shipping',
        create    : 'Add Shipping Method',
        edit      : 'Edit Shipping Method',
    },
    
    editor: {
        shipping_price: 'Basic Shipping Price',

        rules: {
            title          : 'Rules',
            available_rules: 'Available Rules',
            selected_rules : 'Selected Rules',
            rule_settings  : 'Rule Settings for {0}',
            empty          : 'No rule has found.',

            divisions: {
                allowed    : 'Allowed',
                not_allowed: 'Not Allowed',
                empty      : 'Use the form above to add {0} divisions.',
            },
        },
        messages: {
            success: 'Shipping method saved successfully.',
        },

        tabs: {
            base: 'Basic Information',
        },
    },

    custom_fields: {
        title: 'Custom Fields',

        types: {
            text: 'Description',
            date: 'Date',
            file: 'File',
        },

        statuses: {
            sent     : 'Sent',
            delivered: 'Delivered',
        },
    },

    delivery_intervals: {
        title                     : 'Delivery Intervals',
        intervals                 : 'Time Intervals',
        method_booking_days_helper: 'The number of days that the user can choose from when registering the order.',
    },
};
