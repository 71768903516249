/*-----------------------------------------------------------------
- Person Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    /**
     * Persons  Change Password
     *
     * @param {...any} args
     * @returns {*}
     */
    changePassword: (...args) => Resource.post('persons-change-password', args),

    /**
     * Persons   Users Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    userGrid: (...args) => Resource.get('persons-users-grid', args),

    /**
     * Persons   address grid
     *
     * @param {...any} args
     * @returns {*}
     */
    addressGrid: (...args) => Resource.get('persons-address-grid', args),
    
    /**
     * Persons   address form
     *
     * @param {...any} args
     * @returns {*}
     */
    addressForm: (...args) => Resource.get('persons-address-form', args),
    
    /**
     * Persons   address save by admin
     *
     * @param {...any} args
     * @returns {*}
     */
    addressSaveByAdmin: (...args) => Resource.post('persons-address-save-by-admin', args),

    /**
     * Persons   Edit profile grid
     *
     * @param {...any} args
     * @returns {*}
     */
    userProfileFetch: (...args) => Resource.get('persons-user-profile-fetch', args),

    /**
     * Persons   Edit profile save
     *
     * @param {...any} args
     * @returns {*}
     */
    userEditProfile: (...args) => Resource.post('persons-user-edit-profile', args),

    /**
     * Persons  Roles grid
     *
     * @param {...any} args
     * @returns {*}
     */
    rolesGrid: (...args) => Resource.get('persons-roles-grid', args),

    /**
     * Persons  User panel roles
     *
     * @param {...any} args
     * @returns {*}
     */
    userPanelRoles: (...args) => Resource.get('persons-user-panel-roles', args),

    /**
     * Persons  User panel role assign
     *
     * @param {...any} args
     * @returns {*}
     */
    userPanelRoleAssign: (...args) => Resource.post('persons-user-panel-role-assign', args),

    /**
     * Persons  User panel role resign
     *
     * @param {...any} args
     * @returns {*}
     */
    userPanelRoleResign: (...args) => Resource.delete('persons-user-panel-role-resign', args),

    /**
     * Persons  User panel role resign
     *
     * @param {...any} args
     * @returns {*}
     */
    userBlock: (...args) => Resource.post('persons-user-block', args),

    /**
     * Persons  User panel permits
     *
     * @param {...any} args
     * @returns {*}
     */
    userPanelPermits: (...args) => Resource.get('persons-user-panel-permits', args),

    /**
     * Persons  User panel save permits
     *
     * @param {...any} args
     * @returns {*}
     */
    userPanelPermitsSave: (...args) => Resource.put('persons-user-panel-permits-save', args),

    /**
     * Persons  Organizations Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    organizationsGrid: (...args) => Resource.get('persons-organizations-grid', args),

    /**
     * Panel User profile preferences fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    userPreferencesFetch: (...args) => Resource.get('persons-user-preferences-fetch', args),

    /**
     * Panel User profile preferences save
     *
     * @param {...any} args
     * @returns {*}
     */
    userPreferencesSave: (...args) => Resource.post('persons-user-preferences-save', args),

    /**
     * Panel Generate two factor authentication
     *
     * @param {...any} args
     * @returns {*}
     */
    generateTwoFactorAuthentication: (...args) => Resource.patch('persons-generate-two-factor-authentication', args),

    /**
     * Panel Enable two factor authentication
     *
     * @param {...any} args
     * @returns {*}
     */
    enableTwoFactorAuthentication: (...args) => Resource.patch('persons-enable-two-factor-authentication', args),

    /**
     * Panel Disable two factor authentication
     *
     * @param {...any} args
     * @returns {*}
     */
    disableTwoFactorAuthentication: (...args) => Resource.patch('persons-disable-two-factor-authentication', args),

    /**
     * Panel Disable two factor authentication for user
     *
     * @param {...any} args
     * @returns {*}
     */
    disableUserTwoFactorAuthentication: (...args) => Resource.patch('persons-disable-user-two-factor-authentication', args),

    /**
     * Panel Get 2FA backup codes
     *
     * @param {...any} args
     * @returns {*}
     */
    fetchBackupCodes: (...args) => Resource.get('persons-fetch-backup-codes', args),

    /**
     * Panel Get 2FA used codes count
     *
     * @param {...any} args
     * @returns {*}
     */
    usedBackupCodes: (...args) => Resource.get('persons-used-backup-codes', args),
    
    /**
     * Panel My Login History Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    myLoginHistoryGrid: (...args) => Resource.get('persons-my-login-history-grid', args),
    
    /**
     * Panel Login History Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    loginHistoryGrid: (...args) => Resource.get('persons-login-history-grid', args),
    
    /**
     * Panel Login History
     *
     * @param {...any} args
     * @returns {*}
     */
    loginHistory: (...args) => Resource.get('persons-login-history', args),
    
    /**
     * Panel Users Panel Save Form
     *
     * @param {...any} args
     * @returns {*}
     */
    usersPanelSaveForm: (...args) => Resource.get('persons-users-panel-save-form', args),
    
    /**
     * Panel Users Panel Save
     *
     * @param {...any} args
     * @returns {*}
     */
    usersPanelSave: (...args) => Resource.post('persons-users-panel-save', args),
    
    /**
     * Panel Users Panel Save
     *
     * @param {...any} args
     * @returns {*}
     */
    impersonate: (...args) => Resource.put('persons-impersonate', args),
    
    /**
     * Panel Profile Fields Form
     *
     * @param {...any} args
     * @returns {*}
     */
    profileFieldsForm: (...args) => Resource.get('persons-profile-fields-form', args),
    
    /**
     * Panel Profile Fields Form Save
     *
     * @param {...any} args
     * @returns {*}
     */
    profileFieldsSave: (...args) => Resource.post('persons-profile-fields-save', args),
    
    /**
     * Panel Profile Fields Form Setting
     *
     * @param {...any} args
     * @returns {*}
     */
    profileFieldsSettingGet: (...args) => Resource.get('persons-profile-fields-setting-get', args),
    
    /**
     * Panel Profile Fields Form Setting Save
     *
     * @param {...any} args
     * @returns {*}
     */
    profileFieldsSettingSave: (...args) => Resource.post('persons-profile-fields-setting-save', args),
    
    /**
     * Panel User Search
     *
     * @param {...any} args
     * @returns {*}
     */
    userSearch: (...args) => Resource.get('persons-user-search', args),

    /**
     * Panel Password Recovery Fields
     *
     * @param {...any} args
     */
    getUsernameFields: (...args) => Resource.get('persons-get-username-fields', args),

    /**
     * User Edit orm
     * 
     * @param {...any} args
     */
    userEditForm: (...args) => Resource.get('persons-user-edit-form', args),

    /**
     * User Profile
     * 
     * @param {...any} args
     */
    viewProfile: (...args) => Resource.get('persons-view-profile', args),

};
