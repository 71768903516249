<template>
    <div
        class="alert form-alert icon"
        :class="[typeValue]"
    >
        <i
            v-if="iconValue"
            class="icon"
            :class="[iconValue]"
        />
        <div v-html="content" />
    </div>
</template>

<script>

    /**
     * This is an alert component based on UI element accessible witch accept html content, icon,
     * and a type for consistent development.
     *
     * @version 2.0.0
     */

    export default {

        name: 'YFormAlert',

        props: {

            /**
             * Content text. Accepts any string containing HTML.
             */
            content: {
                type    : String,
                default : '',
                required: true,
            },

            /**
             * Icon to be placed on the alert. Accepts any icon from loaded packages on section.
             *
             * @values
             */
            icon: {
                type   : String,
                default: null,
            },

            /**
             * Type of alert for visual help
             *
             * @values default,danger,warning,success,info
             */
            type: {
                type   : String,
                default: 'default',
            },
        },

        computed: {
            /**
             * Return type of alert
             *
             * @returns {Array}
             */
            typeValue() {
                const types = {
                    default: 'gray',
                    danger : 'red',
                    warning: 'orange',
                    success: 'green',
                    info   : 'blue',
                };
                return types[this.type || 'default'];
            },

            /**
             * Return icon of alert
             *
             * @returns {Array}
             */
            iconValue() {
                const types = {
                    default: 'md-information',
                    danger : 'md-close-box',
                    warning: 'md-alert',
                    success: 'md-check-circle',
                    info   : 'md-information',
                };
                return this.icon || types[this.type || 'default'];
            },
        },
    };
</script>
