<template>
    <y-form-uploader
        v-if="params"
        v-model="model"
        :no-label="!params.label"
        v-bind="params"
    />
</template>

<script>

    import YFormUploader from '@deps/form/elements/Uploader';

    export default {

        name: 'YFormAvatar',

        components: {
            YFormUploader,
        },

        props: {
            /**
             * Element of slug
             */
            element: Object,

            /**
             * Input Value
             */
            value: [String, Array],

            /**
             * Form Field details
             */
            details: [Object, String, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || null,
            };
        },

        computed: {

            /**
             * Create params for dropzone
             */
            params() {
                if (this.element) {
                    const params = { ...this.element };
                    params.component_name = 'uploader';
                    params.maxFiles = 1;
                    params.details = this.details;
                    return params;
                }
                return null;
            },

        },

        watch: {
            /**
             * Watch model and emit
             */
            model() {
                this.$emit('input', this.model[0]);
            },
        },

    };
</script>
