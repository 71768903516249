<template>
    <y-modal ref="Modal">
        <div
            v-if="type"
            slot="body"
        >
            <p
                class="mb0"
                v-html="confirmText"
            />
        </div>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="red"
                    loading-on="deleting"
                    @click.prevent.native="doDelete"
                >
                    {{ $t('button.delete') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>
    import YModal from '@deps/Modal';

    export default {
        name: 'DeleteModal',

        components: {
            YModal,
        },

        props: {
            model       : String,
            type        : String,
            confirmOnly : Boolean,
            kindOfDelete: {
                type   : String,
                default: 'destroy',
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                item: null,
            };
        },

        computed: {
            /**
             * Check item is in loading mode
             */
            isLoading() {
                return this.$wait.is('deleting');
            },

            /**
             * Return confirm text
             */
            confirmText() {
                const type = this.item && this.item.title ? 'confirmFull' : 'confirm';
                return this.$t(`messages.destroy.${type}`, {
                    type : this.type,
                    title: this.get(this.item, 'title'),
                });
            },
        },

        methods: {
            /**
             *
             */
            startLoading() {
                this.$wait.start('deleting');
            },

            /**
             *
             */
            endLoading() {
                this.$wait.end('deleting');
            },

            /**
             * Remove item
             */
            doDelete() {
                if (this.confirmOnly) {
                    this.$emit('confirm', this.item);
                    this.close();
                    return;
                }
                const type = this.kindOfDelete;
                this.startLoading();
                this.$services[type](this.model, this.item.id).then(() => {
                    this.$toast.success(this.$t('messages.destroy.success', { type: this.type }));
                    this.$emit('done');
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.endLoading());
            },

            /**
             * Open modal
             *
             * @param {*} item
             */
            open(item) {
                this.item = item;
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.item = null;
            },
        },
    };
</script>
