<template>
    <div>
        <y-chart
            :series="series"
            :tooltip-formatter="tooltip"
            axis-pointer="cross"
            :axis-pointer-formatter="axisPointerFormatter"
            :x-axis-label="xAxisLabel"
            :y-axis-label="yAxisLabel"
            y-axis-split-area
            y-axis-split-line
            y-axis-line
            y-axis-tick
            x-axis-line
            x-axis-tick
            :options="chartOptions"
        />
    </div>
</template>

<script>

    import { date } from '@nodes/helpers/date';
    import { digits } from '@nodes/helpers/number';
    import YChart from '@deps/Chart';

    export default {
        name: 'YFormStockRateChart',

        components: {
            YChart,
        },

        props: {
            value  : [String, Object],
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                rawData     : [],
                chartDate   : [],
                chartValue  : [],
                chartAverage: [],
            };
        },

        computed: {
            /**
             * Tooltip Formatter
             */
            tooltip() {
                return ((params) => `<span class="echarts-tooltip-title">${params[0].axisValue}</span> </br>
                                ${this.$t('stock.rateChart.tooltip.price.start')}: ${digits(params[0].value[1], this.$i18n.locale)} </br>
                                ${this.$t('stock.rateChart.tooltip.price.end')}: ${digits(params[0].value[2], this.$i18n.locale)} </br>
                                ${this.$t('stock.rateChart.tooltip.price.min')}: ${digits(params[0].value[3], this.$i18n.locale)} </br>
                                ${this.$t('stock.rateChart.tooltip.price.max')}: ${digits(params[0].value[4], this.$i18n.locale)} </br>
                                ${this.$t('stock.rateChart.tooltip.price.average')}: ${digits(params[1].value, this.$i18n.locale)}`);
            },

            /**
             * Axis Pointer Formatter
             */
            axisPointerFormatter() {
                return ((params) => `${digits(params.value, this.$i18n.locale)}`);
            },

            /**
             * Generate xAxis Label
             */
            xAxisLabel() {
                return this.chartDate;
            },

            /**
             * Generate yAxis Label
             */
            yAxisLabel() {
                return ((params) => `${digits(params, this.$i18n.locale)}`);
            },

            /**
             * Series of the widget
             */
            series() {
                return [
                    {
                        type             : 'k',
                        data             : this.chartValue,
                        animationEasing  : 'quinticInOut',
                        animationDuration: 800,
                        itemStyle        : {
                            color       : '#03A5A3',
                            borderColor : '#363636',
                            color0      : '#FF1E36',
                            borderColor0: '#363636',
                        },
                    },
                    {
                        type     : 'line',
                        data     : this.chartAverage,
                        smooth   : true,
                        itemStyle: {
                            color: '#363636',
                        },
                    },
                ];
            },

            /**
             * Chart data
             */
            chartOptions() {
                return {
                    xAxis: {
                        min      : 'dataMin',
                        max      : 'dataMax',
                        axisLabel: {
                            interval: 0,
                            rotate  : 45,
                        },
                    },
                    yAxis: {
                        type     : 'value',
                        axisLabel: {
                            show    : true,
                            interval: 50,
                        },
                    },
                };
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.getChartData();
        },

        methods: {
            /**
             * Fetch data
             */
            getChartData() {
                const params = {
                    including        : ['effected_at', 'price_max', 'price_min', 'price_average', 'price_start', 'price_end'],
                    sort             : 'effected_at',
                    order            : 'asc',
                    post             : this.value,
                    rate_chart_period: '',
                };
                return this.$services.list('StockAggregate', params).then((response) => {
                    this.rawData = [];
                    this.rawData = response.data.results;
                    if (this.rawData) {
                        this.convertRawData(this.rawData);
                    }
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Manipulate rawData
             *
             * @param data
             */
            convertRawData(data) {
                Object.values(data).forEach((item) => {
                    this.chartDate.push(date(item.effected_at, 'MMMM D', this.$i18n.locale));
                    this.chartValue.push([item.price_start, item.price_end, item.price_min, item.price_max]);
                    this.chartAverage.push(item.price_average);
                });
            },
        },
    };

</script>
