/* -------------------------------------------------------------------------- */
/* API HELPERS                                                                */
/* -------------------------------------------------------------------------- */

/**
 * Return a debounced function
 *
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @returns {Promise}
 */
export const debounce = (func, wait, immediate) => {
    let timeout;
    return (...args) => {
        const context = this;

        /**
         * Callback used in setTimeout
         */
        const later = () => {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };

        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
};
