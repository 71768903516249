<template>
    <div class="form-group">
        <y-form
            v-model="model"
            :params="children"
        />
    </div>
</template>

<script>

    /**
     * Group Component
     */
    export default {

        name: 'YFormGroup',

        components: {
            YForm: () => import('@deps/form/Form'),
        },

        props: {

            /**
             * Panel children as form elements
             */
            children: [Object, Array],

            /**
             * Form default value passed by parent
             */
            value: Object,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},
            };
        },

        watch: {

            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },

            /**
             * Watch model and emit
             */
            model() {
                this.$emit('input', this.model);
            },
        },

    };

</script>
