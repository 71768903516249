/*-----------------------------------------------------------------
- Fields
-----------------------------------------------------------------*/
export default {
    
    tax_enabled            : 'محاسبات مالیاتی فعال باشند.',
    tax_should_add_to_price: 'مالیات هنگام فروش به قیمت کالا اضافه شود.',
    tax_seller_id          : 'شناسه مالیات',
    tax_specific_cats      : 'دسته‌های معاف از قوانین مالیاتی',
    
    original_price: 'قیمت اصلی',
    sale_price    : 'قیمت در حراج',
    price         : {
        with_tax   : 'قیمت با احتساب مالیات',
        without_tax: 'قیمت بدون احتساب مالیات',
    },
    
    sale_count         : 'تعداد فروش',
    form_original_price: 'قیمت اصلی ({0})',
    form_sale_price    : 'قیمت در حراج ({0})',
    sale_price_expire  : 'انقضای خودکار قیمت در حراج',
    expires_at         : 'زمان انقضا',
    ware_count         : 'تعداد کالا',
    wares              : 'کالاها',
    product            : 'محصول',
    ware               : 'کالا',
    is_available       : 'قابل فروش',
    labels             : 'دسته‌بندی‌ها',
    product_select     : 'انتخاب محصول',
    
    height: 'ارتفاع ({0})',
    length: 'طول ({0})',
    width : 'عرض ({0})',
    weight: 'وزن ({0})',
    
    unit             : 'واحد اندازه‌گیری',
    market_code      : 'کد کالا',
    tags             : 'برچسب‌ها',
    ware_is_available: 'این کالا برای فروش در دسترس باشد.',
    approximate_size : 'اندازه‌ی تقریبی',
    breakable        : 'شکستنی',
    
    supply_unit              : 'واحد عرضه',
    supply_type              : 'نوع عرضه',
    inventory_count          : 'تعداد موجودی',
    inventory_price          : 'قیمت واحد خرید',
    inventory_supply_price   : 'قیمت تامین',
    inventory_return_price   : 'قیمت بازگشت',
    inventory_damage_price   : 'قیمت بازیافت',
    inventory_min_count      : 'حداقل تعداد موجودی',
    inventory_low_level_alarm: 'اعلان کاهش موجودی',
    inventory_low_level_stop : 'خارج از شدن از دسترس، در صورت عدم موجودی',
    inventory_payment_path   : 'مسیر پرداخت',
    
    activate_tax  : 'مستقل از قوانین فروش',
    tax           : 'مالیات',
    tax_type      : 'نوع مالیات',
    payment_type  : 'نوع پرداخت',
    payment_period: 'دوره‌ی پرداخت',
    discount      : 'تخفیف',
    
    tax_value_added : 'ارزش افزوده (٪)',
    tax_withholding : 'مالیات تکلیفی (٪)',
    tax_municipality: 'عوارض شهرداری (٪)',
    
    kind            : 'نوع تغییر',
    kind_inventory  : 'دلیل تغییر موجودی',
    alter           : 'تعداد تغییر',
    effected_at     : 'تاریخ موثر',
    description     : 'توضیح',
    reason          : 'دلیل',
    reason_inventory: 'علت آسیب',

    campaigns: {
        amount: {
            radio         : 'میزان تخفیف',
            percent_number: 'درصد',
        },
    },
};
