/*-----------------------------------------------------------------
- Toast translations
-----------------------------------------------------------------*/
export default {
    assign: {
        success: 'New permission assigned successfully.',
    },
    
    resign: {
        success: 'Permission resigned successfully.',
    },
    
    block: {
        success: 'Permission blocked successfully.',
    },
    
    unblock: {
        success: 'Permission unblocked successfully.',
    },
    
    permitSave: {
        success: 'Permissions saved successfully.',
    },
};
