/*-----------------------------------------------------------------
- General translations
-----------------------------------------------------------------*/
export default {

    layout: {
        title      : 'پنل مدیریت',
        separator  : '::',
        description: 'کوارک :: هسته‌ی قدرتمند یسناتیم',
    },

    empty: {
        msg    : 'چیزی برای نمایش وجود ندارد.',
        msgFull: '{title} یافت نشد.',
    },

    home: 'صفحه اصلی',

    author: {
        1: 'نویسنده',
        s: 'نویسندگان',
    },

    category: {
        1: 'دسته',
        s: 'دسته‌ها',
    },

    form_select: {
        no_options: 'گزینه‌ای وجود ندارد.',
        no_search : 'برای مشاهده گزینه‌ها جستجو کنید',
        no_result : 'نتیجه‌ای یافت نشد.',
    },

    settings: 'تنظیمات',

    division: {
        neighbourhood: 'محله',
        city         : 'شهر',
        province     : 'استان',
        country      : 'کشور',
        continent    : 'قاره',
    },

    profile: 'پروفایل',

    status: {
        active  : 'فعال',
        inactive: 'غیرفعال',
    },

    locales: {
        fa: 'فارسی',
        en: 'انگلیسی',
        de: 'آلمانی',
        zh: 'چینی',
        it: 'ایتالیایی',
        fr: 'فرانسوی',
        ar: 'عربی',
        tr: 'ترکی',
        es: 'اسپانیایی',
        ru: 'روسی',
    },

    current_locale: 'زبان فعلی',

    dropzone: {
        dictRemoveFile              : 'حذف فایل',
        dictRemoveFileConfirmation  : 'آیا از حذف فایل اطمینان دارید؟',
        dictCancelUpload            : 'بی‌خیال!',
        dictUploadCanceled          : 'فرایند آپلود متوقف شد!',
        dictCancelUploadConfirmation: 'آیا از متوقف کردن فرایند آپلود اطمینان دارید؟',
        dictFileTooBig              : 'حجم فایل زیاد است ({filesize} مگابایت). حداکثر حجم فایل قابل ارسال {maxFileSize} مگابایت است.',
        dictInvalidFileType         : 'امکان آپلود فایل با این فرمت وجود ندارد.',
        dictResponseError           : 'اختلال در برقراری ارتباط.',
        dictMaxFilesExceeded        : 'نمی‌توانید بیش از {maxFiles} فایل آپلود کنید.',
    },

    pagination: {
        prev: 'قبلی',
        next: 'بعدی',
    },

    filters: 'فیلترها',

    active  : 'فعال',
    inactive: 'غیرفعال',

    copy_value: {
        copied: 'کپی شد!',
        error : 'مشکلی در کپی عبارت به وجود آمده است. لطفا دوباره تلاش کنید.',
    },

    // Panel
    breadcrumbs: {
        dashboard: 'پیشخوان',
        preview  : 'پیش‌نمایش',
        404      : '۴۰۴',
        500      : '۵۰۰',
    },
    
    dashboard: 'پیشخوان',
    
    timeline: {
        by            : ' توسط {0}',
        node_not_found: 'نود با نام {0} وجود ندارد',
        private_note  : 'پیام محرمانه',
        by_admin      : 'ثبت توسط ادمین',
        add_in_status : 'ثبت شده در وضعیت {0}',
    },
    
    error: {
        404: {
            title: 'آنچه می‌خواهید پیدا نشد. ',
        },

        invalid: 'مقدار وارد شده بعنوان مختصات، معتبر نمی‌باشد.',
    },

};
