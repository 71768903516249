/*-----------------------------------------------------------------
- Subscription trans
-----------------------------------------------------------------*/
export default {

    title: {
        create          : 'Create New Subscription Package',
        edit            : 'Edit Subscription Package',
        general_settings: 'Subscription General Settings',
    },

    empty_fields: 'Empty the fields',
    categories  : 'Categories',

    wallet_settings: {
        title: 'Wallet settings',
        label: 'Customer can pay off their wallet.',
    },

    payment_settings: {
        title       : 'Payment Settings',
        unavailable : 'Be disabled',
        online_path : 'Online payment path',
        offline_path: 'Offline payment path',
    },

    invoice_settings: {
        title                 : 'Official invoice settings',
        invoice_generate      : 'Issuing invoice to buy subscription',
        invoice_prefix        : 'Prefix code',
        invoice_prefix_help   : 'This prefix is added to the beginning of the code. Preferably enter English letters. ',
        invoice_required_field: 'Essential fields for real and legal users',
        seller_info           : 'Seller\'s information',
    },

    notifier_settings: {
        title               : 'Organizations Declaration',
        notif_activate      : 'Send notifications at the time of activation',
        notif_one_month     : 'Submit notifications of one month left over time package',
        notif_one_week      : 'Send a one week notification to complete the package time ',
        notif_one_day       : 'Send notifications of one day to complete the package time',
        notif_fifty_percent : 'Send notifications by passing the user from 50% authorized number',
        notif_ninety_percent: 'Send notifications by passing the user from 90% authorized number',
        notif_finished      : 'Send notifications by finishing time or number to user',
    },

    discount_settings: {
        title             : 'Discount codes',
        discount_available: 'Use the activated discount codes.',
    },

    seller_info_modal: {
        title: 'Seller\'s information',
        done : 'Done.',
        error: 'Error!',
    },

    seller_type: {
        title: 'Type of Seller',
        real : 'Real',
        legal: 'Legal',
    },

    editor_base          : 'Package details',
    advanced_settings    : 'Advanced settings',
    use_advanced_settings: 'Use advanced settings',
    volume_time_value    : 'Volume time value',
    volume_const_value   : 'Volume const value',
    volume_time_type     : 'Volume time type',
    currency             : 'Currency',
    original_price       : 'Original price',
    sale_price           : 'Sale price',

    messages: {
        success: 'The Subscription package successfully saved.',
    },

};
