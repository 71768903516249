/*-----------------------------------------------------------------
- Http instance
-----------------------------------------------------------------*/
import axios from 'axios';
import { configs } from '@nodes/configs';
import router from '@nodes/router';
import { apiBaseUrl } from './helpers';

// Add token
const token = localStorage.getItem('token');
if (token && configs.project.useToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

// Create axios instance
const axiosInstance = axios.create({
    baseURL: apiBaseUrl(),
});

// Create cancel token
axiosInstance.source = axios.CancelToken.source();

/**
 * Cancels every request
 *
 * @function
 */
axiosInstance.cancelRequests = () => {
    axiosInstance.source.cancel({
        isCanceled: true,
        message   : 'Requests canceled',
    });
    axiosInstance.source = axios.CancelToken.source();
};

/**
 * returns if error is being caused by axios cancel
 *
 * @param {*} err
 * @function
 * @returns {boolean} - true if the error caused by canceling the request
 */
axiosInstance.areRequestsCanceled = (err) => err && axios.isCancel(err);

/**
 * Parse error response
 *
 * @function
 * @param {Error} error - Error to parse
 * @returns {Promise} - Contains the error object
 */
function parseError(error) {
    if (error.response) {
        return Promise.reject(error.response.data);
    }
    
    return Promise.reject(error);
}

/**
 * Set cancel token on every request
 */
axiosInstance.interceptors.request.use((request) => {
    if (!request.cancelToken) {
        // eslint-disable-next-line no-param-reassign
        request.cancelToken = axiosInstance.source.token;
    }
    return request;
}, parseError);

/**
 * Set `access-ticket` to header, If it exists on any response
 */
axiosInstance.interceptors.response.use((response) => {
    if (response.data && response.data.metadata && response.data.metadata['access-ticket']) {
        axiosInstance.defaults.headers['access-ticket'] = response.data.metadata['access-ticket'];
    }
    return response;
});

// Remove `access-ticket` on route change
if (router) {
    router.beforeEach((to, from, next) => {
        if (axiosInstance.defaults.headers['access-ticket']) {
            delete axiosInstance.defaults.headers['access-ticket'];
        }
        next();
    });
}

export default axiosInstance;
