/*-----------------------------------------------------------------
- Job Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Job Seeker Timeline
     *
     * @param {...any} args
     * @returns {*}
     */
    seekerTimeline: (...args) => Resource.get('job-seeker-timeline', args),
    
    /**
     * Job Seeker Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    seekersGrid: (...args) => Resource.get('job-seekers-grid', args),
    
    /**
     * Job Seeker Action
     *
     * @param {...any} args
     * @returns {*}
     */
    seekerAction: (...args) => Resource.put('job-seeker-action', args),
    
    /**
     * Job Seeker Sticky Note Save
     *
     * @param {...any} args
     * @returns {*}
     */
    seekerStickyNoteSave: (...args) => Resource.put('job-seeker-sticky-note-save', args),

};
