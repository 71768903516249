/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    divisions: {
        list: 'تقسیمات کشوری',
    },
    
};
