<template>
    <div class="field">
        <label>{{ element.label }}</label>
        <y-form-select
            :options="options"
            group-values="items"
            group-label="group_title"
            label-field="title"
            value-field="id"
            :value="model"
            :is-loading="false"
            @input="setValue"
        />
    </div>
</template>

<script>

    import YFormSelect from '@deps/form/elements/Select';

    import { listToTree } from '@nodes/helpers/misc';

    export default {

        name: 'YFormSelectTag',

        components: {
            YFormSelect,
        },

        props: {
            /**
             * Element
             */
            element: Object,

            /**
             * Input Value
             */
            value: [Object, String],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                options    : this.makeOptions(),
                flatOptions: this.makeFlatOptions(),
                model      : null,
            };
        },

        watch: {

            /**
             * Watch value and change model
             *
             * @param val
             */
            value(val) {
                if (!val) {
                    this.model = null;
                }
                this.model = (typeof val === 'string') ? this.setModel(val) : val;
            },

        },

        /**
         * @inheritDoc
         */
        created() {
            this.options = this.makeOptions();
            this.model = this.value ? this.setModel(this.value) : null;
        },

        methods: {

            /**
             * Set model
             *
             * @param value
             */
            setModel(value) {
                return this.flatOptions.find((el) => el.id === value);
            },

            /**
             * Set value
             *
             * @param value
             */
            setValue(value) {
                if (value !== this.value) {
                    this.$emit('input', value);
                }
            },

            /**
             * Make select options
             */
            makeOptions() {
                const options = this.cloneDeep(this.element.options);
                return options.map((el) => {
                    if (!el.labels || !el.labels.length) {
                        return null;
                    }
                    return {
                        group_title: el.title,
                        items      : this.makeLabels(listToTree(el.labels), []),
                    };
                });
            },

            /**
             * Make select flat options
             */
            makeFlatOptions() {
                const options = this.cloneDeep(this.element.options);
                const arr = [];
                options.forEach((el) => {
                    if (el.labels && el.labels.length) {
                        arr.push(...el.labels);
                    }
                });
                return arr;
            },

            /**
             * Make labels of options
             *
             * @param tree
             * @param items
             * @param level
             */
            makeLabels(tree, items, level = 0) {
                const prefix = `${'-'.repeat(level)} `;
                tree.forEach((el) => {
                    items.push({
                        id   : el.id,
                        title: `${prefix}${el.title}`,
                    });
                    if (el.children && el.children.length) {
                        this.makeLabels([...el.children], items, level + 1);
                    }
                });
                return items;
            },

        },

    };
</script>
