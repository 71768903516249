<template>
    <div
        id="error-layout"
        class="l-error"
    >
        <div class="ta-c p50">
            <slot />
        </div>
    </div>
</template>

<script>

    export default {

        name: 'ErrorLayout',

    };
</script>
