<template>
    <y-panel
        v-if="model"
        class="form-product-tagging"
        :title="$t('market.product_tagging.title')"
    >
        <template
            v-if="element.main_folder_slug && element.has_setting_button"
            slot="functions"
        >
            <y-button
                size="xs"
                tag="router-link"
                target="_blank"
                rel="noopener noreferrer"
                :to="folderLink(element.main_folder_slug)"
            >
                {{ $t('button.manage') }}
            </y-button>
        </template>

        <div class="levels">
            <template v-if="levels && levels.length">
                <div
                    v-for="(labels, level) in levels"
                    :key="`level-${level}`"
                    class="level"
                >
                    <a
                        v-for="label in labels"
                        :key="`label-${label.id}`"
                        href="#"
                        class="button sm"
                        :class="{active: isActive(label.id)}"
                        @click.prevent="fetchLevel(label, level)"
                    >
                        {{ label.title }}
                    </a>
                </div>
            </template>

            <y-empty-state
                v-else
                class="tagging_empty"
                height="250"
                :message="$t('market.product_tagging.empty_levels')"
            />

            <div
                v-if="$wait.is('category-loading')"
                class="level"
            >
                <y-loading
                    :active="$wait.is('category-loading')"
                    height="40px"
                />
            </div>
        </div>

        <div
            v-if="Object.keys(folders).length"
            class="folders"
        >
            <div class="row sm">
                <div
                    v-for="folder in folders"
                    :key="`option-${folder.id}`"
                    class="col-sm-4 folder"
                >
                    <y-panel
                        :title="folder.title"
                        size="sm"
                    >
                        <template slot="functions">
                            <y-button
                                v-if="element.has_setting_button"
                                size="xs"
                                icon="md-cogs"
                                tag="router-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                :to="folderLink(folder.slug)"
                            />
                        </template>

                        <y-loading
                            :active="$wait.is('folders-loading')"
                            height="250px"
                        >
                            <div
                                v-if="folder.tags && folder.tags.length"
                                class="tags-select"
                            >
                                <y-form-field
                                    v-for="tag in folder.tags"
                                    :id="`tag-${tag.id}`"
                                    :key="`tag-${tag.id}`"
                                    v-model="model.options[tag.id]"
                                    type="checkbox"
                                    :label="tag.title"
                                    :name="`tag-${tag.id}`"
                                />
                            </div>
                            <y-empty-state
                                v-else
                                no-image
                                height="230"
                                :message="$t('market.product_tagging.empty_option')"
                            />
                        </y-loading>
                    </y-panel>
                </div>
            </div>
        </div>
    </y-panel>
</template>

<script>

    export default {

        name: 'YFormProductTagging',

        components: {
            YFormField : () => import('@deps/form/FormField'),
            YEmptyState: () => import('@deps/EmptyState'),
        },

        inject: {
            $validator: '$validator',
        },

        props: {
            value  : Object,
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || null,

                folders: {},
                levels : [],
            };
        },

        computed: {},

        watch: {

            value: {
                /**
                 * Handle watch value
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

        },

        /**
         * @inheritDoc
         */
        async created() {
            await this.fetch();

            if (this.model && this.model.categories && this.get(this.model, 'categories', []).length) {
                const prs = this.model.categories.map((category, index) => this.fetch(category, index + 1, true));
                await Promise.all(prs);
                const label = this.levels[this.levels.length - 1].find((el) => el.id === this.model.categories[this.model.categories.length - 1]);
                if (label && label.attached_folders && label.attached_folders.length) {
                    this.fetchFolders(label.attached_folders);
                }

                if (this.get(this.model, 'selected', [])) {
                    this.$set(this.model, 'options', this.model.selected.reduce((acc, val) => {
                        acc[val] = 1;
                        return acc;
                    }, {}));
                }
            } else {
                this.$set(this, 'model', {});
                this.$set(this.model, 'categories', []);
                this.$set(this.model, 'options', {});
            }
        },

        methods: {

            /**
             * Handle fetch level
             *
             * @param label
             * @param level
             */
            fetchLevel(label, level) {
                const target = level + 1;

                this.levels.splice(level + 1);
                this.model.categories.splice(level, this.model.categories.length - level, label.id);

                this.$set(this, 'folders', {});
                this.$set(this.model, 'options', {});

                if (label.children_count > 0) {
                    return this.fetch(label.id, target);
                }
                if (label && label.attached_folders && label.attached_folders.length) {
                    return this.fetchFolders(label.attached_folders);
                }

                return false;
            },

            /**
             * Fetch categories
             *
             * @param parent_id
             * @param level
             * @param init
             */
            fetch(parent_id = 0, level = 0, init = false) {
                this.$wait.start('category-loading');
                const params = {
                    slug     : this.element.main_folder_slug,
                    including: ['title', 'children_count', 'attached_folders'],
                    parent_id,
                };
                return this.$services.Tagging.labels(params).then((response) => {
                    if (response.data.results && response.data.results.length) {
                        this.$set(this.levels, level, response.data.results);

                        if (!init && this.levels.length > level + 1) {
                            this.$set(this, 'levels', this.levels.slice(level + 1));
                        }
                    }
                }).catch((error) => error).finally(() => this.$wait.end('category-loading'));
            },

            /**
             * Fetch folders
             *
             * @param folders
             */
            fetchFolders(folders) {
                this.$wait.start('folders-loading');

                this.folders = folders.reduce((acc, el) => {
                    acc[el.id] = el;
                    return acc;
                }, {});

                const prs = folders.map((folder) => this.$services.Tagging.labels({
                    slug     : folder.slug,
                    including: ['title'],
                }));

                return Promise.all(prs).then((responses) => {
                    responses.forEach((response) => {
                        const id = this.get(response, 'data.metadata.folder.id');
                        this.folders[id].tags = response.data.results;
                    });
                }).catch((error) => error).finally(() => this.$wait.end('folders-loading'));
            },

            /**
             * Create folder manage link
             *
             * @param slug
             */
            folderLink(slug) {
                return {
                    name  : 'content-tagging-manage',
                    params: { slug },
                };
            },

            /**
             * Check if category is active
             *
             * @param id
             */
            isActive(id) {
                if (!this.model.categories) {
                    return false;
                }
                return this.get(this.model, 'categories').includes(id);
            },

        },

    };

</script>
