/*-----------------------------------------------------------------
- Form Maker Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Form Maker Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    fetch: (...args) => Resource.get('form-maker-fetch', args),

    /**
     * Form Maker Preview Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    preview: (...args) => Resource.get('form-maker-preview', args),

    /**
     * Form Maker Save
     *
     * @param {...any} args
     * @returns {*}
     */
    save: (...args) => Resource.post('form-maker-save', args),

    /**
     * Form Maker Save Answers
     *
     * @param {...any} args
     * @returns {*}
     */
    saveAnswers: (...args) => Resource.post('form-maker-save-answers', args),

    /**
     * Check if the model of the form maker has answers
     *
     * @param {...any} args
     * @returns {*}
     */
    checkAnswer: (...args) => Resource.get('form-maker-check-answer', args),

    /**
     * Get answers of the user
     *
     * @param {...any} args
     * @returns {*}
     */
    formsUserAnswers: (...args) => Resource.get('form-maker-forms-user-answers', args),

    /**
     * Get answers of the specific component
     *
     * @param {...any} args
     * @returns {*}
     */
    formsComponentAnswers: (...args) => Resource.get('form-maker-forms-component-answers', args),

    /**
     * Summary of the form
     *
     * @param {...any} args
     * @returns {*}
     */
    formAnswers: (...args) => Resource.get('form-maker-form-answers', args),

    /**
     * Remove Answer(s) of the form
     *
     * @param {...any} args
     * @returns {*}
     */
    removeAnswers: (...args) => Resource.delete('form-maker-remove-answers', args),

    /**
     * Change the activity of the form
     *
     * @param {...any} args
     * @returns {*}
     */
    changeActivity: (...args) => Resource.post('form-maker-change-activity', args),

};
