/*-----------------------------------------------------------------
- FAQs Locales
-----------------------------------------------------------------*/
export default {
    
    title: 'FAQ',
    type : 'question',
    
    edit_modal  : 'Edit Question',
    create_modal: 'Add Question',
    
    categories   : 'Categories',
    uncategorized: 'Uncategorized',
    
    messages: {
        sort: 'Questions sort saved successfully.',
    },
    
    empty: 'No questions found in this category.',
    
};
