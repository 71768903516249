/*-----------------------------------------------------------------
- Load all layouts
-----------------------------------------------------------------*/
const files = require.context('@/layouts', false, /\.vue$/);
const layouts = {};

files.keys().forEach((key) => {
    layouts[`${key.replace(/(\.\/|\.vue)/g, '')}Layout`] = files(key).default;
});

export default layouts;
