<template>
    <div class="grid-404">
        <h4 class="mb50">
            {{ message }}
        </h4>
        <y-button @click.native="$emit('refresh')">
            {{ $t('button.refresh') }}
        </y-button>
    </div>
</template>

<script>
    export default {
        name: 'ErrorServer',

        props: {
            message: {
                type   : String,
                default: () => this.$t('errors.500.title'),
            },
        },
    };
</script>
