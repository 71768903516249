/*-----------------------------------------------------------------
- Subscription trans
-----------------------------------------------------------------*/
export default {

    eco_no          : 'Economical number',
    reg_no          : 'Registration number',
    package_image   : 'Package image',
    nationality_code: 'Nationality code',

};
