/*-----------------------------------------------------------------
- Load all configs and share it into Vue instance
-----------------------------------------------------------------*/
import deepMerge from 'deepmerge';
import { get as getValue } from 'lodash/object';
import sectionConfigs from './modules';

// Default object
let all = {
    ...sectionConfigs,
};

type files = {
    core: any | undefined,
    modules: any | undefined,
}

const importedFiles: files = {
    core   : null,
    modules: null,
};

// Load files
importedFiles.core = require.context('@configs', true, /\.(js|ts)$/);
importedFiles.modules = require.context('@modules', true, /\/configs[\S]*\.(js|ts)$/);
const importedConfigs = {};

// Merge configs
Object.keys(importedFiles).forEach( (section) => {
    importedFiles[section].keys().forEach((fileName) => {
        const file = fileName.match(/[-_\w]+[.][\w]+$/i)[0].replace(/(\.\/|\.(js|ts))/g, '');
        if (file !== 'project') {
            importedConfigs[file] = importedFiles[section](fileName).default;
        }
    });
});

all = deepMerge(all, importedConfigs, { arrayMerge: (destinationArray, sourceArray) => sourceArray });

/**
 * Get config value from registered configs
 *
 * @param {string} key
 * @param {*} defaultValue
 * @returns {*}
 */
const get = (key = null, defaultValue = null) => {
    const value = getValue(all, key);
    
    return value === undefined ? defaultValue : value;
};

// Export all configs to use directly
const configs = all;
export { configs };

// Export plugin
export default {
    
    /**
     * Install plugin
     *
     * @param {*} Vue
     */
    install(Vue) {
        Vue.prototype.$configs = configs; // eslint-disable-line
        Vue.prototype.$config = get; // eslint-disable-line
    },
};
