<template>
    <y-row>
        <y-col
            v-for="(col, index) in children"
            :key="'col-'+index"
            :size="col.col || null"
        >
            <y-form
                v-model="model"
                :params="[col]"
                @input="$emit('input', model)"
            />
        </y-col>
    </y-row>
</template>

<script>

    /**
     * Row Component
     */
    export default {

        name: 'YFormRow',

        components: {
            YForm: () => import('@deps/form/Form'),
        },

        props: {

            /**
             * Row children as rows
             */
            children: Array,

            /**
             * Form default value passed by parent
             */
            value: null,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value,
            };
        },

        watch: {

            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },
        },

    };

</script>
