/*-----------------------------------------------------------------
- Subscription Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Subscription  Category save
     *
     * @param {...any} args
     * @returns {*}
     */
    categorySave: (...args) => Resource.post('subscription-category-save', args),

    /**
     * Subscription  General settings fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    generalSettingsFetch: (...args) => Resource.get('subscription-general-settings-fetch', args),

    /**
     * Subscription  General settings save
     *
     * @param {...any} args
     * @returns {*}
     */
    generalSettingsSave: (...args) => Resource.post('subscription-general-settings-save', args),

    /**
     * Subscription  Seller info fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    sellerInfoFetch: (...args) => Resource.get('subscription-seller-info-fetch', args),

    /**
     * Subscription  Seller info save
     *
     * @param {...any} args
     * @returns {*}
     */
    sellerInfoSave: (...args) => Resource.post('subscription-seller-info-save', args),

    /**
     * Subscription  Subscription package fetch panel
     *
     * @param {...any} args
     * @returns {*}
     */
    packageFetchPanel: (...args) => Resource.get('subscription-package-fetch-panel', args),

    /**
     * Subscription  Subscription package save
     *
     * @param {...any} args
     * @returns {*}
     */
    packageSave: (...args) => Resource.post('subscription-package-save', args),
};
