/*-----------------------------------------------------------------
- Wares trans
-----------------------------------------------------------------*/
export default {
    
    tabs: {
        base     : 'Base',
        measure  : 'Measure',
        inventory: 'Inventory',
        price    : 'Price',
        sizes    : 'Sizes',
        payment  : 'Payment',
        settings : 'Settings',
        tagging  : 'Options',
        specs    : 'Specs',
    },

    fl_specs: {
        shapes_title           : 'Stock Shape Product',
        misc_title             : 'Misc',
        min                    : 'Minimum',
        max                    : 'Maximum',
        shape_sheet            : 'Sheet',
        shape_tape             : 'Tape',
        shape_rode             : 'Rode',
        shape_tube             : 'Tube',
        shape_end_shape        : 'End Shape',
        coefficient_of_friction: 'Coefficient of Friction',
        tensile_strength       : 'Tensile Strength',
        elongation_at_break    : 'Elongation at Break',
        density                : 'Density',
        fda_approval           : 'FDA Approval',
        rohs_number            : 'RoHS Number',
        rohs_compliance        : 'RoHS Compliance',
        wras_approval          : 'WRAS Approval',
        thermal                : 'Thermal',
        length                 : 'Length',
        width                  : 'Width',
        thickness              : 'Thickness',
        od                     : 'OD',
        id                     : 'ID',
    },
};
