/*-----------------------------------------------------------------
- Dashboard for Vuex
-----------------------------------------------------------------*/
/* eslint no-shadow: 0, no-param-reassign: 0, consistent-return: 0, no-confusing-arrow: 0 */
import Vue from 'vue';

const state = {
    manage: false,
};
const getters = {
    state : (state) => state,
    manage: (state) => state.manage,
};
const mutations = {
    
    /**
     * Toggle manage of dashboard
     *
     * @param state
     * @param status
     */
    toggleManage(state, status) {
        Vue.set(state, 'manage', status);
    },
    
};
const actions = {
    
    /**
     * Toggle manage of dashboard
     *
     * @param commit
     * @param commit.commit
     */
    toggleManage({ commit }) {
        commit('toggleManage');
    },
    
};

export default {
    
    namespaced: true,
    
    state,
    getters,
    mutations,
    actions,
};
