/*-----------------------------------------------------------------
- Content buttons
-----------------------------------------------------------------*/
export default {
    
    set_publish_date   : 'تنظیم تاریخ انتشار',
    change_publish_date: 'تغییر تاریخ انتشار',
    publish_now        : 'انتشار فوری',
    publish_scheduled  : 'انتشار زمان‌بندی‌شده',
    
};
