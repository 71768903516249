/* -----------------------------------------------------------------
- Cart trans
----------------------------------------------------------------- */
export default {

    title: 'سبد خرید',

    megamenu: {
        title: 'سبد خرید {0}',
    },

    order: {
        title: 'سفارش‌ها',
        name : 'سفارش',

        create: 'ثبت سفارش جدید',
        edit  : 'ویرایش سفارش',

        details              : 'پردازش سفارش',
        details_panel        : 'جزئیات سفارش',
        details_finance_panel: 'جزئیات مالی سفارش',

        status: {
            title         : 'تغییر وضعیت سفارش',
            status        : 'وضعیت سفارش',
            current_status: 'وضعیت فعلی سفارش',
            new_status    : 'وضعیت جدید سفارش',
        },

        message: {
            close: {
                success: 'سفارش با موفقیت بسته شد.',
            },

            reopen: {
                success: 'سفارش با موفقیت بازگشایی شد.',
            },
        },
    },
    pre: {
        title: 'پیش‌سفارش‌ها',
        name : 'پیش‌سفارش',

        create: 'ثبت پیش‌سفارش جدید',
        edit  : 'ویرایش پیش‌سفارش',

        details      : 'پردازش پیش‌سفارش',
        details_panel: 'جزئیات پیش‌سفارش',

        status: {
            title         : 'تغییر وضعیت پیش‌سفارش',
            status        : 'وضعیت پیش‌سفارش',
            current_status: 'وضعیت فعلی پیش‌سفارش',
            new_status    : 'وضعیت جدید پیش‌سفارش',
        },

        message: {
            convert: {
                success: 'پیش‌سفارش با موفقیت به سفارش تبدیل شد',
            },
        },
    },

    claim: {
        title: 'شکایت‌ها',
        name : 'شکایت',

        create: 'ثبت شکایت جدید',
        edit  : 'ویرایش شکایت',

        process          : 'پردازش شکایت',
        change_status    : 'تغییر وضعیت شکایت',
        claim_date       : 'تاریخ شکایت: {0}',
        claim_item       : 'آیتم‌های شکایت',
        empty_claim_items: 'هیچ آیتمی برای این شکایت وجود ندارد.',
        un_claimable     : 'امکان ثبت شکایت وجود ندارد.',

        inventory_process: {
            title        : 'پردازش کالا',
            select_code  : 'کد(های)‌ کالا را انتخاب کنید',
            returned_code: 'کد‌ کالا‌های برگشتی',
            replaced_code: 'کد کالا‌های جایگزین',
            no_code      : 'کدی یافت نشد.',

            alert: {
                count   : 'تعداد اقلام تایید‌شده نباید بیشتر از تعداد شکایت شده باشد. (اقلام شکایت شده: {0})',
                returned: 'تعداد اقلام مرجوعی نباید از تعداد اقلام تایید‌شده بیشتر باشد.',
                replaced: 'تعداد اقلام جایگزین نباید از تعداد اقلام مرجوعی بیشتر باشد.',
            },
        },

        close: {
            title : 'بستن شکایت',
            text  : 'آیا از بستن شکایت مطمئن هستید؟',
            button: 'بستن شکایت',
        },

        search: {
            first_use: 'با جستجوی سفارش، می‌توانید شکایت را ثبت کنید.',
            result   : 'نتایج جستجو برای: ',
            again    : 'بازگشت',
            no_result: 'سفارشی با این کد سفارش یافت نشد!',
        },

        message: {
            change_status: {
                prompt : 'آیا از تغییر وضعیت شکایت به {0} مطمئن هستید؟',
                success: 'تغییر وضعیت شکایت با موفقیت انجام شد.',
            },

            change_claim_payment: {
                success: 'وضعیت پرداخت شکایت از {0} به {1} تغییر کرد.',
            },

            close: {
                success: 'شکایت با موفقیت بسته شد.',
            },
        },
    },

    invoice: {
        title : 'فاکتور‌ها',
        prompt: {
            generate: {
                title: 'ایجاد فاکتور',
                text : 'آیا از ایجاد فاکتور برای این سفارش مطمئن هستید؟',
            },
            regenerate: {
                title: 'بازسازی فاکتور',
                text : 'آیا از بازسازی فاکتور برای این سفارش مطمئن هستید؟',
            },
        },

        message: {
            generate  : 'فاکتور با موفقیت ایجاد شد.',
            regenerate: 'فاکتور با موفقیت بازسازی شد.',
            error     : 'در انجام فرآیند فاکتور مشکلی پیش آمده است. دوباره تلاش کنید.',
        },
    },

    bill: {
        title: 'صورتحساب سفارش',
    },

    timeline: {
        title  : 'تاریخچه',
        details: 'جزئیات سبد خرید {0}',
        empty  : 'تاریخچه‌ای برای این سبد خرید وجود ندارد.',
        by     : 'توسط',
        filter : 'فیلتر تاریخچه',
        all    : 'نمایش همه',
    },

    panel: {
        comment: {
            title: 'یادداشت ضمیمه',

            message: {
                success: 'پیام شما با موفقیت ارسال شد.',
                error  : 'مشکلی در ارسال پیام ایجاد شده است.',
            },
        },

        address: {
            name  : 'آدرس',
            create: 'افزودن آدرس',
        },

        user: {
            title : 'مشتری',
            name  : 'کاربر',
            create: 'ثبت کاربر جدید',
        },

        shipping: {
            title              : 'حمل و نقل',
            no_shipping_methods: 'هیچ روش حمل و نقلی در دسترس نیست.',
        },

        ware: {
            title         : 'کالاها',
            search_ware   : 'جستجو و انتخاب کالا',
            empty_currency: 'برای افزودن کالا، ابتدا ارز را انتخاب کنید.',
            empty_ware    : 'اولین کالا را از طریق دکمه‌ی افزودن اضافه کنید.',
        },
    },

    coupon_processing: {
        title     : 'پردازش کوپن‌ها',
        new_coupon: 'کوپن جدید',

        message: {
            add_coupon: {
                success: 'کوپن با موفقیت افزوده شد.',
            },
            remove_coupon: {
                success: 'کوپن با موفقیت حذف گردید.',
            },
        },
    },

    payment_management: {
        title: 'مدیریت پرداخت‌ها',

        wallet_balance : 'موجودی کیف پول',
        no_transactions: 'هیچ تراکنشی برای این سفارش وجود ندارد.',

        method: {
            path  : 'مسیر پرداخت',
            wallet: 'کیف پول',
        },

        method_type: {
            online : 'آنلاین',
            offline: 'آفلاین',
        },

        type: {
            in : 'دریافت از مشتری',
            out: 'پرداخت به مشتری',
        },

        message: {
            success: 'تراکنش با موفقیت ثبت شد.',
        },
    },

    inventory_process: {
        title: 'پردازش انبار',

        not_enough_error : 'موجودی انبار برای کالاهای زیر کافی نیست',
        inventory_details: '(موجودی: {0} - درخواست: {1})',
        ok               : 'موجودی انبار برای کالاهای زیر کافی‌ست و به صورت خودکار کسر می‌شوند',
        no_code          : 'کدی یافت نشد.',
        not_enough       : '{0} کد دیگر باید انتخاب شود.',
        not_available    : 'بعضی اقلام انتخاب شده فروش رفته‌اند.',

        available_codes: 'کدهای موجود',
        selected       : 'انتخاب شده ({0})',

        to_inventory_link: 'برو به انبارداری',
    },

    shipping_processing: {
        title         : 'مدیریت روش حمل',
        address_empty : 'آدرس مشتری تکمیل نشده است.',
        change_invoice: 'با تغییر روش حمل، فاکتور این سفارش به‌روزرسانی خواهد شد.',

        message: {
            success: 'روش حمل با موفقیت تغییر کرد.',
        },
    },

    table: {
        row            : 'ردیف',
        ware           : 'کالا',
        dependent_wares: 'کالاهای وابسته',
        unique_wares   : 'کالاهای منحصر به فرد',
        other_wares    : 'سایر کالاها',
        codes          : 'کدهای انتخاب شده',
        count          : 'تعداد',
        raised_at      : 'زمان خرید',
        delivered_at   : 'زمان تحویل',
        effected_at    : 'زمان',
        title          : 'عنوان',
        order_number   : 'شماره سفارش',
        tracking_number: 'شماره پیگیری',
        receipt        : 'رسید پرداخت',
        status         : 'وضعیت',
        actions        : 'عملیات',

        customer: {
            name: 'نام مشتری',
        },

        claim: {
            count_on       : 'تعداد در شکایت',
            count_confirmed: 'تعداد تایید‌شده',
            reason         : 'دلیل',
            ok             : 'مجاز',
            not_ok         : 'غیرمجاز',
            raised_at      : 'زمان خرید',
        },

        payment: {
            method          : 'درگاه',
            method_type     : 'نوع درگاه',
            amount          : 'مبلغ',
            original_price  : 'قیمت',
            unit_price      : 'قیمت واحد',
            total_amount    : 'مبلغ کل',
            paid            : 'مبلغ پرداخت شده',
            payable         : 'مبلغ قابل پرداخت',
            paid_to_customer: 'پرداخت شده به مشتری',
            tax             : 'مالیات',
            tax_total       : 'جمع مالیات',
            unit_tax        : 'مالیات واحد',
            discount        : 'تخفیف',
            unit_discount   : 'تخفیف واحد',
            total_discount  : 'جمع تخفیف',
            overall_discount: 'تخفیف مازاد',
            coupon_discount : 'تخفیف کوپن',
            diff            : 'اختلاف',
            currency        : 'ارز',
            at              : 'تاریخ پرداخت',
            status          : 'وضعیت پرداخت',
            type            : 'نوع پرداخت',
            order_total     : 'مبلغ سفارش',
            shipping        : 'هزینه حمل و نقل',
            wares_discount  : 'تخفیف کالا‌ها',
            coupons_discount: 'جمع تخفیف کوپن‌ها',
            coupons         : {
                code    : 'کوپن',
                discount: 'مبلغ تخفیف',
            },
            shipping_cost: 'هزینه حمل',
        },

        coupon: {
            rulebook: 'سناریو',
            code    : 'کد کوپن',
            discount: 'تخفیف',
        },
    },

    widget: {
        empty: 'به زودی در این مکان ویجت نصب می‌گردد! 😬',
    },

    actions: {
        details            : 'جزئیات',
        edit               : 'ویرایش',
        print              : 'چاپ',
        change_status      : 'تغییر وضعیت',
        inventory          : 'پردازش انبار',
        order_close        : 'بستن سفارش',
        order_reopen       : 'باز کردن سفارش',
        claim_close        : 'بستن شکایت',
        payment            : 'مدیریت پرداخت',
        convert_to_order   : 'تبدیل به سفارش',
        coupon_processing  : 'پردازش کوپن',
        add_coupon         : 'افزودن کوپن',
        shipping_processing: 'پردازش روش حمل',
        ware_process       : 'پردازش کالا',
        view_receipt       : 'مشاهده رسید',
        invoice            : {
            generate    : 'ایجاد فاکتور',
            regenerate  : 'بازسازی فاکتور',
            view        : 'مشاهده و چاپ فاکتور',
            view_invoice: 'مشاهده فاکتور',
        },
    },

    prompt: {
        close_caption  : 'آیا از بستن سفارش اطمینان دارید؟',
        open_caption   : 'آیا از باز کردن سفارش اطمینان دارید؟',
        convert_caption: 'پیش‌سفارش به سفارش تبدیل خواهد شد.',
        convert_title  : 'تبدیل به سفارش',
    },

    message: {
        change_status: {
            success: 'وضعیت با موفقیت به {0} تغییر کرد.',
        },
        convert: {
            success: 'پیش‌سفارش با موفقیت به سفارش تبدیل شد.',
        },
    },

    nodes: {
        order_edited: {
            payable_amount  : 'مبلغ قابل پرداخت از «{0}» به «{1}» تغییر یافت.',
            overall_discount: 'جمع تخفیف از «{0}» به «{1}» تغییر یافت.',
            title_changed   : 'عنوان از «{0}» به «{1}» تغییر یافت.',
            item_added      : '«{0}» به سفارش اضافه شد.',
            item_removed    : '«{0}» از سفارش حذف شد.',
            item_edit       : 'تعداد «{0}» از «{1}» به «{2}» تغییر یافت.',
            exp_date_changed: 'تاریخ اعتبار از «{0}» به «{1}» تغییر یافت.',
        },
        claim_edited: {
            item_counts_changed: 'تعداد آیتم‌های شکایت شده از {0} به {1} تغییر کرد.',
            item_added         : '«{0}» به شکایت اضافه شد.',
            item_removed       : '«{0}» از شکایت حذف شد.',
            item_edit_count    : 'تعداد شکایت شده آیتم «{0}» از «{1}» به «{2}» تغییر کرد.',
            item_edit_reason   : 'دلیل شکایت آیتم «{0}» از «{1}» به «{2}» تغییر کرد.',
        },
        payment_paid: {
            text: 'تراکنش از نوع «{0}» به مبلغ {1} {2}، از درگاه «{3}» انجام گردید.',
        },
        order_invoice: {
            generated  : 'فاکتور شماره {0} ایجاد شد.',
            regenerated: 'فاکتور شماره {0} بازسازی شد.',
            returned   : 'فاکتور شماره {0} برگشت خورد.',
            canceled   : 'فاکتور شماره {0} ابطال شد.',
            documented : 'فاکتور سند شماره {0} زده شد.',
        },
        coupon: {
            used              : 'کد کوپن {0} از سناریو {1} اعمال شد.',
            unused            : 'کد کوپن {0} از سناریو {1} حذف شد.',
            coupon_discount   : 'تخفیف کوپن',
            old_payable_amount: 'مبلغ قابل پرداخت قبلی',
            new_payable_amount: 'مبلغ قابل پرداخت جدید',
            old_payable_status: 'وضعیت پرداخت قبلی',
            new_payable_status: 'وضعیت پرداخت جدید',
        },

        shipping_used: {
            text: 'روش حمل «{0}»',
            fee : ' با مبلغ «{0}»',
            for : ' برای «{0}»',
            verb: ' ثبت شد.',
        },

        undefined_node: 'نود {0} برای این سفارش وجود ندارد.',
    },

    details: {
        customer: {
            name    : 'نام مشتری',
            tel     : 'تلفن مشتری',
            address : 'نشانی مشتری',
            city    : '{0}، ',
            province: '{0}، ',
        },

        order: {
            status     : 'وضعیت سفارش',
            create_date: 'تاریخ ثبت سفارش',
        },

        pre: {
            status     : 'وضعیت پیش‌سفارش',
            create_date: 'تاریخ ثبت پیش‌سفارش',
            expire_date: 'تاریخ انقضای پیش‌سفارش',
        },

        change_status_date         : 'زمان آخرین تغییر وضعیت',
        change_status_address_empty: 'روش حمل انتخاب نشده است. لطفا ابتدا روش حمل را انتخاب کنید.',

        shipping: {
            delivery_date         : 'موعد تحویل',
            delivery_interval     : 'بازه‌ی تحویل',
            delivery_interval_mask: '{0} تا {1}',
        },
    },
};
