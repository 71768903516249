/*-----------------------------------------------------------------
- Coupons trans
-----------------------------------------------------------------*/
export default {

    title: {
        create: 'ایجاد فروش ویژه جدید',
        edit  : 'ویرایش فروش ویژه',
    },

    editor: {
        base: {
            panel: 'عنوان',
        },

        amount: {
            panel         : 'میزان تخفیف',
            fee           : 'مقدار تخفیف',
            fee_number    : 'مبلغ تخفیف',
            percent       : 'درصد تخفیف',
            percent_number: 'درصد',
            currency      : 'ارز',
        },

        slug: {
            panel: 'نامک',
        },

        date: {
            panel    : 'تاریخ',
            starts_at: 'تاریخ شروع',
            ends_at  : 'تاریخ پایان',
        },

        category: {
            panel        : 'دسته‌بندی و محصولات',
            categories   : 'لیست دسته‌بندی‌ها',
            type         : 'انتخاب فروشگاه',
            empty_cat    : 'دسته‌بندی وجود ندارد.',
            empty_product: 'محصولی وجود ندارد.',
            init_product : 'برای مشاهده محصول دسته‌بندی را انتخاب کنید یا جستجو نمایید.',
            search       : {
                category: 'جستجوی دسته‌بندی',
                product : 'جستجوی محصول',
            },
        },

        product: {
            products: 'لیست محصولات',
            title   : {
                category_selected: 'محصولات {0}',
                search           : 'نتایج جستجو در تمامی محصولات',
            },
        },

        messages: {
            success: 'فروش ویژه با موفقیت ذخیره شد.',
        },
    },
};
