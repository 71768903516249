/*-----------------------------------------------------------------
- Fields translations
-----------------------------------------------------------------*/
export default {
    calendar: {
        setting: {
            starts_at        : 'Starts At',
            ends_at          : 'Ends At',
            opening_time     : 'Opening Time',
            closing_time     : 'Closing Time',
            off_days         : 'Off Days',
            off_week_days    : 'Off days of the week',
            show_months_limit: 'Months Limit',
        },
    },
};
