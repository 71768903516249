<template>
    <div class="grid-404">
        <img
            src="@/assets/images/404.svg"
            alt="ERROR 404"
        >
        <p>{{ $t('errors.404.title') }}</p>
        <y-button @click.native="$router.go(-1)">
            {{ $t('button.back') }}
        </y-button>
    </div>
</template>

<script>
    export default {
        name: 'ErrorNotFound',
    };
</script>
