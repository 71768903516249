/*-----------------------------------------------------------------
- Shipping trans
-----------------------------------------------------------------*/
export default {
    
    yasnateam: {
        single: 'Online Brief',
    },
    
};
