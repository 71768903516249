/*-----------------------------------------------------------------
- User Routes
-----------------------------------------------------------------*/
const prefix = 'coupons';

const routes = [
    // Create
    {
        path     : `${prefix}/create`,
        name     : `${prefix}-create`,
        component: () => import(/* webpackChunkName: "coupons" */ '@/modules/coupons/views/Edit'),
    },
    
    // Edit
    {
        path     : `${prefix}/:id/edit`,
        name     : `${prefix}-edit`,
        component: () => import(/* webpackChunkName: "coupons" */ '@/modules/coupons/views/Edit'),
    },
];

export default routes;
