<template>
    <div>
        <y-dropdown
            v-if="element.children && element.children.length"
            ref="Dropdown"
            position="bottom-left"
            class="user-dropdown"
        >
            <template #toggle>
                <button
                    class="button"
                    type="button"
                >
                    {{ element.text }}
                </button>
            </template>

            <y-form-link
                v-for="item in sortByOrder(element.children)"
                :key="`grid-header-button-${item.id}`"
                :element="item"
                type="link"
            >
                <i
                    v-if="item.hasOwnProperty('icon') && item.icon.length"
                    class="icon"
                    :class="item.icon"
                />
                {{ item.label }}
            </y-form-link>
        </y-dropdown>
    </div>
</template>

<script>

    import YDropdown from '@deps/Dropdown';
    import YFormLink from '@deps/form/elements/Link';
    import GlobalMixin from '@/mixins/Global';

    export default {

        name: 'YFormDropdown',

        components: {
            YDropdown,
            YFormLink,
        },

        mixins: [GlobalMixin],

        props: {
            /**
             * Element
             */
            element: Object,
        },

        methods: {

            /**
             * Get type of the link
             *
             * @param link
             * @returns {string}
             */
            linkType(link) {
                if (typeof link === 'object') {
                    if (link.hasOwnProperty('action')) {
                        return 'action-link';
                    }
                    if (link.hasOwnProperty('name')) {
                        return 'router-link';
                    }
                }

                return 'anchor';
            },

            /**
             * Get type of the link
             *
             * @param link
             * @returns {string}
             */
            buttonTag(link) {
                if (typeof link === 'object') {
                    if (link.hasOwnProperty('action')) {
                        return 'button';
                    }
                    if (link.hasOwnProperty('name')) {
                        return 'router-link';
                    }
                }

                return 'a';
            },

            /**
             * Get target of anchor link
             *
             * @param link
             */
            linkTarget(link) {
                return this.linkType(link) === 'anchor' ? link.target || '_self' : null;
            },

            /**
             * Get link of anchor
             *
             * @param link
             */
            linkHref(link) {
                return this.linkType(link) === 'anchor' ? link.url || link || '#' : null;
            },

            /**
             * Handle action of button
             *
             * @param link
             */
            handleAction(link) {
                if (this.link.action === 'openModal' && typeof this.openGlobalModal === 'function') {
                    this.openGlobalModal(link.options.id, link.params);
                } else if (this.$parent.hasOwnProperty(link.action)) {
                    this.$parent[link.action](link.params);
                }
                return () => {
                };
            },

            /**
             * Sort by order
             *
             * @param list
             * @returns {*[]}
             */
            sortByOrder(list) {
                return Object.keys(list).sort((a, b) => list[a].order - list[b].order).map((category) => list[category]);
            },

            /**
             * Get type of dropdown
             *
             * @param type
             * @returns {string|boolean}
             */
            getDropdownType(type = 'item') {
                if (type === 'item') {
                    return 'router-link';
                }

                if (type === 'header') {
                    return 'header';
                }

                if (type === 'divider') {
                    return 'divider';
                }

                return false;
            },

            /**
             * Generate link object to use in router link
             *
             * @param item
             * @returns {{query: null, name: (*|null), params: (*|null)}|null}
             */
            generateLink(item) {
                if (Array.isArray(item.link) || typeof item.link === 'string') {
                    return '#';
                }
                return item.link;
            },

            /**
             * Opens dropdown list and emit relevant event
             */
            open() {
                this.$refs.Dropdown.open();
            },

            /**
             * Closes dropdown list and emit relevant event
             */
            close() {
                this.$refs.Dropdown.close();
            },
        },

    };
</script>
