/*-----------------------------------------------------------------
- Arena Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Arena Order Save
     *
     * @param {...any} args
     * @returns {*}
     */
    orderSave: (...args) => Resource.post('arena-order-save', args),

    /**
     * Arena Order Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    orderFetch: (...args) => Resource.get('arena-order-fetch', args),

    /**
     * Arena Border Report
     *
     * @param {...any} args
     * @returns {*}
     */
    borderReport: (...args) => Resource.post('arena-border-report', args),

    /**
     * Arena Arrival Report
     *
     * @param {...any} args
     * @returns {*}
     */
    arrivalReport: (...args) => Resource.post('arena-arrival-report', args),

    /**
     * Arena Order Status Change
     *
     * @param {...any} args
     * @returns {*}
     */
    orderStatusChange: (...args) => Resource.post('arena-order-status-change', args),

    /**
     * Arena Order Details
     *
     * @param {...any} args
     * @returns {*}
     */
    orderDetails: (...args) => Resource.get('arena-order-details', args),

    /**
     * Arena Comment Save
     *
     * @param {...any} args
     * @returns {*}
     */
    orderComment: (...args) => Resource.post('arena-order-comment', args),

    /**
     * Arena Form download excel file
     *
     * @param {...any} args
     * @returns {*}
     */
    orderExcelDownload: (...args) => Resource.post('arena-order-excel-download', args),
};
