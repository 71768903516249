/*-----------------------------------------------------------------
- Panel Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Panel Admin Layout
     *
     * @param {...any} args
     * @returns {*}
     */
    layout: (...args) => Resource.get('panel-layout', args),
    
    /**
     * Panel Modules List
     *
     * @param {...any} args
     * @returns {*}
     */
    modules: (...args) => Resource.get('panel-modules', args),

    /**
     * Panel Components preview
     *
     * @param {...any} args
     * @returns {*}
     */
    componentsPreview: (...args) => Resource.get('panel-components-preview', args),

    /**
     * Panel Widgets Repo
     *
     * @param {...any} args
     * @returns {*}
     */
    widgetsRepo: (...args) => Resource.get('panel-widgets-repo', args),

    /**
     * Panel Widgets List
     *
     * @param {...any} args
     * @returns {*}
     */
    widgetsList: (...args) => Resource.get('panel-widgets-list', args),

    /**
     * Panel Widget Insert
     *
     * @param {...any} args
     * @returns {*}
     */
    widgetInsert: (...args) => Resource.post('panel-widget-insert', args),

    /**
     * Panel Widget Sort
     *
     * @param {...any} args
     * @returns {*}
     */
    widgetsSort: (...args) => Resource.put('panel-widgets-sort', args),

    /**
     * Panel Widget Settings Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    widgetSettingsFetch: (...args) => Resource.get('panel-widget-settings-fetch', args),

    /**
     * Panel Widget Settings Save
     *
     * @param {...any} args
     * @returns {*}
     */
    widgetSettingsSave: (...args) => Resource.put('panel-widget-settings-save', args),

    /**
     * Panel Widget Content
     *
     * @param {...any} args
     * @returns {*}
     */
    widgetContent: (...args) => Resource.get('panel-widget-content', args),

    /**
     * Panel Widget Content
     *
     * @param {...any} args
     * @returns {*}
     */
    helpIsSeen: (...args) => Resource.get('panel-help-is-seen', args),
    
    /**
     * Panel Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    grid: (...args) => Resource.get('panel-grid', args),
};
