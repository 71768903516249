/*-----------------------------------------------------------------
- Slideshows translations
-----------------------------------------------------------------*/
export default {
    
    preview   : 'Preview',
    page_title: 'Manage slides',
    slide     : 'Slide',
    addItem   : 'A new slide added to the list.',
    empty     : 'No slide has found.',
    delete    : {
        title  : 'Delete Slide',
        message: 'Are you sure you want to delete this slide?',
    },
    empty_preview    : 'No slide for preview has found.',
    style_differences: 'Slideshow may have different styles in real theme',
};
