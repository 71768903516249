/* -------------------------------------------------------------------------- */
/* COMPONENTS: Register Globally                                              */
/* -------------------------------------------------------------------------- */
// eslint-disable-next-line no-unused-vars
import Vue from 'vue';

// DEPS: Deprecated Components Register from `@deps`
import {
    YButton, YPanel, YLoading, YPageHead, YRow, YCol,
} from '@deps/index';


// %import%


// %register%

// DEPS: Deprecated Components Register from `@deps`
Vue.component('YButton', YButton);
Vue.component('YPanel', YPanel);
Vue.component('YLoading', YLoading);
Vue.component('YPageHead', YPageHead);
Vue.component('YRow', YRow);
Vue.component('YCol', YCol);
