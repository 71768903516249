/*-----------------------------------------------------------------
- Cart Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {

    /**
     * Cart Panel Menu
     *
     * @param {...any} args
     * @returns {*}
     */
    panelMenu: (...args) => Resource.get('cart-panel-menu', args),
    
    /**
     * Cart Pre Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    preOrdersGrid: (...args) => Resource.get('cart-pre-orders-grid', args),
    
    /**
     * Cart Order Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    orderGrid: (...args) => Resource.get('cart-orders-grid', args),
    
    /**
     * Cart Claim Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    claimGrid: (...args) => Resource.get('cart-claim-grid', args),
    
    /**
     * Cart Claim Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    claimFetch: (...args) => Resource.get('cart-claim-fetch', args),
    
    /**
     * Cart Invoice Grid
     *
     * @param {...any} args
     * @returns {*}
     */
    invoicesGrid: (...args) => Resource.get('cart-invoices-grid', args),
    
    /**
     * Cart Order Get
     *
     * @param {...any} args
     * @returns {*}
     */
    orderGet: (...args) => Resource.get('cart-order-get', args),
    
    /**
     * Cart Order Fetch Form
     *
     * @param {...any} args
     * @returns {*}
     */
    orderCreateFetch: (...args) => Resource.get('cart-order-create-fetch', args),
    
    /**
     * Cart Order Fetch Form
     *
     * @param {...any} args
     * @returns {*}
     */
    orderFetch: (...args) => Resource.get('cart-order-fetch', args),
    
    /**
     * Cart Order Save
     *
     * @param {...any} args
     * @returns {*}
     */
    orderSave: (...args) => Resource.post('cart-order-save', args),
    
    /**
     * Cart Order Save
     *
     * @param {...any} args
     * @returns {*}
     */
    orderSearch: (...args) => Resource.get('cart-order-search', args),
    
    /**
     * Cart Pre Order Fetch Form
     *
     * @param {...any} args
     * @returns {*}
     */
    preOrderFetchForm: (...args) => Resource.get('cart-pre-order-fetch-form', args),
    
    /**
     * Cart Pre Order Fetch Form
     *
     * @param {...any} args
     * @returns {*}
     */
    preOrderChangeStatus: (...args) => Resource.post('cart-pre-order-change-status', args),
    
    /**
     * Cart Pre Order Save
     *
     * @param {...any} args
     * @returns {*}
     */
    preOrderSave: (...args) => Resource.post('cart-pre-order-save', args),
    
    /**
     * Cart Claim save
     *
     * @param {...any} args
     * @returns {*}
     */
    claimSave: (...args) => Resource.post('cart-claim-save', args),
    
    /**
     * Cart Details
     *
     * @param {...any} args
     * @returns {*}
     */
    orderTimeline: (...args) => Resource.get('cart-order-timeline', args),
    
    /**
     * Cart Details
     *
     * @param {...any} args
     * @returns {*}
     */
    orderDetails: (...args) => Resource.get('cart-order-details', args),
    
    /**
     * Cart User Search
     *
     * @param {...any} args
     * @returns {*}
     */
    userSearch: (...args) => Resource.get('cart-user-search', args),
    
    /**
     * Cart Ware Search
     *
     * @param {...any} args
     * @returns {*}
     */
    wareSearch: (...args) => Resource.get('cart-ware-search', args),
    
    /**
     * Cart Inventory Processing Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    inventoryProcessingFetch: (...args) => Resource.get('cart-inventory-processing-fetch', args),
    
    /**
     * Cart Inventory Processing Save
     *
     * @param {...any} args
     * @returns {*}
     */
    inventoryProcessingSave: (...args) => Resource.post('cart-inventory-processing-save', args),
    
    /**
     * Cart Inventory Processing Save
     *
     * @param {...any} args
     * @returns {*}
     */
    orderChangeStatusDelivered: (...args) => Resource.post('cart-order-change-status-delivered', args),
    
    /**
     * Cart Inventory Processing Save
     *
     * @param {...any} args
     * @returns {*}
     */
    orderChangeStatusConfirmed: (...args) => Resource.post('cart-order-change-status-confirmed', args),

    /**
     * Cart Inventory Processing Save
     *
     * @param {...any} args
     * @returns {*}
     */
    orderChangeStatusConstructed: (...args) => Resource.post('cart-order-change-status-constructed', args),

    /**
     * Cart Inventory Processing Save
     *
     * @param {...any} args
     * @returns {*}
     */
    orderChangeStatusSent: (...args) => Resource.post('cart-order-change-status-sent', args),

    /**
     * Cart Inventory Processing Save
     *
     * @param {...any} args
     * @returns {*}
     */
    orderChangeStatusPrepared: (...args) => Resource.post('cart-order-change-status-prepared', args),

    /**
     * Cart Inventory Processing Save
     *
     * @param {...any} args
     * @returns {*}
     */
    orderChangeStatusCancelled: (...args) => Resource.post('cart-order-change-status-cancelled', args),

    /**
     * Cart Invoice Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    invoiceFetch: (...args) => Resource.get('cart-invoice-fetch', args),

    /**
     * Cart Claim Orders Search
     *
     * @param {...any} args
     * @returns {*}
     */
    claimOrdersSearch: (...args) => Resource.get('cart-claim-orders-search', args),

    /**
     * Cart Claim Order Get
     *
     * @param {...any} args
     * @returns {*}
     */
    claimOrderGet: (...args) => Resource.get('cart-claim-order-get', args),

    /**
     * Cart Order Comment
     *
     * @param {...any} args
     * @returns {*}
     */
    orderComment: (...args) => Resource.post('cart-order-comment', args),

    /**
     * Cart Status Form Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    orderStatusFetch: (...args) => Resource.get('cart-order-status-fetch', args),

    /**
     * Cart Status Form Submission
     *
     * @param {...any} args
     * @returns {*}
     */
    orderStatusSet: (...args) => Resource.put('cart-order-status-set', args),
    
    /**
     * Cart Payment Processing Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    paymentProcessingFetch: (...args) => Resource.get('cart-payment-processing-fetch', args),
    
    /**
     * Cart Payment Processing Save
     *
     * @param {...any} args
     * @returns {*}
     */
    paymentProcessingSave: (...args) => Resource.post('cart-payment-processing-save', args),
    
    /**
     * Cart Payment Status Change
     *
     * @param {...any} args
     * @returns {*}
     */
    paymentStatusChange: (...args) => Resource.post('cart-payment-status-change', args),


    /**
     * Cart Order Close Process
     *
     * @param {...any} args
     * @returns {*}
     */
    orderClose: (...args) => Resource.post('cart-order-close', args),

    /**
     * Cart Order Reopen Process
     *
     * @param {...any} args
     * @returns {*}
     */
    orderReopen: (...args) => Resource.post('cart-order-reopen', args),

    /**
     * Cart Order Reopen Process
     *
     * @param {...any} args
     * @returns {*}
     */
    invoiceSave: (...args) => Resource.post('cart-invoice-save', args),

    /**
     * Cart Order Finder by Tracking number
     *
     * @param {...any} args
     * @returns {*}
     */
    orderTrack: (...args) => Resource.get('cart-order-track', args),

    /**
     * @param {...any} args
     * @returns {*}
     */
    preOrderConvert: (...args) => Resource.put('cart-pre-order-convert', args),

    /**
     * Cart Claim status change
     * 
     * @param {...any} args
     * @returns {*}
     */
    claimStatusChange: (...args) => Resource.post('cart-claim-status-change', args),

    /**
     * Cart Claim Fetch Per Item
     * 
     * @param {...any} args
     * @returns {*}
     */
    claimItemFetch: (...args) => Resource.get('cart-claim-item-fetch', args),

    /**
     * Cart Claim Processing Per Item
     * 
     * @param {...any} args
     * @returns {*}
     */
    claimItemProcessing: (...args) => Resource.post('cart-claim-item-processing', args),

    /**
     * Cart Claim Payment Processing Fetch
     * 
     * @param {...any} args
     * @returns {*}
     */
    claimPaymentProcessingFetch: (...args) => Resource.get('cart-claim-payment-processing-fetch', args),

    /**
     * Cart Claim Payment Processing Save
     * 
     * @param {...any} args
     * @returns {*}
     */
    claimPaymentProcessingSave: (...args) => Resource.post('cart-claim-payment-processing-save', args),

    /**
     * Cart Claim Payment Processing Change Status
     * 
     * @param {...any} args
     * @returns {*}
     */
    claimPaymentStatusChange: (...args) => Resource.post('cart-claim-payment-status-change', args),

    /**
     * Cart Coupon details fetch
     *
     * @param {...any} args
     */
    couponProcessingFetch: (...args) => Resource.get('cart-coupon-processing-fetch', args),

    /**
     * Cart Coupon details save
     *
     * @param {...any} args
     */
    couponProcessingSave: (...args) => Resource.post('cart-coupon-processing-save', args),
    
    /**
     * remove coupon from cart
     *
     * @param {...any} args
     */
    OrderCouponMarkAsUnused: (...args) => Resource.post('cart-order-coupon-mark-as-unused', args),
    
    /**
     * Cart Claim Finalization
     *
     * @param {...any} args
     */
    claimFinalization: (...args) => Resource.post('cart-claim-finalization', args),
    
    /**
     * Cart Shipping Processing Fetch
     *
     * @param {...any} args
     */
    shippingProcessingFetch: (...args) => Resource.get('cart-shipping-processing-fetch', args),
    
    /**
     * Cart Shipping Processing Save
     *
     * @param {...any} args
     */
    shippingProcessingSave: (...args) => Resource.post('cart-shipping-processing-save', args),

    /**
     * Cart Ware details fetch
     *
     * @param {...any} args
     */
    wareFetch: (...args) => Resource.get('cart-ware-fetch', args),

    /**
     * Cart User addresses fetch
     *
     * @param {...any} args
     */
    userAddressList: (...args) => Resource.get('cart-user-address-list', args),
};
