/*-----------------------------------------------------------------
- Arena Routes
-----------------------------------------------------------------*/
const prefix = 'arena';
const routes = [
    {
        path     : `${prefix}/details/:id`,
        name     : 'arena-details',
        component: () => import(/* webpackChunkName: "arena" */ '@/modules/arena/views/Timeline'),
    },
    {
        path     : `${prefix}/forms/:type/:id`,
        name     : 'arena-forms',
        component: () => import(/* webpackChunkName: "arena" */ '@/modules/arena/views/Forms'),
    },
    {
        path     : `${prefix}/create`,
        name     : 'arena-create',
        component: () => import(/* webpackChunkName: "arena" */ '@/modules/arena/views/Create'),
    },
    {
        path     : `${prefix}/edit/:id`,
        name     : 'arena-edit',
        component: () => import(/* webpackChunkName: "arena" */ '@/modules/arena/views/Create'),
    },
];

export default routes;
