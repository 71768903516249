/*-----------------------------------------------------------------
- Roles trans
-----------------------------------------------------------------*/
export default {
    fields: {
        title       : 'مدیریت فیلدهای {0}',
        title_simple: 'مدیریت فیلد',

        settings: {
            title          : 'تنظیمات',
            division       : 'تقسیمات کشوری',
            others         : 'سایر',
            from           : 'از',
            to             : 'تا',
            division__alert: 'این تنظیمات در صورت انتخاب فیلد آدرس اعمال خواهند شد.',
            all_role_alert : 'این تنظیمات برای تمام نقش‌ها یکسان است.',
            no_field       : 'فیلدی به این اسم یافت نشد.',
            messages       : {
                success: 'تنظیمات با موفقیت ذخیره شد.',
                save   : {
                    success: 'فیلد‌ها با موفقیت ذخیره شد.',
                },
            },
        },

        actions: {
            settings: 'تنظیمات',
        },
    },
};
