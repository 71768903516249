/**
 * Dropdown Item from dropdown list items
 */
export default {
    name: 'DropdownItem',
    
    props: {
        
        /**
         * Dropdown Item type
         *
         * "anchor", "header", "divider", "router-link"
         */
        type: {
            type   : String,
            default: 'anchor',
        },
        
        /**
         * Router link `to` object
         */
        to: {
            type: Object,
        },
        
        /**
         * Label text
         */
        label: {
            type: String,
        },
        
        /**
         * Label has badge style
         */
        badge: {
            type: Boolean,
        },
        
        /**
         * label Color
         */
        labelColor: {
            type   : String,
            default: '',
        },
        
    },
    
    computed: {
        
        /**
         * Computes proper HTML element based on type
         *
         * @returns {string}
         */
        tag() {
            if (this.type === 'anchor') {
                return 'a';
            }
            
            if (this.type === 'header') {
                return 'span';
            }
            
            if (this.type === 'divider') {
                return 'span';
            }
            
            if (this.type === 'router-link') {
                return 'router-link';
            }
            
            return 'span';
        },
        
        /**
         * Computes label classes
         *
         * @returns {string}
         */
        labelClass() {
            const classes = this.labelColor;
            
            if (this.badge) {
                return `label badge rounded ${classes}`;
            }
            
            return `label xs ${classes}`;
        },
    },
    
    /**
     * Renders dropdown element
     *
     * @param h
     * @returns {*}
     */
    render(h) {
        const vm = this;
        
        return h(
            this.tag,
            {
                attrs: {
                    class: this.type,
                    to   : this.to,
                },
                
                nativeOn: {
                    /**
                     * Emit close event to parent
                     */
                    click() {
                        vm.$parent.$emit('close');
                    },
                },
                
                on: {
                    /**
                     * Emit close event to parent
                     */
                    click() {
                        vm.$parent.$emit('close');
                    },
                },
                
            },
            [
                this.$slots.default,
                
                (this.label)
                    ? h('span', {
                        class: this.labelClass,
                    }, [
                        this.label,
                    ]) : '',
            ],
        );
    },
};
