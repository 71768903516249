/*-----------------------------------------------------------------
- Button translations
-----------------------------------------------------------------*/

export default {
    
    specs: {
        add_group: 'Add Group',
        add_spec : 'Add Item',
    },
    
};
