/*-----------------------------------------------------------------
- Setting Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Setting  Get
     *
     * @param {...any} args
     * @returns {*}
     */
    get: (...args) => Resource.get('setting-get', args),
    
};
