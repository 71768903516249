/*-----------------------------------------------------------------
- Cart trans
-----------------------------------------------------------------*/
export default {

    note      : 'یادداشت',
    is_private: 'محرمانه',
    reason    : 'دلیل',
    
    order_user_search: 'جستجو با نام، تلفن همراه، ایمیل',
    user             : 'نام مشتری',
    address          : 'آدرس',
    
    raised_at : 'تاریخ ایجاد',
    expires_at: 'تاریخ اعتبار',

    damaged        : 'آیا آسیب داشته است؟',
    damaged_reason : 'شرح آسیب',
    count          : 'تعداد',
    recycled_amount: 'قیمت بازیافت',

    comment: 'یادداشت',
    
    search_code : 'جستجوی کد',
    search_order: 'جستجوی سفارش',
    
    payment_management: {
        methods        : 'نوع درگاه',
        paths          : 'مسیر پرداخت',
        wallets        : 'کیف پول',
        tracking_number: 'شماره پیگیری',
        effected_at    : 'تاریخ',
        amount         : 'مبلغ',
        type           : 'نوع تراکنش',
        receipt        : 'رسید پرداخت',
    },

    shipping_method: 'روش حمل',
    shipping_date  : 'روز حمل',
    shipping_time  : 'ساعت حمل',
};
