/*-----------------------------------------------------------------
- Toast translations
-----------------------------------------------------------------*/

export default {

    faqs: {

        save: {
            success: 'پرسش‌های متداول با موفقیت ذخیره شد.',
            error  : 'مشکلی در ذخیره اسلایدشو به وجود آمده است.',
        },

        trash: {
            success: 'پرسش با موفقیت غیرفعال شد.',
            error  : 'مشکلی در غیرفعال کردن پرسش به وجود آمده است.',
        },

        restore: {
            success: 'پرسش با موفقیت فعال شد.',
            error  : 'مشکلی در فعال کردن پرسش به وجود آمده است.',
        },

        destroy: {
            success: 'پرسش با موفقیت حذف شد.',
            error  : 'مشکلی در حذف کردن پرسش به وجود آمده است.',
        },

        update: {
            success: 'پرسش با موفقیت بروزرسانی شده‌اند.',
            error  : 'مشکلی در بروزرسانی پرسش به وجود آمده است.',
        },

    },
    
    slideshow: {
        
        save: {
            success: 'اسلایدشو با موفقیت ذخیره شد.',
            error  : 'مشکلی در ذخیره اسلایدشو به وجود آمده است.',
        },
        
        trash: {
            success: 'اسلاید با موفقیت غیرفعال شد.',
            error  : 'مشکلی در غیرفعال کردن اسلاید به وجود آمده است.',
        },
        
        restore: {
            success: 'اسلاید با موفقیت فعال شد.',
            error  : 'مشکلی در فعال کردن اسلاید به وجود آمده است.',
        },
        
        destroy: {
            success: 'اسلاید با موفقیت حذف شد.',
            error  : 'مشکلی در حذف کردن اسلاید به وجود آمده است.',
        },
        
        update: {
            success: 'اسلایدشو با موفقیت بروزرسانی شده‌اند.',
            error  : 'مشکلی در بروزرسانی اسلایدشو به وجود آمده است.',
        },
        
    },

};
