/*-----------------------------------------------------------------
- FAQs Routes
-----------------------------------------------------------------*/
const prefix = 'faqs';

const routes = [
    {
        path     : `content/${prefix}/manage/:id`,
        name     : `content-themes-${prefix}-manage`,
        component: () => import(/* webpackChunkName: "content" */ '@/modules/content-themes/views/faqs/Manage'),
    },
    {
        path     : `content/${prefix}/:type`,
        name     : `content-themes-${prefix}-list`,
        component: () => import(/* webpackChunkName: "content" */ '@/modules/content-themes/views/faqs/List'),
    },
];

export default routes;
