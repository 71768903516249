/*-----------------------------------------------------------------
- Theme global mixin
-----------------------------------------------------------------*/
import Vue from 'vue';

Vue.mixin({
    name: 'ThemeMixin',

    methods: {
        /**
         * Get a version of image if possible.
         *
         * @param {object} item
         * @param {string} version
         * @returns {*}
         */
        image(item, version = 'panel_thumb') {
            return this.get(item, `versions.${version}`, null);
        },

        /**
         * Return feature image src
         *
         * @param {object} post
         * @param {string} version
         * @returns {*}
         */
        featureImage(post, version = 'panel_thumb') {
            return this.image(this.get(post, 'image'), version);
        },

        /**
         * Return header image src.
         *
         * @param {object} post
         * @param {string} version
         * @returns {*}
         */
        headerImage(post, version = 'panel_thumb') {
            return this.image(this.get(post, 'header_image'), version);
        },

        /**
         * Fetch menus based on `base.menus` config and save on the Vuex store
         */
        fetchMenus() {
            const slugs = this.$config('base.menus');
            if (Array.isArray(slugs) && slugs.length) {
                this.$wait.start('fetching-menus');
                const filteredSlugs = slugs.map((slug) => slug.replace('$', this.$i18n.locale));
                const including = ['menus', 'slug'];
                this.$services.MenuMaker.getLocationMenus({
                    slugs: filteredSlugs,
                    including,
                }).then((response) => {
                    const locations = {};
                    response.data.results.forEach((item) => {
                        locations[item.slug] = item.menus;
                    });
                    this.$store.dispatch('menu/set', locations);
                }).finally(() => this.$wait.end('fetching-menus'));
            }
        },

        /**
         * Get menu by slug from the Vuex store.
         *
         * @param {string} slug
         * @returns {*}
         */
        getMenu(slug) {
            return this.$store.getters['menu/get'](slug.replace('$', this.$i18n.locale));
        },
        
        /**
         * Return router-link object
         *
         * @param {string} name
         * @param {object} post
         * @returns {object}
         */
        routeTo(name, post) {
            const params = {};
            if (post?.url_encode) {
                params.url_encode = post.url_encode;
            } else if (post?.id) {
                params.id = post.id;
            }

            return {
                name,
                params,
            };
        },
    },
});
