<template>
    <component
        :is="parentTag"
        :target="element && element.link.target ? element.link.target : '_blank'"
        :href="element && element.link.url ? element.link.url : null"
    >
        <div
            v-if="prefixText || postfixText"
            class="d-f align-items-center justify-content-start"
        >
            <span
                v-if="prefixText"
                class="ml-1"
            >
                {{ prefixText }}
            </span>
            <component
                :is="tag"
                :class="[classes, iconClass]"
            />
            <span
                v-if="postfixText"
                class="mr-1"
            >
                {{ postfixText }}
            </span>
        </div>

        <component
            :is="tag"
            v-else
            :class="[classes, iconClass]"
        />
    </component>
</template>

<script>
    export default {
        name: 'YFormIcon',

        props: {
            /**
             * Element
             */
            element: Object,

            /**
             * Icon name
             */
            icon: String,
            
            /**
             * Icons library code name. here's a list of supported icons libraries:
             * 'md' - Material Icon - https://material.io/resources/icons
             * 'mdi' - Material Design Icons - https://materialdesignicons.com/
             * 'uil' - Unicons Line Icons - https://iconscout.com/unicons/explore/line
             * 'fa' - Font Awesome - https://fontawesome.com/icons
             * 'im' - Icomoon - https://icomoon.io/#icons
             * 'br' - Brands icons
             */
            library: {
                type   : String,
                default: 'md',
            },

            /**
             * Tag name used to render icon's element
             */
            tag: {
                type   : String,
                default: 'i',
            },

            /**
             * Font size code name used to render icon. supported values: xs, sm, lg, xlg
             */
            size: String,

            /**
             * A color name, e.g. blue, red, white, green, teal, etc.
             */
            color: String,

            /**
             * Theme variant, light or dark.
             */
            theme: {
                type   : String,
                default: 'light',
            },

            /**
             * A tiny margin applied to specified side. accepted values: left, right, bottom, top
             */
            side: String,

            /**
             * Prefix of the icon
             */
            prefix: String,

            /**
             * Postfix of the icon
             */
            postfix: String,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                sideClasses: {
                    left  : 'ml5',
                    right : 'mr5',
                    bottom: 'mb5',
                    top   : 'mt5',
                },
            };
        },

        computed: {
            /**
             * Return class list of icon element.
             */
            classes() {
                const classNames = ['icon'];

                classNames.push(this.library);

                if (this.icon) {
                    classNames.push(this.icon);
                }
                if (this.size) {
                    classNames.push(this.size);
                }

                if (this.color) {
                    classNames.push(`color-${this.color}`);
                }

                if (this.side) {
                    classNames.push(this.sideClasses[this.side]);
                }

                return classNames;
            },

            /**
             * Icon Class
             */
            iconClass() {
                return this.element && this.element.value ? this.element.value : this.icon;
            },

            /**
             * Prefix Text
             */
            prefixText() {
                return this.element && this.element.prefix ? this.element.prefix : this.prefix;
            },

            /**
             * Postfix Text
             */
            postfixText() {
                return this.element && this.element.postfix ? this.element.postfix : this.postfix;
            },

            /**
             * Return the parent tag
             */
            parentTag() {
                return this.element && this.element.link ? 'a' : 'span';
            },
        },
    };
</script>
