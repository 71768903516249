/*-----------------------------------------------------------------
- Profile trans
-----------------------------------------------------------------*/

export default {
    
    history: {
        title       : 'تاریخچه ورود',
        date        : 'تاریخ',
        browser     : 'مرورگر',
        ip          : 'IP',
        os          : 'سیستم عامل',
        status      : 'وضعیت',
        successful  : 'موفق',
        unsuccessful: 'ناموفق',
    },
    
    password: {
        title: 'تغییر رمز عبور',
    },
    
    edit: {
        title       : 'ویرایش پروفایل',
        addresses   : 'آدرس‌های کاربر',
        make_default: 'تنظیم به عنوان پیش‌فرض',
    },
    
    preferences: {
        title : 'تنظیمات پروفایل',
        locale: 'زبان پنل',
    },

    notification: {
        title: 'تنظیمات اعلان‌ها',

        driver: {
            browser : 'مرورگر',
            database: 'دیتابیس',
            email   : 'ایمیل',
            sms     : 'پیامک',
            pattern : 'پیامک الگودار',
        },

        message: {
            success         : 'تغییر اعلان‌ها با موفقیت ذخیره شد.',
            search_no_result: 'اعلانی پیدا نشد.',
            empty           : 'اعلانی وجود ندارد.',
        },
    },

    two_fa: {
        disable_2fa_button  : 'غیرفعال کردن ورود دومرحله‌ای',
        disable_2fa         : 'آیا از غیرفعال کردن ورود دو مرحله‌ای مطمين هستید؟',
        disable_required_2fa: 'ورود دو مرحله‌ای برای نقش این کاربر الزامی می‌باشد. با اولین ورود کاربر می‌بایست ورود دو مرحله‌ای خود را فعال کند.',
    },

    google: {
        activate                   : 'فعال‌سازی ورود دو مرحله‌ای',
        title                      : 'ورود دو مرحله‌ای',
        backup_codes               : 'کدهای پشتیبان',
        text                       : 'برای فعال‌سازی ورود دو مرحله‌ای گوگل، تصویر بالا را توسط اپلیکیشن Google Authenticator اسکن کنید. پس از ثبت موفق، کد فعال را وارد کرده و دکمه‌ی ذخیره را فشار دهید.',
        // eslint-disable-next-line max-len
        activate_backup_codes_desc : 'ورود ۲ مرحله‌ای گوگل با موفقیت برای شما فعال شد. کدهای نمایش داده شده در پایین، ۶ کد پشتیبان هستند و پس از بستن این مودال، دیگر نمایش داده نخواهند شد. لطفا کدها را در جایی یادداشت و حفظ کنید. البته شما می‌توانید درخواست کدهای جدیدی داشته باشید.',
        new_backup_codes_desc      : 'کدهای جدید ساخته شد. لطفا این کدها را در جایی یادداشت و حفظ کنید. پس از بستن این مودال، قادر به مشاهده مجدد کدها نخواهید بود.',
        generated_backup_codes_desc: 'لطفا این کدها را در جایی یادداشت و حفظ کنید. برای بازیابی حساب خود این کد‌ها نیاز خواهند شد.',
        backup_codes_desc          : 'به دلایل امنیتی کدها نمایش داده نمی‌شوند. اما اگر مایل باشید، می‌توانید کدهای فعلی را دور بریزید و شش کد جدید بسازید.',
        used_codes                 : 'کدهای استفاده شده: {count} از ۶ کد.',
        
        deactivate        : 'غیرفعال‌سازی ورود ۲ مرحله‌ای',
        deactivate_text   : 'با وارد کردن کد فعال یا یکی از کدهای پشتیبان، می‌توانید ورود دو مرحله‌ای را غیرفعال کنید.',
        deactivate_success: 'ورود دو مرحله‌ای با موفقیت غیرفعال شد.',
        
        activate_success: 'ورود دو مرحله‌ای با موفقیت فعال شد.',
    },
    
};
