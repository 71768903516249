<template>
    <div :class="[{'table-responsive': element.responsive}]">
        <table
            class="table grid-table"
            :class="classes"
        >
            <thead v-if="element.columns && element.columns.length">
                <tr>
                    <template v-for="(column, index) in element.columns">
                        <th
                            v-if="typeof column === 'object'"
                            :key="`table-column-${column.id}`"
                            :class="{'col-min': column.min}"
                        >
                            {{ column.title }}
                        </th>
                        <th
                            v-else
                            :key="`${element.id}-column-${index}`"
                        >
                            {{ column }}
                        </th>
                    </template>
                </tr>
            </thead>

            <tbody v-if="element.rows && element.rows.length">
                <tr
                    v-for="(row, index) in element.rows"
                    :key="`table-${element.id}-row-${index}`"
                >
                    <td
                        v-for="(item, i) in row"
                        :key="`row-${element.id}-cell-${i}`"
                    >
                        <y-form
                            v-if="typeof item === 'object'"
                            :params="item"
                        />
                        <div v-else>
                            {{ item }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'YFormTable',

        props: {
            /**
             * Element
             */
            element: Object,
        },

        computed: {

            /**
             * Return theme of table
             *
             * @returns {Array}
             */
            themeColor() {
                const types = {
                    default: '',
                    danger : 'red',
                    warning: 'orange',
                    success: 'green',
                    info   : 'blue',
                };
                return types[this.element.theme || 'default'];
            },

            /**
             * Generate class list
             *
             * @returns {Array}
             */
            classes() {
                const classes = [];

                classes.push(`bar-${this.themeColor}`);

                if (this.element.bordered) {
                    classes.push('bordered');
                }
                if (this.element.striped) {
                    classes.push('striped');
                }
                if (this.element.hover) {
                    classes.push('hover');
                }
                if (this.element.compact) {
                    classes.push('compact');
                }
                if (this.element.padded) {
                    classes.push('padded');
                }
                if (this.element.fixed) {
                    classes.push('fixed');
                }

                return classes;
            },
        },

    };
</script>
