/*-----------------------------------------------------------------
- Global mixin
-----------------------------------------------------------------*/
import Vue from 'vue';
import { digits } from '@nodes/helpers/number';

import {
    get, set, has, cloneDeep, isEqual,
} from 'lodash';

Vue.mixin({
    name: 'GlobalMixin',

    computed: {
        
        /**
         * Check env is development
         *
         * @returns {boolean}
         */
        isDev() {
            return process.env.NODE_ENV === 'development';
        },
        
        /**
         * Check env is development
         *
         * @returns {boolean}
         */
        isProd() {
            return process.env.NODE_ENV === 'production';
        },
        
    },
    
    methods: {
        /**
         * Handle response error
         *
         * @param {object} responseError
         * @param {string} message
         */
        handleError(responseError, message = null) {
            const status = get(responseError, 'response.status');
            const response = get(responseError, 'response.data', {});

            if (status >= 500) {
                const msg = get(response, 'userMessage', this.$t('errors.500.title'));
                this.$toast.error(digits(msg, this.$i18n.locale));
            } else if (response.errorCode === 422) {
                let messages = response.errors;
                let items = [];
                messages = Object.keys(messages).map((key) => {
                    items = [...items, ...messages[key]];
                    return true;
                }, []);
                let output = '<ul>';
                items.map((item) => {
                    output += `<li>${item}</li>`;
                    return true;
                });
                output += '</ul>';
                // TODO it's just for demo
                output = output.replaceAll('</ul>', '').replaceAll('<ul>', '').replaceAll('<li>', '').replaceAll('</li>', '\n');
                this.$toast.error(digits(output, this.$i18n.locale));
            } else if (['endpoint-403', 401].includes(response.errorCode) || (response.errorCode === 'endpoint-404' && !this.$store.getters['auth/token'])) {
                const params = {
                    name  : this.$route.name,
                    params: { ...this.$route.params },
                    query : { ...this.$route.query },
                };
                this.$store.dispatch('auth/setRedirect', params).then(() => {
                    this.$store.dispatch('auth/logout');
                    // TODO: Read This route name from project config
                    this.$router.push({ name: 'auth-login' }).catch((error) => {
                        this.handleError(error);
                    });
                });
            } else {
                const msg = get(response, 'userMessage', '') || message;
                if (msg) {
                    this.$toast.error(digits(msg, this.$i18n.locale));
                }
            }
        },
        
        get,
        
        set,
        
        has,
        
        cloneDeep,
        
        isEqual,
        
    },
    
});
