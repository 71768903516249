<template>
    <span class="form-link">

        <y-button
            v-if="typeName === 'button'"
            :class="classes"
            :tag="tag"
            :to="to"
            :target="linkTarget"
            :href="linkHref"
            :size="element.size"
            :color="element.color"
            :wide="element.wide"
            :wider="element.wider"
            :hover="element.hover"
            :disabled="element.disabled"
            :disabled-on="element.disabledOn"
            :block="element.block"
            :alt="element.alt"
            :inverted="element.inverted"
            :rounded="element.rounded"
            :circular="element.circular"
            :loading="element.loading"
            :loading-on="loadingOn"
            :labeled="element.labeled"
            :reverse="element.reverse"
            :icon="element.icon"
            :with-icon="element.withIcon"
            @click.prevent="handleClick"
        >
            <slot>{{ label }}</slot>
        </y-button>

        <template v-if="typeName === 'link'">

            <router-link
                v-if="isRouterLink"
                :class="classes"
                :to="to"
                :target="link.target || null"
            >
                <slot>{{ label }}</slot>
            </router-link>

            <a
                v-else-if="isAction"
                :target="linkTarget"
                :class="classes"
                :href="linkHref"
                @click.prevent="handleClick"
            >
                <slot>{{ label }}</slot>
            </a>

            <a
                v-else
                :target="linkTarget"
                :class="classes"
                :href="linkHref"
            >
                <slot>{{ label }}</slot>
            </a>

        </template>

    </span>
</template>

<script>

    import LinkMixin from '@/mixins/Link';

    export default {
        name: 'YFormLink',

        mixins: [LinkMixin],

        props: {
            /**
             * Element of link
             */
            element: Object,

            /**
             * Type of component
             */
            type: String,

            /**
             * Loading button on wait slug
             */
            loadingOn: String,
        },

    };
</script>
