/*-----------------------------------------------------------------
- Shipping trans
-----------------------------------------------------------------*/
export default {
    
    shipping: {
        title : 'Shipping',
        create: 'New Shipping Method',
        edit  : 'Edit Shipping Method',
    },
    
};
