/*-----------------------------------------------------------------
- ICIB General Locales
-----------------------------------------------------------------*/
export default {
    
    title             : 'Invoice',
    view_invoice      : 'View Invoice',
    number            : 'Number',
    date              : 'Date',
    seller_title      : 'Seller Information',
    buyer_title       : 'Buyer Information',
    product_title     : 'Products Information',
    wares_title       : 'Wares Information',
    transactions_title: 'Transactions',
    name              : 'Company',
    eco_no            : 'Economical No.',
    reg_no            : 'Register No.',
    national_no       : 'International ID',
    code_melli        : 'ٔNational Code',
    address_full      : 'Full Address',
    address           : 'Address',
    address_dynamic   : '{0}، ',
    state             : 'State',
    county            : 'Country',
    city              : 'City',
    zipcode           : 'Zip Code',
    phone             : 'Phone',
    email             : 'Email',
    
    product: {
        row                  : 'No.',
        code                 : 'Code',
        name                 : 'Product',
        count                : 'Count',
        unit                 : 'Unit',
        unit_price           : 'Unit Price',
        total_fee            : 'Total',
        discount             : 'Discount',
        tax                  : 'Tax',
        total_plus_tax       : 'Total Plus Tax',
        total_text           : '(In Word)',
        total                : 'Total',
        total_coupon_discount: 'Coupons Discount',
        shipping             : 'Shipping Amount',
        payable              : 'Payable Amount',
    },

    wares: {
        row     : 'Row',
        count   : 'Count',
        title   : 'Title',
        code    : 'Code',
        price   : 'Price',
        discount: 'Discount',
        tax     : 'Tax',
        total   : 'Total Amount',
    },

    transactions: {
        row            : 'Row',
        date           : 'Date',
        path           : 'Path',
        amount         : 'Amount',
        type           : 'Type',
        method         : 'Method',
        status         : 'Status',
        tracking_number: 'Tracking Number',
        online         : 'Online',
        offline        : 'Offline',
    },

    empty_transaction: 'No transactions were recorded for this order.',
    empty_product    : 'Product not found!',
    empty_wares      : 'No wares has been added to this order.',
    seller_sign      : 'Seller Signature',
    buyer_sign       : 'Buyer Signature',
    desc             : 'Description',
    unit             : 'Rial',
    
    edit_discount: 'Edit',
    save_discount: 'Save',
    cancel_edit  : 'Cancel',
    print        : 'Print',
    
};
