/*-----------------------------------------------------------------
- Roles trans
-----------------------------------------------------------------*/
export default {
    fields: {
        title       : 'Manage fields for {0}',
        title_simple: 'Manage fields',

        settings: {
            title          : 'Settings',
            division       : 'Divisions',
            others         : 'Others',
            from           : 'From',
            to             : 'To',
            division__alert: 'These settings will be applied if the address field is selected.',
            all_role_alert : 'These settings are the same for all roles.',
            no_field       : 'No field with this name was found.',
            messages       : {
                success: 'Settings saved successfully.',
                save   : {
                    success: 'Fields were successfully saved.',
                },
            },
        },

        actions: {
            settings: 'Settings',
        },
    },
};
