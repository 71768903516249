<template>
    <div class="coupon-code-tracking">
        <!-- Form -->
        <form
            id="code-finder-form"
            class="code-finder-form"
            @submit.prevent="searchCode"
        >
            <y-form-field
                v-model="query"
                type="text"
                :placeholder="$t('coupons.code_tracking.search')"
                no-label
                class="mb0"
                name="search"
            />

            <i
                class="icon md-close"
                @click="reset"
            />

            <y-button
                color="blue"
                loading-on="loading-result"
                form="code-finder-form"
            >
                {{ $t('coupons.code_tracking.search_button') }}
            </y-button>
        </form>

        <!-- Result -->
        <y-loading
            v-if="result"
            :active="$wait.is('loading-result')"
            height="100px"
        >
            <div
                v-if="result && result.id"
                class="finder-result"
            >
                <div class="row">
                    <div class="col-sm-6">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('coupons.code_tracking.result.scenario') }}
                            </div>
                            <div class="value">
                                {{ result.rulebook.title }}
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="detail-item">
                            <div class="title">
                                {{ allowedUsersTitle }}
                            </div>

                            <div
                                v-if="allowedUsers === 'users'"
                                class="value"
                            >
                                <span
                                    v-for="item in result.allowed_users.users"
                                    :key="item.id"
                                    class="label"
                                >
                                    {{ item.name_full | digits }}
                                </span>
                            </div>
                            <div
                                v-else-if="allowedUsers === 'roles'"
                                class="value"
                            >
                                <span
                                    v-for="item in result.allowed_users.roles"
                                    :key="item.id"
                                    class="label"
                                >
                                    {{ item.title | digits }}
                                </span>
                            </div>
                            <div
                                v-else
                                class="value"
                            >
                                <span class="label">
                                    {{ allowedUsers }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('coupons.code_tracking.result.amount') }}
                            </div>
                            <div class="value">
                                <y-form-amount :element="{ value: result.rulebook.applicable_amount }" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('coupons.code_tracking.result.percent') }}
                            </div>
                            <div class="value">
                                {{ result.rulebook.applicable_percent | digits }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('coupons.code_tracking.result.total_discount') }}
                            </div>
                            <div class="value">
                                <y-form-amount :element="{ value: result.total_discounted }" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('coupons.code_tracking.result.allowed_usage') }}
                            </div>
                            <div class="value">
                                <y-form-amount :element="{ value: (result.used_count + result.remained_count) }" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('coupons.code_tracking.result.usage') }}
                            </div>
                            <div class="value">
                                <y-form-amount :element="{ value: result.used_count }" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('coupons.code_tracking.result.remain') }}
                            </div>
                            <div class="value">
                                <y-form-amount :element="{ value: result.remained_count }" />
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="result.usages && result.usages.length"
                    class="row"
                >
                    <div class="col">
                        <div class="detail-item">
                            <div class="title">
                                {{ $t('coupons.code_tracking.result.users') }}
                            </div>
                            <div class="value">
                                <template v-for="users in result.usages">
                                    <span
                                        :key="users.id"
                                        class="label"
                                    >
                                        {{ users.user.name_full | digits }}
                                    </span>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- No Result -->
            <y-empty-state
                v-else
                height="250"
                class="mb0 code-finder-empty"
                :message="$t('coupons.code_tracking.empty')"
            >
                <y-button @click="reset">
                    {{ $t('coupons.code_tracking.search_again') }}
                </y-button>
            </y-empty-state>
        </y-loading>
    </div>
</template>

<script>

    import YFormField from '@deps/form/FormField';
    import YEmptyState from '@deps/EmptyState';
    import YFormAmount from '@deps/form/elements/Amount';

    export default {
        name: 'YFormCouponCodeTracking',

        components: {
            YFormField,
            YEmptyState,
            YFormAmount,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                query : null,
                result: null,
            };
        },

        computed: {
            /**
             * Return appropriate title for allowed users
             */
            allowedUsersTitle() {
                let title = this.$t('coupons.code_tracking.result.allowed.title');
                if (this.result && this.result.allowed_users.roles.length) {
                    title = this.$t('coupons.code_tracking.result.allowed.role');
                }
                if (this.result && this.result.allowed_users.users.length) {
                    title = this.$t('coupons.code_tracking.result.allowed.user');
                }
                return title;
            },

            /**
             * Return allowed users
             */
            allowedUsers() {
                let users = this.$t('coupons.code_tracking.result.allowed.all');
                if (this.result && this.result.allowed_users.roles.length) {
                    users = 'roles';
                } else if (this.result && this.result.allowed_users.users.length) {
                    users = 'users';
                }
                return users;
            },
        },

        methods: {
            /**
             * Search Code
             */
            searchCode() {
                if (!this.query) {
                    return false;
                }
                this.$wait.start('loading-result');
                this.result = null;
                const params = {
                    code: this.query,
                };
                return this.$services.Coupons.codeTracking(params).then((response) => {
                    this.result = response.data.results;
                }).catch((errorResponse) => {
                    this.handleError(errorResponse);
                }).finally(() => {
                    this.$wait.end('loading-result');
                });
            },

            /**
             * Reset search form
             */
            reset() {
                this.result = null;
                this.query = null;
            },
        },
    };
</script>
