/*-----------------------------------------------------------------
- Favicon Mixin
-----------------------------------------------------------------*/
export default {
    
    computed: {
    
        /**
         * return favicon from setting
         *
         * @returns {*}
         */
        favicon() {
            const link = [];
            
            if (!this.$settings('favicon.file')) {
                return link;
            }
            
            const { versions } = this.$settings('favicon.file');
    
            // 16
            link.push({
                rel  : 'icon',
                type : 'image/png',
                sizes: '16x16',
                href : versions['favicon-16'],
            });
        
            // 32
            link.push({
                rel  : 'icon',
                type : 'image/png',
                sizes: '32x32',
                href : versions['favicon-32'],
            });
        
            // 128
            link.push({
                rel  : 'icon',
                type : 'image/png',
                sizes: '128x128',
                href : versions['favicon-128'],
            });
        
            // 192
            link.push({
                rel  : 'icon',
                type : 'image/png',
                sizes: '192x192',
                href : versions['favicon-192'],
            });
        
            // 196
            link.push({
                rel  : 'shortcut icon',
                sizes: '196x196',
                href : versions['favicon-196'],
            });
        
            // Apple
            link.push({
                rel  : 'apple-touch-icon',
                sizes: '152x152',
                href : versions['favicon-152'],
            });
            link.push({
                rel  : 'apple-touch-icon',
                sizes: '167x167',
                href : versions['favicon-167'],
            });
            link.push({
                rel  : 'apple-touch-icon',
                sizes: '180x180',
                href : versions['favicon-180'],
            });
        
            return link;
        },
        
    },
    
};
