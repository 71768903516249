<template>
    <div>
        <textarea
            :id="labelFor"
            :value="value"
            :placeholder="placeholder"
            :class="inputClasses"
            :readonly="!!readonly"
            :disabled="!!disabled"
            :cols="cols"
            :rows="rows"
            :no-resize="noResize"
            :maxlength="characterLimit"
            @input="updateText"
        />

        <span
            v-if="characterLimit"
            class="char-limit"
        >
            {{ `${charCount} / ${characterLimit}` | digits }}
        </span>
    </div>
</template>

<script>

    import direction from '@helpers/direction';
    import FormElementMixin from '@/mixins/FormElement';

    /**
     * Textarea Component
     */
    export default {

        name: 'YFormTextarea',

        mixins: [FormElementMixin],

        props: {

            /**
             * Is Readonly
             */
            readonly: {
                type   : [Boolean, Number, String],
                default: false,
            },

            /**
             * Placeholder Text
             */
            placeholder: {
                type: String,
            },

            /**
             * Is disabled
             */
            disabled: {
                type   : [Boolean, Number, String],
                default: false,
            },

            /**
             * Rows Count
             */
            rows: [String, Number],

            /**
             * Columns Count
             */
            cols: [String, Number],

            /**
             * Disable Resizing
             */
            noResize: {
                type   : [Boolean, Number, String],
                default: false,
            },

            /**
             * Character Limit
             */
            characterLimit: [Number, String],

        },

        computed: {

            /**
             * Calculate input classes
             */
            inputClasses() {
                const classes = [];

                if (this.hasError) {
                    classes.push('error');
                }

                if (this.dir === 'auto') {
                    const dir = direction(this.value) || document.dir;
                    const cls = dir === 'rtl' ? 'rtl ta-r' : 'ltr ta-l';
                    classes.push(cls);
                }

                return classes;
            },

        },

    };

</script>
