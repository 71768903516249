<template>
    <div
        class="form-title"
        :class="classes"
    >
        <y-form-link
            v-if="isLink"
            type="link"
            :element="element"
        >
            {{ element.text }}
        </y-form-link>
        <span
            v-else
            v-html="element.text"
        />
        <y-copy-value
            v-if="copy"
            :value="copy && typeof copy === 'string' ? copy : element.text"
        />
    </div>
</template>

<script>

    import ComponentClassesMixin from '@/mixins/ComponentClasses';
    import YCopyValue from '@deps/CopyValue';

    export default {

        name: 'YFormTitle',

        components: {
            YFormLink: () => import('@deps/form/elements/Link'),
            YCopyValue,
        },

        mixins: [ComponentClassesMixin],

        props: {
            /**
             * Element
             */
            element: Object,

            /**
             * If there should be copy button
             */
            copy: [String, Boolean],
        },

        computed: {

            /**
             * Check if type is link
             *
             * @returns {boolean}
             */
            isLink() {
                return typeof this.element.link === 'object' && !Array.isArray(this.element.link);
            },

        },

    };
</script>
