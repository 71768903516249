/*-----------------------------------------------------------------
- Breadcrumbs trans
-----------------------------------------------------------------*/
export default {

    dashboard: 'Dashboard',
    settings : 'Settings',
    preview  : 'Preview',
    404      : '404',
    help     : 'Help List',

};
