<template>
    <div
        :id="element.id"
        class="form-amount form-stock-amount"
        :class="classes"
    >
        <template v-if="!editing">
            <span
                v-if="!element.empty"
                class="form-stock-amount-value"
                @click.stop="activateEditing"
                v-html="valueContent"
            />

            <y-button
                v-else
                size="xs"
                @click.stop.native="activateEditing"
            >
                {{ $t('components.stock_amount.add_first') }}
            </y-button>
        </template>

        <form
            v-else
            :id="`f-${element.id}`"
            ref="StockForm"
            class="field mb0"
            @submit.prevent="save"
        >
            <y-form-number
                v-model="model"
                type="text"
                class="sm form-stock-amount-input"
                :locale="$i18n.locale"
                :disabled="$wait.is('saving-stock')"
            />
            <y-button
                size="xs"
                class="form-stock-amount-button"
                icon="md-content-save"
                loading-on="saving-stock"
                :form="`f-${element.id}`"
            />
        </form>
    </div>
</template>

<script>

    import { digits } from '@nodes/helpers/number';

    import ComponentClassesMixin from '@/mixins/ComponentClasses';

    export default {

        name: 'YFormStockAmount',

        components: {
            YFormNumber: () => import('@deps/form/elements/Number'),
        },

        mixins: [ComponentClassesMixin],

        props: {
            /**
             * Element
             */
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                editing: false,
                model  : null,
                cls    : ['form-stock-amount', 'amount-value', 'form-stock-amount-value', 'sm form-stock-amount-input', 'form-stock-amount-button button xs icon md-content-save loader loading'],
                el     : null,
            };
        },

        computed: {

            /**
             * Check if type is link
             *
             * @returns {boolean}
             */
            isLink() {
                return typeof this.element.link === 'object' && !Array.isArray(this.element.link);
            },

            /**
             * Return parsed value
             */
            value() {
                const decimal = this.element.decimal >= 0 ? this.element.decimal : 2;
                return parseFloat(this.element.value || 0).toFixed(decimal);
            },

            /**
             * Return parsed value
             */
            link() {
                if (!this.element.link || Array.isArray(this.element.link)) {
                    return null;
                }

                const { link } = this.element;
                link.label = this.valueContent;

                return link;
            },

            /**
             * Return parsed value
             */
            valueContent() {
                let content = Number(this.value).toLocaleString('en');

                content = `<span class="amount-value">${digits(content, this.$i18n.locale)}</span>`;

                if (this.$i18n.locale === 'fa') {
                    content = content.replace('.', '/');
                }

                return this.prefix + content + this.postfix;
            },

            /**
             * Return prefix
             */
            prefix() {
                return this.element.prefix ? `<span>${this.element.prefix} </span>` : '';
            },

            /**
             * Return postfix
             */
            postfix() {
                return this.element.postfix ? `<span> ${this.element.postfix}</span>` : '';
            },

            /**
             * Return classes of element
             *
             * @returns {*}
             */
            classes() {
                const classes = [];

                if (this.element.colorful) {
                    if (this.value > 0) {
                        classes.push('green');
                    } else if (this.value < 0) {
                        classes.push('red');
                    }
                }

                if (this.element.color_negatives && this.value < 0) {
                    classes.push('red');
                }

                if (this.link) {
                    classes.push('with-link');
                }

                return classes;
            },

        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.el = document.getElementById(this.element.id);
            this.setListenerForClosing();
        },

        /**
         * @inheritDoc
         */
        beforeDestroy() {
            document.removeEventListener('click', this.handleClosing.bind(this));
        },

        methods: {

            /**
             * Save inputs
             */
            save() {
                this.$wait.start('saving-stock');
                const params = {
                    price: this.model,
                    id   : this.element.post_id,
                };
                this.$services.Stock.pricesSave(params).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('stock.item') }));
                    this.$root.$emit('refreshGrid', { refresh: this.element.post_id });
                    this.deactivateEditing();
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.end('saving-stock'));
            },

            /**
             * Activate editing mode
             */
            activateEditing() {
                this.editing = true;
                this.model = this.element.value;
            },

            /**
             * Deactivate editing mode
             */
            deactivateEditing() {
                this.editing = false;
                this.model = null;
            },

            /**
             * Sets event listener on document to close dropdown
             * list when anywhere out of dropdown is clicked
             */
            setListenerForClosing() {
                document.addEventListener('click', this.handleClosing.bind(this));
            },

            /**
             * Handle closing
             *
             * @param event
             */
            handleClosing(event) {
                if (this.$refs.StockForm && this.$refs.StockForm.contains(event.target)) {
                    return;
                }

                this.deactivateEditing();
            },

        },

    };
</script>
