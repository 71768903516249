/*-----------------------------------------------------------------
- Locales configs
-----------------------------------------------------------------*/

const options = [
    {
        code    : 'fa',
        title   : 'فارسی',
        title_fa: 'فارسی',
        dir     : 'rtl',
        numbers : 'fa',
    },
    {
        code    : 'en',
        title   : 'English',
        title_fa: 'انگلیسی',
        dir     : 'ltr',
    },
    {
        code    : 'tr',
        title   : 'Turkish',
        title_fa: 'ترکی',
        dir     : 'ltr',
    },
    {
        code    : 'ar',
        title   : 'العربیه',
        title_fa: 'عربی',
        dir     : 'rtl',
        numbers : 'ar',
    },
    {
        code    : 'es',
        title   : 'Español',
        title_fa: 'اسپانیولی',
        dir     : 'ltr',
    },
    {
        code    : 'fr',
        title   : 'Français',
        title_fa: 'فرانسوی',
        dir     : 'ltr',
    },
    {
        code    : 'ru',
        title   : 'Русский',
        title_fa: 'روسی',
        dir     : 'ltr',
    },
    {
        code    : 'de',
        title   : 'Deutsch',
        title_fa: 'آلمانی',
        dir     : 'ltr',
    },
    {
        code    : 'it',
        title   : 'Italiano',
        title_fa: 'ایتالیایی',
        dir     : 'ltr',
    },
];

const locales = process.env.VUE_APP_LOCALES.split(',');

export default { locales, options };
