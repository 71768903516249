<template>
    <y-panel
        :title="$t('tagging.feature.title')"
        class="content-tagging"
    >
        <template slot="functions">
            <y-button
                size="sm"
                aria-label="Refresh"
                icon="md-refresh"
                @click.native.prevent="fetch"
            />
        </template>

        <y-loading
            :active="$wait.is('loading-tagging')"
            height="200px"
        >
            <template v-if="!replaceResult || folders">
                <y-panel
                    v-for="(folder, index) in folders"
                    :key="`tagging-folder-${folder.slug}-${index}`"
                    class="tagging-folder"
                    :title="folder.title"
                    icon="md-tag"
                >
                    <template slot="functions">
                        <y-button
                            v-if="element.has_setting_button"
                            size="xs"
                            tag="router-link"
                            :to="manageLink(folder.slug)"
                            icon="md-settings"
                        />
                    </template>

                    <template v-if="folder.labels && folder.labels.length">
                        <div
                            v-if="folder.nested"
                            class="tagging-nested"
                        >
                            <y-nested-loop
                                v-model="model[folder.slug]"
                                :items="listToTree(folder.labels)"
                            />
                        </div>

                        <y-form-field
                            v-else
                            v-model="model[folder.slug]"
                            type="select"
                            value-field="id"
                            label-field="title"
                            multi
                            no-label
                            :placeholder="$t('tagging.feature.select_placeholder')"
                            :options="folder.labels"
                            allow-empty
                        />
                    </template>

                    <!-- Show empty state -->
                    <y-empty-state
                        v-else
                        no-image
                        :message="$t('tagging.feature.no_label')"
                        height="70"
                        class="tagging-folder-empty"
                    />
                </y-panel>
            </template>

            <!-- Show empty state -->
            <y-empty-state
                v-else
                :message="$t('tagging.feature.no_folder')"
                height="250"
                class="mb0"
            />
        </y-loading>
    </y-panel>
</template>

<script>

    import YEmptyState from '@deps/EmptyState';
    import { listToTree } from '@nodes/helpers/misc';

    export default {

        name: 'YFormTagging',

        components: {
            YFormField : () => import('@deps/form/FormField'),
            YNestedLoop: () => import('@deps/form/elements/Tagging/Nested'),
            YEmptyState,
        },

        props: {
            /**
             * Element of slug
             */
            element: Object,

            /**
             * Input Value
             */
            value: { // eslint-disable-line vue/require-prop-types
                default: null,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},

                folders      : null,
                replaceResult: true,
            };
        },

        watch: {

            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },

            /**
             * Watch value set new model
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.$wait.start('loading-tagging');
            if (this.replaceResult) {
                this.$set(this, 'model', this.element.data.data.results);
                this.replaceResult = false;
            }
            this.folders = this.element.data.data.metadata.folders;
            this.$wait.end('loading-tagging');
        },

        methods: {
            /**
             * Return manage link
             *
             * @param slug
             */
            manageLink(slug) {
                return {
                    name  : 'content-tagging-manage',
                    params: {
                        slug,
                    },
                };
            },

            listToTree,

        },

    };
</script>
