/*-----------------------------------------------------------------
- Tagging Locales
-----------------------------------------------------------------*/
export default {
    
    title: {
        singular: 'Label',
    },
    
    type: 'Label',
    
    label: {
        title         : 'Labels',
        create        : 'Add New Label',
        edit          : 'Edit Label',
        delete        : 'Delete Label',
        delete_confirm: 'Are you sure you want to delete this label?',
        titleFull     : 'Labels: {0}',
    },
    
    empty_state: {
        tag     : 'No tag found. Make the first one.',
        category: 'No category found. Make the first one.',
    },
    
    feature: {
        title             : 'Labels',
        no_folder         : 'No label folder found.',
        no_label          : 'No label found.',
        select_placeholder: 'Select your labels.',
    },
};
