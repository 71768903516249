/*-----------------------------------------------------------------
- Date translations
-----------------------------------------------------------------*/

export default {
    relative: {
        second: {
            single  : 'Few Seconds',
            multiple: 'Few Seconds',
        },
        minute: {
            single  : '1 Minute',
            multiple: '{0} Minutes',
        },
        hour: {
            single  : '1 Hour',
            multiple: '{0} Hours',
        },
        day: {
            single  : '1 Day',
            multiple: '{0} Days',
        },
        month: {
            single  : '1 Month',
            multiple: '{0} Months',
        },
        year: {
            single  : '1 Year',
            multiple: '{0} Years',
        },
    },
    prefix: {
        future: 'In',
        past  : '',
    },
    postfix: {
        future: '',
        past  : 'Ago',
    },

    days: {
        saturday : 'Saturday',
        sunday   : 'Sunday',
        monday   : 'Monday',
        tuesday  : 'Tuesday',
        wednesday: 'Wednesday',
        thursday : 'Thursday',
        friday   : 'Friday',
    },
};
