<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    import PanelMixin from '@/mixins/Panel';
    import AppMixin from '@nodes/mixins/App';
    import SettingsMixin from '@nodes/settings/mixins';
    // %import%

    export default {
        name: 'App',

        mixins: [
            PanelMixin,
            AppMixin,
            SettingsMixin,
            // %mixins%
        ],

        /**
         * @inheritDoc
         */
        created() {
            this.$http.interceptors.response.use((response) => {
                if (response.data.metadata.password_force_change) {
                    this.handleRedirectToChangePassword();
                }
                this.setNewToken(response);
                return response;
            }, (err) => {
                this.setNewToken(err.response);

                const errorCode = this.get(err, 'response.data.errorCode');
                if (errorCode === 'endpoint-401' || errorCode === '401') {
                    if (!err.response.config.url.includes('persons-logout')) {
                        this.$http.cancelRequests();
                        setTimeout(() => this.handleRedirectToLogin(), 1000);
                    } else {
                        this.handleRedirectToLogin();
                    }
                }
                return Promise.reject(err);
            });
        },

        methods: {
            /**
             * Handle redirection to change-password route
             */
            handleRedirectToChangePassword() {
                this.$store.commit('auth/setForcePasswordChange', true);
                if (this.$route.name !== 'persons-profile-change-password') {
                    this.$router.push({
                        name: 'persons-profile-change-password',
                    });
                }
                return Promise.resolve();
            },

            /**
             * @inheritDoc
             */
            handleRedirectToLogin() {
                const params = {
                    name  : this.$route.name,
                    params: { ...this.$route.params },
                    query : { ...this.$route.query },
                };
                if (this.$route.name !== 'auth-login') {
                    return this.$store.dispatch('auth/setRedirect', params).then(() => {
                        this.$router.push({
                            name : 'auth-login',
                            query: { logout: 1 },
                        });
                    });
                }
                return Promise.resolve();
            },

            /**
             * Set new access token
             *
             * @param response
             */
            setNewToken(response) {
                const accessToken = this.get(response, 'headers.new_access_token');
                if (accessToken) {
                    this.$store.commit('auth/login', accessToken);
                }
            },
        },
    };
</script>
