/* -------------------------------------------------------------------------- */
/* ESSENTIAL INITIALIZATION                                                   */
/* -------------------------------------------------------------------------- */

import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueWait from 'vue-wait';
import Toast from 'vue-toastification';
import router from '@nodes/router';
import toastConfig from '@/configs/toast';
import '@components/RegisterComponents.ts';
import '@rogues/RegisterRogues.ts';
import '@nodes/filters';
import '@nodes/mixins/Global';
import '@nodes/mixins/Theme';
import Settings from '@nodes/settings';
import Configs, { configs } from '@nodes/configs';
import Services from '@nodes/services';
import store from '@nodes/store';
import i18n from '@nodes/locales';
import Breadcrumbs from '@nodes/breadcrumbs';
import Http from '@nodes/services/Http';
// %import%

// Import core and theme styles
import '@/assets/scss/Style.scss';
import 'vue-toastification/dist/index.css';

// DEPS: VeeValidate
import '@/Validate';

// DEPS: SlideUpDown
import SlideUpDown from 'vue-slide-up-down';

Vue.component('SlideUpDown', SlideUpDown);

// DEPS: Toast
// import 'izitoast/dist/css/iziToast.css'; // eslint-disable-line
// import VueIziToast from 'vue-izitoast'; // eslint-disable-line
// Vue.use(VueIziToast, toastConfig);

// DEPS: VueScrollTo
import VueScrollTo from 'vue-scrollto'; // eslint-disable-line
Vue.use(VueScrollTo);

// Register Axios in Vue HTTP
Vue.prototype.$http = Http;

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true,
});
Vue.use(VueWait);
Vue.use(Toast, toastConfig);
Vue.use(Settings);
Vue.use(Configs);
Vue.use(Services);
Vue.use(Breadcrumbs, { store });
// %use%

const wait = new VueWait();
const coreInitiate = {
    router,
    store,
    wait,
    i18n,
    // %register%
};

export default coreInitiate;
