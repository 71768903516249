<template>
    <component
        :is="componentType"
        v-slot="clonerRow"
        :rows="model"
        :label="label"
        :allow-empty="allowEmpty"
        :no-label="noLabel"
    >
        <y-form
            v-model="model[clonerRow.index]"
            :params="children"
        />
    </component>
</template>

<script>

    import ClonerMixin from '@/mixins/Cloner';

    /**
     * Cloner Component
     */
    export default {

        name: 'YFormCloner',

        mixins: [ClonerMixin],

        computed: {
            /**
             * Return Cloner type according to the noLabel prop
             */
            componentType() {
                if (this.noLabel) {
                    return 'YClonerWrapper';
                }
                return 'YClonerPanel';
            },
        },

        watch: {
            /**
             * Watch value prop to set new model if it changes
             *
             * @param newVal
             */
            value(newVal) {
                if (this.model !== newVal) {
                    this.$set(this, 'model', newVal);
                }
            },
        },

    };

</script>
