<template>
    <span
        v-if="!isEmpty"
        class="form-date"
        @click="toggleViewMode"
    >
        <y-label :element="{...modifiedElement, text: valueContent}" />
    </span>
</template>

<script>
    import moment from 'moment-jalaali';
    import { relativeDate } from '@helpers/date';
    import { digits } from '@nodes/helpers/number';
    import YLabel from '@deps/form/elements/Label';

    export default {

        name: 'YFormDateLabel',

        components: {
            YLabel,
        },

        props: {
            /**
             * Element
             */
            element: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                formats: {
                    fa: 'DD MMMM YYYY',
                    en: 'MMMM DD, YYYY',
                },
                viewModes: {
                    relative: 'relative',
                    absolute: 'absolute',
                },
                currentViewMode: this.element.relative ? 'relative' : 'absolute',
            };
        },

        computed: {
            /**
             * Return the element
             */
            modifiedElement() {
                return {
                    ...this.element,
                    alt: this.element.alt !== false,
                };
            },

            /**
             * Return whether current mode is relative or else
             */
            isRelative() {
                return this.currentViewMode === this.viewModes.relative;
            },

            /**
             * Return parsed value, whether relative date or absolute!
             */
            valueContent() {
                const rawDate = this.modifiedElement.value;
                if (!rawDate) {
                    return '';
                }

                const { locale = this.$i18n.fallbackLocale } = this.$i18n;

                if (this.isRelative) {
                    const {
                        isSingle,
                        type,
                        count,
                        isFuture,
                    } = relativeDate(rawDate);

                    const fullDate = [
                        this.prefix(isFuture),
                        this.$t(
                            ['date.relative', type, isSingle ? 'single' : 'multiple'].join('.'),
                            [!isSingle && count].filter(Boolean),
                        ),
                        this.postfix(isFuture),
                    ];
                    return digits(
                        fullDate.join(' ').trim(),
                        locale,
                    );
                }

                const calendar = this.get(this.modifiedElement, 'calendar.0', (locale === 'fa' ? 'jalaali' : 'gregorian'));

                let format = this.modifiedElement.format || this.formats[locale];
                if (calendar === 'jalaali') {
                    format = format.replace(/([Y|M|D])\1{0,}/g, (w) => `j${w}`);
                }
                return digits(
                    this.prefix() + moment(rawDate, 'X').locale(locale).format(format) + this.postfix(),
                    locale,
                );
            },

            /**
             * Determine whether if label is going to be empty
             */
            isEmpty() {
                return !this.modifiedElement.value;
            },

        },

        methods: {
            /**
             * find matching date prefix according to past/future state of date
             *
             * @param isFuture {Boolean}
             */
            postfix(isFuture) {
                if (this.modifiedElement.postfix ) {
                    return this.modifiedElement.postfix;
                }
                if (this.isRelative) {
                    return this.$t(`date.postfix.${isFuture ? 'future' : 'past'}`);
                }
                return '';
            },
            /**
             * find matching date postfix according to past/future state of date
             *
             * @param isFuture {Boolean}
             */
            prefix(isFuture) {
                if (this.modifiedElement.prefix) {
                    return this.modifiedElement.prefix;
                }
                if (this.isRelative) {
                    return this.$t(`date.prefix.${isFuture ? 'future' : 'past'}`);
                }
                return '';
            },
            /**
             * Toggle Date's view mode, between relative and absolute
             */
            toggleViewMode() {
                if (!this.element.forced) {
                    if (this.isRelative) {
                        this.currentViewMode = this.viewModes.absolute;
                    } else {
                        this.currentViewMode = this.viewModes.relative;
                    }
                    this.$emit('click', this.element);
                }
            },
        },
    };
</script>
