/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    persons: {
        addresses: 'مدیریت آدرس‌ها',
        
        users: {
            list         : 'کاربران',
            edit         : 'ویرایش',
            print        : 'چاپ',
            create       : 'افزودن',
            login_history: 'تاریخچه ورود',
            profile      : 'پروفایل کاربر',
        },
        
        roles      : 'نقش‌های کاربری',
        permissions: 'دسترسی‌ها',
        
        organizations: 'سازمان‌ها',
        
    },
    
    profile: {
        password    : 'تغییر رمز عبور',
        edit        : 'ویرایش پروفایل',
        preferences : 'تنظیمات پروفایل',
        notification: 'تنظیمات اعلان‌ها',
        history     : 'تاریخچه ورود',
    },
    
};
