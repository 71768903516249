<template>
    <y-form-field-wrapper v-bind="fieldWrapperProps">
        <quill-editor
            :id="labelFor"
            :value="value"
            :options="options || defaults[toolbar || 'full']"
            :class="{ error: hasError }"
            @blur="onEditorBlur"
            @focus="onEditorFocus"
            @ready="onEditorReady"
            @input="onEditorChange"
        />
    </y-form-field-wrapper>
</template>

<script>

    import 'quill/dist/quill.core.css';     // eslint-disable-line
    import 'quill/dist/quill.snow.css';     // eslint-disable-line
    import 'quill/dist/quill.bubble.css';   // eslint-disable-line

    import FormElementMixin from '@/mixins/FormElement';
    import { quillEditor } from 'vue-quill-editor';

    /**
     * Editor Component
     */
    export default {

        name: 'YFormEditor',

        components: {
            quillEditor,
        },

        mixins: [FormElementMixin],

        props: {

            component: {
                type   : String,
                default: 'editor',
            },

            /**
             * Quill editor options
             */
            options: Object,

            /**
             * Type of editor toolbars
             */
            toolbar: {
                type   : String,
                default: 'full',
            },

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                defaults: {
                    full: {
                        placeholder: null,
                        modules    : {
                            toolbar: [
                                ['bold', 'italic', 'underline', 'strike', { script: 'sub' }, { script: 'super' }],
                                ['blockquote', 'code-block'],
                                [{ header: 1 }, { header: 2 }],
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                [{ direction: 'rtl' }],
                                [{ size: ['small', false, 'large', 'huge'] }],
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [{ color: [] }, { background: [] }],
                                [{
                                    align: ['right', '', 'center', 'justify'],
                                }],
                                ['clean'],
                                ['link', 'image', 'video'],
                            ],
                        },
                    },
                    mini: {
                        placeholder: null,
                        modules    : {
                            toolbar: [
                                ['bold', 'italic', 'underline', 'strike', { script: 'sub' }, { script: 'super' }],
                                ['blockquote', 'code-block'],
                                [{ header: 1 }, { header: 2 }],
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                [{ direction: 'rtl' }],
                                [{
                                    align: ['right', '', 'center', 'justify'],
                                }],
                                ['link'],
                            ],
                        },
                    },

                    comment: {
                        placeholder: null,
                        modules    : {
                            toolbar: [
                                [
                                    'bold',
                                    { script: 'sub' },
                                    { script: 'super' },
                                    { list: 'bullet' },
                                    { direction: 'rtl' },
                                    {
                                        align: ['right', '', 'center', 'justify'],
                                    },
                                    { color: [] },
                                    'link',
                                ],
                            ],
                        },
                    },
                },
            };
        },

        methods: {

            /**
             * Emit blur event
             *
             * @param event
             */
            onEditorBlur(event) {
                this.$emit('blur', event);
            },

            /**
             * Emit focus event
             *
             * @param event
             */
            onEditorFocus(event) {
                this.$emit('focus', event);
            },

            /**
             * Emit ready event
             *
             * @param event
             */
            onEditorReady(event) {
                const toolbar = document.getElementsByClassName('ql-toolbar');
                if (toolbar) {
                    const buttons = toolbar[0].getElementsByTagName('button');
                    const pickers = toolbar[0].getElementsByTagName('span');
                    Object.values(buttons).map( item => item.tabIndex = -1 ); // eslint-disable-line
                    Object.values(pickers).map( item => item.tabIndex = -1 ); // eslint-disable-line
                }
                this.$emit('ready', event);
            },

            /**
             * Emit change event
             *
             * @param event
             */
            onEditorChange(event) {
                this.$emit('input', event);
            },

        },

    };

</script>
