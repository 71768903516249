/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    specs: {
        list  : 'مشخصات فنی محصول',
        manage: 'مدیریت مشخصه‌ها',
    },
    
};
