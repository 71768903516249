/*-----------------------------------------------------------------
- Cart trans
-----------------------------------------------------------------*/
export default {
    
    cart: {
        cart: 'Cart',
        
        order: {
            title : 'Orders',
            create: 'Add New Order',
            edit  : 'Edit Order',
        },
        pre: {
            title : 'Pre-Order',
            create: 'Add New Pre-Order',
            edit  : 'Edit Pre-Order',
        },
        claim: {
            title  : 'Claims',
            create : 'Add New Claim',
            edit   : 'Edit Claim',
            process: 'Process Claim',
        },

        bill: {
            title: 'Order Bill',
        },
    },
    
};
