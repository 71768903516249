<template>
    <y-loading
        :active="isLoading"
        height="70px"
    >
        <div id="navbar">
            <div class="navbar-content">
                <div class="right">
                    <div
                        id="logo"
                        :class="['left-separator', {collapsed}]"
                    >
                        <router-link
                            to="/"
                            aria-label="Home"
                        >
                            <!-- Panel Logo and Title -->
                            <span class="full">

                                <template v-if="navbar && navbar.brand">
                                    <img
                                        v-if="navbar.brand.logo"
                                        :src="navbar.brand.logo"
                                        :alt="navbar.brand.name"
                                        height="50"
                                    >
                                    <span
                                        v-else
                                        class="title"
                                    >
                                        {{ navbar.brand.name }}
                                    </span>
                                </template>

                                <img
                                    v-else
                                    src="@/assets/images/quarkino-logo.png"
                                    alt="Yasnateam"
                                    height="50"
                                >

                            </span>
                            <span
                                class="icon md-home small"
                                aria-label="Home"
                            />
                        <!-- !END Panel Logo and Title -->
                        </router-link>
                    </div>
                    <div class="sidebar-toggle">
                        <y-button
                            :class="sidebarToggleClass"
                            @click.prevent="toggleSidebar"
                        />
                    </div>
                    <div
                        v-if="navbarRight.length"
                        class="function"
                    >
                        <div
                            v-for="item in navbarRight"
                            :key="item.id"
                            class="function"
                        >
                            <y-dropdown
                                v-if="item.children && item.children.length"
                                position="bottom-left"
                                class="user-dropdown"
                            >
                                <template slot="toggle">
                                    <a
                                        href="#"
                                        class="icon"
                                        :class="item.icon"
                                    />
                                </template>

                                <template v-for="child in sortByOrder(item.children)">
                                    <y-dropdown-item
                                        :key="child.id"
                                        :type="getDropdownType(child.type)"
                                        :to="generateLink(child)"
                                        :label="child.badge.title"
                                        :label-color="child.badge.color"
                                        :badge="!!+child.badge.title"
                                    >
                                        <i
                                            v-if="child.hasOwnProperty('icon') && child.icon.length"
                                            class="icon"
                                            :class="child.icon"
                                        />
                                        {{ child.link.title }}
                                    </y-dropdown-item>
                                </template>
                            </y-dropdown>

                            <router-link
                                v-else
                                class="icon"
                                :class="item.icon"
                                :to="generateLink(item)"
                            />
                        </div>
                    </div>
                </div>

                <div class="left">
                    <div
                        id="nav-user"
                        class="function right-separator"
                    >
                        <y-dropdown
                            position="bottom-left"
                            class="user-dropdown"
                        >
                            <!-- User Profile Icon -->
                            <template slot="toggle">
                                <a
                                    href="#"
                                    class="user"
                                >

                                    <!-- Profile Pic -->
                                    <span class="avatar">
                                        <img
                                            v-if="profile && profile.avatar"
                                            :src="profile.avatar[0].link"
                                            alt="avatar"
                                        >

                                        <i
                                            v-else
                                            class="icon md-account-circle"
                                        />
                                    </span>
                                    <!-- !END Profile Pic -->

                                    <!-- User Name -->
                                    <h6 v-if="profile">{{ profile.name }}</h6>
                                    <h6 v-else>{{ $t('fields.username') }}</h6>
                                    <!-- !END User Name -->

                                    <i class="icon md-keyboard-arrow-down" />
                                </a>
                            </template>
                            <!-- !END User Profile Icon -->

                            <!-- User Dropdown Menu -->
                            <template v-if="profileMenu.length">
                                <y-dropdown-item
                                    v-for="item in profileMenu"
                                    :key="item.id"
                                    :type="getDropdownType(item.type)"
                                    :to="generateLink(item)"
                                    :label="item.badge.title"
                                    :label-color="item.badge.color"
                                    :badge="!!+item.badge.title"
                                >
                                    <i
                                        v-if="item.hasOwnProperty('icon') && item.icon.length"
                                        class="icon"
                                        :class="item.icon"
                                    />
                                    {{ item.label }}
                                </y-dropdown-item>
                            </template>
                        <!-- !END User Dropdown Menu -->
                        </y-dropdown>
                    </div>

                    <!-- Exit Impersonate Mode Button -->
                    <y-button
                        v-if="isImpersonate"
                        class="function"
                        color="red"
                        @click="exitImpersonate"
                    >
                        {{ $t('button.exit_impersonate') }}
                    </y-button>

                    <!-- Refresh button -->
                    <y-button
                        icon="md-refresh"
                        aria-label="Refresh"
                        class="function"
                        @click.native.prevent="refreshLayout"
                    />

                    <!-- Plus button -->
                    <y-dropdown
                        v-if="createButton.length"
                        position="bottom-right"
                        class="function user-dropdown"
                    >
                        <template slot="toggle">
                            <div class="button icon md-plus" />
                        </template>

                        <!-- User Dropdown Menu -->
                        <y-dropdown-item
                            v-for="item in createButton"
                            :key="item.id"
                            type="router-link"
                            :to="item.link"
                            :label="item.badge.title"
                            :label-color="item.badge.color"
                        >
                            {{ item.label }}
                        </y-dropdown-item>
                    <!-- !END User Dropdown Menu -->
                    </y-dropdown>

                    <y-notifications-button v-if="notifModuleEnabled" />

                    <template v-if="navbarLeft.length">
                        <div
                            v-for="item in navbarLeft"
                            :key="item.id"
                            class="function"
                        >
                            <y-dropdown
                                v-if="item.children && item.children.length"
                                position="bottom-left"
                                class="user-dropdown"
                            >
                                <template slot="toggle">
                                    <a
                                        href="#"
                                        class="icon"
                                        :class="item.icon"
                                    />
                                </template>

                                <template v-for="child in sortByOrder(item.children)">
                                    <y-dropdown-item
                                        :key="child.id"
                                        :type="getDropdownType(child.type)"
                                        :to="generateLink(child)"
                                        :label="child.badge.title"
                                        :label-color="child.badge.color"
                                        :badge="!!+child.badge.title"
                                    >
                                        <i
                                            v-if="child.hasOwnProperty('icon') && child.icon.length"
                                            class="icon"
                                            :class="child.icon"
                                        />
                                        {{ child.label }}
                                    </y-dropdown-item>
                                </template>
                            </y-dropdown>

                            <router-link
                                v-else
                                class="icon"
                                :class="item.icon"
                                :to="generateLink(item)"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </y-loading>
</template>

<script>

    import { YDropdown, YDropdownItem } from '@deps';
    import { YNotificationsButton } from '@deps/notificationsButton';

    export default {
        name: 'NavBar',

        components: {
            YDropdown,
            YDropdownItem,
            YNotificationsButton,
        },

        props: {
            collapsed: Boolean,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                isLoading: !this.$store.getters['layout/isLayoutLoaded'],
                user     : this.$store.getters['auth/user'],
                navbar   : this.$store.getters['layout/navbar'],
                profile  : this.$store.getters['layout/profile'],
                buttons  : this.$store.getters['layout/createButton'],
            };
        },

        computed: {

            /**
             * Load profile menu items
             *
             * @returns {*|unknown[]|Array}
             */
            profileMenu() {
                if (this.profile && this.profile.hasOwnProperty('children')) {
                    const list = this.profile.children;

                    return this.sortByOrder(list);
                }

                return [];
            },

            /**
             * Load button menu items
             *
             * @returns {*|unknown[]|Array}
             */
            createButton() {
                if (this.buttons) {
                    const menu = this.buttons;
                    return Object.keys(menu).sort((a, b) => menu[a].order - menu[b].order).map((item) => menu[item]);
                }

                return [];
            },

            /**
             * Load navbar right part items
             *
             * @returns {*|unknown[]|Array}
             */
            navbarRight() {
                if (this.navbar && this.navbar.hasOwnProperty('right')) {
                    return this.sortByOrder(this.navbar.right);
                }

                return [];
            },

            /**
             * Load navbar left items
             *
             * @returns {*|unknown[]|Array}
             */
            navbarLeft() {
                if (this.navbar && this.navbar.hasOwnProperty('left')) {
                    return this.sortByOrder(this.navbar.left);
                }

                return [];
            },

            /**
             * Check if user is in impersonate mode
             */
            isImpersonate() {
                return localStorage.getItem('admin_token');
            },
            /**
             * compute sidebar toggler icon based on sidebar collapse status
             */
            sidebarToggleClass() {
                return [
                    'icon',
                    this.collapsed
                        ? 'md-menu'
                        : 'md-menu-open',
                ];
            },

            /**
             * check if notifier module is active
             */
            notifModuleEnabled() {
                return !!this.$store.getters['layout/state'].modules?.find((module) => module === 'Notifier');
            },
        },

        watch: {
            /**
             * Watch state of user
             */
            '$store.state.auth.user': function () { // eslint-disable-line func-names
                this.user = this.$store.getters['auth/user'];
            },

            /**
             * Watch if navbar layout is loaded
             */
            '$store.state.layout.layout.navbar': function () { // eslint-disable-line func-names
                this.navbar = this.$store.getters['layout/navbar'];
            },

            /**
             * Watch if profile layout is loaded
             */
            '$store.state.layout.layout.profile': function () { // eslint-disable-line func-names
                this.profile = this.$store.getters['layout/profile'];
            },

            /**
             * Watch items of buttons
             */
            '$store.state.layout.layout.createButton': function () { // eslint-disable-line func-names
                this.buttons = this.$store.getters['layout/createButton'];
            },

            /**
             * Watch if layout is loaded
             */
            '$store.state.layout.isLayoutLoaded': function () { // eslint-disable-line func-names
                this.isLoading = !this.$store.getters['layout/isLayoutLoaded'];
            },
        },

        methods: {

            /**
             * Refresh layout
             */
            refreshLayout() {
                this.$emit('refresh');
            },

            /**
             * Toggle sidebar
             */
            toggleSidebar() {
                this.$root.$emit('toggleSidebar');
            },

            /**
             * Get type of dropdown
             *
             * @param type
             * @returns {string|boolean}
             */
            getDropdownType(type) {
                if (type === 'item') {
                    return 'router-link';
                }

                if (type === 'header') {
                    return 'header';
                }

                if (type === 'divider') {
                    return 'divider';
                }

                return false;
            },

            /**
             * Generate link object to use in router link
             *
             * @param item
             * @returns {{query: null, name: (*|null), params: (*|null)}|null}
             */
            generateLink(item) {
                if (Array.isArray(item.link) || typeof item.link === 'string') {
                    return '#';
                }
                return item.link;
            },

            /**
             * Sort by order
             *
             * @param list
             * @returns {*[]}
             */
            sortByOrder(list) {
                return Object.keys(list).sort((a, b) => list[a].order - list[b].order).map((category) => list[category]);
            },

            /**
             * Exit user from impersonate mode
             */
            exitImpersonate() {
                const token = localStorage.getItem('admin_token');
                this.$router.push({
                    name  : localStorage.getItem('impersonate_route_name'),
                    params: JSON.parse(localStorage.getItem('impersonate_route_params')),
                });
                localStorage.setItem('impersonate_refresh', 'exitImpersonate');
                localStorage.removeItem('impersonate_route');
                localStorage.removeItem('impersonate_route_name');
                localStorage.removeItem('impersonate_route_params');
                localStorage.setItem('token', token);
                localStorage.removeItem('admin_token');
            },

        },
    };
</script>
