/*-----------------------------------------------------------------
- Profile trans
-----------------------------------------------------------------*/
export default {
    
    generate_backup_codes: 'Generate Backup Codes',
    google_code          : 'Activation Code',
    backup_codes         : 'Backup Codes',

    receive_channel: 'Receive Channel',
    recovery_token : 'Password Recovery Token',

    roles: {
        is_unique             : 'Should be unique',
        display               : 'Display',
        required              : 'Required',
        group                 : 'Group',
        order                 : 'Order',
        multi_address         : 'Enable Multi-address feature.',
        multi_phone           : 'Enable Multi-phone feature.',
        has_country_code      : 'Get country code with Mobile Number.',
        phone_has_country_code: 'Get country code with Phone Number.',
        phone_validation      : 'Telephone should be validated.',
    },

    request_password_recovery: 'Request Password Recovery',
    request_otp              : 'Login with OTP',
    select_channel           : 'Submit channel',
    verify_token             : 'Enter your received code',
    
};
