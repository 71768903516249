<template>
    <y-panel
        :title="element.label"
        class="content-tagging"
    >
        <template slot="functions">
            <y-button
                size="sm"
                aria-label="Refresh"
                icon="md-refresh"
                @click.native.prevent="fetch"
            />
        </template>

        <y-loading
            :active="$wait.is('loading-post-category')"
            height="150px"
        >
            <template v-if="folders && folders.length">
                <y-panel
                    v-for="(folder, index) in folders"
                    :key="`tagging-folder-${folder.slug}-${index}`"
                    class="tagging-folder"
                    :title="folder.title"
                    icon="md-tag"
                >
                    <template slot="functions">
                        <y-button
                            v-if="element.has_setting_button"
                            size="xs"
                            tag="router-link"
                            :to="manageLink(folder.slug)"
                            icon="md-settings"
                        />
                    </template>

                    <template v-if="folder.labels && folder.labels.length">
                        <div class="tagging-nested">
                            <y-nested-loop
                                v-model="model[folder.slug]"
                                :items="listToTree(folder.labels)"
                            />
                        </div>
                    </template>

                    <!-- Show empty state -->
                    <y-empty-state
                        v-else
                        no-image
                        :message="$t('content.post_category.no_label')"
                        height="70"
                        class="tagging-folder-empty"
                    />
                </y-panel>
            </template>

            <!-- Show empty state -->
            <y-empty-state
                v-else
                :message="$t('content.post_category.no_folder')"
                height="250"
                class="mb0"
            />
        </y-loading>
    </y-panel>
</template>

<script>

    import YEmptyState from '@deps/EmptyState';
    import { listToTree } from '@nodes/helpers/misc';

    export default {

        name: 'YFormPostCategoryPicker',

        components: {
            YNestedLoop: () => import('@deps/form/elements/Tagging/Nested'),
            YEmptyState,
        },

        props: {
            /**
             * Element of slug
             */
            element: Object,

            /**
             * Input Value
             */
            value: { // eslint-disable-line vue/require-prop-types
                default: null,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {},

                folders      : null,
                replaceResult: true,
                postCategory : [],
            };
        },

        watch: {

            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },

            /**
             * Watch value set new model
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.fetch();
        },

        methods: {
            /**
             * Fetch Method
             */
            fetch() {
                this.fetchData('postCategory', 1, this.element.type_id);
            },

            /**
             * Fetch Category Data
             *
             * @param target {String}
             * @param nested {Number} 1 | 0
             * @param typeId {String}
             */
            fetchData(target, nested, typeId) {
                this.$wait.start('loading-post-category');
                const params = {
                    id  : this.$route.params.id || null,
                    type: typeId,
                    nested,
                };
                this.$services.Tagging.fetchContentComponent(params).then((response) => {
                    if (this.replaceResult) {
                        // Convert categories items type from Array into Object
                        // To sync category picker input model and output model.
                        Object.keys(response.data.results).forEach((cat) => {
                            const mappedItems = {};
                            response.data.results[cat].forEach((item) => {
                                mappedItems[item] = 1;
                            });
                            this.$set(this.model, cat, mappedItems);
                        });
                        this.replaceResult = false;
                    }
                    this.folders = response.data.metadata.folders;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('loading-post-category');
                });
            },

            /**
             * Return manage link
             *
             * @param slug
             */
            manageLink(slug) {
                return {
                    name  : 'content-tagging-manage',
                    params: {
                        slug,
                    },
                };
            },

            listToTree,

        },

    };
</script>
