/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    stock: {
        list  : 'نرخ‌نامه',
        prices: 'تاریخچه',
    },
    
};
