/*-----------------------------------------------------------------
- Auth for Vuex
-----------------------------------------------------------------*/
/* eslint no-shadow: 0, no-param-reassign: 0, consistent-return: 0, no-confusing-arrow: 0 */
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import Http from '@nodes/services/Http';
import appLocales from '@/configs/locales';

type token = {
  payload: any | undefined,
};

const locales = (process.env.VUE_APP_THEME === 'true' && process.env.VUE_APP_LOCALES)
    ? process.env.VUE_APP_LOCALES.split(',')
    : appLocales.locales;

const state = {
    isLoggedIn  : false,
    token       : localStorage.getItem('token') || '',
    id          : null,
    user        : {},
    roles       : [],
    isAdmin     : false,
    isDeveloper : false,
    locale      : null,
    twoFA       : false,
    isTokenValid: false,
    redirect    : null,
    timezone    : null,
};

const getters = {
    state       : (state) => state,
    isLoggedIn  : (state) => state.isLoggedIn,
    user        : (state) => state.user,
    roles       : (state) => state.roles,
    id          : (state) => state.id,
    isAdmin     : (state) => state.isAdmin,
    isDeveloper : (state) => state.isDeveloper,
    locale      : (state) => state.locale,
    twoFA       : (state) => state.twoFA,
    isTokenValid: (state) => state.isTokenValid,
    redirect    : (state) => state.redirect,
    timezone    : (state) => state.timezone,

    forceChangePassword: (state) => state.forceChangePassword,
};

const mutations = {
    
    /**
     * Login user
     *
     * @param {state} state
     * @param {string} token
     * @returns {boolean}
     */
    login(state, token) {
        if (typeof token !== 'string') {
            return false;
        }

        const t: token = {
            payload: jwtDecode(token),
        };

        state.isLoggedIn = true;
        state.isTokenValid = true;
        state.token = token;
        state.user = cloneDeep(t.payload.payload);
        state.id = t.payload.payload.id;
        state.roles = t.payload.payload.roles;
        state.isAdmin = t.payload.payload.admin;
        state.isDeveloper = t.payload.payload.dev;
        state.twoFA = t.payload.payload['2fa'];
        state.locale = get(t.payload.payload, 'preferences.panel_locale', locales[0]);
        state.timezone = get(t.payload.payload, 'preferences.timezone', null);
        Http.defaults.headers.common.Authorization = `Bearer ${token}`;
        if (state.timezone) {
            Http.defaults.headers.common.timezone = state.timezone;
        }

        localStorage.setItem('token', token);
    },
    
    /**
     * Check user state
     *
     * @param state
     * @param user
     */
    check(state, user) {
        state.isLoggedIn = true;
        state.user = cloneDeep(user);
        state.id = user.id;
        state.roles = user.roles;
        state.isAdmin = user.admin;
        state.isDeveloper = user.dev;
    },
    
    /**
     * Logout user
     *
     * @param {state} state
     */
    logout(state) {
        localStorage.removeItem('token');
        if (localStorage.getItem('admin_token')) {
            localStorage.removeItem('admin_token');
        }
        state.isLoggedIn = false;
        state.user = [];
        state.token = null;
        state.id = null;
        state.roles = [];
        state.isAdmin = false;
        state.isTokenValid = false;
        delete Http.defaults.headers.common.Authorization;
        delete Http.defaults.headers.common.timezone;
        Cookies.remove('token');
    },
    
    /**
     * check token is valid from vuex
     *
     * @param {state} state
     * @param {boolean} isValid
     */
    checkToken(state, isValid) {
        state.isTokenValid = isValid;
    },
    
    /**
     * Set redirect for after login
     *
     * @param {state} state
     * @param {string} redirect
     */
    setRedirect(state, redirect) {
        if (!redirect.name.startsWith('auth')) {
            state.redirect = redirect;
        }
    },
    
    /**
     * Reset redirect
     *
     * @param {state} state
     */
    resetRedirect(state) {
        state.redirect = null;
    },

    /**
     * Change ForcePasswordChange
     *
     * @param state
     * @param {boolean} forceChangePassword
     */
    setForcePasswordChange(state, forceChangePassword) {
        state.forceChangePassword = forceChangePassword;
    },
};

const actions = {
    /**
     * Check user
     *
     * @param commit
     * @param commit.commit
     * @param user
     */
    check({ commit }, user) {
        commit('check', user);
    },

    /**
     * Login user
     *
     * @param commit
     * @param commit.commit
     * @param token
     */
    login({ commit }, token) {
        commit('login', token);
    },

    /**
     * Logout user
     *
     * @param commit
     * @param commit.commit
     */
    logout({ commit }) {
        commit('logout');
    },

    /**
     * Checking Token
     *
     * @param root0
     * @param root0.commit
     * @param isValid
     */
    checkToken({ commit }, isValid) {
        commit('checkToken', isValid);
    },

    /**
     * Set redirect
     *
     * @param root0
     * @param root0.commit
     * @param redirect
     */
    setRedirect({ commit }, redirect) {
        commit('setRedirect', redirect);
    },

    /**
     * Reset redirect
     *
     * @param root0
     * @param root0.commit
     */
    resetRedirect({ commit }) {
        commit('resetRedirect');
    },
};

export default {
    namespaced: true,
    
    state,
    getters,
    mutations,
    actions,
};
