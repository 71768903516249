/*-----------------------------------------------------------------
- Components translations
-----------------------------------------------------------------*/

export default {
    
    dropzone: {
        
        drag: {
            single  : 'فایل را اینجا رها کنید!',
            multiple: 'فایل‌ها را اینجا رها کنید!',
        },
        
        button: {
            small   : 'انتخاب فایل',
            single  : 'یا فایل خود را انتخاب کنید',
            multiple: 'یا فایل‌های خود را انتخاب کنید',
        },
        
        add: 'افزودن فایل',
        
    },
    
    select: {
        placeholder      : 'از میان گزینه‌ها انتخاب کنید.',
        placeholderSearch: 'انتخاب کنید یا جستجو نمایید.',
    },

    multi_pick_list: {
        placeholder    : 'از میان گزینه‌ها انتخاب کنید.',
        is_searching   : 'انتخاب کنید یا جستجو نمایید.',
        select_label   : 'انتخاب پست ویدیو',
        duplicate_error: 'خطای دابل کلیک',
        title          : 'عنوان ویژگی مطالب مرتبط',
    },

    stock_amount: {
        add_first: 'نرخی اضافه نشده است.',
    },
    
    images: {
        empty: 'فایل ضمیمه‌ای یافت نشد!',
    },
    
};
