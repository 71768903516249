/*-----------------------------------------------------------------
- Breadcrumb plugin to use in panel and themes
-----------------------------------------------------------------*/
import Mixin from './mixin';
import Store from './store';

const plugin = {
    
    options: {
        store: null,
    },
    
    /**
     * Create breadcrumb item
     *
     * @param to
     * @param label
     */
    bc(to, label) {
        if (!label) {
            return to;
        }
        
        const link = typeof to === 'string' ? { name: to } : to;

        return {
            link,
            label,
        };
    },
    
    /**
     * Install plugin
     *
     * @param Vue
     * @param options
     */
    install(Vue, options = {}) {
        // Check if plugin is installed
        if (Vue.__breadcrumbs_installed) {
            return;
        }
        Vue.__breadcrumbs_installed = true; // eslint-disable-line no-param-reassign
        
        // Init options
        Object.keys(options).forEach((key) => {
            if (key in this.options) {
                this.options[key] = options[key];
            }
        });
        
        Vue.prototype.$bc = this.bc; // eslint-disable-line
        
        // Register Vuex module
        if (options && options.store) {
            options.store.registerModule('breadcrumbs', Store);
        }
        
        Vue.mixin(Mixin());
    },
    
};

export default plugin;
