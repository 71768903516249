/*-----------------------------------------------------------------
- Shipping trans
-----------------------------------------------------------------*/
export default {

    title: {
        short_name: 'روش‌های حمل',
        create    : 'ایجاد روش حمل',
        edit      : 'ویرایش روش حمل',
    },

    editor: {
        shipping_price: 'کرایه پایه',
        
        rules: {
            title          : 'قوانین',
            available_rules: 'قوانین موجود',
            selected_rules : 'قوانین انتخاب‌شده',
            rule_settings  : 'تنظیمات قانون {0}',
            empty          : 'قانونی یافت نشد.',
            
            divisions: {
                allowed    : 'مجاز',
                not_allowed: 'غیرمجاز',
                empty      : 'برای اضافه کردن مناطق {0} از فرم بالا استفاده کنید.',
            },
        },
        messages: {
            success: 'روش حمل و نقل با موفقیت ذخیره شد.',
        },

        tabs: {
            base: 'مشخصات پایه',
        },
    },

    custom_fields: {
        title: 'فیلد‌های اختصاصی',

        types: {
            text: 'توضیحات',
            date: 'تاریخ',
            file: 'فایل',
        },

        statuses: {
            sent     : 'ارسال شده',
            delivered: 'تحویل شده',
        },
    },

    delivery_intervals: {
        title                     : 'بازه‌های تحویل',
        intervals                 : 'بازه‌های زمانی',
        method_booking_days_helper: 'تعداد روز‌هایی که کاربر در هنگام ثبت سفارش می‌تواند از بین آن‌ها انتخاب کند.',
    },
};
