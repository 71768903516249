/*-----------------------------------------------------------------
- Store configs
-----------------------------------------------------------------*/

const store = {
    use            : true,
    key            : process.env.VUE_APP_THEME,
    storage        : 'local', // window.localStorage, cookie
    expires        : 7, // In days, Only works for cookie storage
    modules        : ['auth', 'settings', 'cart', 'division'],
    sharedMutations: true,
};

export default store;
