/*-----------------------------------------------------------------
- Profile trans
-----------------------------------------------------------------*/

export default {
    
    history: {
        title       : 'Login History',
        date        : 'Date',
        browser     : 'Browser',
        ip          : 'IP',
        os          : 'Operating System',
        status      : 'Status',
        successful  : 'Successful',
        unsuccessful: 'Unsuccessful',
    },
    
    password: {
        title: 'Change Password',
    },
    
    edit: {
        title       : 'Edit Profile',
        addresses   : 'Addresses',
        make_default: 'Set as default',
    },
    
    preferences: {
        title : 'Profile Preferences',
        locale: 'Panel Language',
    },

    notification: {
        title: 'Notifications Preferences',

        driver: {
            browser : 'Browser',
            database: 'Database',
            email   : 'Email',
            sms     : 'SMS',
            pattern : 'Patterned SMS',
        },

        message: {
            success         : 'Notifications change saved successfully.',
            search_no_result: 'No notifications found.',
            empty           : 'There is no notification',
        },
    },
    
    google: {
        activate                   : 'Activate Google 2FA.',
        title                      : 'Google 2FA',
        backup_codes               : 'Backup Codes',
        text                       : 'To activate Google 2FA, please scan the QR code on your Google Authenticator app. Then enter active code and click on save button.',
        // eslint-disable-next-line max-len
        activate_backup_codes_desc : 'Google 2FA has been activated successfully. Below codes are backup codes which be hidden forever after closing this modal. So, please write them somewhere and keep them safe. Of course, you can ask for new codes later.',
        new_backup_codes_desc      : 'Your new backup codes generated successfully. Please write them somewhere and keep them safe as it will not be shown as you close this modal.',
        generated_backup_codes_desc: 'Please keep these codes somewhere.These codes will be needed to retrieve your account.',
        backup_codes_desc          : 'Each of these codes can be used just one time. Every time you ask for backup codes, we will generate new codes and old ones will not work.',
        used_codes                 : 'Used codes: {count} from 6 codes.',
        
        deactivate        : 'Deactivate Google 2FA.',
        deactivate_text   : 'You can deactivate Google 2FA by entering active code on app or one of your backup codes.',
        deactivate_success: 'Google 2FA has been deactivated successfully.',
        
        activate_success: '2FA Activated.',
    },
    
};
