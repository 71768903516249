<template>
    <div>
        <y-chart
            class="coupon-echarts"
            :series="series"
            :options="options"
        />
    </div>
</template>

<script>

    import { digits } from '@nodes/helpers/number';
    import YChart from '@deps/Chart';

    export default {
        name: 'YFormCouponCodePieChart',
        
        components: {
            YChart,
        },

        props: {
            value  : [String, Object],
            element: Object,
        },
        
        /**
         * @inheritdoc
         */
        data() {
            return {
                options: {},
            };
        },

        computed: {
            /**
             * Series of the widget
             */
            series() {
                const { data } = this.element;
                return [
                    {
                        type  : 'pie',
                        width : '155px',
                        height: '155px',
                        grid  : {
                            height: '35%',
                        },
                        label: {
                            show      : false,
                            position  : 'outside',
                            color     : 'rgba(96, 125, 139, 1)',
                            fontFamily: '"Vazir", sans-serif',
                        },
                        tooltip: {
                            trigger  : 'item',
                            formatter: this.tooltip,
                            textStyle: {
                                fontFamily: '"Vazir", sans-serif',
                            },
                        },
                        data,
                    },
                ];
            },

            /**
             * Tooltip Formatter
             */
            tooltip() {
                const total = this.element.total_codes;
                return ((params) => `${params.data.name}: ${digits(((params.data.value * 100) / total), this.$i18n.locale)}%`);
            },
        },
    };
</script>
