<template>
    <y-modal
        ref="Modal"
        size="lg"
        :title="$t('dashboard.widget.library')"
        @close="reset"
    >
        <div slot="body">
            <y-loading
                :active="!widgets"
                height="300px"
            >
                <div class="widgets-library">
                    <div
                        v-for="(widget, id) in widgets"
                        :key="`widget-lib-${id}`"
                        class="item"
                    >
                        <i
                            class="icon"
                            :class="[widget.icon]"
                        />
                        <div class="title color-gray">
                            {{ widget.title }}
                        </div>
                        <y-button
                            size="xs"
                            class="color-blue mt10"
                            disabled-on="adding-widget"
                            @click.native.prevent="addWidget(widget, id)"
                        >
                            {{ $t('button.add') }}
                        </y-button>
                    </div>
                </div>
            </y-loading>
        </div>
    </y-modal>
</template>

<script>
    import EventBus from '@/mixins/EventBus';
    import { YModal } from '@deps/index';

    export default {
        name: 'WidgetsLibraryModal',

        components: {
            YModal,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                widgets: null,
            };
        },

        methods: {
            /**
             * Fetch widgets list
             */
            fetch() {
                this.$services.Panel.widgetsRepo().then((response) => {
                    this.widgets = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                    this.close();
                });
            },

            /**
             * Add widget
             *
             * @param widget
             * @param slug
             */
            addWidget(widget, slug) {
                const params = {
                    slug,
                    color: widget.color,
                };
                this.$wait.start('adding-widget');
                this.$services.Panel.widgetInsert(params).then((response) => {
                    EventBus.$emit('addWidgetToList', {
                        id: response.data.metadata.hashid,
                        slug,
                        ...widget,
                    });
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('adding-widget');
                });
            },

            /**
             * Open modal
             */
            open() {
                this.$refs.Modal.open();
                this.fetch();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
            },

            /**
             * Reset modal data
             */
            reset() {
                this.widgets = null;
            },
        },
    };
</script>
