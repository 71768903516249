<template>
    <div class="product-grid-cell">
        <a
            v-if="canViewProduct"
            href="#"
            class="product-title"
            @click.prevent="openGlobalModal('Market-ProductQuickViewModal', { id: productId })"
        >
            {{ title }}
        </a>
        <div
            v-else
            class="product-title"
        >
            {{ title }}
        </div>

        <div class="d-f">
            <div class="product-detail-item">
                <span class="title">{{ $t('fields.ware_count') }}{{ `:` }}</span>
                <span class="value">
                    <router-link
                        v-if="tab !== 'trash'"
                        :to="manageWaresLink"
                    >
                        {{ wareCount || 0 | digits }}
                    </router-link>
                    <span v-else>
                        {{ wareCount || 0 | digits }}
                    </span>
                </span>
            </div>

            <div class="product-detail-item">
                <span class="title">{{ $t('fields.sale_count') }}{{ `:` }}</span>
                <span class="value">{{ Number(saleCount) ? Number(saleCount) : `0` | digits }}</span>
            </div>

            <div
                v-if="labels.length"
                class="product-labels"
            >
                <div class="product-detail-item">
                    <span class="title d-f w-100">{{ $t('fields.labels') }}{{ `:` }}</span>
                    <span
                        v-for="label in labels"
                        :key="`lbs-${label.id}`"
                        class="value label sm"
                    >
                        {{ label.title }}
                    </span>
                </div>
            </div>
        </div>

        <div
            v-if="canEditProduct"
            class="product-detail-item left"
        >
            <span class="value">
                <y-form-field
                    v-model="availability"
                    type="checkbox"
                    class="mb0"
                    name="is_available"
                    size="sm"
                    switch
                    :disabled="called"
                />
            </span>
        </div>

        <div
            v-if="wares.length"
            class="product-wares"
        >
            <table class="table bordered">
                <thead>
                    <tr>
                        <th colspan="5">
                            <div class="product-wares-head">
                                <span>{{ $t('fields.wares') }}</span>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>{{ $t('fields.title') }}</th>
                        <th>{{ $t('fields.original_price') }}</th>
                        <th>{{ $t('fields.sale_price') }}</th>
                        <th>{{ $t('fields.sale_count') }}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="ware in getWares"
                        :key="`ware-${ware.id}`"
                    >
                        <td>{{ ware.title }}</td>
                        <td>
                            <y-form-amount :element="makeAmountObject(ware.original_price, currency)" />
                        </td>
                        <td>
                            <y-form-amount :element="makeAmountObject(ware.sale_price, currency)" />
                        </td>
                        <td>
                            <y-form-amount :element="{value: ware.sale_count}" />
                        </td>
                        <td class="col-min">
                            <!-- Mass Actions -->
                            <y-dropdown
                                v-if="canManageWares"
                                position="bottom-left"
                            >
                                <template slot="toggle">
                                    <y-button size="xs">
                                        {{ $t('button.action') }}
                                    </y-button>
                                </template>

                                <y-form-link
                                    type="link"
                                    :element="makeDeleteLink(ware)"
                                >
                                    <i class="icon md-delete" />
                                    {{ $t('button.delete') }}
                                </y-form-link>
                                <router-link :to="makeWareEditLink(ware)">
                                    <i class="icon md-fountain-pen-tip" />
                                    {{ $t('button.edit') }}
                                </router-link>
                                <y-form-link
                                    type="link"
                                    :element="makeChangePriceLink(ware)"
                                >
                                    <i class="icon md-cash-100" />
                                    {{ $t('market.wares.change_price') }}
                                </y-form-link>
                            </y-dropdown>
                        </td>
                    </tr>
                </tbody>
                <tfoot v-if="wares.length > 2 && !isExpanded">
                    <tr>
                        <th colspan="5">
                            <div class="product-wares-foot">
                                <y-button
                                    size="xs"
                                    :disabled-on="`loading-wares-${productId}`"
                                    @click.native="fetchWares"
                                >
                                    {{ $t('button.load_all') }}
                                </y-button>
                            </div>
                        </th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>
<script>

    import GlobalMixin from '@/mixins/Global';

    import YFormField from '@deps/form/FormField';
    import YFormLink from '@deps/form/elements/Link';
    import { YDropdown } from '@deps';
    import YFormAmount from '@deps/form/elements/Amount';

    /**
     * Image component
     */
    export default {
        name: 'YFormProductGridCell',

        components: {
            YFormField,
            YDropdown,
            YFormAmount,
            YFormLink,
        },

        mixins: [GlobalMixin],

        props: {

            /**
             * Can edit product permission
             */
            canEditProduct: Boolean,

            /**
             * Can manage wares permission
             */
            canManageWares: Boolean,

            /**
             * Can set prices permission
             */
            canSetPrices: Boolean,

            /**
             * Can view product permission
             */
            canViewProduct: Boolean,

            /**
             * Ware currency label
             */
            currency: String,

            /**
             * Check if all wares should be shown
             */
            expanded: Boolean,

            /**
             * Check if all wares should be shown
             */
            isAvailable: [Boolean, Number],

            /**
             * List of labels for this product
             */
            labels: Array,

            /**
             * Product Id to be used in links
             */
            productId: [String, Number],

            /**
             * Sale count
             */
            saleCount: [Number, String],

            /**
             * Product title
             */
            title: String,

            /**
             * Ware count
             */
            wareCount: Number,

            /**
             * List of wares
             */
            wares: Array,

            /**
             * The Tab which ware product is in
             */
            tab: String,

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                manageWaresLink: {
                    name  : 'market-wares-list',
                    params: {
                        type: this.$route.params.type,
                        id  : this.productId,
                    },
                },

                called      : false,
                availability: this.get(this, 'isAvailable'),
                loadedWares : null,
                isExpanded  : false,
            };
        },

        computed: {
            /**
             * Edit link
             */
            link() {
                return {
                    name  : 'market-product-edit',
                    params: {
                        id: this.productId,
                    },
                };
            },

            /**
             * Get wares
             */
            getWares() {
                return this.isExpanded ? this.loadedWares : this.loadedWares.slice(0, 2);
            },
        },

        watch: {
            /**
             * Call endpoint when availability changed
             */
            availability: {
                /**
                 * @inheritDoc
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(!!newVal, !!oldVal)) {
                        this.changeAvailability();
                    }
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.loadedWares = this.wares;
            this.isExpanded = this.expanded;
        },

        methods: {

            /**
             * Create object for amount component
             *
             * @param value
             * @param postfix
             */
            makeAmountObject(value, postfix) {
                return {
                    value,
                    postfix: postfix || '',
                };
            },

            /**
             * Make edit ware link
             *
             * @param ware
             */
            makeWareEditLink(ware) {
                return {
                    name  : 'market-wares-edit',
                    params: {
                        id    : this.productId,
                        type  : this.$route.params.type,
                        wareid: ware.id,
                    },
                };
            },

            /**
             * Make link item
             *
             * @param ware
             */
            makeDeleteLink(ware) {
                return {
                    link: {
                        action : 'openModal',
                        options: {
                            id  : 'Panel-TypicalTrashModal',
                            mass: false,
                        },

                        params: {
                            id    : ware.id,
                            model : 'MarketWare',
                            _title: ware.title,
                        },
                    },
                };
            },

            /**
             * Make change price link
             *
             * @param ware
             */
            makeChangePriceLink(ware) {
                return {
                    link: {
                        action : 'openModal',
                        options: {
                            id: 'Market-WareQuickPriceEditModal',
                        },

                        params: {
                            id                   : ware.id,
                            original_price       : ware.original_price,
                            discount             : ware.discount,
                            sale_price_expires_at: ware.sale_price_expires_at,
                            currency_title       : this.currency,
                        },
                    },
                };
            },

            /**
             * Fetch all wares
             */
            fetchWares() {
                this.$wait.start(`loading-wares-${this.productId}`);
                this.$services.Market.productWares({ id: this.productId }).then((response) => {
                    const wares = response.data.results || [];
                    this.loadedWares = wares.filter((ware) => !ware.deleted_at);
                    this.isExpanded = true;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => this.$wait.end(`loading-wares-${this.productId}`));
            },

            /**
             * Change the availability of the product
             */
            changeAvailability() {
                this.called = true;
                if (this.called) {
                    const params = {
                        id          : this.productId,
                        availability: this.availability,
                    };
                    this.$services.Market.productAvailability(params).then(() => {
                        this.$toast.success(this.$t('market.product.is_available_toast', [this.title]));
                        this.called = false;
                        this.$root.$emit('refreshGrid', { refresh: this.productId });
                    }).catch((error) => {
                        this.handleError(error);
                        this.called = false;
                    });
                }
            },

        },

    };
</script>
