/*-----------------------------------------------------------------
- Components translations
-----------------------------------------------------------------*/

export default {
    
    dropzone: {
        
        drag: {
            single  : 'Drop file here!',
            multiple: 'Drop files here!',
        },
        
        button: {
            small   : 'Select file',
            single  : 'Or select your file',
            multiple: 'Or select your files',
        },
        
        add: 'Add file',
        
    },
    
    select: {
        placeholder      : 'Select from the options.',
        placeholderSearch: 'Select or search.',
    },

    multi_pick_list: {
        placeholder    : 'Select file',
        is_searching   : 'Select or search',
        select_label   : 'Select video post',
        duplicate_error: 'Double click error',
        title          : 'title of related feature',
    },

    stock_amount: {
        add_first: 'No rate has been added',
    },
    
    images: {
        empty: 'No attachments found!',
    },
    
};
