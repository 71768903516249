/*-----------------------------------------------------------------
- ICIB General Locales
-----------------------------------------------------------------*/
export default {
    
    title             : 'صورتحساب فروش کالا و خدمات',
    view_invoice      : 'مشاهده فاکتور',
    number            : 'شماره',
    date              : 'تاریخ صدور',
    seller_title      : 'اطلاعات فروشنده',
    buyer_title       : 'اطلاعات خریدار',
    product_title     : 'مشخصات کالا یا خدمات مورد معامله',
    wares_title       : 'مشخصات کالا',
    transactions_title: 'تراکنش‌ها',
    name              : 'نام',
    eco_no            : 'کد اقتصادی',
    reg_no            : 'شماره ثبت',
    national_no       : 'شناسه ملی',
    code_melli        : 'کد ملی',
    address_full      : 'نشانی کامل',
    address           : 'نشانی',
    address_dynamic   : '{0}، ',
    state             : 'استان',
    county            : 'شهرستان',
    city              : 'شهر',
    zipcode           : 'کد پستی',
    phone             : 'تلفن/نمابر',
    email             : 'ایمیل',

    product: {
        row                  : 'ردیف',
        code                 : 'کد کالا',
        name                 : 'شرح کالا یا خدمات',
        count                : 'تعداد',
        unit                 : 'واحد اندازه‌گیری',
        unit_price           : 'مبلغ واحد',
        total_fee            : 'مبلغ کل',
        discount             : 'مبلغ تخفیف',
        tax                  : 'جمع مالیات و عوارض',
        total_plus_tax       : 'جمع کل',
        total_text           : '(به حروف)',
        total                : 'جمع کل',
        total_coupon_discount: 'تخفیف کوپن',
        shipping             : 'هزینه حمل',
        payable              : 'مبلغ قابل پرداخت',
    },

    wares: {
        row     : 'ردیف',
        count   : 'تعداد',
        title   : 'نام کالا',
        code    : 'کد کالا',
        price   : 'قیمت',
        discount: 'تخفیف',
        tax     : 'مالیات',
        total   : 'جمع کل',
    },

    transactions: {
        row            : 'ردیف',
        date           : 'تاریخ',
        path           : 'مسیر پرداخت',
        amount         : 'مبلغ',
        type           : 'نوع پرداخت',
        method         : 'روش پرداخت',
        status         : 'وضعیت',
        tracking_number: 'شماره پیگیری',
        online         : 'آنلاین',
        offline        : 'آفلاین',
    },
    
    empty_transaction: 'هیچ تراکنشی برای این سفارش ثبت نشده است.',
    empty_product    : 'هیچ محصولی برای این فاکتور ثبت نشده است.',
    empty_wares      : 'هیچ کالایی برای این سفارش ثبت نشده است.',
    seller_sign      : 'مهر و امضای فروشنده',
    buyer_sign       : 'مهر و امضای خریدار',
    desc             : 'توضیحات',
    unit             : 'ریال',
    
    edit_discount: 'ویرایش',
    save_discount: 'ذخیره',
    cancel_edit  : 'انصراف',
    print        : 'چاپ',
    
};
