/*-----------------------------------------------------------------
- Fluorocarbon Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Fetch Brief Single
     *
     * @param {...any} args
     * @returns {*}
     */
    briefFormTimeline: (...args) => Resource.get('yasnateam-brief-form-timeline', args),
    
    /**
     * Brief Change Status
     *
     * @param {...any} args
     * @returns {*}
     */
    briefFormChangeStatus: (...args) => Resource.post('yasnateam-brief-form-change-status', args),
};
