<template>
    <div class="checkboxes">
        <div
            v-for="(val, key) in options"
            :key="name + '-' + key"
            :class="[{checkbox: !isSwitch}, ...classes]"
        >
            <input
                :id="name + '_' + key"
                v-model="model"
                type="checkbox"
                :value="key"
                :disabled="disabled"
                @change="handleCheckbox"
            >

            <label :for="name + '_' + key">
                <template v-if="!noLabel">{{ val }}</template>
            </label>
        </div>
    </div>
</template>

<script>

    import FormElementMixin from '@/mixins/FormElement';
    import SwitchInputMixin from '@/mixins/SwitchInput';

    /**
     * Checkbox List Component
     */
    export default {

        name: 'YFormCheckboxList',

        mixins: [FormElementMixin, SwitchInputMixin],

        props: {

            /**
             * List of Checkbox Options
             */
            options: [Array, Object],

            /**
             * Checkbox List Value
             */
            value: {
                type: Array,
            },

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || [],
            };
        },

        methods: {
            /**
             * Emit input event on checkbox change
             */
            handleCheckbox() {
                this.$emit('input', this.model);
            },
        },

    };

</script>
