/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    persons: {
        addresses: 'Manage Addresses',
        
        users: {
            list         : 'Users',
            edit         : 'Edit',
            print        : 'Print',
            create       : 'Create',
            login_history: 'Login History',
            profile      : 'User Profile',
        },
        
        roles      : 'Roles',
        permissions: 'Permissions',
        
        organizations: 'Organizations',
        
    },
    
    profile: {
        password    : 'Change Password',
        edit        : 'Edit Profile',
        preferences : 'Profile Preferences',
        notification: 'Notifications Preferences',
        history     : 'Login History',
    },
    
};
