/*-----------------------------------------------------------------
- Specs Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Specs  Values List
     *
     * @param {...any} args
     * @returns {*}
     */
    valuesList: (...args) => Resource.get('specs-values-list', args),
    
};
