/*-----------------------------------------------------------------
- Shipping trans
-----------------------------------------------------------------*/
export default {
    
    shipping: {
        title : 'روش‌های حمل',
        create: 'ایجاد روش حمل',
        edit  : 'ویرایش روش حمل',
    },
    
};
