<template>
    <div class="field">
        <label
            v-if="!noLabel"
            for="range"
        >
            {{ label }}
        </label>
        <vue-slider
            id="range"
            v-model="model"
            :dot-size="dotSize"
            :contained="contained"
            :direction="setDirection"
            :data="data"
            :data-value="dataValue"
            :data-label="dataLabel"
            :min="min"
            :max="max"
            :interval="interval"
            :disabled="disabled"
            :adsorb="adsorb"
            :tooltip="tooltip"
            :tooltip-formatter="digits"
            :marks="marks"
            :silent="silent"
            @change="$emit('input', model)"
        >
            <template #mark="{ pos, text }">
                <div
                    class="vue-slider-custom-mark"
                    :style="{ left: `${pos}%` }"
                >
                    {{ text }}
                </div>
            </template>
        </vue-slider>
    </div>
</template>

<script>

    import VueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/antd.css';
    import { digits } from '@nodes/helpers/number';

    export default {
        name: 'YFormRange',

        components: {
            VueSlider,
        },

        props: {
            label    : String,
            value    : [String, Number, Array, Object],
            dotSize  : [Number, Array],
            contained: Boolean,
            direction: String,
            data     : [Array, Object],
            dataValue: String,
            dataLabel: String,
            min      : Number,
            max      : Number,
            // eslint-disable-next-line vue/prop-name-casing
            min_title: String,
            // eslint-disable-next-line vue/prop-name-casing
            max_title: String,
            interval : Number,
            disabled : Boolean,
            adsorb   : Boolean,
            tooltip  : String,
            noLabel  : Boolean,
            silent   : Boolean,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                model: this.value || '',

                digits: (v) => digits(v, this.$i18n.locale),

                marks: {
                    0: {
                        label: this.min_title,
                        pos  : 0,
                    },
                    100: {
                        label: this.max_title,
                        pos  : 100,
                    },
                },
            };
        },

        computed: {
            /**
             * Set direction
             */
            setDirection() {
                const dir = this.$i18n.locale === 'fa' ? 'rtl' : 'ltr';
                return this.direction ? this.direction : dir;
            },
        },

        methods: {
            /**
             * Format separated
             *
             * @param value
             */
            formatSeparated(value) {
                const arr = value.split('.');
                let result = this.comma(arr[0]);

                if (arr.length === 2) {
                    result += `${this.currentLocale.decimal}${arr[1]}`;
                }

                return this.formatNumber(result);
            },
        },
    };

</script>
