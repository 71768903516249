/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    content: {
        post: {
            list  : 'Posts',
            create: 'New Post',
            edit  : 'Edit Post',
        },
        type: {
            list  : 'Content Types',
            create: 'New Content Type',
            edit  : 'Edit Content Type',
        },
    },
    
};
