<template>
    <span
        v-if="value"
        v-clipboard:copy="value"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        class="copy-icon icon copy md-content-copy"
    />
</template>

<script>
    import Vue from 'vue';
    import VueClipboard from 'vue-clipboard2';

    Vue.use(VueClipboard);
    export default {
        name: 'CopyValue',

        props: {
            value: [String, Number],
        },

        methods: {
            /**
             * @inheritdoc
             */
            onCopy() {
                this.$toast.success(this.$t('general.copy_value.copied'));
            },

            /**
             * @inheritdoc
             */
            onError() {
                this.$toast.success(this.$t('general.copy_value.error'));
            },
        },
    };
</script>
