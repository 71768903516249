/*-----------------------------------------------------------------
- ICIB General Locales
-----------------------------------------------------------------*/
export default {

    plans: {
        plural: 'پلن‌های تجاری',
    },

    organization: {
        view_organization: 'مشاهده سازمان',

        type: {
            state  : 'دولتی',
            private: 'خصوصی',
        },

        activity_field: {
            financial: 'مالی',
            apparel  : 'پوشاک',
        },
    },
    
    invoice: {
        view_invoice : 'مشاهده فاکتور',
        title        : 'صورتحساب فروش کالا و خدمات',
        number       : 'شماره',
        date         : 'تاریخ صدور',
        seller_title : 'اطلاعات فروشنده',
        buyer_title  : 'اطلاعات خریدار',
        product_title: 'مشخصات کالا یا خدمات مورد معامله',
        name         : 'نام',
        eco_no       : 'کد اقتصادی',
        reg_no       : 'شماره ثبت',
        national_no  : 'شناسه ملی',
        address_full : 'نشانی کامل',
        address      : 'نشانی',
        state        : 'استان',
        county       : 'شهرستان',
        city         : 'شهر',
        zipcode      : 'کد پستی',
        phone        : 'تلفن/نمابر',
        product      : {
            row_no        : 'ردیف',
            name          : 'شرح کالا یا خدمات',
            number        : 'تعداد',
            unit_price    : 'فی',
            total_fee     : 'جمع',
            discount      : 'تخفیف',
            tax           : 'مالیات و عوارض',
            total_plus_tax: 'جمع کل',
            total_text    : '(به حروف)',
            total         : 'جمع کل',
        },
        seller_sign: 'مهر و امضای فروشنده',
        buyer_sign : 'مهر و امضای خریدار',
        desc       : 'توضیحات',
        unit       : 'ریال',
        
        edit_discount: 'ویرایش',
        save_discount: 'ذخیره',
        cancel_edit  : 'انصراف',
        print        : 'چاپ',
    },
    
};
