/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/
export default {
    
    toast: {
        move_to_trash: {
            success: 'انتقال به زباله‌دان با موفقیت انجام شد.<br> برای بازیابی به زباله‌دان مراجعه کنید.',
            error  : 'انتقال به زباله‌دان با مشکل مواجه شد. مجددا تلاش کنید.',
        },
        
        restore_from_trash: {
            success: 'بازیابی با موفقیت انجام شد.<br> برای مشاهده به لیست اصلی مراجعه کنید.',
            error  : 'بازیابی با مشکل مواجه شد. مجددا تلاش کنید.',
        },
        
        permanent_delete: {
            success: 'آدرس حذف شد.',
            error  : 'حذف با مشکل مواجه شد. مجددا تلاش کنید.',
        },
        
        fetch: {
            error: 'خطای سیستمی! لطفا مجددا تلاش کنید.',
        },
        
        save: {
            success: 'کاربر با موفقیت ذخیره شد.',
            error  : 'در فرایند ذخیره‌سازی مشکلی بوجود آمد. مجددا تلاش کنید.',
        },
        
        edit: {
            success: 'به‌روزرسانی انجام شد.',
            error  : 'در فرایند به‌روزرسانی مشکلی بوجود آمد. مجددا تلاش کنید.',
        },
        
    },
    
    user: {
        create_user: 'کاربر جدید',
        edit_user  : 'ویرایش کاربر',
    },

    profile: {
        title: 'پروفایل {0}',

        tabs: {
            login_history: {
                empty: 'هیچ تاریخچه ورودی برای کاربر ثبت نشده است.',
            },
            transactions: {
                empty: 'هیچ تراکنشی برای کاربر ثبت نشده است.',
            },
            addresses: {
                empty: 'هیچ آدرسی برای کاربر ثبت نشده است.',
            },
        },
    },
    
    new_user: {
        search: {
            placeholder : 'جستجو (نام، نام‌خانوادگی، کد‌ملی،‌ ایمیل، نام‌کاربری)',
            button      : 'جستجو',
            new_search  : 'جستجوی جدید',
            clear       : 'پاک کردن',
            register    : 'ثبت نام کاربر جدید',
            select      : 'انتخاب',
            empty       : 'کاربری یافت نشد.',
            not_searched: 'بین کاربران جستجو کنید و یا کاربر جدید ایجاد کنید.',
        },
        create: {
            new_user         : 'ثبت کاربر جدید',
            add_selected_user: 'ثبت کاربر {0}',
        },
    },
    
    history: {
        title       : 'تاریخچه ورود کاربر',
        date        : 'تاریخ',
        browser     : 'مرورگر',
        ip          : 'IP',
        os          : 'سیستم عامل',
        status      : 'وضعیت',
        successful  : 'موفق',
        unsuccessful: 'ناموفق',
    },
    
    organization: {
        user_list: {
            add: {
                title        : 'افزودن کاربر',
                placeholder  : 'جستجو (نام، نام‌خانوادگی، کد‌ملی،‌ ایمیل، نام‌کاربری)',
                button       : 'افزدون کاربر',
                already_exist: 'موجود است',
                empty        : 'کاربری پیدا نشد',
                success_msg  : 'کاربر با موفقیت افزوده شد.',
            },
            
            remove: {
                button     : 'حذف',
                success_msg: 'کاربر با موفقیت حذف شد.',
            },
        },
    },
};
