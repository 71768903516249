/*-----------------------------------------------------------------
- Subscription trans
-----------------------------------------------------------------*/
export default {

    eco_no          : 'شماره اقتصادی',
    reg_no          : 'شماره ثبت',
    package_image   : 'تصویر بسته اشتراک',
    nationality_code: 'شناسه ملی',

};
