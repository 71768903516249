/*-----------------------------------------------------------------
- Form element mixin
-----------------------------------------------------------------*/
import { snakeToCamel, generateId } from '@nodes/helpers/string';
import YFormFieldWrapper from '@deps/form/FormFieldWrapper';

/**
 * @mixin
 */
export default {
    
    name: 'FormElementMixin',
    
    components: {
        YFormFieldWrapper,
    },
    
    inject: {
        $validator: '$validator',
    },
    
    props: {
        component: {
            type: String,
        },
        
        id: {
            type: [String, Number],
        },
        
        element: {
            type: Object,
        },
        
        /**
         * Input Name
         */
        name: String,
        
        /**
         * Input label
         */
        label: String,
        
        /**
         * Field placeholder
         */
        placeholder: String,
        
        /**
         * Is disabled
         */
        disabled: {
            type   : [Boolean, Number, String],
            default: false,
        },
        
        /**
         * Readonly Input
         */
        readonly: {
            type: Boolean,
        },
        
        /**
         * No label
         */
        noLabel: {
            type: Boolean,
        },
        
        /**
         * Validation rules
         */
        validation: String,
    
        /**
         * Make input direction rtl, ltr, or auto
         */
        dir: {
            type   : String,
            default: 'auto',
        },
        
        /**
         * Input Value
         */
        value: {
            default: null,
        },
    },
    
    /**
     * @inheritDoc
     */
    data() {
        return {
            el       : null,
            keyHelper: generateId(),
            charCount: typeof this.value === 'string' ? this.value.length : 0,
        };
    },
    
    /**
     * @inheritDoc
     */
    mounted() {
        const el = this.$el;
        
        if (el instanceof Element) {
            if (el.hasAttribute('data-vv-as')) {
                this.el = el.getAttribute('data-vv-as');
            } else if (this.name !== null) {
                el.setAttribute('data-vv-as', this.labelValue);
            }
        }
    },
    
    computed: {
        
        /**
         * Make props for field wrapper
         */
        fieldWrapperProps() {
            return {
                ...this.$props,
                labelValue: this.labelValue,
                labelFor  : this.labelFor,
                noLabel   : this.getValue('no_label'),
                hasError  : this.hasError,
                error     : this.error,
            };
        },
        
        /**
         * Return first error for this element
         *
         * @returns {*}
         */
        error() {
            return this.errors.first(this.labelFor);
        },
        
        /**
         * Check if form element has error
         *
         * @returns {*}
         */
        hasError() {
            return this.errors.has(this.labelFor);
        },
        
        /**
         * Return label for target
         *
         * @returns {*|null}
         */
        labelFor() {
            return this.id || this.name;
        },
        
        /**
         * Return label value
         *
         * @returns {*|null}
         */
        labelValue() {
            return this.label
                || this.el
                || this.$t(`fields.${this.name}`);
        },
        
    },
    
    methods: {
        
        /**
         * Emit Target Value
         *
         * @event input
         * @param event
         */
        updateText(event) {
            this.$emit('input', event.target.value);
            if (typeof event.target.value === 'string') {
                this.$set(this, 'charCount', event.target.value.length);
            }
        },
        
        /**
         * Emit "blur" Event
         *
         * @event blur
         * @type {object}
         * @param event
         */
        blurField(event) {
            this.$emit('blur', event);
        },
        
        /**
         * Emit "focus" Event
         *
         * @event focus
         * @type {object}
         * @param event
         */
        focusField(event) {
            this.$emit('focus', event);
        },
        
        /**
         * Remove keyboard focus from the current element
         */
        blur() {
            this.$refs.input.blur();
        },
    
        /**
         * Get field value
         *
         * @param value
         * @param defaultValue
         * @returns {*}
         */
        getValue(value, defaultValue = null) {
            if (this.element) {
                return this.element[value] || defaultValue;
            }
            return this[snakeToCamel(value)] || defaultValue;
        },

        /**
         * Get field value as boolean
         *
         * @param value
         * @param default_value
         * @returns {boolean}
         */
        getBoolValue(value, default_value = false) {
            return !!this.getValue(value, default_value);
        },

    },
    
};
