<template>
    <e-charts
        ref="chart"
        :options="mergedOptions"
    />
</template>

<script>
    import deepMerge from 'deepmerge';
    import ECharts from 'vue-echarts';
    import 'echarts/lib/chart/line';
    import 'echarts/lib/chart/bar';
    import 'echarts/lib/chart/pie';
    import 'echarts/lib/chart/candlestick';
    import 'echarts/lib/component/tooltip';
    import 'echarts/lib/component/legend';

    export default {
        name: 'Chart',

        components: {
            ECharts,
        },

        props: {
            /**
             * Tooltip Data using formatter
             */
            tooltipFormatter: Function,

            /**
             * Tooltip Trigger
             */
            tooltipTrigger: {
                type   : String,
                default: 'axis',
            },

            /**
             * Tooltip axisPointer Type
             */
            axisPointer: {
                type   : String,
                default: 'none',
            },

            /**
             * Tooltip axisPointer Formatter
             */
            axisPointerFormatter: Function,

            /**
             * xAxis Label
             */
            xAxisLabel: {
                type: [Object, Array],
            },

            /**
             * xAxis Type
             */
            xAxisType: {
                type: String,
            },

            /**
             * xAxis Line
             */
            xAxisLine: {
                type   : Boolean,
                default: false,
            },

            /**
             * xAxis Tick
             */
            xAxisTick: {
                type   : Boolean,
                default: false,
            },

            /**
             * yAxis Label Data
             */
            yAxisLabelData: {
                type: [Object, Array],
            },

            /**
             * yAxis Label
             * Formatter allowed
             */
            yAxisLabel: {
                type: [Object, Array, Function],
            },

            /**
             * yAxis Line
             */
            yAxisLine: {
                type   : Boolean,
                default: false,
            },

            /**
             * yAxis Tick
             */
            yAxisTick: {
                type   : Boolean,
                default: false,
            },

            /**
             * yAxis Split Area
             */
            yAxisSplitArea: {
                type   : Boolean,
                default: false,
            },

            /**
             * yAxis Split Line
             */
            yAxisSplitLine: {
                type   : Boolean,
                default: false,
            },

            /**
             * Options for the chart
             * It will be merged with the core options
             */
            options: [Object, Array],

            /**
             * Data of the charts
             * It should be objects of array which represent the chart
             * Type of the chart should be defined in the series props
             */
            series: {
                type    : Array,
                required: true,
            },

        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                core: {
                    grid: {
                        tooltip: {
                            axisPointer: {
                                label: {
                                    fontFamily: this.$i18n.locale === 'fa' ? '"Vazir", sans-serif' : 'sans-serif',
                                    formatter : this.axisPointerFormatter,
                                },
                            },
                        },
                    },
                    tooltip: {
                        trigger  : this.tooltipTrigger,
                        formatter: this.tooltipFormatter,
                        textStyle: {
                            fontFamily: this.$i18n.locale === 'fa' ? '"Vazir", sans-serif' : 'sans-serif',
                        },
                        axisPointer: {
                            type: this.axisPointer,
                        },
                    },
                    xAxis: {
                        data     : this.xAxisLabel,
                        type     : this.xAxisType,
                        axisLabel: {
                            textStyle: {
                                fontFamily: this.$i18n.locale === 'fa' ? '"Vazir", sans-serif' : 'sans-serif',
                            },
                        },
                        axisLine: {
                            show: this.xAxisLine,
                        },
                        axisTick: {
                            show: this.xAxisTick,
                        },
                    },
                    yAxis: {
                        data     : this.yAxisLabelData,
                        axisLabel: {
                            formatter: this.yAxisLabel,
                            textStyle: {
                                fontFamily: this.$i18n.locale === 'fa' ? '"Vazir", sans-serif' : 'sans-serif',
                            },
                        },
                        axisLine: {
                            show: this.yAxisLine,
                        },
                        axisTick: {
                            show: this.yAxisTick,
                        },
                        splitArea: {
                            show: this.yAxisSplitArea,
                        },
                        splitLine: {
                            show: this.yAxisSplitLine,
                        },
                    },
                    series: this.series,
                },
            };
        },

        computed: {
            /**
             * Merged Options (Core and Prop)
             */
            mergedOptions() {
                if (this.options) {
                    return deepMerge(this.core, this.options);
                }
                return this.core;
            },
        },
    };

</script>
