<template>
    <div class="discount-code-modal">
        <form
            id="create-codes-form"
            @submit.prevent="create"
        >
            <div class="row">
                <div class="col-sm-6">
                    <y-form-field
                        v-model="model.length"
                        type="number"
                        :label="$t('coupons.code_generate.length')"
                        validation="required"
                        name="length"
                        @input="inputLength($event)"
                    />
                </div>
                <div class="col-sm-6">
                    <y-form-field
                        v-model="model.count"
                        type="number"
                        format="separated"
                        :disabled="model.length === '0'"
                        :label="$t('coupons.code_generate.count')"
                        validation="required"
                        name="count"
                    />
                </div>
            </div>

            <div class="row">
                <div
                    v-if="model.length !== '0'"
                    class="col-sm-4"
                >
                    <y-form-field
                        v-model="model.postfix"
                        type="text"
                        :label="$t('coupons.code_generate.postfix')"
                        name="postfix"
                    />
                </div>
                <div
                    v-if="model.length !== '0'"
                    class="col-sm-4"
                >
                    <y-form-field
                        v-model="model.pattern"
                        type="select"
                        :options="patternOptions"
                        :label="$t('coupons.code_generate.pattern')"
                        name="pattern"
                    />
                </div>
                <div :class="model.length !== '0' ? 'col-sm-4' : 'col-sm-12'">
                    <y-form-field
                        v-model="model.prefix"
                        type="text"
                        :label="model.length !== '0' ? $t('coupons.code_generate.prefix') : $t('coupons.code_generate.discount_code')"
                        name="prefix"
                        :validation="model.length === '0' ? 'required' : ''"
                    />
                </div>
            </div>
        </form>

        <div class="code-preview d-f justify-content-around align-items-center">
            <span>{{ $t('coupons.code_generate.preview') }}</span>
            <span class="ltr">{{ sample }}</span>
        </div>
    </div>
</template>

<script>

    import { isEqual } from 'lodash';
    import { digits } from '@nodes/helpers/number';
    import FormMixin from '@/mixins/Form';
    import YFormField from '@deps/form/FormField';

    export default {
        name: 'YFormCouponCodeGenerate',

        components: {
            YFormField,
        },

        mixins: [FormMixin],

        props: {
            element: [Object, Array],
            value  : [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {
                    pattern: 1,
                    length : 6,
                },

                patternOptions: [
                    {
                        label: this.$t('coupons.code_generate.pattern_select.numeric'),
                        value: 1,
                    },
                    {
                        label: this.$t('coupons.code_generate.pattern_select.alpha'),
                        value: 2,
                    },
                    {
                        label: this.$t('coupons.code_generate.pattern_select.mix'),
                        value: 3,
                    },
                ],
            };
        },

        computed: {
            /**
             * Return modal title
             */
            title() {
                return this.$t('coupons.code_generate.title');
            },

            /**
             * Generate sample code based on pattern
             */
            patternSample() {
                let sample;
                if (this.model.pattern === 1) {
                    sample = this.randomNumeric;
                } else if (this.model.pattern === 2) {
                    sample = this.randomAlphaNumeric(true);
                } else if (this.model.pattern === 3) {
                    sample = this.randomAlphaNumeric();
                }
                return sample;
            },

            /**
             * Return Sample
             */
            sample() {
                let sample = this.patternSample;
                if (this.model.length === '0') {
                    sample = this.model.prefix;
                } else if (this.model.prefix && this.model.postfix) {
                    sample = `${this.model.prefix}-${this.patternSample}-${this.model.postfix}`;
                } else if (this.model.prefix && !this.model.postfix) {
                    sample = `${this.model.prefix}-${this.patternSample}`;
                } else if (!this.model.prefix && this.model.postfix) {
                    sample = `${this.patternSample}-${this.model.postfix}`;
                }
                return sample;
            },

            /**
             * Length
             */
            length() {
                return this.model.length ? this.model.length : 6;
            },

            /**
             * Generate Random Number
             */
            randomNumeric() {
                return Math.floor((10 ** (this.length - 1)) + Math.random() * ((10 ** (this.length - 1)) * 9));
            },
        },

        watch: {
            /**
             * Watch value and change model
             *
             * @param val
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(newVal, oldVal) {
                    if (!isEqual(newVal, oldVal)) {
                        this.$set(this, 'model', { ...this.model, ...newVal });
                    }
                },
                deep: true,
            },

            /**
             * Watch model and empty model on length of zero
             */
            'model.length': {
                // eslint-disable-next-line require-jsdoc
                handler(newVal) {
                    if (Number(newVal) === 0) {
                        this.model.count = 0;
                        this.model.postfix = null;
                        this.model.prefix = null;
                    }
                },
                deep: true,
            },

            /**
             * Watch model and emit on change
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            if (this.model.count === 0) {
                this.$set(this.model, 'count', null);
            }
        },

        methods: {
            /**
             * Generate Random MixPattern
             *
             * @param alpha
             */
            randomAlphaNumeric(alpha = false) {
                let result = '';
                let characters = 'ABCDEFGHIJ0123456KL7890123MNOP456789QRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                if (alpha) {
                    characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
                }
                const charactersLength = characters.length;
                // eslint-disable-next-line no-plusplus
                for ( let i = 0; i < this.length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            },

            /**
             * Check the length input value
             *
             * @param length
             */
            inputLength(length) {
                if (length > 20) {
                    this.$toast.error(this.$t('coupons.code_generate.max_length', [digits(20, this.$i18n.locale)]));
                }
            },
        },
    };

</script>
