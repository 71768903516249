/*-----------------------------------------------------------------
- VeeValidate
-----------------------------------------------------------------*/
/* eslint camelcase:0 */

import Vue from 'vue';
import { Validator, install as VeeValidate } from 'vee-validate/dist/vee-validate.minimal.esm.js';
import {
    alpha, alpha_dash, alpha_num, between, confirmed,
    decimal, digits, email, included, excluded, length,
    max, max_value, min, min_value, numeric, regex, required, required_if,
    size, url,
} from 'vee-validate/dist/rules.esm';

// Locales
import en from 'vee-validate/dist/locale/en';
import fa from 'vee-validate/dist/locale/fa';

fa.messages.between = (n, e) => `${n} باید بین ${e[0]} و ${e[1]} باشد`;

Validator.localize('en', en);
Validator.localize('fa', fa);

// Register rules
Validator.extend('alpha', alpha);
Validator.extend('alpha_dash', alpha_dash);
Validator.extend('alpha_num', alpha_num);
Validator.extend('between', between);
Validator.extend('confirmed', confirmed);
Validator.extend('decimal', decimal);
Validator.extend('digits', digits);
Validator.extend('email', email);
Validator.extend('included', included);
Validator.extend('excluded', excluded);
Validator.extend('length', length);
Validator.extend('max', max);
Validator.extend('max_value', max_value);
Validator.extend('min', min);
Validator.extend('min_value', min_value);
Validator.extend('numeric', numeric);
Validator.extend('regex', regex);
Validator.extend('required', required);
Validator.extend('required_if', required_if);
Validator.extend('size', size);
Validator.extend('url', url);

Vue.use(VeeValidate);
