<template>
    <textarea
        :id="labelFor"
        v-model="model"
        :placeholder="placeholder"
        :class="inputClasses"
        :readonly="!!readonly"
        :disabled="!!disabled"
        :cols="cols"
        :rows="rows"
        :no-resize="noResize"
    />
</template>

<script>

    import direction from '@helpers/direction';
    import FormElementMixin from '@/mixins/FormElement';

    /**
     * Textarea Component
     */
    export default {

        name: 'YFormArray',

        mixins: [FormElementMixin],

        props: {

            /**
             * Input Name
             */
            name: String,

            /**
             * Input Value
             */
            value: { // eslint-disable-line vue/require-prop-types
                default: null,
            },

            /**
             * Is Readonly
             */
            readonly: {
                type   : [Boolean, Number, String],
                default: false,
            },

            /**
             * Placeholder Text
             */
            placeholder: {
                type: String,
            },

            /**
             * Is disabled
             */
            disabled: {
                type   : [Boolean, Number, String],
                default: false,
            },

            /**
             * Rows Count
             */
            rows: [String, Number],

            /**
             * Columns Count
             */
            cols: [String, Number],

            /**
             * Disable Resizing
             */
            noResize: {
                type   : [Boolean, Number, String],
                default: false,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value ? this.value.join('\n') : '',
            };
        },

        computed: {

            /**
             * Calculate input classes
             */
            inputClasses() {
                const classes = [];

                if (this.hasError) {
                    classes.push('error');
                }

                if (this.dir === 'auto') {
                    const dir = direction(this.value) || document.dir;
                    const cls = dir === 'rtl' ? 'rtl ta-r' : 'ltr ta-l';
                    classes.push(cls);
                }

                return classes;
            },

        },

        watch: {

            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    const value = val ? val.join('\n') : '';
                    this.$set(this, 'model', value);
                },
                deep: true,
            },

            /**
             * Watch model and emit
             */
            model() {
                this.$emit('input', this.model.split('\n'));
            },
        },

    };

</script>
