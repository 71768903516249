/* -------------------------------------------------------------------------- */
/* NUMBER HELPERS                                                             */
/* -------------------------------------------------------------------------- */
import { NotValidDigit } from './validate.js';

const numbers = {
    fa: ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'],
    ar: ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'],
    en: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
};

/**
 * Converts English digits into asked locale formatted number
 *
 * @param {*} digit
 * @param {*} locale {string}
 * @returns {string}
 */
export const digits = (digit, locale = 'en') => {
    if (NotValidDigit(digit)) {
        return digit;
    }
    
    return `${digit}`.replace(/[0123456789]/g, (w) => numbers[locale][numbers.en.indexOf(w)]).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (w) => numbers[locale][numbers.fa.indexOf(w)]).replace(/[٠١٢٣٤٥٦٧٨٩]/g, (w) => numbers[locale][numbers.ar.indexOf(w)]);
};

/**
 * Converts English digits into Persian formatted number
 *
 * @param {*} digit
 * @returns {string}
 */
export const pd = (digit) => digits(digit, 'fa');

/**
 * Converts English digits into arabic formatted number
 *
 * @param {*} digit
 * @returns {string}
 */
export const ad = (digit) => digits(digit, 'ar');

/**
 * Converts arabic and
 *
 * @param {*} digit
 * @returns {*}
 */
export const ed = (digit) => digits(digit, 'en');

/**
 * Format Numbers
 *
 * @param {*} number
 * @returns {*}
 */
export const numberFormat = (number) => new Intl.NumberFormat().format(number);

/**
 * Check if a number is between a range
 *
 * @param {number} number 
 * @param {number} min 
 * @param {number} max 
 * @returns {boolean}
 */
export const betweenRange = (number, min, max) => number >= min && number <= max;

/**
 * Generates random integer
 *
 * @param {*} min
 * @param {*} max
 * @returns {number}
 */
export const getRandomInt = (min, max) => {
    min = Math.ceil(min); // eslint-disable-line no-param-reassign
    max = Math.floor(max); // eslint-disable-line no-param-reassign
    return Math.floor(Math.random() * (max - min)) + min;
};

/**
 * Convert to comma separated
 *
 * @param {string|number} value
 * @param {string} separator
 * @returns {string}
 */
export const comma = (value, separator = ',') => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);

/**
 * Format separated
 *
 * @param {string|number} value
 * @param {string} locale
 * @param {boolean} decimal
 * @param {string} decimalChar
 * @returns {string}
 */
export const separated = (value, locale = 'en', decimal = false, decimalChar = '.') => {
    const val = digits(value).replace(/[,٬\s]/g, '').replace('/', '.');
    let result;
    if (decimal) {
        let arr = [];
        arr = val.split(decimalChar);
        result = comma(arr[0]);
        result += `${decimalChar}${arr[1]}`;
    } else {
        result = comma(val);
    }
    return digits(result, locale);
};

/**
 * Calculate percentage of the new number based on original number
 *
 * @param {string|number} originalNumber
 * @param {string|number} newNumber
 * @returns {number|string}
 */
export const percent = (originalNumber, newNumber) => {
    const val = ((originalNumber - newNumber) / originalNumber) * 100;
    // eslint-disable-next-line no-nested-ternary
    return val.toFixed(1)?.toString()?.split('.')[1] === '0' ? Math.round(val) : Math.round(val) > 1 ? Math.round(val) : val.toFixed(1);
};
