/*-----------------------------------------------------------------
- Settings' vuex module
-----------------------------------------------------------------*/
/* eslint no-shadow: 0, no-param-reassign: 0, consistent-return: 0, no-confusing-arrow: 0 */
import { get, set } from 'lodash';

const state = {
    all: [],
};

const getters = {
    state   : (state) => state,
    all     : (state) => state.all,
    isLoaded: (state) => state.all.length,
    get     : (state) => (slug, defaultValue = '') => get(state.all, slug, defaultValue),
};

const mutations = {
    /**
     * Set settings data
     *
     * @param {object} state
     * @param {*} settings
     */
    set(state, settings) {
        state.all = settings;
    },
    
    /**
     * Set new value to settings data
     *
     * @param {object} state
     * @param {object} payload
     */
    setNewValue(state, payload) {
        set(state.all, payload.slug, payload.value);
    },
    
};

const actions = {
    /**
     * Set settings data
     *
     * @param { commit } commit
     * @param {*} settings
     */
    set({ commit }, settings) {
        commit('set', settings);
    },
    
    /**
     * Set new value to settings data
     *
     * @param { commit } commit
     * @param {object} payload
     */
    setNewValue({ commit }, payload) {
        commit('setNewValue', payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
