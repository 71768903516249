<template>
    <div class="add-user-modal">
        <!-- Search -->
        <div v-if="!isCreate">
            <form
                id="user-search-form"
                class="persons-user-search d-f align-items-end"
                @submit.prevent="searchUser"
            >
                <y-form-field
                    v-model="query"
                    type="text"
                    :placeholder="$t('person.new_user.search.placeholder')"
                    no-label
                    name="search"
                    class="mb0 ml5"
                    @input="debouncedSearchUser"
                />

                <y-button
                    color="blue"
                    class="ml5"
                    loading-on="loading-result"
                    form="user-search-form"
                >
                    {{ $t('person.new_user.search.button') }}
                </y-button>

                <y-button
                    color="success"
                    @click="reset"
                >
                    {{ $t('person.new_user.search.clear') }}
                </y-button>
            </form>

            <y-empty-state
                v-if="!searched"
                no-image
                height="150"
                class="mt10 mb0"
                :message="$t('person.new_user.search.not_searched')"
            >
                <y-button
                    color="green"
                    class="mt10"
                    @click.prevent.native="isCreate = true"
                >
                    {{ $t('person.new_user.search.register') }}
                </y-button>
            </y-empty-state>

            <y-loading
                v-if="searched"
                :active="$wait.is('loading-result')"
                height="100px"
            >
                <!-- Result -->
                <div
                    v-if="results && results.length > 0"
                    class="finder-result table-wrapper mt10"
                >
                    <table class="table">
                        <thead>
                            <tr>
                                <th
                                    v-for="column in tableHeader"
                                    :key="column.id"
                                >
                                    {{ column.title }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="row in results"
                                :key="row.id"
                            >
                                <td class="invoice-block-value">
                                    {{ row.name_full }}
                                </td>
                                <td class="invoice-block-value">
                                    {{ row.username }}
                                </td>
                                <td class="invoice-block-value">
                                    {{ row.code_melli }}
                                </td>
                                <td class="invoice-block-value">
                                    {{ row.mobile }}
                                </td>
                                <td class="invoice-block-value">
                                    {{ row.email }}
                                </td>
                                <td class="invoice-block-value">
                                    <y-button
                                        size="xs"
                                        @click.prevent.native="selectUser(row)"
                                    >
                                        {{ $t('person.new_user.search.select') }}
                                    </y-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- No Result -->
                <y-empty-state
                    v-else
                    no-image
                    height="150"
                    class="mt10 mb0"
                    :message="$t('person.new_user.search.empty')"
                >
                    <y-button
                        color="green"
                        class="mt10"
                        @click.prevent.native="isCreate = true"
                    >
                        {{ $t('person.new_user.search.register') }}
                    </y-button>
                </y-empty-state>
            </y-loading>
        </div>

        <!-- Create New User -->
        <div v-if="isCreate">
            <div class="panel p10 d-f justify-content-between align-items-center">
                <span>
                    {{ selectedUser ? $t('person.new_user.create.add_selected_user', [selectedUser.name_full]) : $t('person.new_user.create.new_user') }}
                </span>
                <y-button
                    color="success"
                    type="reset"
                    @click.prevent.native="reset"
                >
                    {{ $t('person.new_user.search.new_search') }}
                </y-button>
            </div>
            <y-form
                v-model="model"
                :params="element.form"
            />
        </div>
    </div>
</template>

<script>

    import { debounce } from '@nodes/helpers/api';
    import FormMixin from '@/mixins/Form';
    import YFormField from '@deps/form/FormField';
    import YEmptyState from '@deps/EmptyState';

    export default {
        name: 'YFormUserRegister',

        components: {
            YFormField,
            YEmptyState,
            YForm: () => import('@deps/form/Form'),
        },

        mixins: [FormMixin],

        props: {
            element: [Object, Array],
            value  : [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                searched    : false,
                isCreate    : false,
                model       : this.value || {},
                query       : null,
                results     : null,
                selectedUser: null,
                usersModel  : [],
                tableHeader : [
                    {
                        title: this.$t('fields.name'),
                        id   : 'name',
                    },
                    {
                        title: this.$t('fields.username'),
                        id   : 'username',
                    },
                    {
                        title: this.$t('fields.code_melli'),
                        id   : 'code_melli',
                    },
                    {
                        title: this.$t('fields.mobile'),
                        id   : 'mobile',
                    },
                    {
                        title: this.$t('fields.email'),
                        id   : 'email',
                    },
                    {
                        title: this.$t('button.action'),
                        id   : 'action',
                    },
                ],
            };
        },

        watch: {
            /**
             * Watch model and emit on change
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.debouncedSearchUser = debounce(this.searchUser, 1000);
        },

        methods: {
            /**
             * Search
             */
            searchUser() {
                if (!this.query) {
                    return false;
                }
                this.$wait.start('loading-result');
                this.results = null;
                this.searched = true;
                const params = {
                    search: this.query,
                    slug  : this.value.role,

                    form_attributes: this.element.form_attributes,
                };
                return this.$services.Person.userSearch(params).then((response) => {
                    this.usersModel = response.data.metadata.resources;
                    this.results = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('loading-result');
                });
            },

            /**
             * Select User from the result
             *
             * @param {object} user - selected user
             */
            selectUser(user) {
                const userModel = this.usersModel.find((model) => model.id === user.id);
                if (userModel) {
                    this.$set(this, 'selectedUser', user);
                    this.$set(this, 'model', userModel);
                    this.isCreate = true;
                }
            },

            /**
             * Reset the form
             */
            reset() {
                this.isCreate = false;
                this.searched = false;
                this.query = null;
                this.results = null;
                this.selectedUser = null;

                Object.keys(this.model).forEach((key) => {
                    this.$set(this.model, key, '');
                });
            },

        },
    };

</script>
