<template>
    <div
        class="copy-array d-f align-items-center"
        :class="classes"
    >
        <span
            v-if="helpText"
            class="help"
        >
            {{ helpText }}
        </span>
        <div
            v-if="array && array.length"
            class="copy-array-wrapper"
            :dir="directionValue"
        >
            <span
                v-for="(item, index) in array"
                :key="index"
                v-clipboard:copy="item"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                class="copy-icon copy"
            >
                {{ item }}{{ index !== array.length - 1 ? `,` : `` }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'YFormCopyArray',

        props: {
            /**
             * Element
             */
            element: Object,

            /**
             * Value
             */
            value: [Object, Array],

            /**
             * Align
             */
            align: {
                type   : String,
                default: 'between',
            },

            /**
             * Direction
             */
            direction: {
                type   : String,
                default: 'rtl',
            },

            /**
             * Help Text
             */
            help: String,

            /**
             * Use in Form
             */
            useInForm: {
                type   : Boolean,
                default: false,
            },
        },

        computed: {
            /**
             * Value of the Array
             */
            array() {
                return this.element && this.element.value ? this.element.value : this.value;
            },

            /**
             * Set the align of the items
             */
            alignValue() {
                return this.element && this.element.align ? this.element.align : this.align;
            },

            /**
             * Set the align of the items
             */
            directionValue() {
                return this.element && this.element.copy_direction ? this.element.copy_direction : this.direction;
            },

            /**
             * Wether this is used for hint or not
             */
            isHint() {
                return this.element && this.element.use_in_form ? this.element.use_in_form : this.useInForm;
            },

            /**
             * Help Text
             */
            helpText() {
                return this.element && this.element.help ? this.element.help : this.help;
            },

            /**
             * Classes of the component
             */
            classes() {
                const align = `justify-content-${this.alignValue}`;
                const hint = this.isHint ? 'use-in-form' : '';
                return [align, hint];
            },
        },

        methods: {
            /**
             * @inheritdoc
             */
            onCopy() {
                this.$toast.success(this.$t('general.copy_value.copied'));
            },

            /**
             * @inheritdoc
             */
            onError() {
                this.$toast.success(this.$t('general.copy_value.error'));
            },
        },
    };
</script>
