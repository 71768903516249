/*-----------------------------------------------------------------
- Coupons trans
-----------------------------------------------------------------*/
export default {

    title: {
        create: 'Create New Scenario',
        edit  : 'Edit Scenario',
    },

    code_generate: {
        title         : 'Generate New Codes',
        prefix        : 'Prefix',
        postfix       : 'postfix',
        pattern       : 'Pattern',
        length        : 'Length of Pattern',
        count         : 'Count',
        discount_code : 'Discount Code',
        preview       : 'Sample Code',
        pattern_select: {
            numeric: 'Number',
            alpha  : 'Alphabet',
            mix    : 'Alphabet and Number',
        },
        generated : 'Discount codes generated successfully',
        max_length: 'The number of allowed lengths is {0} characters.',
    },
    
    editor: {
        amount: {
            panel         : 'Discount Amount',
            fee           : 'Discount Amount',
            fee_number    : 'Discount Price',
            percent       : 'Discount Percent',
            percent_number: 'Percent',
            percent_max   : 'Max',
        },
        
        group: {
            panel: 'Allowed User',
            user : 'Specific Users',
            role : 'Specific Roles',
            all  : 'All Users',
        },
        
        limitation: {
            panel    : 'Usage Limitation',
            once     : 'Once',
            many     : 'More than Once',
            unlimited: 'Unlimited',
        },
    
        messages: {
            success: 'The scenario successfully saved.',
            user   : 'Discount codes can be used by all users.',
            limit  : 'Discount codes can be used over and over.',
        },

        rules: {
            rules          : 'Rules',
            available_rules: 'Available Rules',
            selected_rules : 'Selected Rules',
            rule_settings  : 'Rule Settings',
            empty          : 'No rule has found.',
        },
    },

    code_tracking: {
        search       : 'Search Discount Code',
        empty        : 'No discount code found.',
        search_again : 'Search again',
        search_button: 'Search',
        result       : {
            scenario: 'Coupon Scenario',
            allowed : {
                title: 'Allowed Users',
                user : 'Allowed Users',
                role : 'Allowed Roles',
                all  : 'All Users',
            },
            amount        : 'Maximum Discount Price',
            percent       : 'Discount Percent',
            total_discount: 'Total Discounted',
            allowed_usage : 'Number of allowed times',
            usage         : 'Number of used times',
            remain        : 'Number of remained times',
            users         : 'Users',
        },
    },
        
    batch: {
        title: 'Batch Codes',
    },
    
};
