/*-----------------------------------------------------------------
- Comments translations
-----------------------------------------------------------------*/
export default {
    
    single: {
        title: 'مشاهده دیدگاه',
        
        reply: {
            title        : 'پاسخ به دیدگاه',
            status       : 'وضعیت دیدگاه',
            placeholder  : 'پاسخ خود را تایپ کنید...',
            success_msg  : 'دیدگاه شما با موفقیت ارسال شد.',
            error_msg    : 'در ارسال دیدگاه مشکلی پیش آمد. دوباره تلاش کنید.',
            confirmed    : 'تایید',
            close        : 'بستن',
            change_status: {
                placeholder: 'وضعیت دیدگاه را انتخاب کنید',
                success_msg: 'وضعیت دیدگاه با موفقیت تغییر کرد.',
                error_msg  : 'در تغییر وضعیت دیدگاه مشکلی پیش آمد. دوباره تلاش کنید.',
            },
        },
        
        info: {
            title       : 'جزئیات دیدگاه',
            user_name   : 'نام کاربر',
            user_email  : 'ایمیل',
            user_mobile : 'موبایل',
            user_website: 'وب سایت',
            user_ip     : 'آی پی',
            related_post: 'دریافت‌شده بر',
            related_type: 'مربوط به',
            created_at  : 'تاریخ ایجاد',
            status      : 'وضعیت',
            private     : 'محرمانه',
            empty       : 'جزئیاتی برای نمایش وجود ندارد.',
        },
        
        files: {
            title   : 'فایل‌های پیوست',
            no_files: 'هیچ فایلی پیوست نشده است.',
        },
    },
    
};
