/*-----------------------------------------------------------------
- Cloner Wrapper Mixin
-----------------------------------------------------------------*/
import { YFormAlert } from '@deps/form/elements/Alert';

export default {
    
    name: 'ClonerWrapperMixin',
    
    components: {
        YFormAlert,
    },
    
    props: {
        /**
         * Rows of cloner
         */
        rows: Array,

        /**
         * Hide Action button
         */
        hideAction: Boolean,
    },
    
    inject: {
        $validator: '$validator',
    },
    
    computed: {
        /**
         * Helper id
         */
        id() {
            return this.$parent.id;
        },

        /**
         * Maximum rows
         */
        max() {
            return this.$parent.max;
        },

        /**
         * Determine whether to show remove-all prompt
         */
        showRemoveAllPrompt() {
            return this.$parent.showRemoveAllPrompt;
        },
    },
    
    methods: {
        /**
         * Add new empty row
         */
        addInput() {
            this.$parent.addInput();
        },

        /**
         * Handle before input removal
         *
         * @param {number} index
         */
        onRemoveInput(index) {
            this.$parent.onRemoveInput(index);
        },

        /**
         * Remove a row
         *
         * @param {number} index
         */
        removeInput(index) {
            this.$parent.removeInput(index);
        },

        /**
         * Show prompt for removing all rows
         */
        onRemoveAll() {
            this.$parent.onRemoveAll();
        },

        /**
         * Remove all rows
         */
        removeAll() {
            this.$parent.removeAll();
        },

        /**
         * Clear remove prompt
         */
        cancelRemovePrompt() {
            this.$parent.cancelRemovePrompt();
        },

        /**
         * Clear remove all prompt
         */
        cancelRemoveAllPrompt() {
            this.$parent.cancelRemoveAllPrompt();
        },

        /**
         * Check if a prompt is active, using its row's index
         *
         * @param {number} index
         */
        isPromptActive(index) {
            return this.$parent.isPromptActive(index);
        },
    },
    
};
