/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    
    specs: {
        list  : 'Specs',
        manage: 'Manage Specs',
    },
    
};
