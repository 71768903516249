<!-- eslint-disable max-len -->
<template>
    <y-form-field-wrapper v-bind="fieldWrapperProps">
        <div
            v-if="simple || isSingle"
            ref="singleFileUploader"
            class="dropzone-wrapper single-file"
            :class="{xs: getValue('small')}"
        >
            <vue-dropzone
                v-show="!reachUploadLimit"
                :id="id"
                ref="singleFileDropzone"
                :key="keyHelper"
                :options="dropzoneOptions"
                use-custom-slot
                @vdropzone-sending="sending"
                @vdropzone-success="successUpload"
                @vdropzone-error="handleUploadError"
                @vdropzone-file-added="fileAdded"
                @vdropzone-removed-file="removedFile"
                @vdropzone-queue-complete="queueComplete"
            >
                <div class="dropzone-custom-content">
                    <div class="img">
                        <img
                            src="@/assets/images/uploader-file.svg"
                            alt="Select File"
                        >
                    </div>
                    <div class="dropzone-custom-title">
                        {{ $t(`components.dropzone.drag.${uploaderType}`) }}
                    </div>
                    <div class="subtitle">
                        <span
                            v-if="small"
                            class="button sm"
                        >
                            {{ $t(`components.dropzone.button.small`) }}
                        </span>
                        <span
                            v-else
                            class="button"
                        >
                            {{ $t(`components.dropzone.button.${uploaderType}`) }}
                        </span>
                    </div>
                </div>
            </vue-dropzone>

            <div
                v-if="uploadedFiles && uploadedFiles.length"
                class="previews"
            >
                <a
                    v-for="upload in uploadedFiles"
                    :key="upload.id"
                    target="_blank"
                    rel="noopener noreferrer"
                    :href="upload.link"
                    class="file-preview"
                >
                    <span
                        class="remove"
                        @click.stop.prevent="removeFile(upload.id)"
                    >
                        <img
                            src="@/assets/images/close.svg"
                            alt="Close"
                        >
                    </span>
                    <img
                        v-if="isImage(upload)"
                        class="img"
                        :src="imageThumb(upload)"
                    >
                    <img
                        v-else
                        class="icon"
                        :src="uploadedFileIcon(upload)"
                    >
                </a>
            </div>
        </div>

        <!-- eslint-disable max-len -->
        <div
            v-else
            class="dropzone-wrapper"
        >
            <div class="previews">
                <a
                    v-for="upload in uploadedFiles"
                    :key="upload.hashid"
                    target="_blank"
                    rel="noopener noreferrer"
                    :href="upload.link"
                    class="file-preview"
                >
                    <span
                        class="remove"
                        @click.stop.prevent="removeFile(upload.id)"
                    >
                        <img
                            src="@/assets/images/close.svg"
                            alt="Close"
                        >
                    </span>
                    <img
                        v-if="isImage(upload)"
                        class="img"
                        :src="imageThumb(upload)"
                    >
                    <img
                        v-else
                        class="icon"
                        :src="uploadedFileIcon(upload)"
                    >
                </a>

                <div
                    v-if="!reachUploadLimit"
                    class="file-preview add-file"
                >
                    <button
                        class="select-file"
                        @click.prevent="$refs.modal.open()"
                    >
                        <!-- eslint-disable-next-line max-len -->
                        <img
                            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDcxLjIgNDcxLjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ3MS4yIDQ3MS4yOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMiIgaGVpZ2h0PSI1MTIiIGNsYXNzPSIiPjxnPjxnPgoJPGc+CgkJPHBhdGggZD0iTTQ1Ny43LDIzMC4xNWMtNy41LDAtMTMuNSw2LTEzLjUsMTMuNXYxMjIuOGMwLDMzLjQtMjcuMiw2MC41LTYwLjUsNjAuNUg4Ny41Yy0zMy40LDAtNjAuNS0yNy4yLTYwLjUtNjAuNXYtMTI0LjggICAgYzAtNy41LTYtMTMuNS0xMy41LTEzLjVzLTEzLjUsNi0xMy41LDEzLjV2MTI0LjhjMCw0OC4zLDM5LjMsODcuNSw4Ny41LDg3LjVoMjk2LjJjNDguMywwLDg3LjUtMzkuMyw4Ny41LTg3LjV2LTEyMi44ICAgIEM0NzEuMiwyMzYuMjUsNDY1LjIsMjMwLjE1LDQ1Ny43LDIzMC4xNXoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgc3R5bGU9ImZpbGw6IzkwQTRBRSIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiPjwvcGF0aD4KCQk8cGF0aCBkPSJNMTU5LjMsMTI2LjE1bDYyLjgtNjIuOHYyNzMuOWMwLDcuNSw2LDEzLjUsMTMuNSwxMy41czEzLjUtNiwxMy41LTEzLjVWNjMuMzVsNjIuOCw2Mi44YzIuNiwyLjYsNi4xLDQsOS41LDQgICAgYzMuNSwwLDYuOS0xLjMsOS41LTRjNS4zLTUuMyw1LjMtMTMuOCwwLTE5LjFsLTg1LjgtODUuOGMtMi41LTIuNS02LTQtOS41LTRjLTMuNiwwLTcsMS40LTkuNSw0bC04NS44LDg1LjggICAgYy01LjMsNS4zLTUuMywxMy44LDAsMTkuMUMxNDUuNSwxMzEuMzUsMTU0LjEsMTMxLjM1LDE1OS4zLDEyNi4xNXoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgc3R5bGU9ImZpbGw6IzkwQTRBRSIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiPjwvcGF0aD4KCTwvZz4KPC9nPjwvZz4gPC9zdmc+"
                            width="24"
                        >
                        <span>{{ $t(`components.dropzone.add`) }}</span>
                    </button>
                </div>
            </div>

            <y-modal
                ref="modal"
                size="lg"
                class="uploadModal"
            >
                <template #body>
                    <vue-dropzone
                        :id="id"
                        :key="keyHelper"
                        :options="dropzoneOptions"
                        :use-custom-slot="true"
                        @vdropzone-sending="sending"
                        @vdropzone-error="handleUploadError"
                        @vdropzone-success="successUpload"
                        @vdropzone-queue-complete="$refs.modal.close()"
                    >
                        <div class="dropzone-custom-content">
                            <div class="img">
                                <img
                                    src="@/assets/images/uploader-file.svg"
                                    alt="Select File"
                                >
                            </div>
                            <div class="dropzone-custom-title">
                                {{ $t(`components.dropzone.drag.${uploaderType}`) }}
                            </div>
                            <div class="subtitle">
                                <span class="button">{{ $t(`components.dropzone.button.${uploaderType}`) }}</span>
                            </div>
                        </div>
                    </vue-dropzone>
                </template>
            </y-modal>
        </div>
    </y-form-field-wrapper>
</template>

<script>
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import vueDropzone from 'vue2-dropzone';
    import { generateId } from '@nodes/helpers/string';
    import { apiUrl } from '@nodes/services/helpers';
    import { digits } from '@nodes/helpers/number';
    import { YModal } from '@deps/Modal';

    import imageIcon from '@/assets/images/file-type/image.svg';
    import videoIcon from '@/assets/images/file-type/video.svg';
    import docIcon from '@/assets/images/file-type/document.svg';
    import fileIcon from '@/assets/images/file-type/file.svg';

    import FormElementMixin from '@/mixins/FormElement';

    /**
     * Uploader Component
     */
    export default {

        name: 'YFormUploader',

        components: {
            vueDropzone,
            YModal,
        },

        mixins: [FormElementMixin],

        props: {
            component: {
                type   : String,
                default: 'uploader',
            },

            /**
             * Dropzone Id
             */
            id: {
                type   : String,
                default: () => generateId(),
            },

            /**
             * Dropzone Maximum File Size
             */
            maxFileSize: {
                type   : [Number, String],
                default: 10,
            },

            /**
             * Dropzone Maximum File Number
             */
            maxFiles: {
                type   : [Number, String],
                default: 100,
            },

            /**
             * Dropzone Accepted Mime Types
             */
            acceptedFiles: {
                type: [Array, String],
            },

            /**
             * Dropzone Request Header
             */
            headers: {
                type   : [Object, String],
                default: () => {
                },
            },

            /**
             * Dropzone Payload
             */
            payload: {
                type: String,
            },

            /**
             * Dropzone Value (Array of Hashids)
             */
            value: { // eslint-disable-line vue/require-prop-types
                default: () => [],
            },

            /**
             * Dropzone item title
             */
            title: String,

            /**
             * Small single uploader
             */
            small: Boolean,

            /**
             * Check is uploader is simple mode without modal
             */
            simple: Boolean,

            /**
             * Uploaded files
             */
            files: Array,

            /**
             * Dropzone options
             */
            options: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model        : null,
                uploadedFiles: null,

                statics: {
                    thumbnailWidth      : 768,
                    thumbnailHeight     : null,
                    timeout             : 6000000,
                    addRemoveLinks      : true,
                    hiddenInputContainer: '.dropzone-wrapper',
                    url                 : apiUrl('uploader-upload'),
                    headers             : {
                        Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null,
                    },
                },

                icon: {
                    image   : imageIcon,
                    video   : videoIcon,
                    document: docIcon,
                    file    : fileIcon,
                },

                trans: {
                    errorCommon                 : this.$t('errors.upload.common'),
                    dictRemoveFile              : this.$t('general.dropzone.dictRemoveFile'),
                    dictRemoveFileConfirmation  : this.$t('general.dropzone.dictRemoveFileConfirmation'),
                    dictCancelUpload            : this.$t('general.dropzone.dictCancelUpload'),
                    dictUploadCanceled          : this.$t('general.dropzone.dictUploadCanceled'),
                    dictCancelUploadConfirmation: this.$t('general.dropzone.dictCancelUploadConfirmation'),
                    dictFileTooBig              : this.$t('general.dropzone.dictFileTooBig'),
                    dictInvalidFileType         : this.$t('general.dropzone.dictInvalidFileType'),
                    dictResponseError           : this.$t('general.dropzone.dictResponseError'),
                    dictMaxFilesExceeded        : this.$t('general.dropzone.dictMaxFilesExceeded'),
                },

                mimes: {
                    jpg : 'image/jpeg',
                    jpeg: 'image/jpeg',
                    png : 'image/png',
                    svg : 'image/svg+xml',
                    gif : 'image/gif',
                    ico : 'image/vnd.microsoft.icon',
                    pdf : 'application/pdf',
                    mp3 : 'audio/mpeg',
                    mpga: 'audio/mpeg',
                    mpeg: 'video/mpeg',
                    mp4 : 'video/mp4',
                    m4v : 'video/mp4',
                    zip : 'application/zip',
                },

                keyHelper: generateId(),
            };
        },

        computed: {

            /**
             * Return files resources
             */
            getFiles() {
                return this.getValue('files');
            },

            /**
             * Generate dropzone options
             *
             * @returns {{headers}}
             */
            dropzoneOptions() {
                const options = {
                    headers: {},
                };

                options.maxFilesize = this.get(this.element, 'max_size', this.maxFileSize) * 1;
                options.maxFiles = this.get(this.element, 'max_files', this.maxFiles) * 1;
                options.acceptedFiles = this.acceptedFilesParsed.join(',');
                options.headers = this.getValue('headers', {});

                return {
                    ...options,
                    ...this.statics,
                    ...this.trans,
                    ...this.getValue('options', {}),
                };
            },

            /**
             * Check if reach upload limit
             *
             * @returns {boolean}
             */
            reachUploadLimit() {
                return (this.maxFilesParsed === this.model.length);
            },

            /**
             * Check if uploader is single
             */
            isSingle() {
                return this.maxFilesParsed === 1;
            },

            /**
             * Check type of uploader by maxFiles
             */
            uploaderType() {
                return this.maxFilesParsed === 1 ? 'single' : 'multiple';
            },

            /**
             * Convert max files to number
             */
            maxFilesParsed() {
                return this.getValue('max_files') * 1;
            },

            /**
             * Accepted files parsed
             */
            acceptedFilesParsed() {
                const arr = this.getValue('accepted_files', ['jpg', 'jpeg', 'png', 'gif', 'pdf']);
                const mimes = new Set();
                arr.forEach((ext) => {
                    if (this.mimes.hasOwnProperty(ext)) {
                        mimes.add(this.mimes[ext]);
                    }
                });
                return Array.from(mimes);
            },
        },

        watch: {
            /**
             * Change string value to array
             *
             * @param value
             * @returns {string[]}
             */
            value(value) { // eslint-disable-line consistent-return
                if (typeof value === 'string') {
                    return [value];
                }
            },

            /**
             * Change string value to array
             *
             * @param value
             */
            model(value) {
                this.$emit('input', value);
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.model = [];
            this.uploadedFiles = [];

            if (this.value && typeof this.value === 'object' && !Array.isArray(this.value)) {
                this.makeModel([this.value]);
            } else if (Array.isArray(this.value) && this.value.length) {
                this.makeModel(this.value);
            } else if (this.getFiles) {
                if (Array.isArray(this.getFiles) && this.getFiles.length) {
                    this.makeModel(this.getFiles);
                } else if (typeof this.getFiles === 'object' && !Array.isArray(this.getFiles)) {
                    this.makeModel([this.getFiles]);
                }
            }
        },

        methods: {

            /**
             * Make model
             *
             * @param files
             */
            makeModel(files) {
                files.forEach((item) => {
                    if (item) {
                        this.model.push(item.id);

                        const {
                            id, link, mime_type, versions, // eslint-disable-line camelcase
                        } = item;
                        const thumb = versions && versions.panel_thumb ? versions.panel_thumb : null;
                        this.uploadedFiles.push({
                            id,
                            link,
                            mime_type,
                            thumb,
                        });
                    }
                });
            },

            /**
             * Handle file error
             *
             * @param file
             * @param error
             */
            handleUploadError(file, error) {
                let msg = this.trans.errorCommon;

                // Define Message
                if (typeof error === 'string') {
                    msg = error;
                    if (msg.includes('{maxFiles}')) {
                        msg = msg.replace('{maxFiles}', digits(this.maxFiles, this.$i18n.locale));
                    } else if (msg.includes('{filesize}')) {
                        msg = msg.replace('{filesize}', digits((file.size / 1024 / 1024).toFixed(2), this.$i18n.locale));
                        msg = msg.replace('{maxFileSize}', digits(this.maxFileSize, this.$i18n.locale));
                    }
                    this.$toast.error(msg);
                } else if (error.message) {
                    msg = error.message;
                    this.$toast.error(msg);
                } else {
                    this.handleError({ response: { data: error } });
                }

                // File Error message
                const elements = document.getElementsByClassName('dz-error');
                const lastErrorDiv = elements[elements.length - 1].children[3];
                lastErrorDiv.innerHTML = `<span>${msg}</span>`;
            },

            /**
             * Successful Upload
             *
             * @param file
             * @param response
             */
            successUpload(file, response) {
                const {
                    id, link, mime_type, versions, // eslint-disable-line camelcase
                } = response ? response.metadata : this.get(JSON.parse(file.xhr.response), 'metadata', {});
                if (this.reachUploadLimit) {
                    return;
                }
                this.$emit('success', {
                    id,
                    link,
                    mime_type,
                    versions,
                });
                const thumb = versions && versions.panel_thumb ? versions.panel_thumb : null;
                this.model.push(id);
                this.uploadedFiles.push({
                    id,
                    link,
                    mime_type,
                    thumb,
                });
            },

            /**
             * Handle some actions when a file added
             *
             * @param file
             */
            fileAdded(file) {
                if (this.maxFilesParsed === 1 && this.$refs.hasOwnProperty('singleFileUploader')) {
                    this.$refs.singleFileUploader.classList.add('afterUpload');
                }

                // check if file type was not image then remove image thumbnail
                if (file.type.split('/')[0] !== 'image') {
                    this.$el.querySelector('.dz-image').style.display = 'none';
                }
            },

            /**
             * Handle some actions when a file removed
             */
            removedFile() {
                if (this.maxFilesParsed === 1 && this.$refs.hasOwnProperty('singleFileUploader') && this.$refs.singleFileUploader) {
                    this.$refs.singleFileUploader.classList.remove('afterUpload');
                }
            },

            /**
             * Sending Action
             *
             * @param file
             * @param xhr
             * @param formData
             */
            sending(file, xhr, formData) {
                if (this.payload) {
                    formData.append('payload', this.payload);
                }
            },

            /**
             * Remove file
             *
             * @param item
             */
            removeFile(item) {
                const index = this.model.findIndex((file) => file === item);

                if (index !== -1) {
                    this.model.splice(index, 1);
                    this.uploadedFiles.splice(index, 1);
                    this.keyHelper = generateId();
                    this.$emit('remove', item);
                }
            },

            /**
             * Get proper icon for each file
             *
             * @param file
             */
            uploadedFileIcon(file) {
                const mime = file.mime_type;

                if (!mime) {
                    return this.icon.file;
                }

                if (mime.includes('image')) {
                    return this.icon.image;
                }

                if (mime.includes('video')) {
                    return this.icon.video;
                }

                if (mime.includes('pdf')) {
                    return this.icon.document;
                }

                return this.icon.file;
            },

            /**
             * Check file is image
             *
             * @param file
             */
            isImage(file) {
                return file && file.mime_type && !!file.mime_type.includes('image');
            },

            /**
             * Get file thumbnail
             *
             * @param file
             * @param useThumb
             */
            imageThumb(file, useThumb = false) {
                return useThumb ? (file.thumb || file.link) : file.link;
            },

            /**
             * Check if object is empty
             *
             * @param obj
             */
            isEmpty(obj) {
                return (obj && Array.isArray(obj) && obj.length)
                    || (typeof obj === 'object' && !Array.isArray(obj));
            },

            /**
             * Emit completion of queue
             */
            queueComplete() {
                if (!this.simple && !this.isSingle) {
                    this.$refs.modal.close();
                }
                this.$emit('complete');
            },

        },
    };
</script>
