/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    form: {
        form_maker: 'Form Maker',
        list      : 'Forms',
        edit      : 'Edit Form',
        create    : 'Create Form',
        preview   : 'Preview Form',
        responses : 'Form Responses',
    },
};

