/*-----------------------------------------------------------------
- Toast translations
-----------------------------------------------------------------*/

export default {
    
    // Auth
    login: {
        success: 'خوش‌آمدید :)',
        error  : 'مشکلی در ورود شما به وجود آمده است. دوباره تلاش کنید.',
    },

    register: {
        success: 'ثبت نام با موفقیت انجام شد.',
        error  : 'در فرآیند ثبت نام مشکلی به وجود آمده است. دوباره تلاش کنید.',
    },
    
    // General
    save: {
        confirm    : 'آیا از ذخیره‌ی این {type} مطمئنید؟',
        confirmFull: 'آیا از ذخیره‌ی {type} <b>«{title}»</b> مطمئنید؟',
        success    : 'ذخیره‌ی {type} با موفقیت انجام شد.',
        error      : 'مشکلی در ذخیره‌ی {type} به وجود آمده است.',
    },
    
    update: {
        confirm    : 'آیا از به‌روزرسانی این {type} مطمئنید؟',
        confirmFull: 'آیا از به‌روزرسانی {type} <b>«{title}»</b> مطمئنید؟',
        success    : 'به‌روزرسانی {type} با موفقیت انجام شد.',
        error      : 'مشکلی در به‌روزرسانی {type} به وجود آمده است.',
    },
    
    trash: {
        confirm    : 'آیا از انتقال این {type} به سطل زباله مطمئنید؟',
        confirmFull: 'آیا از انتقال {type} <b>«{title}»</b> به سطل زباله مطمئنید؟',
        success    : 'انتقال {type} به سطل زباله با موفقیت انجام شد.',
        error      : 'مشکلی در انتقال {type} به سطل زباله به وجود آمده است.',
    },
    
    restore: {
        confirm    : 'آیا از بازیابی این {type} از سطل زباله مطمئنید؟',
        confirmFull: 'آیا از بازیابی {type} <b>«{title}»</b> از سطل زباله مطمئنید؟',
        success    : 'بازیابی {type} از سطل زباله با موفقیت انجام شد.',
        error      : 'مشکلی در بازیابی {type} از سطل زباله به وجود آمده است.',
    },
    
    destroy: {
        confirm    : 'آیا از حذف این {type} مطمئنید؟',
        confirmFull: 'آیا از حذف {type} <b>«{title}»</b> مطمئنید؟',
        success    : 'حذف {type} با موفقیت انجام شد.',
        error      : 'مشکلی در حذف {type} به وجود آمده است.',
    },
    
    delete: {
        confirm    : 'آیا از حذف این {type} مطمئنید؟',
        confirmFull: 'آیا از حذف {type} <b>«{title}»</b> مطمئنید؟',
        success    : 'حذف {type} با موفقیت انجام شد.',
        error      : 'مشکلی در حذف {type} به وجود آمده است.',
    },
    
    activate: {
        confirm    : 'آیا از فعال‌سازی این {type} مطمئنید؟',
        confirmFull: 'آیا از فعال‌سازی {type} <b>«{title}»</b> مطمئنید؟',
        success    : 'فعال‌سازی {type} با موفقیت انجام شد.',
        error      : 'مشکلی در فعال‌سازی {type} به وجود آمده است.',
    },
    
    deactivate: {
        confirm    : 'آیا از غیرفعال‌سازی این {type} مطمئنید؟',
        confirmFull: 'آیا از غیرفعال‌سازی {type} <b>«{title}»</b> مطمئنید؟',
        success    : 'غیرفعال‌سازی {type} با موفقیت انجام شد.',
        error      : 'مشکلی در غیرفعال‌سازی {type} به وجود آمده است.',
    },

    operation: {
        success: 'عملیات با موفقیت انجام شد.',
        error  : 'مشکلی در انجام عملیات به وجود آمده است.',
    },
    
    change_password: {
        success: 'تغییر رمز شما با موفقیت انجام شد.',
        error  : 'مشکلی در تغییر رمز شما به وجود آمده است. دوباره تلاش کنید.',
    },
    
    cloner: {
        confirm_delete    : 'آیا از حذف این آیتم مطمئنید؟',
        confirm_delete_all: 'آیا از حذف تمام آیتم‌ها مطمئنید؟',
    },
    
};
