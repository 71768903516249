<template>
    <div v-if="showPhoneNumber">
        <i
            v-if="icon"
            class="icon"
            :class="[icon]"
        />
        <span
            class="form-label ta-r"
            dir="ltr"
            v-html="showPhoneNumber"
        />
        <y-copy-value
            v-if="copy"
            :value="copy && typeof copy === 'string' ? copy : element.value"
        />
    </div>
</template>

<script>
    import { digits } from '@nodes/helpers/number';
    import ComponentClassesMixin from '@/mixins/ComponentClasses';
    import FormElementMixin from '@/mixins/FormElement';
    import YCopyValue from '@deps/CopyValue';

    export default {

        name: 'YFormPhoneLabel',

        components: {
            YCopyValue,
        },

        mixins: [FormElementMixin, ComponentClassesMixin],

        /**
         * @inheritDoc
         */
        props: {
            /**
             * Element
             */
            element: {
                type: Object,
            },

            icon: String,
            copy: [String, Boolean],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                locale: this.element && this.element.locale ? this.element.locale : this.$i18n.locale,
            };
        },

        /**
         * @inheritDoc
         */
        computed: {

            /**
             * this function responsible for make space in specifics index
             *
             * @returns {string}
             */
            showPhoneNumber() {
                let phone = this.element && this.element.value ? this.element.value : null;
                if (phone) {
                    if (this.locale) {
                        phone = (digits(phone, this.locale)).toString();
                    }
                    if (phone.startsWith('+')) {
                        return `&nbsp;${phone.slice(0, 3)}&nbsp;${phone.slice(3, 6)}&nbsp;${phone.slice(6, 9)}&nbsp;${phone.slice(9, 11)}&nbsp;${phone.slice(11, 13)}&nbsp;`;
                    }
                    return `&nbsp;${phone.slice(0, 4)}&nbsp;${phone.slice(4, 7)}&nbsp;${phone.slice(7, 9)}&nbsp;${phone.slice(9, 11)}&nbsp;`;
                }
                return null;
            },
        },

    };
</script>
