<template>
    <y-form-field-wrapper v-bind="fieldWrapperProps">
        <div class="row divisions_field">
            <div
                v-for="(div, index) in visibleDivs"
                :key="div"
                class="col"
            >
                <template v-if="showSelect(div, index)">
                    <label
                        v-if="!noLabel"
                        :for="div +'-' + name"
                    >
                        {{ $t('general.division.' + div) }}
                    </label>

                    <y-form-select
                        :id="div +'-' + name"
                        :name="name"
                        :value="model[div]"
                        :options="hierarchy[div].options"
                        :is-loading="hierarchy[div].loading"
                        :placeholder="getPlaceholder"
                        :allow-empty="allowEmpty"
                        :multi="isMulti(div)"
                        close-on-select
                        :disabled="!hideEmpties && hierarchy[div].options && !hierarchy[div].options.length"
                        @input="emitValue($event, div)"
                        @select="emitSelectedDiv($event, div)"
                    />
                </template>
            </div>
        </div>
    </y-form-field-wrapper>
</template>

<script>
    import FormElementMixin from '@/mixins/FormElement';
    import { YFormSelect } from '@deps/form/elements/Select';

    export default {
        name: 'Division',

        components: {
            YFormSelect,
        },

        mixins: [FormElementMixin],

        props: {

            /**
             * Component type
             */
            component: {
                type   : String,
                default: 'division',
            },

            /**
             * lower limit of divisions
             */
            limit: {
                type: String,
            },

            /**
             * value of neighbourhood as higher limit
             */
            neighbourhood: {
                type: [String, Number],
            },

            /**
             * value of city as higher limit
             */
            city: {
                type: [String, Number],
            },

            /**
             * value of province as higher limit
             */
            province: {
                type: [String, Number],
            },

            /**
             * value of country as higher limit
             */
            country: {
                type: [String, Number],
            },

            /**
             * value of continent as higher limit
             */
            continent: {
                type: [String, Number],
            },

            /**
             * form field value
             */
            value: {}, // eslint-disable-line vue/require-prop-types

            /**
             * allow divisions select to be empty
             */
            allowEmpty: {
                type   : Boolean,
                default: false,
            },

            /**
             * disable divisions select
             */
            disabled: [Boolean, String],

            /**
             * Disable or hide next level
             */
            hideEmpties: {
                type: Boolean,
            },

            /**
             * Enable Multi Option for each Item
             */
            multi: {
                type   : Array,
                default: () => [],
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {},

                selectedDivs: {},

                ajaxFilter: {
                    continent: 'continents_id',
                    country  : 'countries_id',
                    province : 'provinces_id',
                    city     : 'cities_id',
                },

                hierarchy: {
                    continent: {
                        loading: false,
                        options: [],
                    },

                    country: {
                        loading: false,
                        options: [],
                    },

                    province: {
                        loading: false,
                        options: [],
                    },

                    city: {
                        loading: false,
                        options: [],
                    },

                    neighbourhood: {
                        loading: false,
                        options: [],
                    },
                },

                currentDiv: null,

                divs: {
                    continent    : [],
                    country      : [],
                    province     : [],
                    city         : [],
                    neighbourhood: [],
                },
            };
        },

        computed: {
            /**
             * Gets an array of hierarchy keys
             *
             * @returns {string[]}
             */
            hierarchyArray() {
                return Object.keys(this.hierarchy);
            },

            /**
             * Gets an list of visible Divisions
             *
             * @returns {*}
             */
            visibleDivs() {
                const start = this.startIndex();
                const end = this.endIndex();

                return this.hierarchyArray.slice(start, end);
            },

            /**
             * Get placeholder
             */
            getPlaceholder() {
                return this.placeholder || this.$t('fields.choose');
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.currentDiv = this.startIndex();

            if (this.value) {
                this.model = this.value;
                this.selectedDivs = { ...this.model };
            }

            if (!this.value) {
                this.$emit('input', this.model);
            }

            this.getData();
        },

        methods: {
            /**
             * gets first field index in hierarchyArray
             *
             * @returns {number}
             */
            startIndex() {
                const start = this.hierarchyArray.findIndex((div) => !!this.$props[div]);

                return start > -1 ? start + 1 : 0;
            },

            /**
             * gets last field index in hierarchyArray
             *
             * @returns {*}
             */
            endIndex() {
                const end = this.hierarchyArray.findIndex((div) => div === this.limit);

                return end > -1 ? end + 1 : this.hierarchy.length;
            },

            /**
             * emit value of chosen field
             *
             * @param value
             * @param div
             */
            emitValue(value, div) {
                this.model[div] = value;

                if (this.currentDiv !== this.hierarchyArray.indexOf(div)) {
                    this.currentDiv = this.hierarchyArray.indexOf(div);
                }

                this.currentDiv += 1;

                if (this.currentDiv < this.endIndex() && value) {
                    this.getData();
                }

                this.removeModel(this.hierarchyArray.indexOf(div));
                if (!value) {
                    this.$emit('remove');
                }
            },

            /**
             * gets divisions list according to filters
             *
             * @param type
             * @param filter
             */
            getOptions(type, filter) {
                const params = {
                    types    : [type],
                    sort     : `title_${this.$i18n.locale}`,
                    order    : 'asc',
                    including: [
                        'id',
                        `title_${this.$i18n.locale}`,
                    ],
                    ...filter,
                };
                this.hierarchy[type].loading = true;
                const titleLocale = `title_${this.$i18n.locale}`;
                this.$services.list('division', params).then((response) => {
                    const options = response.data.results.map((item) => ({
                        value: item.id,
                        label: item[titleLocale] || item.name,
                    }));
                    this.$set(this.hierarchy[type], 'options', options);
                }).catch((error) => {
                    this.handleError(error);
                }).then(() => {
                    this.hierarchy[type].loading = false;
                });
            },

            /**
             * generate filters for divisions list and gets list
             */
            getData() {
                const first = this.hierarchyArray[this.currentDiv];
                const before = this.currentDiv ? this.hierarchyArray[this.currentDiv - 1] : 0;
                const beforeVal = this.$props[before] || this.model[before];

                const filter = { [this.ajaxFilter[before]]: [beforeVal] };
                this.getOptions(first, filter);
            },

            /**
             * emit empty value if a higher level field updates
             *
             * @param changedIndex
             */
            removeModel(changedIndex) {
                const done = this.hierarchyArray[changedIndex];
                if (this.selectedDivs && this.selectedDivs.hasOwnProperty(done)) {
                    delete this.selectedDivs[done];
                } else {
                    Object.keys(this.model).forEach((item) => {
                        if (this.hierarchyArray.indexOf(item) > changedIndex) {
                            if (this.model.hasOwnProperty(item)) {
                                delete this.model[item];
                            }
                            this.hierarchy[item].options = [];
                            this.divs[item] = [];
                        }
                    });
                }
                this.$emit('input', this.model);
            },

            /**
             * @inheritDoc
             */
            showSelect(div, index) {
                return !this.hideEmpties || (this.hierarchy[div].options && this.hierarchy[div].options.length && this.currentDiv >= index);
            },

            /**
             * Selected Div
             *
             * @param data
             * @param div
             */
            emitSelectedDiv(data, div) {
                const pushable = this.allowEmpty ? this.isMulti(div) : false;
                if (pushable) {
                    const duplicate = false;
                    if (!duplicate) {
                        this.divs[div].push(data);
                    }
                } else {
                    this.divs[div] = [];
                    this.divs[div].push(data);
                }
                this.$emit('divs', this.divs);
            },

            /**
             * Clear Selected Divs
             */
            clearSelectedDiv() {
                this.$set(this, 'divs', {
                    continent    : [],
                    country      : [],
                    province     : [],
                    city         : [],
                    neighbourhood: [],
                });
            },

            /**
             * Check whether the div is multi
             *
             * @param div
             */
            isMulti(div) {
                return this.multi.includes(div);
            },
        },

    };
</script>
