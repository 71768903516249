/*-----------------------------------------------------------------
- Payment Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    /**
     * User Transactions
     *
     * @access public
     * @param {...any} args
     * @returns {*}
     */
    userTransactions: (...args: any[]): any => Resource.get('payment-user-transactions', args),
};
