<script>
    export default {
        name: 'Button',

        props: {
            /**
             * Button Size
             *
             * @values xs, sm, lg, xlg
             */
            size: String,

            /**
             * Button Html Tag
             *
             * Accepted Values: _a, button, router-link_
             */
            tag: {
                type   : String,
                default: 'button',
            },

            /**
             * The **to** object of Vue Router for button's internal links
             */
            to: [Object, String],

            /**
             * Button Color
             *
             * _Varies based on theme colors._
             */
            color: String,

            /**
             * Hovering color
             *
             * _Varies based on theme colors._
             */
            hover: String,

            /**
             * Makes button wide
             */
            wide: {
                type   : Boolean,
                default: false,
            },

            /**
             * Makes button wider
             */
            wider: {
                type   : Boolean,
                default: false,
            },

            /**
             * Disables the Button
             */
            disabled: {
                type   : Boolean,
                default: false,
            },

            /**
             * Block Size Button
             */
            block: {
                type   : Boolean,
                default: false,
            },

            /**
             * Button Alt Style
             */
            alt: {
                type   : Boolean,
                default: false,
            },

            /**
             * Button Inverted Style
             */
            inverted: {
                type   : Boolean,
                default: false,
            },

            /**
             * Button Rounded Style
             */
            rounded: {
                type   : Boolean,
                default: false,
            },

            /**
             * Button Circular Style
             */
            circular: {
                type   : Boolean,
                default: false,
            },

            /**
             * Change button style to loading
             */
            loading: {
                type   : Boolean,
                default: false,
            },

            /**
             * Loading button on wait slug
             */
            loadingOn: String,

            /**
             * Disables the Button on wait slug
             */
            disabledOn: String,

            /**
             * Labeled Style of Button
             */
            labeled: {
                type   : Boolean,
                default: false,
            },

            /**
             * Reverse Label's Position
             */
            reverse: {
                type   : Boolean,
                default: false,
            },

            /**
             * Sets Button Icon alongside the button text
             */
            withIcon: String,

            /**
             * Button icon, Used when button has no text
             */
            icon: String,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {};
        },

        computed: {

            /**
             * Return element tag tag
             *
             * @returns {*}
             */
            elementTag() {
                return this.tag;
            },

            /**
             * Generate class list
             *
             * @returns {*}
             */
            classes() {
                const classes = ['button'];

                if (this.size) {
                    classes.push(this.size);
                }
                if (this.color) {
                    classes.push(this.color);
                }
                if (this.hover) {
                    classes.push(`hover-${this.hover}`);
                }
                if (this.block) {
                    classes.push('block');
                }
                if (this.alt) {
                    classes.push('alt');
                }
                if (this.inverted) {
                    classes.push('inverted');
                }
                if (this.rounded) {
                    classes.push('rounded');
                }
                if (this.circular) {
                    classes.push('circular');
                }
                if (this.wide) {
                    classes.push('wide');
                }
                if (this.wider) {
                    classes.push('wider');
                }
                if (this.icon) {
                    classes.push(`icon ${this.icon}`);
                }
                if (this.reverse) {
                    classes.push('reverse');
                }
                if (this.labeled) {
                    classes.push('labeled');
                }

                if (this.loading || (this.loadingOn && this.$wait.is(this.loadingOn))) {
                    classes.push('loader loading');
                }
                if (this.disabled || (this.disabledOn && this.$wait.is(this.disabledOn))) {
                    classes.push('disabled');
                }

                return classes;
            },

            /**
             * Is there more than one slot on a button?
             *
             * @returns {boolean}
             */
            hasMoreThanOneSlot() {
                return this.$slots.default && this.$slots.default.length > 1;
            },

            /**
             * Add button content
             *
             * @returns {object}
             */
            buttonContent() {
                let content = '';

                // Directly return slots if slots length is bigger than one
                if (this.hasMoreThanOneSlot) {
                    return {};
                }

                if (this.$slots.default) {
                    content = `<span>${this.$slots.default[0].text}</span>`;
                }

                if (this.withIcon) {
                    if (this.reverse) {
                        content = `${content}<i class="icon ${this.withIcon}"></i>`;
                    } else {
                        content = `<i class="icon ${this.withIcon}"></i>${content}`;
                    }
                }

                return {
                    domProps: {
                        innerHTML: content,
                    },
                };
            },
        },

        methods: {
            /**
             * Emit click event on callback
             *
             * @param {*} e
             */
            callback(e) {
                this.$emit('click', e);
            },
        },

        /**
         * @inheritDoc
         */
        render(h) {
            return h(
                this.elementTag,
                {
                    attrs: {
                        to: this.to,
                    },

                    class: this.classes,
                    on   : {
                        click: this.callback,
                    },
                    // innerHTML is returned if the number of slots is less than or equal to 1.
                    ...this.buttonContent,
                },
                // Return slots directly if there is more than one slot
                this.hasMoreThanOneSlot ? this.$slots.default : undefined,
            );
        },
    };

</script>
