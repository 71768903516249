<template>
    <div
        id="notifications-wrapper"
        :class="{open: isOpen}"
    >
        <div
            v-show="isOpen"
            class="backdrop"
            @click="close"
        />
        <div class="notifications-panel">
            <!-- Notifications Filters -->
            <y-button-group
                class="notifications-filter"
                block
            >
                <y-button
                    :color="showAll ? activeFilterClass : undefined"
                    @click.prevent="toggleFilter(true)"
                >
                    {{ $t('notifications.filters.all') }}
                </y-button>
                <y-button
                    :color="!showAll ? activeFilterClass : undefined"
                    @click.prevent="toggleFilter(false)"
                >
                    {{ $t('notifications.filters.unread') }}
                </y-button>
            </y-button-group>
            <y-loading
                :active="$wait.is('loading-notifications')"
                class="notifications-loading"
            >
                <!-- Notifications List -->
                <ul
                    v-if="notifications && notifications.length"
                    class="m0 p0 lis-n"
                >
                    <li
                        v-for="(notif, index) in notifications"
                        :key="index"
                        class="notification-item"
                        :class="{unread: !notif.read_at}"
                    >
                        <div class="notification-item-body row">
                            <span
                                class="icon xlg"
                                :class="[notif.icon || 'md-bell']"
                            />
                            <span>{{ notif.data }}</span>
                        </div>
                        <div class="notification-item-footer row justify-content-end">
                            <y-date-label :element="{value: notif.created_at}" />
                        </div>
                    </li>

                    <!-- Intersection Observer -->
                    <y-intersect
                        v-if="Array.isArray(notifications) && notifications.length"
                        @intersect="onScrollToBottom"
                    />
                </ul>
                <div
                    v-else-if="Array.isArray(notifications) && !notifications.length"
                    class="notifications-empty"
                >
                    <p>{{ $t('notifications.empty') }}</p>
                </div>
            </y-loading>
        </div>
    </div>
</template>

<script>
    import YButtonGroup from '@deps/ButtonGroup';
    import YDateLabel from '@deps/form/elements/DateLabel';
    import YIntersect from '@deps/Intersect';

    export default {
        name: 'NotificationsPanel',

        components: {
            YDateLabel,
            YIntersect,
            YButtonGroup,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                isOpen       : false,
                notifications: null,
                showAll      : true,
                page         : 1,

                isNextPageAvailable: true,
                activeFilterClass  : 'blue',
            };
        },

        /**
         * @inheritdoc
         */
        created() {
            this.$root.$on('openNotifs', this.open);
        },

        methods: {
            /**
             * Open panel
             *
             * @param notifications
             */
            open(notifications) {
                this.notifications = notifications;
                this.page = 1;
                this.isOpen = true;
                this.showAll = false;
            },

            /**
             * Close Panel
             */
            close() {
                this.isOpen = false;
                this.notifications = null;
                this.$root.$emit('checkForNewNotifications');
            },

            /**
             * intersection observer callback on scroll
             */
            onScrollToBottom() {
                this.onLoadMoreNotifs();
            },

            /**
             * load more notifications according to current page and filters
             */
            onLoadMoreNotifs() {
                if (this.isNextPageAvailable) {
                    if (this.page < 1) {
                        this.$wait.start('loading-notifications');
                    }
                    const params = {
                        page: this.showAll ? this.page + 1 : 1,
                    };
                    if (!this.showAll) {
                        params.unread = true;
                    }
                    this.$services.Notifier.userNotificationsList(params)
                        .then((response) => {
                            const { results } = response.data;
                            this.isNextPageAvailable = !!response.data.metadata.next_page_url;

                            if (results.length) {
                                this.notifications = [...this.notifications, ...results];
                                this.page += 1;
                            }
                        })
                        .catch((error) => {
                            this.handleError(error);
                        })
                        .finally(() => {
                            this.$wait.end('loading-notifications');
                        });
                }
            },

            /**
             * Toggle notifications filter mode
             *
             * @param showAll
             */
            toggleFilter(showAll) {
                this.showAll = showAll;
                this.page = 0;
                this.isNextPageAvailable = true;
                this.notifications = [];
                this.onLoadMoreNotifs();
            },
        },
    };
</script>
