/*-----------------------------------------------------------------
- Global mixin
-----------------------------------------------------------------*/

export default {
    name: 'GlobalMixin',

    computed: {
        /**
         * Check if user is logged in
         *
         * @returns {*}
         */
        isLoggedIn() {
            return this.$store.getters['auth/isLoggedIn'];
        },

        /**
         * Check if user is developer
         *
         * @returns {*}
         */
        isDeveloper() {
            return this.$store.getters['auth/isDeveloper'];
        },

        /**
         * Check if user is superadmin
         *
         * @returns {*}
         */
        isAdmin() {
            return this.$store.getters['auth/isAdmin'];
        },

        /**
         * Get current user hashid
         *
         * @returns {*}
         */
        userId() {
            return this.$store.getters['auth/id'];
        },
    },

    methods: {
        /**
         * Return user roles
         *
         * @returns {*}
         */
        userRoles() {
            return this.$store.getters['auth/roles'];
        },

        /**
         * Check user has specific role
         *
         * @param role
         * @returns {*}
         */
        userHasRole(role) {
            if (this.userRoles().includes('developer')) {
                return true;
            }
            
            return this.userRoles().includes(role);
        },

        /**
         * Register global modals into store
         *
         * @param modals
         */
        registerGlobalModals(modals = []) {
            this.$store.dispatch('modals/register', modals);
        },

        /**
         * Open the modal in panel by id of modal
         *
         * @param id
         * @param params
         */
        openGlobalModal(id, params = {}) {
            this.$store.dispatch('modals/open', {
                id,
                params,
            });
        },

        /**
         * Check if modal exists
         *
         * @param id
         */
        hasGlobalModal(id) {
            const modals = this.$store.getters['modals/list'];
            return modals.hasOwnProperty(id);
        },
    },
};
