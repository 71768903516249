<template>
    <div
        v-if="element"
        class="img-wrapper"
        :class="classes"
        :style="{
            width: px(element.width),
            height: px(element.height),

            minWidth: px(element.min_width),
            minHeight: px(element.min_height),

            maxWidth: px(element.max_width),
            maxHeight: px(element.max_height),
        }"
    >
        <div
            class="img-ratio"
            :style="aspectRatio"
        />

        <img
            :src="element.src"
            :alt="element.alt"
            :style="grayScaleStyle"
        >
    </div>
</template>
<script>

    /**
     * Image component
     */
    export default {
        name: 'YImage',

        props: {

            /**
             * Element
             */
            element: Object,

        },

        computed: {
            /**
             * Gets a list of classes
             *
             * @returns {*}
             */
            classes() {
                const classes = [];

                if (this.element.contain) {
                    classes.push('contain');
                }

                if (this.element.isThumbnail) {
                    classes.push('thumbnail');
                }

                if (this.element.ratio) {
                    classes.push('has-ratio');
                }

                return classes;
            },

            /**
             * Calculate padding based on ratio
             *
             * @returns {null|{paddingBottom: string}}
             */
            aspectRatio() {
                if (this.element.ratio) {
                    return { paddingBottom: `${100 / this.element.ratio}%` };
                }

                return null;
            },

            /**
             * Generate grayscale filter style
             *
             * @returns {null|{filter: string}}
             */
            grayScaleStyle() {
                if (this.element.grayScale) {
                    return { filter: `grayscale(${this.element.grayScale})` };
                }

                return null;
            },
        },

        methods: {

            /**
             * Convert to px
             *
             * @param value
             */
            px(value) {
                // eslint-disable-next-line no-restricted-globals
                if (!isNaN(value)) {
                    return `${value}px`;
                }
                return value;
            },

        },

    };
</script>
