/*-----------------------------------------------------------------
- Verify Modal trans
-----------------------------------------------------------------*/
export default {
    
    label: {
        current    : 'Current Organization Status',
        status     : 'Select Organization Status',
        description: 'Reason: ',
        confirmed  : 'Confirmed',
        rejected   : 'Rejected',
    },
    
    timeline: {
        label      : 'History',
        date       : 'Date',
        status     : 'Organization status changed to {0}.',
        description: 'Reason: {0}',
    },
    
};
