/*-----------------------------------------------------------------
- Content types Routes
-----------------------------------------------------------------*/
const prefix = 'content/type';
const routes = [
    {
        path     : `${prefix}`,
        name     : 'content-type-list',
        component: () => import(/* webpackChunkName: "content" */ '@/modules/content/views/type/List'),
    },
];

export default routes;
