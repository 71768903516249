/*-----------------------------------------------------------------
- Buttons
-----------------------------------------------------------------*/
export default {
    
    load_all        : 'View all Product Items',
    add_ware        : 'Add New Product Item',
    back_to_product : 'Back to Product',
    inventory_change: 'Edit Inventory',
    inventory_grid  : 'Inventory',
    back_to_wares   : 'Back to Product Items',
    clone           : 'Clone',
};
