/*-----------------------------------------------------------------
- Content translations
-----------------------------------------------------------------*/
export default {

    list: {
        title : 'Help',
        search: {
            placeholder: 'Search in help...',
        },
        table: {
            title: {
                no      : '#',
                name    : 'Title',
                action  : 'Actions',
                category: 'Categories',
            },
            view      : 'View',
            noCategory: '-',
        },
    },

};
