/*-----------------------------------------------------------------
- Breadcrumbs translations
-----------------------------------------------------------------*/
export default {
    form: {
        form_maker: 'فرم‌ساز',
        list      : 'فرم‌ها',
        edit      : 'ویرایش فرم',
        create    : 'ایجاد فرم',
        preview   : 'پیش‌نمایش فرم',
        responses : 'پاسخ‌های فرم',
    },
};
