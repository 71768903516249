/*-----------------------------------------------------------------
- Setting Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Setting  List
     *
     * @param {...any} args
     * @returns {*}
     */
    list: (...args) => Resource.get('setting-list', args),
    
    /**
     * Setting  set
     *
     * @param {...any} args
     * @returns {*}
     */
    set: (...args) => Resource.put('setting-set', args),
    
    /**
     * Setting  Fetch
     *
     * @param {...any} args
     * @returns {*}
     */
    fetch: (...args) => Resource.get('setting-fetch', args),
    
    /**
     * Setting  notifier
     *
     * @param {...any} args
     * @returns {*}
     */
    notifier: (...args) => Resource.get('notifier-settings-grid', args),

    /**
     * Settings reset
     * 
     * @param {...any} args
     */
    reset: (...args) => Resource.delete('setting-reset', args),
    
};
