<template>
    <div :class="classes">
        <label
            v-if="shouldShowLabel"
            :for="labelFor"
        >{{ labelValue }}</label>

        <slot />

        <div
            v-if="getValue('help')"
            class="help"
        >
            {{ getValue('help') }}
        </div>
        <div
            v-show="hasError"
            class="help color-red"
        >
            {{ error | digits }}
        </div>
    </div>
</template>

<script>
    import { snakeToCamel } from '@nodes/helpers/string';

    /**
     * Form Field
     */
    export default {
        name: 'FormFieldWrapper',

        inject: {
            $validator: '$validator',
        },

        props: {

            component: {
                type    : String,
                required: true,
            },

            element: {
                type: Object,
            },

            /**
             * Field Name
             */
            name: {
                type   : String,
                default: null,
            },

            /**
             * Field size
             */
            size: String,

            /**
             * Field Label
             */
            label: String,

            /**
             * Is field required
             */
            required: Boolean,

            /**
             * Has no label
             */
            noLabel: Boolean,

            /**
             * Validation
             */
            validation: {}, // eslint-disable-line vue/require-prop-types

            /**
             * Help text
             */
            help: String,

            /**
             * Label for element target
             */
            labelFor: String,

            /**
             * Label value
             */
            labelValue: String,

            /**
             * Check if field has error
             */
            hasError: Boolean,

            /**
             * Error message
             */
            error: String,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                el: null,
            };
        },

        computed: {

            /**
             * return classes string
             *
             * @returns {Array}
             */
            classes() {
                const classes = [];

                if (this.component === 'hidden') {
                    classes.push('d-n');
                }
                if (!this.noWrapper) {
                    classes.push('field');
                }
                if (this.hasError) {
                    classes.push('error');
                }
                if (this.size) {
                    classes.push(this.size);
                }
                if (this.has(this.params, 'css_class')) {
                    classes.push(this.params.css_class);
                }

                if (this.getValue('validation', '').includes('required') || this.getValue('required')) {
                    classes.push('required-label');
                }

                return classes;
            },

            /**
             * Check if label should be shown
             *
             * @returns {*}
             */
            shouldShowLabel() {
                return this.supportLabel
                    && (this.getValue('label') || this.labelValue)
                    && !this.getValue('no_label')
                    && !this.get(this.params, 'no_label', false)
                    && this.labelFor !== null
                    && this.ofType !== 'checkbox';
            },

            /**
             * Check if it's required
             *
             * @returns {*}
             */
            isRequired() {
                return this.getBoolValue('required');
            },

            /**
             * Check is one of registered types
             *
             * @returns {string|null}
             */
            ofType() {
                const generalTypes = [
                    'checkbox', 'checkbox-list', 'radio', 'number', 'array',
                    'textarea', 'select', 'heading', 'divider', 'division', 'editor',
                    'html', 'paragraph', 'color-picker', 'uploader',
                ];

                if (generalTypes.includes(this.component)) {
                    return this.component;
                }

                if (['text', 'email', 'url', 'hidden', 'password'].includes(this.component)) {
                    return 'text';
                }

                if (['date', 'time', 'datetime', 'date-picker'].includes(this.component)) {
                    return 'date-picker';
                }

                return null;
            },

            /**
             * Check if this element supports label
             *
             * @returns {boolean}
             */
            supportLabel() {
                const arr = [
                    'text', 'number', 'email', 'password', 'array',
                    'checkbox', 'radio', 'textarea', 'select',
                    'date-picker', 'date', 'time', 'datetime',
                    'editor', 'uploader', 'color-picker',
                ];

                return arr.includes(this.component);
            },

        },

        methods: {

            /**
             * Get field value
             *
             * @param value
             * @param defaultValue
             * @returns {*}
             */
            getValue(value, defaultValue = null) {
                if (this.params) {
                    return this.params[value] || defaultValue;
                }
                return this[snakeToCamel(value)] || defaultValue;
            },

            /**
             * Get field value as boolean
             *
             * @param value
             * @param default_value
             * @returns {boolean}
             */
            getBoolValue(value, default_value = false) {
                return !!this.getValue(value, default_value);
            },

            /**
             * Get validation
             */
            getValidation() {
                const validations = this.getValue('validation', '').split('|');
                const results = validations.filter((item) => {
                    const rule = item.match(/^([^:]+)/);
                    return rule ? this.$config('validations.rules').includes(rule[1]) : false;
                });
                return results.join('|');
            },
        },

    };
</script>
