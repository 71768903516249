/*-----------------------------------------------------------------
- Cart trans
-----------------------------------------------------------------*/
export default {
    
    note      : 'Note',
    is_private: 'Private',
    reason    : 'Reason',
    
    order_user_search: 'Search user (name, phone, email)',
    user             : 'User',
    address          : 'Address',
    
    raised_at : 'Creation date',
    expires_at: 'Validity date',

    damaged        : 'Is it damaged?',
    damaged_reason : 'Damage Description',
    count          : 'Count',
    recycled_amount: 'Recycling Amount',
    
    comment: 'Comment',
    
    search_code : 'Search Code',
    search_order: 'Search Order',
    
    payment_management: {
        methods        : 'Method',
        paths          : 'Paths',
        wallets        : 'Wallets',
        tracking_number: 'Tracking Number',
        effected_at    : 'Date',
        amount         : 'Amount',
        type           : 'Type',
        receipt        : 'Receipt',
    },

    shipping_method: 'Shipping Method',
    shipping_date  : 'Shipping Date',
    shipping_time  : 'Shipping Time',
};
