/*-----------------------------------------------------------------
- Errors translations
-----------------------------------------------------------------*/
export default {

    404: {
        title : 'صفحه‌ای که دنبال آن می‌گردید، وجود ندارد!',
        button: 'بازگشت به صفحه‌ی اصلی',
    },

    500: {
        title : 'مشکلی در پاسخگویی سرور به وجود آمده است!',
        button: 'بارگذاری مجدد',
    },

    upload: {
        common: 'مشکلی در آپلود به وجود آمده است.',
    },
};
