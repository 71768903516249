/*-----------------------------------------------------------------
- Notifier Services
-----------------------------------------------------------------*/
import { Resource } from '@nodes/services';

export default {
    
    /**
     * Get user Notifications
     *
     * @param {...any} args
     * @returns {*}
     */
    userNotifications: (...args) => Resource.get('notifier-user-notifications', args),
};
