<template>
    <div
        id="sidebar"
        :class="{collapsed}"
        @mouseleave="onMouseLeave"
    >
        <y-loading
            class="loading"
            :active="isLoading"
        >
            <ul id="sidebar-menu">
                <!-- Sidebar main items -->
                <panel-menu-item
                    v-for="(item) in sidebar"
                    :key="`sidebar-item-${item.id}`"
                    :item="item"
                    :sidebar-collapsed="collapsed"
                />

                <!-- Settings -->
                <panel-menu-item
                    v-if="hasSettings"
                    :key="settingsItems.id"
                    :item="settingsItems"
                    :sidebar-collapsed="collapsed"
                />

                <!-- Preview page -->
                <!-- <template v-if="(mode !== 'production') && isDeveloper && ($config('theme.modules.all') || $config('theme.modules.developer-tools', false))">
                    <panel-menu-item
                        v-for="(item, index) in developerTools"
                        :key="`sidebar-item-developer-${index}`"
                        :item="item"
                        :sidebar-collapsed="collapsed"
                    />
                </template> -->
            </ul>
        </y-loading>
    </div>
</template>

<script>

    import PanelMenuItem from '@deps/PanelMenuItem';

    export default {

        name: 'Sidebar',

        components: {
            PanelMenuItem,
        },

        props: {
            collapsed: Boolean,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                isLoading: !this.$store.getters['layout/isLayoutLoaded'],

                items   : this.$store.getters['layout/sidebar'],
                settings: this.$store.getters['layout/sidebarSettings'],

                developerTools: [
                    {
                        component_name: 'panel-item',
                        id            : 'developer-tools',
                        label         : this.$t('dashboard.sidebar.developerTools'),
                        type          : 'item',
                        icon          : 'md-eye',
                        link          : [],
                        children      : [
                            {
                                component_name: 'panel-item',
                                id            : 'developer-tools-preview',
                                label         : this.$t('dashboard.sidebar.preview'),
                                type          : 'item',
                                link          : {
                                    name: 'developer-tools-preview',
                                },
                            },
                            {
                                component_name: 'panel-item',
                                id            : 'developer-tools-components-list',
                                label         : this.$t('dashboard.sidebar.components'),
                                type          : 'item',
                                link          : {
                                    name: 'developer-tools-components-list',
                                },
                            },
                            {
                                component_name: 'panel-item',
                                id            : 'developer-tools-lab',
                                label         : this.$t('dashboard.sidebar.lab'),
                                type          : 'item',
                                link          : {
                                    name: 'developer-tools-lab',
                                },
                            },
                            {
                                component_name: 'panel-item',
                                id            : 'developer-tools-info',
                                label         : this.$t('dashboard.sidebar.info'),
                                type          : 'item',
                                link          : {
                                    name: 'developer-tools-info',
                                },
                            },
                            {
                                component_name: 'panel-item',
                                id            : 'developer-tools-routes',
                                label         : this.$t('dashboard.sidebar.routes'),
                                type          : 'item',
                                link          : {
                                    name: 'developer-tools-routes',
                                },
                            },
                        ],
                    },
                ],

            };
        },

        computed: {
            /**
             * Return consistent array of sidebar items between renders
             */
            sidebarItems() {
                return Object.values(this.items);
            },

            /**
             * Sort and filter sidebar menu
             *
             * @returns {*}
             */
            sidebar() {
                if (this.sidebarItems) {
                    const menu = this.collapsed
                        ? this.sidebarItems.filter((item) => item.id !== 'dashboard')
                        : this.sidebarItems;
                    return menu.sort((a, b) => a.order - b.order);
                }

                return [];
            },

            /**
             * Check if settings has any item
             */
            hasSettings() {
                return !!(this.settings && Object.keys(this.settings).length);
            },

            /**
             * Settings items on sidebar
             */
            settingsItems() {
                const children = this.hasSettings ? Object.values(this.settings) : [];
                return {
                    component_name: 'panel-item',
                    id            : 'panel-item-settings2',
                    label         : this.$t('dashboard.sidebar.settings'),
                    type          : 'item',
                    icon          : 'md-cogs',
                    link          : '#',
                    children,
                };
            },

            /**
             * Check the run mode
             */
            mode() {
                return process.env.NODE_ENV;
            },
        },

        watch: {
            /**
             * Watch items of sidebar
             */
            '$store.state.layout.layout.sidebar': function () { // eslint-disable-line func-names
                this.items = this.$store.getters['layout/sidebar'];
            },

            /**
             * Watch items of settings
             */
            '$store.state.layout.layout.sidebarSettings': function () { // eslint-disable-line func-names
                this.settings = this.$store.getters['layout/sidebarSettings'];
            },

            /**
             * Watch loading status of layout
             */
            '$store.state.layout.isLayoutLoaded': function () { // eslint-disable-line func-names
                this.isLoading = !this.$store.getters['layout/isLayoutLoaded'];
            },
        },

        /**
         * @inheritdoc
         */
        methods: {
            /**
             * Emit event when sidebar being closed in collapsed mode
             */
            onMouseLeave() {
                if (this.collapsed) {
                    this.$root.$emit('closeSidebarMenus');
                }
            },
        },

    };
</script>
