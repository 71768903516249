/*-----------------------------------------------------------------
- Auth trans
-----------------------------------------------------------------*/

export default {
    
    page_title: {
        login   : 'Login',
        logout  : 'Logout',
        register: 'Register',
        forget  : 'Recover Password',
        otp     : 'Log with OTP',
    },
    
    username: 'Enter your mobile or email.',
    send    : 'Send',
    verify  : 'Verify',
    
    google: {
        verify_text: 'Enter Google Authenticator active code or one of your backup codes.',
    },
    
    messages: {
        request_token: {
            success: {
                email : 'Check your email for reset password token.',
                mobile: 'Check your mobile for reset password token.',
            },
        },
        
        verify_token: {
            success: 'Please set your new password.',
        },
        
        reset_password: {
            success: 'New password set successfully.',
        },
    },

    get_code: {
        resend              : 'Resend',
        time_to_expire_token: 'Time to token expiration: {0}',
        token               : 'Token has expired.',
    },

    token_channel: {
        sent_to: 'Token will be sent to the {0}.',
    },

    channels: {
        sms  : 'SMS',
        email: 'Email',
    },

    otp: {
        title      : 'Log with OTP',
        otp_request: 'Request OTP code',
        go_register: 'Please sign up.',
        channels   : {
            pattern   : 'Pattern SMS',
            mail      : 'Email',
            two_factor: 'Two-step',
        },
    },
};
