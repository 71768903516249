/*-----------------------------------------------------------------
- Market trans
-----------------------------------------------------------------*/
export default {
    
    settings    : 'قوانین فروش {0}',
    settings_msg: 'قوانین فروش',
    
    product_tagging: {
        title       : 'انتخاب دسته‌ها و ویژگی‌ها',
        empty_option: 'ویژگی پیدا نشد.',
        empty_levels: 'دسته‌ای پیدا نشد.',
    },
    
    product: {
        title: 'محصول',
        
        create         : 'افزودن محصول',
        createWithTitle: 'افزودن محصول: {0}',
        edit           : 'ویرایش محصول: {0}',
        
        availability_status     : 'وضعیت موجودی',
        wares_count             : 'تعداد کالا',
        ware_available_count    : 'تعداد کالاهای فعال',
        ware_not_available_count: 'تعداد کالاهایی که در دسترس فروش نیستند',
        
        availability_true : 'فعال',
        availability_false: 'غیر‌فعال',

        salable  : 'قابل فروش',
        unsalable: 'غیر‌قابل فروش',
        
        is_available_toast: 'تغییر قابلیت فروش کالای {0} با موفقیت انجام شد.',
    },
    
    wares: {
        manage      : 'مدیریت کالاها',
        subtitle    : 'محصول: {0}',
        empty       : 'کالایی یافت نشد. اولین را شما بسازید.',
        create      : 'افزودن کالا',
        edit        : 'ویرایش کالا: {0}',
        edit_title  : 'ویرایش کالا',
        create_modal: 'افزودن کالا',
        edit_modal  : 'ویرایش کالا',
        type        : 'کالا',
        sort        : 'مرتب‌سازی با موفقیت ذخیره شد.',
        change_price: 'ویرایش قیمت',
        
        current_inventory      : 'موجودی',
        sale_count             : 'تعداد فروش',
        purchase_price_average : 'میانگین قیمت خرید',
        original_price_average : 'میانگین قیمت فروش',
        original_price         : 'قیمت فروش',
        original_price_with_tax: 'قیمت فروش با مالیات',
        sale_price             : 'قیمت حراج',
        sale_price_with_tax    : 'قیمت حراج با مالیات',
        sale_price_expires_at  : 'زمان انقضا',
        supply_type            : 'نوع عرضه',
        unit                   : 'واحد عرضه',
        currency               : 'ارز',
        
        unique_wares: {
            label: 'کدهای منحصر به فرد',
            alert: 'برای ثبت مشخصات کالاهای منحصر به فرد، ابتدا موجودی را مشخص کنید.',
        },
        
        cloner: {
            ware : 'انتخاب کالا',
            count: 'تعداد',
            fee  : 'قیمت',
        },

        message: {
            trash  : 'کالا با موفقیت حذف شد.',
            restore: 'کالا با موفقیت بازیافت شد.',
        },
    },
    
    units: {
        gr: 'گرم',
        cm: 'سانتی‌متر',
    },
    
    inventory_type: {
        standalone: 'مستقل',
        related   : 'وابسته',
        unique    : 'منحصر به فرد',
    },
    
    inventory_unique_ware: 'کد کالاهای منحصر به فرد',
    inventory_unique_code: {
        searched: 'کد‌های موجود',
        selected: 'کد‌های انتخاب شده',
    },
    
    seller_info_button: {
        text  : 'تکمیل اطلاعات فروشنده',
        button: 'اطلاعات فروشنده',
    },
    
};
